import React from 'react';
import * as R from 'ramda';
import { Chip } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
const AssessmentScoreChip = ({ record, scoreSource, cutScoreSource }) => {
  const score = R.pathOr(null, R.split('.', scoreSource), record);
  const cutScore = R.pathOr(0, R.split('.', cutScoreSource), record);
  const isPassed = R.ifElse(R.equals(0), R.gt(score), R.gte(score))(cutScore);

  const scoreStyle = isPassed
    ? { color: 'black', backgroundColor: green[400] }
    : { color: 'white', backgroundColor: red[900] };

  return R.is(Number, score) ? <Chip label={score} style={scoreStyle} /> : null;
};

export default AssessmentScoreChip;

import * as React from 'react';
import { memo, useState } from 'react';
import * as R from 'ramda';
import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { useListFilterContext } from 'react-admin';
import { shallowEqual } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: '2em',
  },
  listItemText: {
    margin: 0,
  },
}));

const FilterListItemMulti = (props) => {
  const { label } = props;
  const value = R.map(R.of, props.value);
  const { filterValues, setFilters } = useListFilterContext();
  const [isSelected, setSelected] = useState(
    R.pipe(
      R.keys,
      R.find(R.includes(R.__, R.keys(props.value))),
      R.ifElse(R.isNil, R.F, (key) =>
        R.includes(props.value[key], filterValues[key])
      )
    )(filterValues)
  );
  const classes = useStyles(props);

  const addFilter = () => {
    const newFilter = R.mergeWith(
      R.pipe(R.concat, R.uniq),
      filterValues,
      value
    );
    setFilters(newFilter, null, false);
  };

  const removeFilter = () => {
    const newFilter = R.mergeWith(R.without, value, filterValues);
    setFilters(newFilter, null, false);
  };

  const toggleFilter = () => {
    if (isSelected) {
      setSelected(false);
      removeFilter();
    } else {
      setSelected(true);
      addFilter();
    }
  };

  return (
    <ListItem
      button
      onClick={toggleFilter}
      selected={isSelected}
      className={classes.listItem}
    >
      <ListItemText primary={label} className={classes.listItemText} />
      {isSelected && (
        <ListItemSecondaryAction>
          <IconButton size="small" onClick={toggleFilter}>
            <CancelIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const arePropsEqual = (prevProps, nextProps) =>
  prevProps.label === nextProps.label &&
  shallowEqual(prevProps.value, nextProps.value);

export default memo(FilterListItemMulti, arePropsEqual);

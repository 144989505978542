import React from 'react';
import * as R from 'ramda';

import {
  BooleanField,
  FunctionField,
  Labeled,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';
import { Grid } from '@material-ui/core';

import { SalaryField } from 'resources/students/fields';
import { DateField, NiceTextField } from 'resources/common';
import {
  outcomeContractLength,
  outcomeJobSource,
  outcomeWeeklyHours,
} from 'data';

const JobFields = ({ student, basePath }) => {
  return (
    <Grid container>
      <Grid item xs={12} lg={2}>
        <Labeled label="Offer Date">
          <DateField
            record={student}
            source="career_services.outcome.offer_date"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Company">
          <TextField
            record={student}
            source="career_services.outcome.company"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Job Title">
          <NiceTextField
            record={student}
            source="career_services.outcome.job_title"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Hours/Week">
          <SelectField
            record={student}
            source="career_services.outcome.weekly_hours"
            choices={outcomeWeeklyHours}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Location">
          <ReferenceField
            record={student}
            basePath={basePath}
            reference="data"
            source="career_services.outcome.location.country"
            link={false}
          >
            <FunctionField
              label="Name"
              render={(location) =>
                R.pipe(
                  R.pathOr('', [
                    'career_services',
                    'outcome',
                    'location',
                    'region',
                  ]),
                  R.unless(R.isEmpty, R.concat(R.__, ', ')),
                  R.concat(R.__, location.name),
                  R.replace(/(^|[\s-])\S/g, R.toUpper)
                )(student)
              }
            />
          </ReferenceField>
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Contract Length">
          <SelectField
            record={student}
            source="career_services.outcome.contract_length"
            choices={outcomeContractLength}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Internship">
          <BooleanField
            source="career_services.outcome.internship"
            record={student}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Salary">
          <SalaryField
            amount={R.path(
              ['career_services', 'outcome', 'salary', 'amount'],
              student
            )}
            currency={R.path(
              ['career_services', 'outcome', 'salary', 'currency'],
              student
            )}
            type={R.path(
              ['career_services', 'outcome', 'salary', 'salary_type'],
              student
            )}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Job Source">
          <SelectField
            record={student}
            source="career_services.outcome.job_source"
            choices={outcomeJobSource}
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default JobFields;

import React from 'react';
import { Typography } from '@material-ui/core';
import * as R from 'ramda';
import moment from 'moment';

const JobSearchStartDate = ({ record }) => {
  const startDate = R.pathOr(
    [],
    ['career_services', 'job_search_start', 'date'],
    record
  );

  return (
    <Typography component="span" variant="body2">
      {moment.utc(startDate).format('DD MMM YYYY')}
    </Typography>
  );
};

JobSearchStartDate.defaultProps = {
  source: 'career_services.job_search_start.date',
  label: 'Job search start date',
};

export default JobSearchStartDate;

import { stringify } from 'query-string';
import * as R from 'ramda';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const dropProvider = {
  create: async (params) => {
    const url = `${apiUrl}/students/${params.data.student}/drop`;
    const options = {
      method: 'POST',
      body: JSON.stringify(R.omit(['student'], params.data)),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
  getManyReference: async (params) => {
    const options = {
      method: 'POST',
      body: JSON.stringify(params.search),
      headers: { 'Content-Type': 'application/json' },
    };
    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const sortPrefix =
      R.pathOr('ASC', ['sort', 'order'], params) === 'DESC' ? '-' : '';
    const sort = `${sortPrefix}${R.pathOr('id', ['sort', 'field'], params)}`;
    const query = {
      sort,
      page,
      limit,
    };
    const url = `${apiUrl}/drops/search?${stringify(query)}`;
    return { url, options };
  },
};

export default dropProvider;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  Button,
  Datagrid,
  EditButton,
  Labeled,
  ReferenceManyField,
  ShowController,
  ShowView,
  Tab,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import { Delete, Add } from '@material-ui/icons';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { authServer } from 'auth/keycloak';
import { ChangesCodeBlock, DateField, NavigableTabs } from 'resources/common';
import {
  SalesforceField,
  BookmarkField,
} from 'resources/common/cohorts/list/fields';
import {
  AttendanceTab,
  CareerhackProgressTab,
  InformationTab,
  ActionsTab,
  OneOnOnesTab,
  OutcomesSurveysTab,
  ProgressTab,
  SkillsTab,
  StudentsTab,
  SurveysTab,
} from './tabs';
import AddStudentsDialog from './AddStudentsDialog';

const CohortB2bTitle = ({ record }) =>
  `${record.campus.toUpperCase()} ${record.track.toUpperCase()} ${record.format.toUpperCase()} ${
    record.start_date.split('T')[0]
  } ${record.language.toUpperCase()}`;

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const CohortB2bShowActions = ({ basePath, data, deleteCohort }) => {
  const classes = useStyles();
  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(false);
  return (
    <TopToolbar>
      {data && authServer.isAllRoles(['b2b', 'pm']) && (
        <Box>
          <Button
            onClick={() => setOnboardingDialogOpen(true)}
            startIcon={<Add />}
            label="Add Students"
          />
          <AddStudentsDialog
            onClose={() => setOnboardingDialogOpen(false)}
            open={onboardingDialogOpen}
            record={data}
          />
        </Box>
      )}
      <BookmarkField record={data} size="small" labeled />
      {authServer.isAllRoles(['pm', 'b2b']) && (
        <EditButton basePath={basePath} record={data} />
      )}
      {data && authServer.isAllRoles(['pm', 'b2b']) && (
        <Button
          className={classes.deleteButton}
          label="Delete"
          onClick={deleteCohort(data)}
        >
          <Delete />
        </Button>
      )}
    </TopToolbar>
  );
};
const CohortB2bShow = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const deleteCohort = (cohort) => async () => {
    const result = await dataProvider.getManyReference('students', {
      target: 'cohorts',
      id: cohort.id,
      pagination: { perPage: 1 },
      filter: { Object },
    });
    if (result.total === 0) {
      await dataProvider.delete('cohorts_b2b', {
        id: cohort.id,
      });
      redirect('/cohorts_b2b');
      notify('Cohort deleted');
    } else {
      notify('Cannot delete a cohort with onbaorded students');
    }
  };

  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  return (
    // Forces the max with to allow tabs to be scrollable and avoid horizontal scroll
    <Box sx={{ maxWidth: `calc(100vw - ${isSidebarOpen ? 229 : 84}px)` }}>
      <ShowController {...props}>
        {(controllerProps) => {
          const record = R.propOr({}, 'record', controllerProps);
          return (
            <ShowView
              {...props}
              {...R.omit(['translate'], controllerProps)}
              title={<CohortB2bTitle />}
              actions={<CohortB2bShowActions deleteCohort={deleteCohort} />}
            >
              <NavigableTabs
                matchUrl={R.pipe(R.split('/'), R.take(5), R.join('/'))}
                variant="scrollable"
              >
                <Tab label="Cohort Information" path="info">
                  <InformationTab {...props} />
                </Tab>
                <Tab label="Actions" path="actions">
                  <ActionsTab {...props} />
                </Tab>
                <Tab label="Students" path="students">
                  <StudentsTab rowClick="show" {...props} />
                </Tab>
                {authServer.isRoles(['pm', 'education', 'teacher', 'ta']) ? (
                  <Tab label="Surveys" path="surveys">
                    <SurveysTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['pm', 'education', 'teacher', 'ta']) ? (
                  <Tab label="Prework" path="prework_progress">
                    <ProgressTab course="prework" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['pm', 'ta', 'teacher']) &&
                record.track === 'wd' ? (
                  <Tab label="Skills" path="skills">
                    <SkillsTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['pm', 'education', 'teacher', 'ta']) ? (
                  <Tab label="Course Progress" path="course_progress">
                    <ProgressTab course="course" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['pm']) && record.career_services ? (
                  <Tab label="Progress" path="careerhack_progress">
                    <CareerhackProgressTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['pm', 'teacher', 'ta']) ? (
                  <Tab label="Attendance" path="attendance">
                    <AttendanceTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isNotRoles(['education', 'finance']) ? (
                  <Tab label="One On Ones" path="one_on_ones">
                    <OneOnOnesTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['product']) ? (
                  <Tab label="History" path="history">
                    <ReferenceManyField
                      addLabel={false}
                      source="id"
                      reference="changes"
                      target="id"
                    >
                      <Datagrid rowClick="expand" expand={<ChangesCodeBlock />}>
                        <TextField label="Event" source="event" />
                        <DateField label="Date" source="date" showTime />
                        <TextField label="User" source="user" />
                        <TextField label="App" source="client" />
                      </Datagrid>
                    </ReferenceManyField>
                  </Tab>
                ) : null}
                {authServer.isRoles(['product']) ? (
                  <Tab label="Meta" path="meta">
                    <Box p="16px" display="block">
                      <Box>
                        <Labeled label="Internal Id">
                          <TextField source="id" record={record} />
                        </Labeled>
                      </Box>
                      <Box>
                        <Labeled label="Salesforce Id">
                          <SalesforceField
                            source="external_ids.salesforce"
                            record={record}
                          />
                        </Labeled>
                      </Box>
                    </Box>
                  </Tab>
                ) : null}
              </NavigableTabs>
            </ShowView>
          );
        }}
      </ShowController>
    </Box>
  );
};

export default CohortB2bShow;

import React from 'react';

import { SelectField } from 'react-admin';
import { Chip } from '@material-ui/core';

import {
  careerServiceStatuses,
  careerServiceStatusColors,
} from '../../../data';

const StatusChip = ({ record }) => {
  return (
    <Chip
      style={{
        color: 'white',
        backgroundColor: careerServiceStatusColors[record.id],
      }}
      label={record.name}
    />
  );
};

const OutcomesStatusField = ({ record, source }) => {
  return (
    <SelectField
      record={record}
      source={source}
      choices={careerServiceStatuses}
      optionText={<StatusChip />}
    />
  );
};

OutcomesStatusField.defaultProps = {
  source: 'career_services.status',
  label: 'Outcomes Status',
};

export default OutcomesStatusField;

import React from 'react';
import * as R from 'ramda';
import { Flag } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

const FlagField = ({ record }) => {
  const flagged = R.propOr(false, 'flagged', record);
  return flagged ? <Flag style={{ fill: red[500] }} /> : null;
};

export default FlagField;

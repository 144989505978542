import React from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';

import {
  BooleanInput,
  Create,
  FormWithRedirect,
  Labeled,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Box, Grid } from '@material-ui/core';
import { Redirect } from 'react-router';

import { authServer } from 'auth/keycloak';
import { DateInput } from 'resources/common/DatePickers';
import { FullNameField } from 'resources/students/fields';

const validateRequired = [required()];

const moduleChoices = [
  { id: 1, name: 'Module 1' },
  { id: 2, name: 'Module 2' },
  { id: 3, name: 'Module 3' },
];

const ModuleOneOnOneCreateForm = ({ student, module, redirect, ...props }) => {
  const studentId = student.id;
  const oneOnOnes = R.propOr([], 'one_on_ones', student);
  const pendingModuleChoices = R.pipe(
    R.pluck('module'),
    R.difference([1, 2, 3]),
    R.map((module) => ({ id: module, name: `Module ${module}` }))
  )(oneOnOnes);
  if (!module && R.isEmpty(pendingModuleChoices)) {
    return <Redirect to={`/students/${studentId}/show/one_on_ones}`} />;
  }
  let initialValues = {
    student: studentId,
    date: moment.utc().format('YYYY-MM-DD'),
    interviewer: authServer.getUser(),
  };
  if (module) {
    initialValues = R.merge(
      initialValues,
      R.find(R.propEq('module', module), oneOnOnes)
    );
  }
  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={redirect}
      render={(formProps) => (
        <form>
          <Box p="16px">
            <Grid container>
              <Grid item xs={12} lg={3}>
                <Labeled label="Student">
                  <FullNameField record={student} />
                </Labeled>
                <DateInput
                  label="Date"
                  source="date"
                  options={{
                    format: 'Do MMMM YYYY',
                    disableFuture: true,
                  }}
                  fullWidth
                  variant="standard"
                  validate={validateRequired}
                />
                <TextInput
                  label="Interviewer"
                  source="interviewer"
                  fullWidth
                  variant="standard"
                  validate={validateRequired}
                  disabled={true}
                />
                <SelectInput
                  label="Module"
                  choices={module ? moduleChoices : pendingModuleChoices}
                  source="module"
                  variant="standard"
                  fullWidth
                  disabled={module}
                  validate={validateRequired}
                />
                <Labeled label="Flagged">
                  <BooleanInput
                    label=""
                    source="flagged"
                    variant="standard"
                    fullWidth
                    defaultValue={false}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Box id="quill" ml={{ lg: '2em' }}>
                  <RichTextInput
                    label="Comments"
                    source="comments"
                    options={{ bounds: '#quill' }}
                    toolbar={[['bold', 'italic', 'underline']]}
                    validate={validateRequired}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              {authServer.isNotRoles(['education']) && (
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              )}
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};

const WeeklyOneOnOneCreateForm = ({
  student,
  week,
  cohort,
  redirect,
  ...props
}) => {
  const studentId = student.id;
  const oneOnOnes = R.propOr([], 'one_on_ones', student);

  const weeksNumber = Math.ceil(
    moment(cohort.end_date).diff(moment(cohort.start_date), 'weeks', true)
  );
  const weekChoices = R.range(1, weeksNumber + 1).map((weekNumber) => ({
    id: weekNumber,
    name: `Week ${weekNumber}`,
  }));

  let initialValues = {
    student: studentId,
    date: moment.utc().format('YYYY-MM-DD'),
    interviewer: authServer.getUser(),
  };
  if (week) {
    initialValues = R.merge(
      initialValues,
      R.find(R.propEq('week', week), oneOnOnes)
    );
  }
  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={redirect}
      render={(formProps) => (
        <form>
          <Box p="16px">
            <Grid container>
              <Grid item xs={12} lg={3}>
                <Labeled label="Student">
                  <FullNameField record={student} />
                </Labeled>
                <DateInput
                  label="Date"
                  source="date"
                  options={{
                    format: 'Do MMMM YYYY',
                    disableFuture: true,
                  }}
                  fullWidth
                  variant="standard"
                  validate={validateRequired}
                />
                <TextInput
                  label="Interviewer"
                  source="interviewer"
                  fullWidth
                  variant="standard"
                  validate={validateRequired}
                  disabled={true}
                />
                <SelectInput
                  label="Week"
                  choices={weekChoices}
                  source="week"
                  variant="standard"
                  fullWidth
                  disabled={week}
                  validate={validateRequired}
                />
                <Labeled label="Flagged">
                  <BooleanInput
                    label=""
                    source="flagged"
                    variant="standard"
                    fullWidth
                    defaultValue={false}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Box id="quill" ml={{ lg: '2em' }}>
                  <RichTextInput
                    label="Comments"
                    source="comments"
                    options={{ bounds: '#quill' }}
                    toolbar={[['bold', 'italic', 'underline']]}
                    validate={validateRequired}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              {authServer.isNotRoles(['education']) && (
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              )}
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};

const OneOnOneCreate = (props) => {
  const student = R.pathOr({}, ['location', 'state', 'student'], props);
  const cohort = R.pathOr({}, ['location', 'state', 'cohort'], props);
  const module = R.path(['location', 'state', 'module'], props);
  const week = R.path(['location', 'state', 'week'], props);
  const isWeekly = R.path(['location', 'state', 'isWeekly'], props);
  const redirect = R.path(['location', 'state', 'redirect'], props);

  if (R.isEmpty(student)) {
    return <Redirect to="/students" />;
  }

  return (
    <Create {...props}>
      {isWeekly ? (
        <WeeklyOneOnOneCreateForm
          student={student}
          week={week}
          cohort={cohort}
          redirect={redirect}
        />
      ) : (
        <ModuleOneOnOneCreateForm
          student={student}
          module={module}
          redirect={redirect}
        />
      )}
    </Create>
  );
};

export default OneOnOneCreate;

import React from 'react';
import * as R from 'ramda';

import { Box } from '@material-ui/core';

import { authServer } from 'auth/keycloak';
import {
  CareerServicesPanel,
  PersonalInformationPanel,
  StudentRiskPanel,
} from './panels';

function InformationTab({ record, ...rest }) {
  return (
    <Box px="16px" pt="16px">
      <Box>
        <PersonalInformationPanel record={record} {...rest} />
      </Box>
      {R.hasPath(['performance', 'risk'], record) && (
        <Box>
          <StudentRiskPanel record={record} {...rest} />
        </Box>
      )}
      {authServer.isRoles([
        'gm',
        'pm',
        'outcomes',
        'teacher',
        'ta',
        'education',
        'campus_lead',
      ]) ? (
        <Box>
          <CareerServicesPanel record={record} {...rest} />
        </Box>
      ) : null}
    </Box>
  );
}

export default InformationTab;

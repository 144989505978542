import React from 'react';
import Typography from '@material-ui/core/Typography';
import * as R from 'ramda';
import moment from 'moment-timezone';

const DateField = ({
  record = {},
  source,
  format,
  showTime = false,
  disabled = false,
  timezone = 'Etc/UTC',
}) => {
  const date = R.pipe(R.split('.'), R.pathOr('', R.__, record))(source);
  const defaultFormat = showTime ? 'Do MMMM YYYY HH:mm:ss' : 'Do MMMM YYYY';
  const dateFormat = format ? format : defaultFormat;
  const formattedDate = R.isEmpty(date)
    ? date
    : moment(date).tz(timezone).format(dateFormat);

  const style =
    disabled || (R.has('timeline', record) && record.timeline !== 'today')
      ? { color: 'rgba(0,0,0,0.38)' }
      : {};

  return (
    <Typography component="span" variant="body2" style={style}>
      {formattedDate}
    </Typography>
  );
};

export default DateField;

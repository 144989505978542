import React from 'react';
import * as R from 'ramda';

import { NewProgressField } from 'resources/common';

const LabsProgressField = ({ record: student, course }) => {
  const labs = R.pathOr([], [course, 'labs'], student);
  const isRequired = R.propEq('required', true);
  const requiredLabs = R.when(R.any(isRequired), R.filter(isRequired))(labs);
  const total = R.length(requiredLabs);
  const completed = R.pipe(R.filter(R.has('attempts')), R.length)(requiredLabs);
  const text = `${completed}/${total}`;
  const progress = R.defaultTo(0, Math.round((100 * completed) / total));
  return <NewProgressField value={progress} text={text} />;
};

LabsProgressField.defaultProps = {
  label: 'Labs Progress',
};

export default LabsProgressField;

import React from 'react';
import * as R from 'ramda';
import { ArrayField, Datagrid } from 'react-admin';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  Link,
  Redirect,
  Route,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import moment from 'moment-timezone';

import { surveyQuestions } from 'data';
import { authServer } from 'auth/keycloak';
import { FullNameField } from 'resources/students/fields';
import { QuestionField } from '../fields';
import { QuestionMetrics } from '../components';

const shouldShow = R.curryN(2, (question, survey) => {
  return R.cond([
    [R.equals('prework'), R.always(survey.survey === '1')],
    [R.equals('overall_experience'), R.always(R.prop('is_project', survey))],
    [R.T, R.T],
  ])(question);
});

function ByQuestionTab({ record: cohort, students }) {
  let filteredQuestions = surveyQuestions;
  const isInstructionalStaff =
    authServer.isRoles(['teacher', 'ta']) && !authServer.isRoles(['admin']);

  const teacherHiddenQuestions = ['comments'];
  if (isInstructionalStaff) {
    filteredQuestions = R.reject(
      R.propSatisfies(R.contains(R.__, teacherHiddenQuestions), 'id'),
      surveyQuestions
    );
  }
  const isB2b = R.hasPath(['company'], cohort);
  const hasPrework = R.hasPath(['prework'], cohort);
  const b2bHiddenQuestions = [
    'v2_nps_reason_promoter',
    'v2_nps_reason_detractor',
    isB2b && !hasPrework && 'prework',
  ];

  if (isB2b) {
    filteredQuestions = R.reject(
      R.propSatisfies(R.includes(R.__, b2bHiddenQuestions), 'id'),
      surveyQuestions
    );
  }

  const now = moment.utc();
  const sentSurveys = R.pipe(
    R.propOr([], 'weekly_surveys'),
    R.filter((survey) => now.isAfter(survey.date))
  )(cohort);

  const match = useRouteMatch();
  const location = useLocation();

  if (location.pathname.endsWith('question')) {
    return <Redirect to={`${match.url}/ironhack`} />;
  }

  return (
    <Route
      path={`${match.url}/:question`}
      render={(routeProps) => {
        const question = routeProps.match.params.question;
        return (
          <Box display="block">
            <Box display="flex" m="16px" width="25%">
              <FormControl fullWidth>
                <Typography variant="h5" component="h3" gutterBottom>
                  Question
                </Typography>
                <Select label="Select Question" value={question}>
                  {R.map(
                    (question) => (
                      <MenuItem
                        key={question.id}
                        value={question.id}
                        component={Link}
                        to={`${match.url}/${question.id}`}
                      >
                        {R.cond([
                          [
                            R.equals('instructional_staff'),
                            R.always('Instructor Experience (old)'),
                          ],
                          [
                            R.equals('teacher_assistant'),
                            R.always('Teacher Assistant (new)'),
                          ],
                          [
                            R.equals('lead_teacher'),
                            R.always('Lead Teacher (new)'),
                          ],
                          [R.T, R.always(question.short_name)],
                        ])(question.id)}
                      </MenuItem>
                    ),
                    filteredQuestions
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box mt="4em">
              <QuestionMetrics cohortId={cohort.id} question={question} />
            </Box>
            {!isInstructionalStaff && (
              <>
                <Box mx="16px" mt="4em">
                  <Typography variant="h5" gutterBottom>
                    Students Answers
                  </Typography>
                </Box>
                <ArrayField
                  record={{ students }}
                  source="students"
                  basePath="/students"
                >
                  <Datagrid question={question}>
                    <FullNameField sortable={false} link />
                    {R.map((sentSurvey) => {
                      const surveyNumber = parseInt(sentSurvey.survey);
                      return shouldShow(question, sentSurvey) ? (
                        <QuestionField
                          label={`Survey ${surveyNumber}`}
                          questionId={question}
                          surveyNumber={`${surveyNumber}`}
                        />
                      ) : null;
                    }, sentSurveys)}
                  </Datagrid>
                </ArrayField>
              </>
            )}
          </Box>
        );
      }}
    />
  );
}

export default ByQuestionTab;

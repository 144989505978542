import React from 'react';
import * as R from 'ramda';
import { parse } from 'query-string';
import {
  Create,
  email,
  Error,
  Loading,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useQuery,
  useRedirect,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

import { authServer } from 'auth/keycloak';

const useStyles = makeStyles({
  first_name: { display: 'inline-block' },
  middle_name: { display: 'inline-block', marginLeft: 32 },
  last_name: { display: 'inline-block', marginLeft: 32 },
  email: { width: 832 },
  cohort: { width: 832 },
});

const validateRequired = [required()];
const validateEmail = [required(), email()];

const StudentCreateToolbar = (props) => (
  <Toolbar {...props}>{authServer.isRoles(['pm']) && <SaveButton />}</Toolbar>
);

const StudentCreate = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const searchParams = R.pipe(
    R.pathOr('', ['location', 'search']),
    parse,
    R.defaultTo({}),
    R.evolve({ sf: R.equals('true') })
  )(props);
  const cohortId = R.propOr('', 'cohort', searchParams);
  if (!R.test(/^[a-f\d]{24}$/, cohortId)) {
    redirect('/students');
  }
  const { data: cohort, loading, error } = useQuery({
    type: 'getOne',
    resource: 'cohorts',
    payload: { id: cohortId },
  });
  const formRedirect = cohortId ? `/cohorts/${cohortId}/show/1` : 'list';
  // Validation
  const emailCache = {};
  const dataProvider = useDataProvider();
  const validateExistingUser = async function (email) {
    if (!R.has(email, emailCache)) {
      const result = await dataProvider.getList('students', {
        filter: { email_search: email },
        pagination: {},
      });
      emailCache[email] = !R.isEmpty(result.data);
    }
    return emailCache[email];
  };

  const validateStudentCreation = async (values) => {
    const errors = {};

    const isIronhackEmail = R.includes(
      '@ironhack.com',
      R.propOr('', 'email', values)
    );
    if (isIronhackEmail) {
      errors.email = `Are you an employee pretending to be a student? This really harms our data! Use preview.my.ironhack.com or contact the Product Team!`;
    }

    if (!isIronhackEmail) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const invalidFormat = !R.test(emailRegex, email);
      if (invalidFormat) {
        errors.email = 'The email format is not valid.';
      }
      if (!isIronhackEmail && !invalidFormat) {
        const emailExists = await validateExistingUser(email);
        if (emailExists) {
          errors.email = 'A student with this email already exists!';
        }
      }
    }
  };

  if (loading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (error)
    return (
      <Box p="16px">
        <Error />
      </Box>
    );
  if (!cohort) return null;
  if (cohort.track !== 'other') {
    redirect(`/cohorts/${cohortId}/show`);
  }
  return (
    <Create actions={null} undoable={false} {...props}>
      <SimpleForm
        redirect={formRedirect}
        toolbar={<StudentCreateToolbar />}
        initialValues={{ cohort: cohort.id }}
        validate={validateStudentCreation}
        variant="standard"
      >
        <TextInput
          source="first_name"
          formClassName={classes.first_name}
          validate={validateRequired}
        />
        <TextInput
          source="last_name"
          formClassName={classes.last_name}
          validate={validateRequired}
        />
        <TextInput
          type="email"
          source="email"
          label="Email"
          fullWidth={true}
          formClassName={classes.email}
          validate={validateEmail}
          parse={R.toLower}
        />
      </SimpleForm>
    </Create>
  );
};
export default StudentCreate;

import Keycloak from 'keycloak-js';
import * as R from 'ramda';

const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM;
const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
const keycloakSSLRequired = process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED;
const keycloakResource = process.env.REACT_APP_KEYCLOAK_RESOURCE;
const keycloakPublicClient = true;
const keycloakConfidentialPort = 0;
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const keycloakOnLoad = process.env.REACT_APP_KEYCLOAK_ON_LOAD;

const authServer = Keycloak({
  realm: keycloakRealm,
  url: keycloakUrl,
  'ssl-required': keycloakSSLRequired,
  resource: keycloakResource,
  'public-client': keycloakPublicClient,
  'confidential-port': keycloakConfidentialPort,
  clientId: keycloakClientId,
});

const initAuthServer = () => {
  return authServer.init({ onLoad: keycloakOnLoad, checkLoginIframe: false });
};

const parseRoles = () => {
  let roles = R.pipe(
    R.pathOr([], ['realmAccess', 'roles']),
    R.filter(R.includes(':')),
    R.groupBy(R.pipe(R.split(':'), R.head)),
    R.map(R.map(R.pipe(R.split(':'), R.last))),
    R.when(R.isEmpty, R.always({ role: [], campus: [] }))
  )(authServer);
  authServer.roles = roles;

  authServer.getUser = () => authServer.tokenParsed.email;

  authServer.getRoles = () => roles.role;
  authServer.getCampuses = () => roles.campus;

  authServer.hasRoles = () => !R.isEmpty(roles.role);

  authServer.isRoles = (rolesToCheck) =>
    !R.isEmpty(R.intersection(rolesToCheck, roles.role));

  authServer.isAllRoles = (rolesToCheck) =>
    R.all(R.includes(R.__, roles.role), rolesToCheck);

  authServer.isNotRoles = (rolesToCheck) =>
    R.includes('admin', roles.role)
      ? true
      : R.isEmpty(R.intersection(rolesToCheck, roles.role));

  authServer.isCampuses = (campusesToCheck) =>
    !R.isEmpty(R.intersection(campusesToCheck, roles.campus));

  authServer.isRolesAndCampuses = (roles, campuses) =>
    authServer.isRoles(roles) && authServer.isCampuses(campuses);
};
export { authServer, initAuthServer, parseRoles };

import React from 'react';
import * as R from 'ramda';

import { NewProgressField } from 'resources/common';

const ScoredOutcomesProgressField = ({ record: student }) => {
  const ratio = R.propOr(0, 'scored_outcomes_ratio', student);
  const progress = R.defaultTo(0, Math.round(100 * ratio));
  const text = `${progress}%`;
  return <NewProgressField value={progress} text={text} />;
};

export default ScoredOutcomesProgressField;

import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';

export default ({ data = [] }) => {
  return (
    <ResponsiveRadar
      data={data}
      keys={['Score']}
      indexBy="subskill"
      maxValue="100"
      margin={{ top: 0, right: 80, bottom: 0, left: 80 }}
      curve="linearClosed"
      borderWidth={2}
      borderColor={{ from: 'color' }}
      gridLevels={5}
      gridShape="circular"
      gridLabelOffset={36}
      enableDots={true}
      dotSize={10}
      dotColor={{ theme: 'background' }}
      dotBorderWidth={2}
      dotBorderColor={{ from: 'color' }}
      enableDotLabel={true}
      dotLabelYOffset={-12}
      colors={{ scheme: 'nivo' }}
      fillOpacity={0.25}
      blendMode="multiply"
      animate={true}
      motionConfig="wobbly"
      isInteractive={true}
      gridLabel={LabelComponent}
      dotLabel={({ value }) => (value === 'Not Assessed' ? '' : value)}
      theme={{
        dots: {
          text: {
            fontSize: '12',
          },
        },
      }}
    />
  );
};

const offsetByAnchor = {
  start: {
    x: () => -30,
    y: (y) => (Math.abs(y) > 1 ? Math.pow(-1, Number(y > 0)) * -10 : y),
  },
  middle: {
    x: (id) => -3 + -2.8 * id.length,
    y: (y) => (Math.abs(y) > 1 ? Math.pow(-1, Number(y > 0)) * 10 : y),
  },
  end: {
    x: (id) => -1 * Math.pow(id.length, 1.5),
    y: (y) => (Math.abs(y) > 1 ? Math.pow(-1, Number(y > 0)) * -10 : y),
  },
};

function LabelComponent(props) {
  let { id, anchor, y } = props;
  id = anchor === 'middle' ? id : truncate(id);
  const xOffset = offsetByAnchor[anchor].x(id);
  const yOffset = offsetByAnchor[anchor].y(y);
  return (
    <g transform={`translate(${xOffset},${yOffset})`}>
      <text fontSize="12">{id}</text>
    </g>
  );
}

function truncate(subskill) {
  return subskill.length > 16 ? subskill.substring(0, 13) + '...' : subskill;
}

import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  Datagrid,
  Error,
  FunctionField,
  Loading,
  useMutation,
  useNotify,
  useQuery,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

import { ScoreInput } from 'resources/cohorts/inputs';
import { FullNameField } from 'resources/students/fields';
import { OfflineList, ChipField } from 'resources/common';
import { ScoredOutcomesProgressField } from 'resources/common/cohorts/list/fields';

import {
  rubricProjects,
  rubricOutcomes,
  outcomesDomains,
} from '../../../../../../data';

const useListStyles = makeStyles({
  scored_outcomes: { width: 250 },
  score_average: { width: 100 },
});

const OutcomesList = ({ record: student, onStudentOutcomeScore }) => {
  const ScoreOutcomeInput = ({ record: outcome, onOutcomeScore }) => {
    return (
      <ScoreInput
        defaultScore={outcome.score}
        onScore={async (newScore) => {
          await onOutcomeScore(outcome.id, newScore);
        }}
      />
    );
  };

  return (
    <OfflineList list={student.project_outcomes} uniqueKey="id">
      <Datagrid>
        <FunctionField
          label="Intended Learning Outcome"
          render={({ id }) => R.path([id, 'description'], rubricOutcomes)}
        />
        <FunctionField
          label="Domains"
          render={({ domains }) =>
            R.pipe(
              R.map((domain) => R.path([domain, 'name'], outcomesDomains)),
              R.join(', ')
            )(domains)
          }
        />
        <ScoreOutcomeInput
          onOutcomeScore={async (outcomeId, score) =>
            await onStudentOutcomeScore(student.id, outcomeId, score)
          }
        />
      </Datagrid>
    </OfflineList>
  );
};

const StudentList = ({ record: project, course, apiStudents }) => {
  const notify = useNotify();
  const classes = useListStyles();
  const [students, setStudents] = useState([]);

  const getAdaptedStudents = useCallback(
    (students) =>
      R.map((student) => {
        const scoredOutcomes = R.map((outcome) => {
          const score = R.pipe(
            R.pathOr([], [course, 'projects']),
            R.find(R.propEq('name', project.name)),
            R.path(['outcomes', outcome.id, 'score'])
          )(student);
          return Number.isInteger(score)
            ? R.assoc('score', score, outcome)
            : outcome;
        })(project.outcomes);
        const scores = R.pipe(
          R.pluck('score'),
          R.values,
          R.reject(R.isNil)
        )(scoredOutcomes);
        return {
          ...student,
          project_outcomes: scoredOutcomes,
          outcomes_score_average:
            Math.round((R.mean(scores) + Number.EPSILON) * 100) / 100,
          scored_outcomes_ratio: scores.length / scoredOutcomes.length,
        };
      })(students),
    [course, project.name, project.outcomes]
  );

  useEffect(() => {
    if (apiStudents) {
      const adaptedStudents = getAdaptedStudents(apiStudents);
      setStudents(adaptedStudents);
    }
  }, [apiStudents, getAdaptedStudents]);

  const [mutate] = useMutation();
  const onStudentOutcomeScore = (studentId, outcomeId, newScore) => {
    return mutate(
      {
        type: 'update',
        resource: 'project_outcomes',
        payload: {
          id: studentId,
          data: {
            course,
            projectName: project.name,
            outcomeId: outcomeId,
            score: newScore,
          },
        },
      },
      {
        returnPromise: true,
        onSuccess: ({ data: updatedStudent }) => {
          const updatedStudentsWithScoring = R.pipe(
            R.concat([updatedStudent]),
            R.uniqBy(R.prop('id')),
            getAdaptedStudents
          )(students);
          setStudents(updatedStudentsWithScoring);
          notify('Outcome Graded');
        },
        onFailure: () => {
          notify('Could not grade the outcome', 'warning');
        },
      }
    );
  };

  if (R.isEmpty(students)) return null;
  return (
    <OfflineList
      list={students}
      uniqueKey="id"
      defaultSort={{ field: 'first_name', order: 'ASC' }}
    >
      <Datagrid
        rowClick="expand"
        expand={
          <OutcomesList
            students={students}
            onStudentOutcomeScore={onStudentOutcomeScore}
          />
        }
      >
        <FullNameField />
        <ScoredOutcomesProgressField
          label="Graded Outcomes"
          cellClassName={classes.scored_outcomes}
        />
        <ChipField
          label="Avg. Grade"
          source="outcomes_score_average"
          chipStyle="outcomesScore"
          cellClassName={classes.score_average}
        />
      </Datagrid>
    </OfflineList>
  );
};

function ByStudentsProjectSubtab({ record: cohort, course }) {
  const cohortProjects = R.pathOr([], [course, 'projects'], cohort);

  const {
    data: apiStudents,
    loaded: studentsLoaded,
    error: studentsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: cohort.id,
      pagination: { perPage: 100 },
      filter: { course: true },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  if (!studentsLoaded)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );

  if (studentsError)
    return (
      <Box p="16px">
        <Error error={studentsError} errorInfo={studentsError} />
      </Box>
    );

  if (R.isEmpty(apiStudents) || R.isEmpty(cohortProjects)) return null;

  if (cohortProjects.length === 1)
    return (
      <StudentList
        record={R.head(cohortProjects)}
        apiStudents={apiStudents}
        course={course}
      />
    );

  return (
    <OfflineList list={cohortProjects} uniqueKey="name">
      <Datagrid
        rowClick="expand"
        expand={<StudentList apiStudents={apiStudents} course={course} />}
      >
        <FunctionField
          label="Project Name"
          render={(record) =>
            R.pipe(
              R.find(R.propEq('id', record.name)),
              R.prop('name')
            )(rubricProjects)
          }
        />
      </Datagrid>
    </OfflineList>
  );
}

export default ByStudentsProjectSubtab;

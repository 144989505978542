import * as R from 'ramda';
import React, { useState } from 'react';
import { Datagrid } from 'react-admin';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';

import { lightGreen, grey, red } from '@material-ui/core/colors';

import { FullNameField } from 'resources/students/fields';
import OfflineList from 'resources/common/OfflineList';

import ScoreField from '../components/ScoreField';
import SkillChart from '../components/SkillChart';

import getSkillsData from './skillsData';

const statusStageMap = {
  finished: 'final',
  not_started: 'prework',
  ongoing: 'ongoing',
};

const stageFilterMap = {
  prework: { path: 'prework' },
  ongoing: { path: 'course' },
  final: { path: 'course', type: 'final' },
};

const OverviewSubtab = (props) => {
  const { cohort, students } = props;
  const defaultStage = statusStageMap[cohort.status] || 'ongoing';

  const [stageFilter, setStageFilter] = useState(stageFilterMap[defaultStage]);

  const { skills, skillStats, skilledStudents } = getSkillsData(
    cohort,
    students,
    stageFilter
  );

  return (
    <>
      <Box margin={4}>
        <Select
          defaultValue={defaultStage}
          onChange={(event) =>
            setStageFilter(stageFilterMap[event.target.value])
          }
        >
          <MenuItem value="prework">Prework</MenuItem>
          <MenuItem value="ongoing">Course</MenuItem>
          <MenuItem value="final">Final Assessment</MenuItem>
        </Select>
      </Box>
      <Box
        marginBottom={2}
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        justifyContent="space-around"
      >
        {R.values(
          R.mapObjIndexed(
            ({ score, subskills }, skill) => (
              <Box
                key={`stats-${skill}`}
                marginTop={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h4" align="center">
                    {skill}
                  </Typography>
                </Box>
                <Box
                  bgcolor={scoreStyle(score)}
                  color="white"
                  width={100}
                  borderRadius={4}
                  padding={1}
                  marginY={1}
                >
                  <Typography variant="h3" align="center">
                    {score}
                  </Typography>
                </Box>
                <Box width={400} height={350}>
                  <SkillChart data={subskills} />
                </Box>
              </Box>
            ),
            skillStats
          )
        )}
      </Box>
      <OfflineList
        list={skilledStudents}
        uniqueKey="id"
        defaultSort={{ field: 'last_name', order: 'ASC' }}
      >
        <Datagrid>
          <FullNameField />
          {R.map(
            (skill) => (
              <ScoreBySkill
                key={`score-${skill}`}
                textAlign="center"
                skill={skill}
                label={skill}
                source={`scoreBySkill.${skill}`}
              />
            ),
            skills
          )}
        </Datagrid>
      </OfflineList>
    </>
  );
};

function ScoreBySkill({ skill, record }) {
  const score = R.when(R.equals(-1), R.always(''), record.scoreBySkill[skill]);
  return <ScoreField score={score} />;
}

// Aux functions

const scoreStyle = R.cond([
  [R.lte(80), R.always(lightGreen[500])],
  [R.gt(50), R.always(red[100])],
  [R.T, R.always(grey[300])],
]);

export default OverviewSubtab;

import * as React from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AccessTime,
  Business,
  Category,
  HowToReg,
  ListAlt,
  Language,
  School,
  Today,
  Work,
} from '@material-ui/icons';
import { FilterList, FilterLiveSearch, FilterListItem } from 'react-admin';

import { authServer } from 'auth/keycloak';
import { FilterDateInput, FilterListItemMulti } from 'resources/common';
import {
  careerhackOptedIn,
  careerServiceCategories,
  careerServiceStatuses,
  cohortTimezones,
  formats,
  tracks,
  tracksB2b,
} from 'data';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const CohortListAside = (props) => {
  const campusChoices = props.campusChoices;
  return (
    <Card>
      <CardContent>
        <FilterLiveSearch label="Search By Name" source="name_search" />
        <FilterLiveSearch label="Search By Email" source="email_search" />
        {authServer.isNotRoles(['b2b']) && campusChoices.length !== 1 && (
          <FilterList label="Campuses" icon={<Business />}>
            {campusChoices.map((campus) => (
              <FilterListItemMulti
                key={campus.name}
                label={campus.name}
                value={{ cohort_campus: campus.id }}
              />
            ))}
          </FilterList>
        )}
        <FilterList label="Tracks" icon={<School />}>
          {(authServer.isNotRoles(['b2b']) ? tracks : tracksB2b).map(
            (track) => (
              <FilterListItemMulti
                key={track.name}
                label={track.name}
                value={{ cohort_track: track.id }}
              />
            )
          )}
        </FilterList>
        <FilterList label="Format" icon={<AccessTime />}>
          {formats.map((format) => (
            <FilterListItemMulti
              key={format.name}
              label={format.name}
              value={{ cohort_format: format.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Timezones" icon={<Language />}>
          {cohortTimezones.map((tz) => (
            <FilterListItemMulti
              key={tz.name}
              label={tz.name}
              value={{ cohort_timezone: tz.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Start Date" icon={<Today />}>
          <FilterDateInput
            label="From"
            source="cohort_starts_since"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
          <FilterDateInput
            label="To"
            source="cohort_starts_before"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
        </FilterList>
        <FilterList label="End Date" icon={<Today />}>
          <FilterDateInput
            label="From"
            source="cohort_ends_since"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
          <FilterDateInput
            label="To"
            source="cohort_ends_before"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
        </FilterList>
        {authServer.isRoles(['outcomes']) && authServer.isNotRoles(['b2b']) && (
          <>
            <FilterList label="Outcomes Form" icon={<ListAlt />}>
              <FilterListItemMulti
                label="Pending"
                value={{ outcomes_form_filled: false }}
              />
            </FilterList>
            <FilterList label="Outcomes Category" icon={<Category />}>
              {careerServiceCategories.map((category) => (
                <FilterListItemMulti
                  key={category.name}
                  label={category.name}
                  value={{ outcomes_category: category.id }}
                />
              ))}
            </FilterList>
          </>
        )}
        <FilterList label="Outcomes Status" icon={<Work />}>
          {careerServiceStatuses.map((status) => (
            <FilterListItemMulti
              key={status.name}
              label={status.name}
              value={{ outcomes_status: status.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Opt in Careerhack" icon={<HowToReg />}>
          {careerhackOptedIn.map((isOptedIn) => (
            <FilterListItem
              key={isOptedIn.name}
              label={isOptedIn.name}
              value={{ careerhack_opted_in: isOptedIn.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default CohortListAside;

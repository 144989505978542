import React, { Fragment } from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    height: 10,
    borderRadius: 20,
    width: '70%',
    display: 'inline-block',
    'margin-right': '10px'
  },
  bar: {
    borderRadius: 20,
    backgroundColor: 'green'
  }
});

const ProgressField = ({ record, cohort, course, target, ...rest }) => {
  const classes = useStyles();

  let completed;
  let total;
  let progress;
  let text;
  if (target === 'deliveries') {
    const assignments = R.pathOr([], ['assignments', course], record);
    total =
      R.propOr([], 'delivered', assignments).length +
      R.propOr([], 'pending', assignments).length;
    completed = R.propOr([], 'delivered', assignments).length;
    text = `${completed}/${total}`;
    progress = R.defaultTo(0, Math.round((100 * completed) / total));
  } else if (target === 'assignment') {
    total = record.expected;
    completed = R.propOr([], 'deliveries', record).length;
    progress = Math.round((100 * completed) / total);
    text = `${completed}/${total}`;
  } else if (target === 'section_assignments') {
    const { total, completed } = R.reduce(
      (acc, assignment) => {
        acc.total += assignment.expected;
        acc.completed += assignment.deliveries.length;
        return acc;
      },
      { total: 0, completed: 0 },
      R.propOr([], 'assignments', record)
    );
    progress = Math.round((100 * completed) / total);
    text = `${completed}/${total}`;
  } else {
    completed = R.pipe(
      R.propOr({}, course),
      R.props([target, 'deliveries']),
      R.map(R.defaultTo([])),
      R.map(R.length),
      R.sum
    )(record);
    total = R.pathOr(0, [course, 'learning_units_count'], record);
    progress = R.defaultTo(0, Math.round((100 * completed) / total));
    text = `${progress}%`;
  }
  return (
    <Fragment>
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={classes}
      />
      <Typography component="span" variant="body2">
        {text}
      </Typography>
    </Fragment>
  );
};

export default ProgressField;

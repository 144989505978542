import React from 'react';
import * as R from 'ramda';
import { Datagrid, ReferenceManyField } from 'react-admin';

import { DeliveriesList, NewProgressField } from 'resources/common';
import { FullNameField, OutcomesStatusField } from 'resources/students/fields';
import { UnitProgressField } from 'resources/common/cohorts/list/fields';

const SectionProgress = ({ record, section }) => {
  const { value, text } = R.pipe(
    R.pathOr([], ['career_services', 'deliveries']),
    R.values,
    R.unnest,
    R.filter(R.pathEq(['path', '1', 'display_name'], section)),
    R.reduce(
      (acc, delivery) => {
        acc.total += 1;
        if (R.startsWith('delivered', delivery.status)) {
          acc.delivered += 1;
        }
        return acc;
      },
      { total: 0, delivered: 0 }
    ),
    R.applySpec({
      value: ({ total, delivered }) =>
        R.defaultTo(0, Math.round((100 * delivered) / total)),
      text: ({ total, delivered }) => `${delivered}/${total}`,
    })
  )(record);
  return <NewProgressField value={value} text={text} />;
};

const CareerhackProgressTab = (props) => {
  const { basePath, record } = props;
  const sections = R.pipe(
    R.pathOr([], ['career_services', 'assignments']),
    R.map(R.path(['path', '1', 'display_name'])),
    R.uniq
  )(record);
  return (
    <ReferenceManyField
      source="id"
      reference="students"
      target="cohort"
      perPage={100}
      record={record}
      basePath={basePath}
      filter={{ course: 'career_services' }}
      sort={{ field: 'first_name', order: 'ASC' }}
    >
      <Datagrid
        rowClick="expand"
        expand={
          <DeliveriesList
            label="Progress"
            type="students"
            record={record}
            source="career_services.deliveries"
            grade={false}
          />
        }
      >
        <FullNameField sortable={false} />
        <OutcomesStatusField sortable={false} />
        <UnitProgressField
          label="Unit Progress"
          course="career_services"
          target="progress"
          sortable={false}
        />
        {R.map((section) => {
          return (
            <SectionProgress
              label={`${section} Assignments`}
              section={section}
            />
          );
        }, sections)}
      </Datagrid>
    </ReferenceManyField>
  );
};

export default CareerhackProgressTab;

import React from 'react';
import { Chip } from '@material-ui/core';

import { getGradingColors } from 'lib/gradingColors';

const NpsField = ({ value }) => {
  const color = getGradingColors(value / 2);

  return value ? (
    <Chip
      style={{ color: color.typography, backgroundColor: color.background }}
      label={value}
    />
  ) : null;
};

export default NpsField;

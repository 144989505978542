import * as R from 'ramda';
import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const dataProvider = {
  getMany: async params => {
    const url = `${apiUrl}/data/countries?${stringify(
      { id: R.propOr([], 'ids', params) },
      { arrayFormat: 'comma' }
    )}`;
    return { url };
  }
};

export default dataProvider;

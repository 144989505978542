import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import { Box } from '@material-ui/core';
import { Error, Loading, useNotify } from 'react-admin';

import { BeeEditor, EmailForm } from './components';
import { useBackend } from 'resources/common';

const EmailEdit = ({ id }) => {
  const [{ data, loading, error }, setCall] = useBackend();
  const [emailInfo, setEmailInfo] = useState({
    subject: '',
    name: '',
    language: '',
    family: '',
  });
  const emailInfoRef = useRef(emailInfo);
  const notify = useNotify();

  const onSave = (jsonFile, htmlFile) => {
    setCall({
      url: `/email_templates/${data.result.id}`,
      method: 'PATCH',
      body: {
        ...data.result,
        template: jsonFile,
        html: htmlFile,
        ...emailInfoRef.current,
      },
    });
  };

  const onError = (error) =>
    notify(`Email update error: ${error.message}`, 'warning');

  useEffect(() => {
    setCall({
      url: `/email_templates/${id}`,
      method: 'GET',
    });
  }, [id, setCall]);

  useEffect(() => {
    if (data) {
      const update = R.pipe(
        R.prop('result'),
        R.pick(['name', 'subject', 'language', 'family', 'variants'])
      )(data);
      emailInfoRef.current = update;
      setEmailInfo(update);
    }
  }, [data]);

  if (loading) {
    return (
      <Box>
        <Loading />
      </Box>
    );
  }
  if (error) {
    return (
      <Box>
        <Error error={error} errorInfo={error} />
      </Box>
    );
  }
  if (data) {
    return (
      <>
        <EmailForm
          emailInfo={emailInfo}
          setEmailInfo={setEmailInfo}
          emailInfoRef={emailInfoRef}
        />
        {/* Header plus two rows of input fields */}
        <Box height="calc(100vh - 80px - 56px - 56px)">
          <BeeEditor
            template={data.result.template}
            onSave={onSave}
            onError={onError}
          />
        </Box>
      </>
    );
  }
  return null;
};
export default EmailEdit;

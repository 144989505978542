import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import {
  DatePicker,
  DateTimePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';

class UTCUtils extends MomentUtils {
  format(value, formatString) {
    return value.format(formatString);
  }
}

const makePicker = (PickerComponent) => {
  class _makePicker extends Component {
    onChange(date) {
      if (date) {
        this.props.input.onChange(date.format('YYYY-MM-DD'));
      }
      this.props.input.onBlur();
    }

    render() {
      const {
        input,
        options,
        label,
        source,
        resource,
        isRequired,
        className,
        meta,
        providerOptions,
        margin = 'dense',
        disabled = false,
        fullWidth,
      } = this.props;

      const { touched, error } = meta;

      return (
        <div className="picker">
          <MuiPickersUtilsProvider {...providerOptions}>
            <PickerComponent
              {...options}
              label={
                <FieldTitle
                  label={label}
                  source={source}
                  resource={resource}
                  isRequired={isRequired}
                />
              }
              margin={margin}
              disabled={disabled}
              error={!!(touched && error)}
              helperText={touched && error}
              className={className}
              value={input.value ? input.value : null}
              onChange={(date) => {
                if (this.props.onChange) {
                  this.props.onChange(date?.format('YYYY-MM-DD'));
                }
                this.onChange(date);
              }}
              fullWidth={fullWidth}
            />
          </MuiPickersUtilsProvider>
        </div>
      );
    }
  }
  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
      utils: PropTypes.func,
      locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  };

  _makePicker.defaultProps = {
    input: {},
    isRequired: 'false',
    label: '',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
      utils: UTCUtils,
      libInstance: moment,
      locale: undefined,
    },
  };
  return _makePicker;
};

export const DateInput = addField(makePicker(DatePicker));
export const TimeInput = addField(makePicker(TimePicker));
export const DateTimeInput = addField(makePicker(DateTimePicker));

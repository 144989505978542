import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  ArrayField,
  Datagrid,
  Error,
  FunctionField,
  Loading,
  TextField,
  useQuery,
} from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import {
  ChipField,
  DeliveryTitle,
  LabDueDateField,
  LabsList,
  OfflineList,
} from 'resources/common';
import { LabProgressField } from 'resources/common/cohorts/list/fields';

const LinkToStudio = ({ record }) => {
  const baseUrl = process.env.REACT_APP_STUDIO_BASE_URL;
  const courseId = R.pipe(
    R.pathOr('', ['path', 0, 'id']),
    R.split(':'),
    R.last
  )(record);
  const unitId = R.pathOr('', ['path', 3, 'id'])(record);
  const url = `${baseUrl}/container/block-v1:${courseId}+type@vertical+block@${unitId}`;
  return (
    <Button
      size="small"
      color="primary"
      target="_blank"
      href={url}
      label="See In Studio"
    >
      <OpenInNew style={{ paddingRight: '0.2em' }} />
      See In Studio
    </Button>
  );
};

const StudentsLabsList = ({ record, course, section = null }) => {
  const [labs, setLabs] = useState([]);
  const {
    data: apiLabs,
    loading: labsLoading,
    error: labsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'labs',
    payload: {
      target: 'cohort',
      id: record.id,
      pagination: {
        perPage: 100,
      },
      filter: R.reject(R.isNil, { course, section }),
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  useEffect(() => {
    if (apiLabs) {
      const tableData = R.map((lab) => {
        const averageAiGrade = R.pipe(
          R.propOr([], 'deliveries'),
          R.map(
            R.pipe(
              R.propOr([], 'attempts'),
              R.last,
              R.propOr([], 'evaluables'),
              R.reject(R.isNil),
              R.pluck('score'),
              R.mean,
              R.defaultTo(0),
              (mean) => parseFloat(mean.toFixed(2))
            )
          ),
          R.mean,
          R.defaultTo(0),
          (mean) => parseFloat(mean.toFixed(2))
        )(lab);

        return R.assoc(
          'average_ai_score',
          averageAiGrade === 0 ? undefined : averageAiGrade,
          lab
        );
      })(apiLabs);
      setLabs(tableData);
    }
  }, [apiLabs, course]);

  if (labsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );

  if (labsError)
    return (
      <Box p="16px">
        <Error error={labsError} errorInfo={labsError} />
      </Box>
    );

  if (R.isEmpty(labs)) return null;

  return (
    <OfflineList list={labs} uniqueKey="id">
      <Datagrid
        rowClick="expand"
        expand={<LabsList record={record} type="lab" course={course} />}
      >
        <DeliveryTitle />
        <FunctionField
          label="Type"
          source="type"
          render={(record) => {
            return record.type === 'file_type' ? 'File Upload' : 'URL';
          }}
        />
        <LabDueDateField label="Due Date" source="due_date" />
        <LabProgressField label="Progress" source="lab_progress" />
        <ChipField
          label="Avg. AI Grade"
          source="average_ai_score"
          chipStyle="labGrade"
          sx={{ with: 100 }}
        />
        <LinkToStudio label="Studio Link" sortable={false} />
      </Datagrid>
    </OfflineList>
  );
};

const SectionLabList = ({ record, cohort, course }) => {
  return (
    <StudentsLabsList record={cohort} course={course} section={record.id} />
  );
};

const SectionList = ({ record, course }) => {
  const sections = R.pipe(
    R.pathOr([], [course, 'labs']),
    R.map(R.pipe(R.prop('path'), R.nth(1))),
    R.uniq
  )(record);
  return (
    <ArrayField source="sections" record={{ sections }}>
      <Datagrid
        rowClick="expand"
        expand={<SectionLabList cohort={record} course={course} />}
      >
        <TextField label="Section" source="display_name" sortable={false} />
      </Datagrid>
    </ArrayField>
  );
};

function ByLabsSubtab(props) {
  const { record, course } = props;
  if (course === 'course') {
    return <SectionList record={record} course={course} />;
  } else {
    return <StudentsLabsList record={record} course={course} />;
  }
}

export default ByLabsSubtab;

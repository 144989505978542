import React from 'react';
import * as R from 'ramda';
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  FormWithRedirect,
  minValue,
  number,
  NumberInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useQuery,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';

import { DateInput } from 'resources/common/DatePickers';
import { authServer } from 'auth/keycloak';
import {
  cohortTimezones,
  formats,
  languages,
  tracksB2b,
  b2bProjectTypes,
} from 'data';
const GROUPS = { pm: '8b9f3f55-1147-409e-adb0-7e5f1f6a9d03' };

const createGroupQuery = (pagination, group) => ({
  type: 'getList',
  resource: 'users',
  payload: {
    filter: { group: GROUPS[group], with_groups: false },
    pagination,
  },
});

const validateRequired = [required('Required')];
const validateNumbers = [number(), minValue(0)];
const validateUrl = regex(
  /^(ftp|http|https):\/\/[^ "]+$/,
  'Invalid URL. Be sure to include the `http://` or `https://` protocol to the URL'
);

const validateRequiredNumbers = R.concat(validateRequired, validateNumbers);

const CohortB2bCreateForm = (props) => {
  const pms = useQuery(createGroupQuery({ page: 1, perPage: 100 }, 'pm'));

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        has_career_services: true,
        has_prework: true,
      }}
      render={(formProps) => {
        return (
          <form>
            <Box display="block" p="1.5em">
              <Typography variant="h5" gutterBottom>
                Cohort
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Track"
                    source="track"
                    choices={tracksB2b}
                    validate={validateRequired}
                    variant="standard"
                    onChange={R.path(
                      ['form', 'mutators', 'courseTitleMutator'],
                      formProps
                    )}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Format"
                    source="format"
                    choices={formats}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Timezone"
                    source="timezone"
                    choices={cohortTimezones}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flex={1}>
                  <SelectInput
                    label="Language"
                    source="language"
                    choices={languages}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flexBasis="calc((100% - 6em)/4)" mr={{ md: '2em' }}>
                  <TextInput
                    label="Company"
                    source="company"
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flexBasis="calc((100% - 6em)/4)" mr={{ md: '2em' }}>
                  <SelectInput
                    label="Project Type"
                    source="project_type"
                    choices={b2bProjectTypes}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flexBasis="calc((100% - 6em)/4)">
                  <NumberInput
                    label="Hours per week"
                    source="week_hours"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Dates
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flexBasis="50%">
                  <DateInput
                    label="Start Date"
                    source="start_date"
                    options={{
                      format: 'Do MMMM YYYY',
                      openTo: 'year',
                      views: ['year', 'month', 'date'],
                    }}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <FormDataConsumer>
                  {({ formData }) =>
                    R.equals('other', formData.format) && (
                      <Box flex={1} ml={{ md: '2em' }}>
                        <DateInput
                          label="End Date"
                          source="end_date"
                          options={{
                            format: 'Do MMMM YYYY',
                            openTo: 'year',
                            views: ['year', 'month', 'date'],
                          }}
                          validate={validateRequired}
                          variant="standard"
                          fullWidth
                        />
                      </Box>
                    )
                  }
                </FormDataConsumer>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Staff
              </Typography>
              <Box>
                <SelectInput
                  label="PM"
                  source="staff.pm"
                  format={(pm) =>
                    pm ? `${pm.email}#${pm.first_name}#${pm.last_name}` : ''
                  }
                  parse={R.pipe(
                    R.defaultTo(''),
                    R.split('#'),
                    R.zipObj(['email', 'first_name', 'last_name'])
                  )}
                  choices={R.map((pm) => {
                    pm.info = `${pm.email}#${pm.first_name}#${pm.last_name}`;
                    return pm;
                  }, R.defaultTo([], pms.data))}
                  variant="standard"
                  optionText={(pm) => `${pm.first_name} ${pm.last_name}`}
                  optionValue="info"
                  fullWidth
                />
              </Box>
              <FormDataConsumer>
                {({ formData }) => (
                  <>
                    <Box mb="3em">
                      <Typography variant="h5" gutterBottom>
                        Custom Cohort Config
                      </Typography>
                      <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1}>
                          <BooleanInput
                            label="Has Prework?"
                            source="has_prework"
                            defaultValue={true}
                            variant="standard"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1}>
                          <BooleanInput
                            label="Has Careerhack?"
                            source="has_career_services"
                            defaultValue={true}
                            variant="standard"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Box>
                    {R.propEq('track', 'cy', formData) && (
                      <Box mb="3em">
                        <Typography variant="h5" gutterBottom>
                          External IDs
                        </Typography>
                        <Box display={{ sm: 'block', md: 'flex' }}>
                          <Box flex={1}>
                            <TextInput
                              label="Cybint Access Code"
                              source="external_ids.cybint_access_code"
                              variant="standard"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </FormDataConsumer>
              <Typography variant="h5" gutterBottom>
                Cohort specifics
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flexBasis="50%">
                  <TextInput
                    label="Link to FAQs"
                    source="faq_url"
                    validate={validateUrl}
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
            <Toolbar>
              {authServer.isRoles(['pm', 'b2b']) && (
                <Box display="flex" width="100%">
                  <Box flex={2}>
                    <SaveButton
                      record={formProps.record}
                      basePath={formProps.basePath}
                      undoable={true}
                      invalid={formProps.invalid}
                      saving={formProps.saving}
                      resource="cohorts"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect
                      }
                    />
                  </Box>
                </Box>
              )}
            </Toolbar>
          </form>
        );
      }}
    />
  );
};

const CohortB2bCreate = ({ classes, ...props }) => {
  return (
    <Create actions={null} undoable={false} {...props}>
      <CohortB2bCreateForm redirect="list" />
    </Create>
  );
};

export default CohortB2bCreate;

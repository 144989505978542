import React from 'react';
import * as R from 'ramda';
import { Box, Grid, Typography } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';
import { ResponsivePie } from '@nivo/pie';

import { surveyQuestions } from 'data';
import { getGradingColors } from 'lib/gradingColors';

const FeelingsPie = ({ data }) => {
  const colors = {
    lost: red[600],
    frustrated: red[400],
    neutral: red[100],
    happy: blue[200],
    excited: blue[400],
    proud: blue[600],
  };

  return (
    <ResponsivePie
      margin={{ top: 20, right: 0, bottom: 25, left: 0 }}
      data={data}
      innerRadius={0.5}
      padAngle={0.7}
      colors={({ id }) => {
        return colors[id];
      }}
      cornerRadius={3}
      theme={{
        labels: { text: { fontSize: 14 } },
      }}
    />
  );
};

const questionsMap = R.pipe(
  R.groupBy(R.prop('id')),
  R.map(R.head)
)(surveyQuestions);

const defaultFeelings = {
  lost: 0,
  frustrated: 0,
  neutral: 0,
  happy: 0,
  excited: 0,
  proud: 0,
};

const averageMetric = R.pipe(
  R.mean,
  (mean) => Math.round((mean + Number.EPSILON) * 100) / 100
);

const metricsMap = {
  feelings: R.pipe(
    R.reduce(
      (feelings, feeling) =>
        R.evolve({ [R.toLower(feeling)]: R.inc })(feelings),
      defaultFeelings
    ),
    R.filter(R.identity),
    R.toPairs,
    R.map(R.zipObj(['id', 'value'])),
    R.map((feelingCount) => R.assoc('label', feelingCount.id, feelingCount))
  ),
  nps: R.pipe(
    R.groupBy(
      R.cond([
        [R.lt(8), R.always('promoters')],
        [R.gt(7), R.always('detractors')],
        [R.lt(6), R.always('neutrals')],
      ])
    ),
    R.map(R.length),
    ({ promoters = 0, detractors = 0, neutrals = 0 }) =>
      R.converge(R.subtract, [
        R.pipe(R.divide(promoters), R.multiply(100)),
        R.pipe(R.divide(detractors), R.multiply(100)),
      ])(R.sum([promoters, detractors, neutrals])),
    Math.round
  ),
  comments: R.identity,
};

const getMetricColor = (metric, value) => {
  if (metric === 'nps') {
    return getGradingColors(value / 20);
  } else {
    return getGradingColors(value);
  }
};

function SurveyMetrics({ students, surveyNumber }) {
  const metrics = R.pipe(
    R.pluck('weekly_surveys'),
    R.unnest,
    R.reject(R.isNil),
    R.filter(R.propEq('survey', surveyNumber)),
    R.pluck('answers'),
    R.unnest,
    R.groupBy(R.prop('question')),
    R.map(R.pluck('answer')),
    R.mapObjIndexed((answers, question) =>
      R.propOr(averageMetric, question, metricsMap)(answers)
    )
  )(students);
  const averages = R.omit(
    [
      'comments',
      'feelings',
      'sp_satisfaction',
      'sp_main_issue',
      'sp_secondary_issue',
      'v2_nps_reason_promoter',
      'v2_nps_reason_detractor',
      'v2_sp_ai_reason_promoter',
      'v2_sp_ai_reason_detractor',
      'v2_tech_support',
      'v2_low_experience_comment',
    ],
    metrics
  );

  return !R.isEmpty(metrics) ? (
    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
      <Grid container>
        <Grid item xs={12} lg={5}>
          <Typography variant="h5" gutterBottom>
            Students Feeling
          </Typography>
          <Box height="400px">
            <FeelingsPie data={metrics.feelings} />
          </Box>
        </Grid>
        <Grid xs={12} lg={7}>
          <Typography variant="h5" gutterBottom>
            Cohort Metrics
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {R.pipe(
              R.mapObjIndexed((metric, question) => {
                const metricColors = getMetricColor(question, metric);
                return (
                  <Box
                    display="flex"
                    borderRadius={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={metricColors.background}
                    m="1em"
                    p="16px"
                    width="120px"
                    height="120px"
                    key={question}
                  >
                    <Box color={metricColors.typography} flex={1}>
                      <Typography variant="subtitle1" align="center">
                        {questionsMap[question].short_name}
                      </Typography>
                    </Box>
                    <Box color={metricColors.typography} flex={1}>
                      <Typography variant="h3">{metric}</Typography>
                    </Box>
                  </Box>
                );
              }),
              R.values,
              R.splitEvery(4),
              R.intersperse(<Box width="100%" />),
              R.flatten
            )(averages)}
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : null;
}

export default SurveyMetrics;

import React from 'react';
import * as R from 'ramda';
import { capitalize } from 'lodash';
import { Chip } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';

const chipStyles = {
  pending: { color: 'white', backgroundColor: grey[500] },
  success: { color: 'white', backgroundColor: green[900] },
  failed: { color: 'white', backgroundColor: red[500] },
};

const TaskStatus = (props) => {
  const { status } = props;

  const style = R.propOr({}, status, chipStyles);
  return <Chip label={capitalize(status)} style={style} />;
};

export default TaskStatus;

import React, { Fragment } from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';

import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  Labeled,
  ReferenceManyField,
  RichTextField,
  TextField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { Add, Clear, Edit } from '@material-ui/icons';

import { FullNameField } from 'resources/students/fields';
import { FlagField } from 'resources/one_on_ones/fields';
import { authServer } from 'auth/keycloak';

const EditOneOnOneButton = ({ record, student, cohort }) => {
  return (
    <Button
      component={Link}
      variant="outlined"
      to={{
        pathname: `/one_on_ones/create`,
        state: {
          student,
          isWeekly: true,
          week: record.week,
          cohort,
          redirect: `/cohorts/${cohort.id}/show/one_on_ones`,
        },
      }}
      label="Edit"
    >
      <Edit />
    </Button>
  );
};

const OneOnOne = ({ record, student, cohort, week }) => {
  const oneOnOne = record[week];
  return oneOnOne ? (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="h5" gutterBottom>
          Week {week}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <EditOneOnOneButton
          record={oneOnOne}
          student={student}
          cohort={cohort}
        />
      </Grid>
      <Grid item xs={12}>
        <Labeled label="Interviewer">
          <TextField record={oneOnOne} source="interviewer" />
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Labeled label="Date">
          <DateField record={oneOnOne} source="date" />
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Labeled label="Flagged">
          {oneOnOne.flagged ? <FlagField record={oneOnOne} /> : <Clear />}
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Labeled label="Comments">
          <Box maxWidth="400px">
            <RichTextField record={oneOnOne} source="comments" />
          </Box>
        </Labeled>
      </Grid>
    </Grid>
  ) : null;
};

const OneOnOnes = ({ record, cohort, weeksNumber }) => {
  const oneOnOnes = R.pipe(
    R.propOr([], 'one_on_ones'),
    R.groupBy(R.prop('week')),
    R.map(R.head)
  )(record);
  return (
    <Box display="flex">
      {R.range(1, weeksNumber + 1).map((weekNumber) => (
        <Box>
          <OneOnOne
            record={oneOnOnes}
            week={weekNumber}
            student={record}
            cohort={cohort}
          />
        </Box>
      ))}
    </Box>
  );
};

const OneOnOneField = ({ record, week }) => {
  const oneOnOne = R.pipe(
    R.propOr([], 'one_on_ones'),
    R.find(R.propEq('week', week)),
    R.defaultTo({})
  )(record);
  const hasOneOnOne = !R.isEmpty(oneOnOne);
  return (
    <Fragment>
      <BooleanField record={{ one_on_one: hasOneOnOne }} source="one_on_one" />
      {hasOneOnOne ? <FlagField record={oneOnOne} /> : null}
    </Fragment>
  );
};

const CreateOneOnOneButton = ({ record, cohort }) => {
  return (
    <Button
      component={Link}
      variant="outlined"
      to={{
        pathname: '/one_on_ones/create',
        state: {
          student: record,
          isWeekly: true,
          cohort,
          redirect: `/cohorts/${cohort.id}/show/one_on_ones`,
        },
      }}
      label="Create One On One"
    >
      <Add />
    </Button>
  );
};

const OneOnOnesTab = ({ basePath, record, toolbar, ...rest }) => {
  const weeksNumber = Math.ceil(
    moment(record.end_date).diff(moment(record.start_date), 'weeks', true)
  );
  return (
    <Box display="block" overflow="auto" mt="16px">
      <ReferenceManyField
        label="Students"
        source="id"
        reference="students"
        target="cohort"
        perPage={100}
        record={record}
        basePath={basePath}
        sort={{ field: 'first_name', order: 'ASC' }}
        {...rest}
      >
        <Datagrid
          rowClick="expand"
          expand={
            <OneOnOnes weeksNumber={weeksNumber} cohort={record} {...rest} />
          }
        >
          <FullNameField sortable={false} />
          {R.range(1, weeksNumber + 1).map((weekNumber) => (
            <OneOnOneField
              label={`Week ${weekNumber}`}
              week={weekNumber}
              sortable={false}
              {...rest}
            />
          ))}
          {authServer.isNotRoles(['education']) && (
            <CreateOneOnOneButton cohort={record} />
          )}
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};

export default OneOnOnesTab;

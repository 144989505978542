import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Datagrid, Error, Loading, useQuery } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

import {
  AssignmentsProgressField,
  UnitProgressField,
  AssessmentsProgressField,
  LabsProgressField,
} from 'resources/common/cohorts/list/fields';
import { FullNameField, OutcomesStatusField } from 'resources/students/fields';
import {
  AssessmentScoreChip,
  AssignmentGradeDistributionField,
  ChipField,
  OfflineList,
} from 'resources/common';

const useListStyles = makeStyles({
  units: { width: 100 },
  grades: { width: 250 },
  average_grade: { width: 100 },
  average_ai_score: { width: 100 },
  average_score: { width: 100 },
});

const scoreMap = {
  very_good: 5,
  good: 4,
  normal: 3,
  bad: 2,
  very_bad: 1,
};

const ByStudentsSubtab = (props) => {
  const { record, course } = props;
  const classes = useListStyles();
  const [students, setStudents] = useState([]);

  const assignments = R.pathOr([], [course, 'assignments'], record);
  const assessments = R.pipe(
    R.pathOr([], [course, 'challenges']),
    R.uniqBy(R.prop('assessment_id'))
  )(record);
  const labs = R.pathOr([], [course, 'labs'], record);

  const {
    data: apiStudents,
    loading: studentsLoading,
    error: studentsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: record.id,
      pagination: {
        perPage: 100,
      },
      filter: { course: true },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  useEffect(() => {
    if (apiStudents) {
      const isRequired = R.propEq('required', true);
      const tableData = R.pipe(
        R.map((student) => {
          const averageGrade = R.pipe(
            R.pathOr([], [course, 'deliveries']),
            R.when(R.any(isRequired), R.filter(isRequired)),
            R.pluck('grade'),
            R.reject(R.isNil),
            R.map(R.prop(R.__, scoreMap)),
            (grades) =>
              R.defaultTo(
                null,
                Math.round((R.mean(grades) + Number.EPSILON) * 100) / 100
              )
          )(student);
          const averageScore = R.pipe(
            R.pathOr([], [course, 'challenges']),
            R.when(R.any(isRequired), R.filter(isRequired)),
            R.uniqBy(R.prop('assessment_id')),
            R.pluck('assessment_score_average'),
            R.reject(R.isNil),
            (averages) =>
              R.defaultTo(
                null,
                Math.round((R.mean(averages) + Number.EPSILON) * 100) / 100
              )
          )(student);

          const averageAiScore = R.pipe(
            R.pathOr([], [course, 'labs']),
            R.when(R.any(isRequired), R.filter(isRequired)),
            R.map((lab) => {
              const lastAttempt = R.last(R.propOr([], 'attempts', lab));
              return R.pipe(
                R.propOr([], 'evaluables'),
                R.reject(R.isNil),
                R.pluck('score'),
                (scores) =>
                  R.defaultTo(
                    null,
                    Math.round((R.mean(scores) + Number.EPSILON) * 100) / 100
                  )
              )(lastAttempt);
            }),
            R.reject(R.isNil),
            (averages) =>
              R.defaultTo(
                null,
                Math.round((R.mean(averages) + Number.EPSILON) * 100) / 100
              )
          )(student);

          const averageCutScore = R.pipe(
            R.pathOr([], [course, 'challenges']),
            R.when(R.any(isRequired), R.filter(isRequired)),
            R.uniqBy(R.prop('assessment_id')),
            R.pluck('assessment_cut_score'),
            R.reject(R.isNil),
            R.mean,
            (mean) => Math.round((mean + Number.EPSILON) * 100) / 100
          )(student);
          return R.mergeDeepRight(student, {
            [course]: {
              average_ai_score: averageAiScore,
              average_cut_score: averageCutScore,
              average_grade: averageGrade,
              average_score: averageScore,
            },
          });
        })
      )(apiStudents);
      setStudents(tableData);
    }
  }, [apiStudents, course]);

  if (studentsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (studentsError)
    return (
      <Box p="16px">
        <Error error={studentsError} errorInfo={studentsError} />
      </Box>
    );

  if (R.isEmpty(students)) {
    return null;
  }

  return (
    <OfflineList
      list={students}
      uniqueKey="id"
      defaultSort={{ field: 'first_name', order: 'ASC' }}
      sorters={{
        units_progress: R.pipe(R.pathOr([], [course, 'progress']), R.length),
        assignments_progress: R.pipe(
          R.pathOr([], [course, 'deliveries']),
          R.filter(R.pipe(R.propOr('', 'status'), R.startsWith('delivered'))),
          R.length
        ),
        assessments_progress: R.pipe(
          R.pathOr([], [course, 'challenges']),
          R.filter(R.propEq('status', 'delivered')),
          R.length
        ),
        [`${course}.deliveries`]: R.pipe(
          R.pathOr([], [course, 'deliveries']),
          R.filter(R.propEq('grade', 'very_good')),
          R.length
        ),
      }}
    >
      <Datagrid
        rowClick={(id) => {
          window.open(`/#/students/${id}/show/${course}_progress`, '_blank');
        }}
      >
        <FullNameField />
        <OutcomesStatusField />
        <UnitProgressField
          label="Unit Progress"
          source="units_progress"
          course={course}
          cohort={record}
          cellClassName={classes.units}
        />
        <AssignmentsProgressField
          label={`${
            R.any(R.propEq('required', true), assignments) ? 'Req. ' : ''
          }Assignments`}
          source="assignments_progress"
          course={course}
          cohort={record}
        />
        {R.hasPath([course, 'labs'], record) && (
          <LabsProgressField
            label={`${
              R.any(R.propEq('required', true), labs) ? 'Req. ' : ''
            }Labs`}
            source="labs"
            course={course}
            cohort={record}
          />
        )}
        <AssignmentGradeDistributionField
          label="Grades"
          source={`${course}.deliveries`}
          sortable={false}
          cellClassName={classes.grades}
        />
        <ChipField
          label="Avg. Grade"
          source={`${course}.average_grade`}
          chipStyle="assignmentGrade"
          cellClassName={classes.average_grade}
        />
        <AssessmentsProgressField
          label={`${
            R.any(R.propEq('required', true), assessments) ? 'Req. Passed ' : ''
          }Assessments`}
          course={course}
          cohort={record}
          source="assessments_progress"
        />
        <AssessmentScoreChip
          label="Avg. Score"
          scoreSource={`${course}.average_score`}
          cutScoreSource={`${course}.average_cut_score`}
        />
        {R.hasPath([course, 'labs'], record) && (
          <ChipField
            label="Avg. AI Score"
            source={`${course}.average_ai_score`}
            chipStyle="labGrade"
            cellClassName={classes.average_ai_score}
          />
        )}
      </Datagrid>
    </OfflineList>
  );
};

export default ByStudentsSubtab;

import * as React from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AccessTime,
  Business,
  Language,
  LinearScale,
  School,
  Today,
  Star,
} from '@material-ui/icons';
import { FilterList } from 'react-admin';

import { authServer } from 'auth/keycloak';
import { FilterDateInput, FilterListItemMulti } from 'resources/common';
import { cohortStatus, cohortTimezones, formats, tracks } from 'data';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const CohortListAside = (props) => {
  const campusChoices = props.campusChoices;
  return (
    <Card>
      <CardContent>
        <FilterList label="Bookmarks" icon={<Star />}>
          <FilterListItemMulti
            key={'Bookmarked'}
            label={'Bookmarked'}
            value={{ bookmarked_by: authServer.getUser() }}
          />
        </FilterList>
        <FilterList label="Status" icon={<LinearScale />}>
          {cohortStatus.map((cohortState) => (
            <FilterListItemMulti
              key={cohortState.name}
              label={cohortState.name}
              value={{ status: cohortState.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Tracks" icon={<School />}>
          {tracks.map((track) => (
            <FilterListItemMulti
              key={track.name}
              label={track.name}
              value={{ track: track.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Format" icon={<AccessTime />}>
          {formats.map((format) => (
            <FilterListItemMulti
              key={format.name}
              label={format.name}
              value={{ format: format.id }}
            />
          ))}
        </FilterList>
        {campusChoices.length === 1 ? null : (
          <FilterList label="Campuses" icon={<Business />}>
            {campusChoices.map((campus) => (
              <FilterListItemMulti
                key={campus.name}
                label={campus.name}
                value={{ campus: campus.id }}
              />
            ))}
          </FilterList>
        )}
        {authServer.isCampuses(['rmt']) ? (
          <FilterList label="Timezones" icon={<Language />}>
            {cohortTimezones.map((tz) => (
              <FilterListItemMulti
                key={tz.name}
                label={tz.name}
                value={{ timezone: tz.id }}
              />
            ))}
          </FilterList>
        ) : null}
        <FilterList label="Start Date" icon={<Today />}>
          <FilterDateInput
            label="From"
            source="starts_since"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
          <FilterDateInput
            label="To"
            source="starts_before"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
        </FilterList>
        <FilterList label="End Date" icon={<Today />}>
          <FilterDateInput
            label="From"
            source="ends_since"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
          <FilterDateInput
            label="To"
            source="ends_before"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default CohortListAside;

import { stringify } from 'query-string';
import * as R from 'ramda';
import { authServer } from 'auth/keycloak';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const emailsProvider = {
  getOne: async (params) => {
    const url = `${apiUrl}/email_templates/${params.id}`;
    return { url };
  },
  getList: async (params) => {
    const filter = params.filter;

    let search = [{ $match: {} }];

    if (R.has('name_search', filter)) {
      search = [
        {
          $match: {
            roles: { $in: authServer.getRoles() },
            $text: { $search: filter.name_search },
          },
        },
        { $project: { template: false, html: false } },
      ];
    }

    if (R.has('family', filter)) {
      search = [
        {
          $match: {
            $and: [
              { family: filter.family },
              { _id: { $exists: true } },
              { name: { $exists: true } },
            ],
          },
        },
      ];
    }

    if (R.has('id', filter)) {
      search = [
        {
          $match: {
            $and: [
              { _id: filter.id },
              { _id: { $exists: true } },
              { name: { $exists: true } },
            ],
          },
        },
      ];
    }

    const options = {
      method: 'POST',
      body: JSON.stringify(search),
      headers: { 'Content-Type': 'application/json' },
    };
    const page = params.pagination.page;
    const limit = params.pagination.perPage;

    const sortPrefix = params.sort.order === 'DESC' ? '-' : '';
    const sort = `${sortPrefix}${R.pathOr('id', ['sort', 'field'], params)}`;
    const query = { page, limit, sort };
    const url = `${apiUrl}/email_templates/search?${stringify(query)}`;
    return { url, options };
  },
};

export default emailsProvider;

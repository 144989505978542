import React from 'react';
import { DateField, Labeled, NumberField, TextField } from 'react-admin';
import { Box, Typography } from '@material-ui/core';

const AdditionalInformationShow = ({ record }) => (
  <>
    <Typography variant="h5" gutterBottom>
      Cohort additional information
    </Typography>
    <Typography variant="h6" gutterBottom>
      BG Financial Germany
    </Typography>
    <Box display={{ sm: 'block', md: 'flex' }}>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG Title">
          <TextField
            source="additional_information.bg_financing.bg_title"
            record={record}
          />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG Action Number">
          <TextField
            source="additional_information.bg_financing.bg_action_number"
            record={record}
            fullWidth
          />
        </Labeled>
      </Box>
    </Box>
    <Box display={{ sm: 'block', md: 'flex' }}>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG Number of Hours">
          <NumberField
            source="additional_information.bg_financing.bg_hours"
            record={record}
            fullWidth
          />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG Number of Weeks">
          <NumberField
            source="additional_information.bg_financing.bg_weeks"
            record={record}
            fullWidth
          />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG Schedule">
          <TextField
            source="additional_information.bg_financing.bg_schedule"
            record={record}
            fullWidth
          />
        </Labeled>
      </Box>
    </Box>
    <Box display={{ sm: 'block', md: 'flex' }}>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG Start Date">
          <DateField
            source="additional_information.bg_financing.bg_start_date"
            record={record}
            fullWidth
          />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG End Date">
          <DateField
            source="additional_information.bg_financing.bg_end_date"
            record={record}
            fullWidth
          />
        </Labeled>
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <Labeled label="BG Public End Date">
          <DateField
            source="additional_information.bg_financing.bg_public_end_date"
            record={record}
            fullWidth
          />
        </Labeled>
      </Box>
    </Box>
  </>
);

export default AdditionalInformationShow;

import React from 'react';
import * as R from 'ramda';
import { BooleanField, SelectField, TextField } from 'react-admin';

import { surveyFeelings } from 'data';
import { NpsField, ScoreField } from '.';

const AnwserField = ({
  record,
  label,
  student,
  surveyNumber,
  hasLegacyWeeklySurveysEnabled,
  ...rest
}) => {
  switch (record.question) {
    case 'feelings':
      return (
        <SelectField
          record={record}
          source="answer"
          choices={surveyFeelings}
          optionValue="id"
          {...rest}
        />
      );
    case 'prework':
    case 'challenge':
    case 'instruction':
    case 'material':
    case 'lab_preparation':
    case 'instructional_staff':
    case 'ironhack':
    case 'overall_experience':
    case 'v2_learning':
    case 'v2_support':
    case 'v2_tech_support_satisfaction':
    case 'v2_sp_ai':
    case 'v2_campus_experience':
    case 'v2_job_readiness':
    case 'v2_expectations':
      return (
        <ScoreField
          record={student}
          question={record.question}
          surveyNumber={surveyNumber}
          {...rest}
        />
      );
    case 'nps':
      return (
        <NpsField
          record={student}
          question={record.question}
          surveyNumber={surveyNumber}
          {...rest}
        />
      );
    case 'v2_tech_support':
      return <BooleanField record={record} source="answer" />;
    default:
      return <TextField record={record} source="answer" {...rest} />;
  }
};

const QuestionField = ({
  record,
  questionId,
  surveyNumber,
  hasLegacyWeeklySurveysEnabled,
  ...rest
}) => {
  const question = R.pipe(
    R.propOr([], ['weekly_surveys']),
    R.find(({ survey }) => survey.toString() === surveyNumber),
    R.defaultTo({}),
    R.propOr([], 'answers'),
    R.find(R.propEq('question', questionId)),
    R.defaultTo({ answer: '' })
  )(record);
  return (
    <AnwserField
      hasLegacyWeeklySurveysEnabled={hasLegacyWeeklySurveysEnabled}
      record={question}
      student={record}
      surveyNumber={surveyNumber}
      {...rest}
    />
  );
};

export default QuestionField;

import React from 'react';
import * as R from 'ramda';

import { Labeled } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';

import { ScoreField } from '../fields';

const SatisfactionInfo = ({ student }) => {
  const answers = R.pipe(
    R.pathOr([], ['career_services', 'surveys']),
    R.map((answer) => [
      [`Content - Stage ${answer.stage}`, answer.content],
      [`Coach - Stage ${answer.stage}`, answer.coach],
    ]),
    R.unnest,
    R.filter(R.nth(-1))
  )(student);
  return !R.isEmpty(answers) ? (
    <Grid container>
      <Grid item xs={12}>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            Satisfaction
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} />
      {R.pipe(
        R.filter(R.pathSatisfies(R.startsWith('Content'), ['0'])),
        R.map(([question, score]) => {
          return (
            <Grid item xs={12} lg={2} key={question}>
              <Labeled label={question}>
                <ScoreField value={score} />
              </Labeled>
            </Grid>
          );
        }),
        R.values
      )(answers)}
      <Grid item xs={12} />
      <Grid item xs={12} lg={2} />
      {R.pipe(
        R.filter(R.pathSatisfies(R.startsWith('Coach'), ['0'])),
        R.map(([question, score]) => {
          return (
            <Grid item xs={2} lg={2}>
              <Labeled label={question}>
                <ScoreField value={score} />
              </Labeled>
            </Grid>
          );
        }),
        R.values
      )(answers)}
    </Grid>
  ) : null;
};

export default SatisfactionInfo;

import * as R from 'ramda';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const attendanceProvider = {
  create: async params => {
    const url = `${apiUrl}/attendance`;
    const options = {
      method: 'POST',
      body: JSON.stringify(R.omit(['cohort'], params.data)),
      headers: { 'Content-Type': 'application/json' }
    };
    return { url, options };
  },
  getOne: async params => {
    const url = `${apiUrl}/attendance/${params.id}`;
    return { url };
  }
};

export default attendanceProvider;

import React from 'react';
import * as R from 'ramda';
import { Datagrid, DateField, FunctionField, SelectField } from 'react-admin';
import { Button } from '@material-ui/core';
import { GetApp, OpenInNew } from '@material-ui/icons';
import { green, orange, red, yellow } from '@material-ui/core/colors';

import { FullNameField } from 'resources/students/fields';
import {
  AssignmentDueDateField,
  OfflineList,
  DeliveryTitle,
  ChipField,
} from 'resources/common';
import { labsStatus } from 'data';

const rowStyle = (record, index, defaultStyle = {}) => {
  if (record.status === 'delivered')
    return {
      ...defaultStyle,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'pending')
    return {
      ...defaultStyle,
      borderLeftColor: yellow[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'delivered_late')
    return {
      ...defaultStyle,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'not_delivered')
    return {
      ...defaultStyle,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return defaultStyle;
};

const LinkToLab = ({ record }) => {
  return R.hasPath(['attempts'], record) ? (
    <Button
      size="small"
      color="primary"
      target="_blank"
      rel="noreferrer"
      href={R.pipe(
        R.propOr([], 'attempts'),
        R.last,
        R.path(['pull_request', 'url'])
      )(record)}
    >
      {record.type === 'file_type' ? (
        <GetApp style={{ paddingRight: '0.2em' }} />
      ) : (
        <OpenInNew style={{ paddingRight: '0.2em' }} />
      )}
      {'See Lab'}
    </Button>
  ) : null;
};

const LabList = ({ record }) => {
  const deliveries = R.pipe(
    R.props(['deliveries', 'pending']),
    R.flatten
  )(record);

  const deliveriesWithAvgScore = R.pipe(
    R.map((lab) => {
      const averageAiGrade = R.pipe(
        R.propOr([], 'attempts'),
        R.last,
        R.propOr('', 'evaluables'),
        R.reject(R.isNil),
        R.pluck('score'),
        R.mean,
        (mean) => Math.round((mean + Number.EPSILON) * 100) / 100
      )(lab);
      return R.assoc('average_ai_score', averageAiGrade, lab);
    })
  )(deliveries);

  return (
    <OfflineList list={deliveriesWithAvgScore} uniqueKey="email">
      <Datagrid rowStyle={rowStyle}>
        <FullNameField />
        <SelectField label="Status" choices={labsStatus} source="status" />
        <FunctionField
          label="Submit Date"
          render={(lab) => (
            <DateField
              record={R.pipe(R.propOr([], 'attempts'), R.last)(lab)}
              source="date"
            />
          )}
        />
        <ChipField
          label="Avg. AI Grade"
          source="average_ai_score"
          chipStyle="labGrade"
          sx={{ with: 100 }}
        />
        <LinkToLab />
      </Datagrid>
    </OfflineList>
  );
};

const StudentList = (props) => {
  const { record, source } = props;
  const labs = R.pipe(R.split('.'), R.pathOr([], R.__, record))(source);

  const labsWithAvgScore = R.pipe(
    R.map((lab) => {
      const averageAiGrade = R.pipe(
        R.propOr([], 'attempts'),
        R.last,
        R.propOr([], 'evaluables'),
        R.reject(R.isNil),
        R.pluck('score'),
        R.mean,
        (mean) => Math.round((mean + Number.EPSILON) * 100) / 100
      )(lab);
      return R.assoc('average_ai_score', averageAiGrade, lab);
    })
  )(labs);

  return (
    <OfflineList
      list={labsWithAvgScore}
      uniqueKey="id"
      defaultSort={{ field: 'status', order: 'ASC' }}
    >
      <Datagrid rowStyle={rowStyle}>
        <DeliveryTitle />
        <FunctionField
          label="Type"
          render={(record) => {
            return record.type === 'file_type' ? 'File Upload' : 'URL';
          }}
          source="type"
        />
        <SelectField label="Status" choices={labsStatus} source="status" />
        <AssignmentDueDateField label="Due Date" source="due_date" />
        <FunctionField
          label="Submit Date"
          render={(lab) => (
            <DateField
              record={R.pipe(R.propOr([], 'attempts'), R.last)(lab)}
              source="date"
            />
          )}
        />
        <ChipField
          label="Avg. AI Grade"
          source="average_ai_score"
          chipStyle="labGrade"
          sx={{ with: 100 }}
        />
        <LinkToLab />
      </Datagrid>
    </OfflineList>
  );
};

const LabsList = ({ type, ...rest }) => {
  return type === 'students' ? (
    <StudentList {...rest} />
  ) : (
    <LabList {...rest} />
  );
};

export default LabsList;

import React from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  Error,
  FormDataConsumer,
  FormWithRedirect,
  Loading,
  minValue,
  number,
  NumberInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useQuery,
} from 'react-admin';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateRange } from '@material-ui/icons';

import { DateInput } from 'resources/common/DatePickers';
import { authServer } from 'auth/keycloak';
import {
  campuses,
  campusesAll,
  cohortTimezones,
  currencies,
  formats,
  languages,
  partTimeDays,
  partTimeHours,
  tracks,
} from 'data';
import mutators from './mutators';
import CohortSaveButton from './CohortSaveButton';
import AdditionalInformationEdit from './components/AdditionalInformationEdit/AdditionalInformationEdit';

const CohortTitle = ({ record }) =>
  `${record.campus.toUpperCase()} ${record.track.toUpperCase()} ${record.format.toUpperCase()} ${
    record.start_date.split('T')[0]
  } ${record.language.toUpperCase()}`;

const validateRequired = [required('Required')];
const validateNumbers = [number(), minValue(0)];
const validateRequiredNumbers = R.concat(validateRequired, validateNumbers);
const validateDupeOfferings = (offerings) => {
  const hasDupes = R.pipe(
    R.defaultTo([]),
    R.pluck('campus'),
    R.reject(R.isNil),
    R.converge(R.complement(R.equals), [R.identity, R.uniq])
  )(offerings);
  return hasDupes
    ? 'One or more campuses have duplicated offerings'
    : undefined;
};
const validateOfferings = [validateDupeOfferings];
const validateCohortEdit = (values) => {
  const errors = {};

  const wrongTaAmount = R.pipe(
    R.pathOr('', ['staff', 'ta_amount']),
    R.gt(0)
  )(values);
  if (wrongTaAmount) {
    errors.staff = {
      ta_amount: ['Must be a positive number'],
    };
  }

  const emptyPartTimeDays = R.pathEq(['timetable', 'days'], '0000000', values);
  if (emptyPartTimeDays) {
    errors.timetable = {
      days: ['Required'],
    };
  }

  const wrongStartDate =
    moment(values.start_date).toDate() > moment(values.end_date).toDate();
  if (wrongStartDate) {
    errors.start_date = ['The start date cannot be later than the end date'];
    errors.end_date = ['The end date cannot be earlier than the start date'];
  }

  if (values.format !== 'other') {
    const wrongCareerWeekStartDate =
      moment(R.prop('start_date', values)).toDate() >
      moment(R.path(['hiring_fair', 'start_date'], values)).toDate();
    if (wrongCareerWeekStartDate) {
      errors.hiring_fair = {
        start_date: [
          'The career week start date cannot be earlier than the course start date',
        ],
      };
    }

    const wrongCareerWeekEndDate =
      moment(R.path(['hiring_fair', 'start_date'], values)).toDate() >
      moment(R.path(['hiring_fair', 'end_date'], values)).toDate();
    if (wrongCareerWeekEndDate) {
      errors.hiring_fair = {
        end_date: [
          'The career week end date cannot be earlier than the start date',
        ],
      };
    }
  }

  const wrongPartTimeStartDate = R.allPass([
    R.propEq('format', 'pt'),
    R.has('start_date'),
    R.hasPath(['timetable', 'days']),
    (values) => {
      const startDay = moment(values.start_date).day();
      const partTimeDays = R.path(['timetable', 'days'], values);
      return partTimeDays[startDay] !== '1';
    },
  ])(values);
  if (wrongPartTimeStartDate) {
    const startDay =
      R.path(['timetable', 'days', 1], values) === '1' ? 'Monday' : 'Tuesday';
    errors.start_date = [`The cohort must start on a ${startDay}`];
  }

  const wrongDiscount =
    R.path(['price', 'discount'], values) > R.path(['price', 'amount'], values);
  if (wrongDiscount) {
    errors.price = {
      discount: ['Cannot be larger than the cohort price'],
    };
  }

  return errors;
};

const GROUPS = {
  pm: '8b9f3f55-1147-409e-adb0-7e5f1f6a9d03',
  om: '873a6b8e-38ba-4de3-a153-b6600bee2b38',
  teacher: '4a7f515d-cd31-48bc-b360-4eca24baf47f',
};

const createGroupQuery = (pagination, group) => ({
  type: 'getList',
  resource: 'users',
  payload: {
    filter: { group: GROUPS[group], with_groups: false },
    pagination,
  },
});

const campusChoices = R.pipe(
  R.pathOr([], ['roles', 'campus']),
  R.of,
  R.partialRight(R.includes),
  R.propSatisfies(R.__, 'id'),
  R.filter(R.__, campuses)
)(authServer);

const useFormIteratorStyles = makeStyles({
  form: {
    display: 'flex',
    flex: 1,
    '& > .ra-input': {
      display: 'flex',
      flex: 1,
      marginRight: '2em',
    },
  },
});

const CohortEditForm = (props) => {
  const pms = useQuery(createGroupQuery({ page: 1, perPage: 100 }, 'pm'));
  const oms = useQuery(createGroupQuery({ page: 1, perPage: 100 }, 'om'));
  const teachers = useQuery(
    createGroupQuery({ page: 1, perPage: 200 }, 'teacher')
  );
  const formIteratorClasses = useFormIteratorStyles();

  const { data: emails, loading: emailsLoading } = useQuery({
    type: 'getList',
    resource: 'emails',
    payload: {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'name',
        order: 'ASC',
      },
      filter: {
        family: {
          $in: [
            'course_welcome',
            'course_welcome_preparation',
            'career_welcome',
          ],
        },
      },
    },
  });

  const getEmailTemplateNames = (searchedFamilies) =>
    R.pipe(
      R.filter(R.propSatisfies(R.includes(R.__, searchedFamilies), 'family')),
      R.map((template) =>
        R.assoc(
          'name',
          `${template.name}${
            template.language ? ` -  ${template.language.toUpperCase()}` : ''
          }`,
          template
        )
      ),
      R.sortBy(R.prop('name'))
    )(emails);

  if (pms.loading || oms.loading || teachers.loading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );

  if (pms.error || oms.error || teachers.error)
    return (
      <Box p="16px">
        <Error />
      </Box>
    );

  const fieldsConfig = {
    track: {
      validate: validateRequired,
      disabled: true,
    },
    format: {
      validate: validateRequired,
      disabled: true,
    },
    campus: {
      validate: validateRequired,
      disabled: true,
    },
    timezone: {
      validate: validateRequired,
      disabled: true,
    },
    language: {
      validate: validateRequired,
      disabled: !authServer.isRoles(['finance', 'gm']),
    },
    'price.amount': {
      validate: validateRequiredNumbers,
      disabled: !authServer.isRoles(['finance', 'gm']),
    },
    'price.discount': {
      validate: validateNumbers,
      disabled: !authServer.isRoles(['finance', 'gm', 'campus_lead']),
    },
    'price.currency': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['finance']),
    },
    start_date: {
      validate: validateRequired,
      disabled:
        !authServer.isRoles(['finance', 'gm', 'pm']) ||
        !moment().isBefore(props?.record?.start_date),
    },
    end_date: {
      validate: validateRequired,
      disabled: !authServer.isRoles(['finance', 'gm', 'campus_lead', 'pm']),
    },
    'hiring_fair.start_date': {
      validate: validateRequired,
      disabled: !authServer.isRoles([
        'gm',
        'outcomes',
        'finance',
        'campus_lead',
        'pm',
      ]),
    },
    'hiring_fair.end_date': {
      validate: validateRequired,
      disabled: !authServer.isRoles([
        'gm',
        'outcomes',
        'finance',
        'campus_lead',
        'pm',
      ]),
    },
    'timetable.days': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['gm', 'finance']),
    },
    'timetable.time.start_time': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['gm', 'pm', 'finance']),
    },
    'timetable.time.end_time': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['gm', 'pm', 'finance']),
    },
    'timetable.time.weekend_start_time': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['gm', 'pm', 'finance']),
    },
    'timetable.time.weekend_end_time': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['gm', 'pm', 'finance']),
    },
    target: {
      validate: validateRequiredNumbers,
      disabled: !authServer.isRoles(['finance', 'gm']),
    },
    'staff.pm': {
      validate: authServer.isRoles(['gm', 'pm', 'campus_lead'])
        ? validateRequired
        : [],
      disabled: !authServer.isRoles(['gm', 'pm', 'campus_lead']),
    },
    'staff.pm_assistant': {
      validate: [],
      disabled: !authServer.isRoles(['gm', 'pm', 'campus_lead']),
    },
    'staff.om': {
      validate: authServer.isRoles(['gm', 'pm', 'outcomes', 'campus_lead'])
        ? validateRequired
        : [],
      disabled: !authServer.isRoles(['gm', 'pm', 'outcomes', 'campus_lead']),
    },
    'staff.om_assistant': {
      validate: [],
      disabled: !authServer.isRoles(['gm', 'pm', 'outcomes', 'campus_lead']),
    },
    'staff.teachers': {
      validate: [],
      disabled: !authServer.isRoles(['gm', 'pm', 'campus_lead']),
    },
    'staff.ta_amount': {
      validate: [],
      disabled: !authServer.isRoles(['gm', 'pm', 'campus_lead']),
    },
    active: {
      validate: [],
      disabled: !authServer.isRoles(['gm', 'campus_lead']),
    },
    sold_out: {
      validate: [],
      disabled: !authServer.isRoles(['gm', 'campus_lead']),
    },
    'prework.title': {
      validate: validateRequired,
      disabled: !(
        authServer.isRoles(['pm', 'education', 'campus_lead']) &&
        props.record.track === 'other'
      ),
    },
    'prework.openedx': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['pm', 'education', 'campus_lead']),
    },
    'course.title': {
      validate: validateRequired,
      disabled: !(
        authServer.isRoles(['pm', 'education', 'campus_lead']) &&
        props.record.track === 'other'
      ),
    },
    'course.openedx': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['pm', 'education', 'campus_lead']),
    },
    'career_services.title': {
      validate: validateRequired,
      disabled: !(
        authServer.isRoles(['pm', 'education', 'campus_lead']) &&
        props.record.track === 'other'
      ),
    },
    'career_services.openedx': {
      validate: validateRequired,
      disabled: !authServer.isRoles(['pm', 'education', 'campus_lead']),
    },
    'external_ids.cybint_access_code': {
      validate: [],
      disabled: !authServer.isRoles(['pm']),
    },
    feature_flags: {
      validate: [],
      disabled: !authServer.isRoles(['admin']),
    },
  };

  return (
    <FormWithRedirect
      {...props}
      mutators={mutators}
      validate={validateCohortEdit}
      render={(formProps) => {
        return (
          <form>
            <Box display="block" p="1.5em">
              <Typography variant="h5" gutterBottom>
                Cohort
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                  <Box>
                    <SelectInput
                      label="Track"
                      source="track"
                      choices={tracks}
                      variant="standard"
                      fullWidth
                      {...fieldsConfig.track}
                    />
                  </Box>
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Format"
                    source="format"
                    choices={formats}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.format}
                  />
                </Box>
                <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                  <Box>
                    <SelectInput
                      label="Campus"
                      source="campus"
                      choices={campusChoices}
                      variant="standard"
                      fullWidth
                      {...fieldsConfig.campus}
                    />
                  </Box>
                  <FormDataConsumer>
                    {({ formData }) =>
                      R.equals('rmt', formData.campus) && (
                        <Box>
                          <SelectInput
                            label="Timezone"
                            source="timezone"
                            choices={cohortTimezones}
                            variant="standard"
                            fullWidth
                            {...fieldsConfig.timezone}
                          />
                        </Box>
                      )
                    }
                  </FormDataConsumer>
                </Box>
                <Box flex={1}>
                  <SelectInput
                    label="Language"
                    source="language"
                    choices={languages}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.language}
                  />
                </Box>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Pricing
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <NumberInput
                    label="Price"
                    source="price.amount"
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig['price.amount']}
                  />
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <NumberInput
                    label="Discount"
                    source="price.discount"
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig['price.discount']}
                  />
                </Box>
                <Box flex={1}>
                  <SelectInput
                    label="Currency"
                    choices={currencies}
                    source="price.currency"
                    variant="standard"
                    fullWidth
                    {...fieldsConfig['price.currency']}
                  />
                </Box>
              </Box>
              <Typography variant="h5" gutterBottom>
                Offerings
              </Typography>
              <ArrayInput
                source="offerings"
                label=""
                validate={validateOfferings}
                fieldKey="campus"
              >
                <SimpleFormIterator classes={formIteratorClasses}>
                  <SelectInput
                    label="Campus"
                    source="campus"
                    choices={R.reject(R.propEq('id', 'rmt'), campusesAll)}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                  <NumberInput
                    label="Price"
                    source="price.amount"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />

                  <NumberInput
                    label="Discount"
                    source="price.discount"
                    validate={validateNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />

                  <SelectInput
                    label="Currency"
                    choices={currencies}
                    source="price.currency"
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                  <NumberInput
                    label="Target"
                    source="target"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />
                </SimpleFormIterator>
              </ArrayInput>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Dates
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <DateInput
                    label="Start Date"
                    source="start_date"
                    options={{
                      format: 'Do MMMM YYYY',
                      openTo: 'year',
                      views: ['year', 'month', 'date'],
                    }}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig['start_date']}
                  />
                </Box>
                <Box flex={1}>
                  <DateInput
                    label="End Date"
                    source="end_date"
                    options={{
                      format: 'Do MMMM YYYY',
                      openTo: 'year',
                      views: ['year', 'month', 'date'],
                    }}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig['end_date']}
                  />
                </Box>
                {props.record.format !== 'other' && <Box mr={{ md: '2em' }} />}
                {props.record.format !== 'other' && (
                  <Box flex={1} mr={{ md: '2em' }}>
                    <DateInput
                      label="Career Week Start Date"
                      source="hiring_fair.start_date"
                      options={{
                        format: 'Do MMMM YYYY',
                        openTo: 'year',
                        views: ['year', 'month', 'date'],
                      }}
                      variant="standard"
                      fullWidth
                      {...fieldsConfig['hiring_fair.start_date']}
                    />
                  </Box>
                )}
                {props.record.format !== 'other' && (
                  <Box flex={1}>
                    <DateInput
                      label="Career Week End Date"
                      source="hiring_fair.end_date"
                      options={{
                        format: 'Do MMMM YYYY',
                        openTo: 'year',
                        views: ['year', 'month', 'date'],
                      }}
                      variant="standard"
                      fullWidth
                      {...fieldsConfig['hiring_fair.end_date']}
                    />
                  </Box>
                )}
              </Box>
              <FormDataConsumer>
                {({ formData }) =>
                  R.equals('pt', formData.format) && (
                    <>
                      <Box display={{ sm: 'block', md: 'flex' }} mt="1em">
                        <Box flex={1}>
                          <SelectInput
                            label="Part Time Days"
                            source="timetable.days"
                            choices={partTimeDays}
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.days']}
                          />
                        </Box>
                        <Box flex={1} ml={{ md: '2em' }}>
                          <SelectInput
                            source="timetable.time.0.start_time"
                            label="Weekday Start Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.time.start_time']}
                          />
                        </Box>
                        <Box flex={1} ml={{ md: '2em' }}>
                          <SelectInput
                            source="timetable.time.0.end_time"
                            label="Weekday End Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.time.end_time']}
                          />
                        </Box>
                      </Box>
                      <Box
                        display={{ sm: 'block', md: 'flex' }}
                        justifyContent="flex-end"
                      >
                        <Box flex={1} />
                        <Box ml={{ md: '2em' }} flex={1}>
                          <SelectInput
                            source="timetable.time.1.start_time"
                            label="Weekend Start Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig[
                              'timetable.time.weekend_start_time'
                            ]}
                          />
                        </Box>
                        <Box ml={{ md: '2em' }} flex={1}>
                          <SelectInput
                            source="timetable.time.1.end_time"
                            label="Weekend End Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.time.weekend_end_time']}
                          />
                        </Box>
                      </Box>
                    </>
                  )
                }
              </FormDataConsumer>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Target
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1}>
                  <NumberInput
                    label="Target"
                    source="target"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.target}
                  />
                </Box>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Staff
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="PM"
                    source="staff.pm"
                    format={(pm) =>
                      pm ? `${pm.email}#${pm.first_name}#${pm.last_name}` : ''
                    }
                    parse={R.pipe(
                      R.defaultTo(''),
                      R.split('#'),
                      R.zipObj(['email', 'first_name', 'last_name'])
                    )}
                    choices={R.map((pm) => {
                      pm.info = `${pm.email}#${pm.first_name}#${pm.last_name}`;
                      return pm;
                    }, R.defaultTo([], pms.data))}
                    variant="standard"
                    optionText={(pm) => `${pm.first_name} ${pm.last_name}`}
                    optionValue="info"
                    fullWidth
                    {...fieldsConfig['staff.pm']}
                  />
                </Box>
                <Box flex={1}>
                  <SelectInput
                    label="PM Assistant"
                    source="staff.pm_assistant"
                    format={(pm) =>
                      pm ? `${pm.email}#${pm.first_name}#${pm.last_name}` : ''
                    }
                    parse={R.pipe(
                      R.defaultTo(''),
                      R.split('#'),
                      R.zipObj(['email', 'first_name', 'last_name']),
                      R.reject(R.isEmpty)
                    )}
                    allowEmpty
                    emptyText="None"
                    choices={R.map((pm) => {
                      pm.info = `${pm.email}#${pm.first_name}#${pm.last_name}`;
                      return pm;
                    }, R.defaultTo([], pms.data))}
                    variant="standard"
                    optionText={(pm) => `${pm.first_name} ${pm.last_name}`}
                    optionValue="info"
                    fullWidth
                    {...fieldsConfig['staff.pm_assistant']}
                  />
                </Box>
              </Box>
              <FormDataConsumer>
                {({ formData }) =>
                  !R.equals('other', formData.track) && (
                    <>
                      <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1} mr={{ md: '2em' }}>
                          <SelectInput
                            label="Student Success Manager"
                            source="staff.om"
                            format={(om) =>
                              om
                                ? `${om.email}#${om.first_name}#${om.last_name}`
                                : ''
                            }
                            parse={R.pipe(
                              R.defaultTo(''),
                              R.split('#'),
                              R.zipObj(['email', 'first_name', 'last_name'])
                            )}
                            choices={R.map((om) => {
                              om.info = `${om.email}#${om.first_name}#${om.last_name}`;
                              return om;
                            }, R.defaultTo([], oms.data))}
                            variant="standard"
                            optionText={(om) =>
                              `${om.first_name} ${om.last_name}`
                            }
                            optionValue="info"
                            fullWidth
                            {...fieldsConfig['staff.om']}
                          />
                        </Box>
                        <Box flex={1}>
                          <SelectInput
                            label="Student Success Assistant"
                            source="staff.om_assistant"
                            format={(om) =>
                              om
                                ? `${om.email}#${om.first_name}#${om.last_name}`
                                : ''
                            }
                            parse={R.pipe(
                              R.defaultTo(''),
                              R.split('#'),
                              R.zipObj(['email', 'first_name', 'last_name']),
                              R.reject(R.isEmpty)
                            )}
                            allowEmpty
                            emptyText="None"
                            choices={R.map((om) => {
                              om.info = `${om.email}#${om.first_name}#${om.last_name}`;
                              return om;
                            }, R.defaultTo([], oms.data))}
                            variant="standard"
                            optionText={(om) =>
                              `${om.first_name} ${om.last_name}`
                            }
                            optionValue="info"
                            fullWidth
                            {...fieldsConfig['staff.om_assistant']}
                          />
                        </Box>
                      </Box>
                      <Box display={{ sm: 'block', md: 'flex' }}>
                        <Box flex={1} mr={{ md: '2em' }}>
                          <SelectArrayInput
                            label="Lead Teachers"
                            source="staff.teachers"
                            choices={R.map((teacher) => {
                              teacher.info = `${teacher.email}#${teacher.first_name}#${teacher.last_name}`;
                              return teacher;
                            }, R.defaultTo([], teachers.data))}
                            format={R.pipe(
                              R.defaultTo([]),
                              R.map((teacher) =>
                                teacher
                                  ? `${teacher.email}#${teacher.first_name}#${teacher.last_name}`
                                  : ''
                              )
                            )}
                            parse={R.pipe(
                              R.defaultTo([]),
                              R.map(
                                R.pipe(
                                  R.defaultTo(''),
                                  R.split('#'),
                                  R.zipObj(['email', 'first_name', 'last_name'])
                                )
                              )
                            )}
                            variant="standard"
                            optionText={(teacher) =>
                              teacher
                                ? `${teacher.first_name} ${teacher.last_name}`
                                : ''
                            }
                            optionValue="info"
                            fullWidth
                            {...fieldsConfig['staff.teachers']}
                          />
                        </Box>
                        <Box flex={1}>
                          <NumberInput
                            source="staff.ta_amount"
                            label="Number Of TAs"
                            variant="standard"
                            min={0}
                            step={1}
                            fullWidth
                            {...fieldsConfig['staff.ta_amount']}
                          />
                        </Box>
                      </Box>
                      <Box mb="3em" />
                    </>
                  )
                }
              </FormDataConsumer>
              <Box>
                <Typography variant="h5" gutterBottom>
                  Web Status
                </Typography>
                <Box display={{ sm: 'block', md: 'flex' }}>
                  <Box flex={1} mr={{ md: '2em' }}>
                    <BooleanInput
                      label="Show On Web"
                      source="active"
                      defaultValue={false}
                      variant="standard"
                      fullWidth
                      {...fieldsConfig.active}
                    />
                  </Box>
                  <Box flex={1}>
                    <BooleanInput
                      label="Show As Sold Out"
                      source="sold_out"
                      defaultValue={false}
                      variant="standard"
                      fullWidth
                      {...fieldsConfig.sold_out}
                    />
                  </Box>
                </Box>
                <Box mb="3em" />
              </Box>
              <Box>
                <Typography variant="h5" gutterBottom>
                  Courses
                </Typography>
                <Box display={{ sm: 'block', md: 'flex' }} mt="10px">
                  <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                    {R.hasPath(['record', 'prework'], props) && (
                      <Box>
                        <Box mb="10px">
                          <Typography variant="h6" gutterBottom>
                            {R.hasPath(['record', 'preparation'], props)
                              ? 'Prework (M0)'
                              : 'Prework'}
                          </Typography>
                        </Box>
                        <FormDataConsumer>
                          {({ formData }) => (
                            <Box>
                              <TextInput
                                source="prework.title"
                                label="Student Portal Title"
                                variant="standard"
                                fullWidth
                                {...fieldsConfig['prework.title']}
                              />
                            </Box>
                          )}
                        </FormDataConsumer>
                        <Box>
                          <TextInput
                            source="prework.openedx"
                            label="OpenEdx Id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['prework.openedx']}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                    <Box mb="10px">
                      <Typography variant="h6" gutterBottom>
                        Main Course
                      </Typography>
                    </Box>
                    <FormDataConsumer>
                      {({ formData }) => (
                        <Box>
                          <TextInput
                            source="course.title"
                            label="Student Portal Title"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['course.title']}
                          />
                        </Box>
                      )}
                    </FormDataConsumer>
                    <Box>
                      <TextInput
                        source="course.openedx"
                        label="OpenEdx Id"
                        variant="standard"
                        fullWidth
                        {...fieldsConfig['course.openedx']}
                      />
                    </Box>
                  </Box>
                  <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                    {R.hasPath(['record', 'career_services'], props) && (
                      <Box>
                        <Box mb="10px">
                          <Typography variant="h6" gutterBottom>
                            Career Services
                          </Typography>
                        </Box>
                        <Box>
                          <TextInput
                            source="career_services.title"
                            label="Student Portal Title"
                            variant="standard"
                            defaultValue="Careerhack"
                            fullWidth
                            {...fieldsConfig['career_services.title']}
                          />
                        </Box>
                        <Box>
                          <TextInput
                            source="career_services.openedx"
                            label="OpenEdx Id"
                            variant="standard"
                            defaultValue="course-v1:IRONHACK+CAREERHACK_v2+MASTER"
                            fullWidth
                            {...fieldsConfig['career_services.openedx']}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                {authServer.isRoles(['admin']) && !emailsLoading && (
                  <>
                    <Typography variant="h5" gutterBottom>
                      Cohort specifics
                    </Typography>
                    <SelectInput
                      label="Course Welcome email"
                      source="course.email_templates.welcome_email_template_id"
                      choices={getEmailTemplateNames([
                        'course_welcome',
                        'course_welcome_preparation',
                      ])}
                      variant="standard"
                      fullWidth
                    />
                    <SelectInput
                      label="Career Week Welcome email"
                      source="career_services.email_templates.welcome_email_template_id"
                      choices={getEmailTemplateNames(['career_welcome'])}
                      variant="standard"
                      fullWidth
                    />
                    <Box width="120px" mt="30px">
                      <BooleanInput
                        label="AI Chatbot active"
                        source="feature_flags.ai_chatbot_enabled"
                        defaultValue={false}
                        variant="standard"
                        fullWidth
                        {...fieldsConfig.feature_flags}
                      />
                      <BooleanInput
                        label="Labs active"
                        source="feature_flags.labs_enabled"
                        defaultValue={false}
                        variant="standard"
                        fullWidth
                        {...fieldsConfig.feature_flags}
                      />
                    </Box>
                  </>
                )}
                <FormDataConsumer>
                  {({ formData }) => {
                    const isBerlinCohort =
                      formData.campus === 'ber' ||
                      (formData.timezone === 'Europe/Madrid' &&
                        formData.language === 'en');

                    return isBerlinCohort && <AdditionalInformationEdit />;
                  }}
                </FormDataConsumer>
              </Box>
              <FormDataConsumer>
                {({ formData }) =>
                  R.propEq('track', 'cy', formData) && (
                    <Box>
                      <Typography variant="h5" gutterBottom>
                        External IDs
                      </Typography>
                      <Box>
                        <TextInput
                          source="external_ids.cybint_access_code"
                          label="Cybint Access Code"
                          variant="standard"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  )
                }
              </FormDataConsumer>
            </Box>
            <Toolbar>
              {authServer.isRolesAndCampuses(
                ['gm', 'pm', 'finance', 'outcomes', 'campus_lead', 'education'],
                [props.record.campus]
              ) && (
                <Box display="flex" justifyContent="space-between" width="100%">
                  <CohortSaveButton
                    record={formProps.record}
                    basePath={formProps.basePath}
                    undoable={true}
                    invalid={formProps.invalid}
                    saving={formProps.saving}
                    resource="cohorts"
                    submitOnEnter={false}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                  {authServer.isRoles(['finance']) && (
                    <FormDataConsumer>
                      {({ formData }) => {
                        const calculateCohortDates = () => {
                          const mutator = R.path(
                            ['form', 'mutators', 'cohortDatesMutator'],
                            formProps
                          );
                          mutator();
                        };
                        return (
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<DateRange />}
                            onClick={calculateCohortDates}
                            disabled={
                              !R.allPass([
                                R.has('format'),
                                R.has('start_date'),
                                R.complement(R.propEq)('format', 'other'),
                              ])(formData)
                            }
                          >
                            Calculate Cohort Dates
                          </Button>
                        );
                      }}
                    </FormDataConsumer>
                  )}
                </Box>
              )}
            </Toolbar>
          </form>
        );
      }}
    />
  );
};

const CohortEdit = ({ classes, ...props }) => (
  <Edit title={<CohortTitle />} actions={null} undoable={false} {...props}>
    <CohortEditForm redirect="show" />
  </Edit>
);

export default CohortEdit;

import React from 'react';
import {
  EditButton as EmailEdit,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
} from 'react-admin';
import { EmailDuplicate } from '../../resources/emails';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { authServer } from '../../auth/keycloak';

const useRowControlsStyles = makeStyles({
  row: {
    '& > td:last-of-type > div': {
      textAlign: 'right',
      opacity: 0,
    },
    '&:hover': {
      '& > td:last-of-type > div': {
        opacity: 1,
      },
    },
  },
});

const RowControls = ({ record, basePath }) => {
  return (
    <Box>
      {authServer.isRoles(['product']) && (
        <EmailDuplicate record={record} basePath={basePath} />
      )}
      <EmailEdit record={record} basePath={basePath} />
    </Box>
  );
};

export default ({ ...props }) => {
  const classes = useRowControlsStyles();
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      filters={
        <Filter>
          <SearchInput
            placeholder="Search by email name"
            source="name_search"
            alwaysOn
          />
        </Filter>
      }
    >
      <Datagrid classes={{ row: classes.row }}>
        <TextField label="Template Name" source="name" sortable={false} />
        <TextField label="Language" source="language" sortable={false} />
        <RowControls {...props} />
      </Datagrid>
    </List>
  );
};

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const dataProvider = {
  getOne: async params => {
    const query = params.filter;
    const options = {
      method: 'POST',
      body: JSON.stringify(query),
      headers: { 'Content-Type': 'application/json' }
    };
    const url = `${apiUrl}/compute`;
    return { url, options };
  }
};

export default dataProvider;

import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  CreateButton,
  Datagrid,
  Filter,
  List,
  Loading,
  Pagination,
  SelectArrayInput,
  SelectField,
  TextField,
  TopToolbar,
  useGetList,
} from 'react-admin';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';

import { BookmarkField } from '../../common/cohorts/list/fields';
import { DateInput } from 'resources/common/DatePickers';

import { authServer } from 'auth/keycloak';
import { formats, cohortStatus, tracksB2b, b2bProjectTypes } from 'data';
import { DateField, OpenInNewIconButton } from 'resources/common';
import CohortB2bListAside from './CohortB2bListAside';

const useFilterStyles = makeStyles({ filter: { width: 240 } });
const useListStyles = makeStyles({ openInNew: { width: 30 } });

const CohortFilter = ({ ...props }) => {
  const classes = useFilterStyles();

  return (
    <Filter {...props} variant="standard">
      <SelectArrayInput
        label="Status"
        source="status"
        choices={cohortStatus}
        className={classes.filter}
      />
      <SelectArrayInput
        label="Track"
        source="track"
        choices={tracksB2b}
        className={classes.filter}
      />
      <SelectArrayInput
        label="Project Type"
        source="project_type"
        choices={b2bProjectTypes}
        className={classes.filter}
      />
      <DateInput
        label="Starts On Or After"
        source="starts_since"
        options={{ clearable: true, format: 'Do MMMM YYYY' }}
        isRequired={false}
      />
      <DateInput
        label="Starts On Or Before"
        source="starts_before"
        options={{ clearable: true, format: 'Do MMMM YYYY' }}
        isRequired={false}
      />
    </Filter>
  );
};

const CohortListB2bToolbar = ({
  basePath,
  displayedFilters,
  filterValues,
  filters,
  resource,
  showFilter,
}) => {
  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const CohortListB2bPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const CohortListB2b = (props) => {
  const classes = useListStyles();
  const [isLoaded, setLoaded] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState({ status: ['ongoing'] });

  const { data, loaded } = useGetList(
    'cohorts_b2b',
    {},
    {},
    { bookmarked_by: authServer.getUser() }
  );

  useEffect(() => {
    if (!R.isEmpty(data)) {
      setDefaultFilters({
        bookmarked_by: [authServer.getUser()],
      });
    }
    setLoaded(loaded);
  }, [loaded]); //eslint-disable-line

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  if (!isLoaded) {
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  }

  return (
    <List
      toolbar={<CohortListB2bToolbar />}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      aside={<CohortB2bListAside />}
      filters={isSmall ? <CohortFilter /> : undefined}
      filterDefaultValues={defaultFilters}
      pagination={<CohortListB2bPagination />}
      sort={{ field: 'start_date', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        {!isXSmall && <OpenInNewIconButton cellClassName={classes.openInNew} />}
        <TextField label="Course title" source="course.title" />
        <SelectField source="track" choices={tracksB2b} />
        <SelectField source="project_type" choices={b2bProjectTypes} />
        <SelectField
          label="Format"
          source="format"
          choices={formats}
          sortable={false}
        />
        <DateField label="Start Date" source="start_date" />
        <BookmarkField label="Bookmark" />
      </Datagrid>
    </List>
  );
};

export default CohortListB2b;

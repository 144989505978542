import React from 'react';

import { Labeled } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';

import {
  OutcomesCategoryField,
  OutcomesStatusField,
} from 'resources/students/fields';

import { StatusTimeline } from '.';

const StatusInfo = ({ student }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            Status
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Category">
          <OutcomesCategoryField
            record={student}
            source="career_services.category"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Status">
          <OutcomesStatusField
            record={student}
            source="career_services.status"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Labeled label="Timeline" fullWidth>
          <StatusTimeline
            record={student}
            source="career_services.status_history"
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default StatusInfo;

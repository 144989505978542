import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Logo from './Logo';
import { ProductHelpLinks } from './ProductHelpLinks';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: { flex: 1 },
});

const CustomAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <Logo />
      <span className={classes.spacer} />
      <ProductHelpLinks />
    </AppBar>
  );
};

export default CustomAppBar;

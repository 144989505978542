import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const changesProvider = {
  getManyReference: async params => {
    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const query = { page, limit };
    const url = `${apiUrl}/changes/${params.id}?${stringify(query)}`;
    return { url };
  }
};

export default changesProvider;

import React from 'react';
import * as R from 'ramda';
import { BooleanField } from 'react-admin';

const HasCommentField = ({ record: answers }) => {
  const hasComment = R.pipe(
    R.propOr([], 'answers'),
    R.find(R.propEq('question', 'comments')),
    R.defaultTo({}),
    R.prop('answer'),
    R.isNil,
    R.not
  )(answers);
  return <BooleanField record={{ hasComment }} source="hasComment" />;
};

export default HasCommentField;

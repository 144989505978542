const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const actionsProvider = {
  getList: async (params) => {
    const url = `${apiUrl}/actions/search`;
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
  create: async (params) => {
    const url = `${apiUrl}/actions`;
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
  update: async (params) => {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = `${apiUrl}/actions/${params.id}`;
    return { url, options };
  },
};

export default actionsProvider;

import React from 'react';
import * as R from 'ramda';

const ChangesCodeBlock = ({ record }) => {
  const text = `<pre class="prettyprint lang-js" style="border: none">${JSON.stringify(
    R.propOr(record.changes, 'doc', record),
    null,
    2
  )}</pre>`;
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
};

export default ChangesCodeBlock;

import React from 'react';
import { capitalize } from 'lodash';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import actionsMap from './actions';
import { authServer } from 'auth/keycloak';

const Action = (props) => {
  const { action, onSelected } = props;
  const ActionShow = actionsMap[action.type].Show;
  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6" component="h3">
          {action.type.split('_').map(capitalize).join(' ')}
        </Typography>
        {authServer.isRoles(action.permissions.edit) && (
          <Box ml="8px">
            <IconButton onClick={onSelected} size="small">
              <Edit size="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box display="flex" flex={1}>
        <ActionShow action={action} />
      </Box>
    </>
  );
};

export default Action;

import React from 'react';
import * as R from 'ramda';
import { Chip } from '@material-ui/core';

import { getGradingColors } from 'lib/gradingColors';

const OutcomesSatisfactionField = ({ record: student, stage, area }) => {
  const score = R.pipe(
    R.pathOr([], ['career_services', 'surveys']),
    R.find(R.propEq('stage', stage)),
    R.defaultTo({}),
    R.prop(area)
  )(student);
  const colors = getGradingColors(score);
  return score ? (
    <Chip
      style={{ color: colors.typography, backgroundColor: colors.background }}
      label={score}
    />
  ) : null;
};

export default OutcomesSatisfactionField;

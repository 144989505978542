import React from 'react';
import * as R from 'ramda';
import { Box } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import {
  HelpOutline,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from '@material-ui/icons';
import { ResponsiveBar } from '@nivo/bar';

const colorMap = {
  very_good: green[900],
  good: green[500],
  normal: grey[500],
  bad: red[500],
  very_bad: red[900],
  not_graded: '#BCB6E2',
};

const iconMap = {
  very_good: SentimentVerySatisfied,
  good: SentimentSatisfiedAlt,
  normal: SentimentSatisfied,
  bad: SentimentDissatisfied,
  very_bad: SentimentVeryDissatisfied,
  not_graded: HelpOutline,
};

const AssignmentGradeDistributionField = (props) => {
  const { record, source } = props;

  const isRequired = R.propEq('required', true);
  const graphData = R.pipe(
    R.pathOr([], R.split('.', source)),
    R.when(R.any(isRequired), R.filter(isRequired)),
    R.filter(R.pipe(R.propOr('', 'status'), R.startsWith('delivered'))),
    R.pluck('grade'),
    R.reduce((acc, grade) => {
      return R.over(
        R.lensProp(grade ? grade : 'not_graded'),
        R.pipe(R.defaultTo(0), R.inc)
      )(acc);
    }, {}),
    R.of
  )(record);
  return (
    <Box height="40px" width="98%">
      <ResponsiveBar
        data={graphData}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        keys={['very_good', 'good', 'normal', 'bad', 'very_bad', 'not_graded']}
        layout="horizontal"
        tooltip={({ id, color }) => {
          const Icon = iconMap[id];
          return <Icon style={{ color }} />;
        }}
        labelTextColor={R.pipe(
          R.propOr('', 'key'),
          R.ifElse(R.startsWith('very_'), R.always('white'), R.always('black'))
        )}
        colors={({ id }) => colorMap[id]}
      />
    </Box>
  );
};

AssignmentGradeDistributionField.defaultProps = {
  addLabel: true,
  label: 'Grades',
  source: 'grade',
};

export default AssignmentGradeDistributionField;

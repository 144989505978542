import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { ListContextProvider } from 'react-admin';
import * as hash from 'hash-sum';

export default (props) => {
  const {
    list,
    uniqueKey,
    defaultSort = { field: '', order: 'ASC' },
    sorters = {},
    children,
  } = props;

  const dataMap = useMemo(() => R.indexBy(R.prop(uniqueKey), list), [
    list,
    uniqueKey,
  ]);

  const [currentSort, setSort] = useState(defaultSort);
  const [sortedKeys, setSortedKeys] = useState(R.pluck(uniqueKey, list));

  const cachedSorters = useMemo(() => R.map(R.memoizeWith(hash), sorters), [
    sorters, //eslint-disable-line
  ]);

  const handleSort = (field, order) => {
    const sorter = R.propOr(R.path(R.split('.', field)), field, cachedSorters);
    setSortedKeys(
      R.pipe(
        R.sortBy(sorter),
        R.pluck(uniqueKey),
        R.when(R.always(R.equals(order, 'DESC')), R.reverse)
      )(list)
    );
    setSort({ field, order });
  };

  // Perform initial sort
  useEffect(() => {
    if (!R.isEmpty(defaultSort.field)) {
      handleSort(defaultSort.field, defaultSort.order);
    }
  }, []); //eslint-disable-line

  return (
    <ListContextProvider
      value={{
        basePath: '',
        data: dataMap,
        ids: sortedKeys,
        currentSort: currentSort,
        setSort: handleSort,
        selectedIds: [],
      }}
    >
      {children}
    </ListContextProvider>
  );
};

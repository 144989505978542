import React from 'react';
import * as R from 'ramda';

const getUrl = {
  card: R.pipe(
    R.prop('profile_slug'),
    R.concat(`${process.env.REACT_APP_PROFILE_BASE_URL}/`)
  ),
  cv: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'cv')),
    R.prop('url')
  ),
  github: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'github')),
    R.prop('url')
  ),
  linkedin: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'linkedin')),
    R.prop('url')
  ),
  portfolio: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'portfolio')),
    R.prop('url')
  ),
  medium: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'medium')),
    R.prop('url')
  ),
};

const ProfileLinkField = ({ record = {}, target }) => {
  const getText = {
    card: R.pipe(R.split('/'), R.reject(R.isEmpty), R.last),
    cv: R.always('CV Link'),
    github: R.always('GitHub Link'),
    linkedin: R.always('LinkedIn Link'),
    portfolio: R.always('Portfolio Link'),
    medium: R.always('Medium Link'),
  };
  const url = getUrl[target](record);
  const text = getText[target](url);
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

export default ProfileLinkField;

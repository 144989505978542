import React from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import {
  Create,
  Error,
  Loading,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { Box } from '@material-ui/core';

import { authServer } from 'auth/keycloak';
import { dropReasons } from 'data';
import { DateInput } from 'resources/common/DatePickers';
import { FullNameField } from 'resources/students/fields';

const validateRequired = [required()];

const DropCreateToolbar = (props) => (
  <Toolbar {...props}>
    {authServer.isRoles(['pm']) && <SaveButton label="Drop/Expel" />}
  </Toolbar>
);

const DropCreate = (props) => {
  const redirect = useRedirect();
  const studentId = R.pipe(
    R.pathOr({}, ['location', 'pathname']),
    R.split('/'),
    R.nth(3)
  )(props);
  if (!R.test(/^[a-f\d]{24}$/, studentId)) {
    redirect('/students');
  }

  const {
    data: student,
    loading: studentLoading,
    error: studentError,
  } = useGetOne('students', studentId);

  if (studentLoading) {
    return (
      <Box>
        <Loading />
      </Box>
    );
  }
  if (studentError) {
    return (
      <Box>
        <Error error={studentError} errorInfo={studentError} />
      </Box>
    );
  }

  if (!student) {
    return null;
  }

  return (
    <Create
      {...props}
      actions={null}
      undoable={false}
      title="Drop/Expel Student"
    >
      <SimpleForm
        redirect="/students"
        toolbar={<DropCreateToolbar />}
        initialValues={{ student: studentId }}
      >
        <FullNameField size={50} data={student} />
        <SelectInput
          variant="standard"
          label="Reason"
          source="reason"
          choices={dropReasons}
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          variant="standard"
          label="Comment"
          source="comment"
          fullWidth
          validate={validateRequired}
        />
        <DateInput
          label="Effective Date"
          source="date"
          options={{
            clearable: true,
            format: 'Do MMMM YYYY',
            disableFuture: true,
          }}
          fullWidth
          variant="standard"
          validate={validateRequired}
          defaultValue={moment().format('YYYY-MM-DD')}
        />
      </SimpleForm>
    </Create>
  );
};
export default DropCreate;

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin';

import { authServer } from './keycloak';

export default type => {
  if (type === AUTH_LOGIN) {
    authServer.login({ redirectUri: window.location.origin });
  } else if (type === AUTH_LOGOUT) {
    authServer.logout({ redirectUri: window.location.origin });
    return Promise.resolve();
  } else if (type === AUTH_CHECK) {
    const isExpired = authServer.isTokenExpired(30);
    return isExpired
      ? Promise.reject({ redirectTo: '/no-access' })
      : Promise.resolve();
  } else if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve(authServer.realmAccess.roles);
  } else {
    return Promise.resolve();
  }
};

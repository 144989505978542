import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import moment from 'moment-timezone';
import * as R from 'ramda';

import {
  Button,
  Datagrid,
  DateField,
  downloadCSV,
  Error,
  Link,
  Loading,
  ReferenceField,
  SelectField,
  TextField,
  Toolbar,
  useQuery,
  useNotify,
} from 'react-admin';
import {
  Box,
  Button as MaterialButton,
  makeStyles,
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core';
import { PersonAdd, FileCopy, GetApp } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import jsonExport from 'jsonexport/dist';
import { ResponsiveBar } from '@nivo/bar';

import { authServer } from 'auth/keycloak';
import {
  CohortNameField,
  EmailField,
  ExpertiseAreasField,
  FullNameField,
  HasCertificateField,
  HasGraduatedField,
  OutcomesCategoryField,
  OutcomesFormPendingField,
  OutcomesNotesButton,
  RiskLevelField,
} from 'resources/students/fields';
import { OfflineList } from 'resources/common';
import { dropReasons, dropReasonsOld } from 'data';
import CreateStudentsCertificates from './components/CreateStudentsCertificates';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  enroll_button: {},
}));
const StudentsBarChart = ({ data, target, handleClick }) => {
  const barData = R.pipe(R.map(R.length), R.reject(R.equals(0)))(data);
  const markers = target
    ? [
        {
          axis: 'x',
          value: target,
          lineStyle: { stroke: 'rgba(0, 0, 0, .5)', strokeWidth: 4 },
          legend: 'Target Booked Students',
          legendOrientation: 'horizontal',
          legendOffsetX: -170,
        },
      ]
    : [];
  const colors = {
    expel: red[300],
    drop: red[100],
    move: '#e0e0e0',
    active: green[300],
  };
  const totalStudents = R.sum(R.values(barData));
  const maxValue = target >= totalStudents ? target : totalStudents;
  return (
    <ResponsiveBar
      data={[{ ...barData, index: 'Students' }]}
      indexBy="index"
      keys={['active', 'move', 'drop', 'expel']}
      layout="horizontal"
      colors={({ id }) => colors[id]}
      margin={{ top: 16, right: 32, bottom: 66, left: 16 }}
      padding={0.3}
      theme={{ labels: { text: { fontSize: 16, fontStyle: 'bold' } } }}
      markers={markers}
      enableGridY={false}
      enableGridX={true}
      axisTop={null}
      axisRight={null}
      minValue={0}
      maxValue={maxValue}
      axisBottom={{
        tickValues: R.range(0, maxValue + 1),
        tickSize: 0,
        legendPosition: 'middle',
      }}
      axisLeft={null}
      label={(d) => `${d.id}: ${d.value}`}
      onClick={handleClick}
    />
  );
};

const AddStudentButton = ({ record }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.enroll_button}
      component={Link}
      variant="outlined"
      to={{
        pathname: '/students/create',
        search: `?cohort=${R.prop('id', record)}`,
      }}
      label="Add A Student"
    >
      <PersonAdd />
    </Button>
  );
};

const ExportStudentsButton = ({ students }) => {
  const exportStudents = () => {
    const studentsForExport = R.map(
      R.pick(['first_name', 'last_name', 'email']),
      students
    );
    jsonExport(
      studentsForExport,
      { headers: ['first_name', 'last_name', 'email'] },
      (error, csv) => {
        downloadCSV(csv, 'students');
      }
    );
  };

  return (
    <MaterialButton
      variant="outlined"
      color="primary"
      startIcon={<GetApp />}
      onClick={exportStudents}
    >
      Export Students CSV
    </MaterialButton>
  );
};

const shouldShow = (field) => {
  const roleFieldsMap = {
    outcomes: [
      'outcomes_category',
      'outcomes_form',
      'outcomes_one_on_one',
      'outcomes_status',
    ],
    pm: ['outcomes_category', 'outcomes_status'],
    gm: ['outcomes_category', 'outcomes_status'],
    campus_lead: ['outcomes_category', 'outcomes_status'],
    teacher: ['outcomes_category', 'outcomes_status'],
    ta: ['outcomes_category', 'outcomes_status'],
  };
  const roles = authServer.getRoles();
  return R.pipe(
    R.pick(R.__, roleFieldsMap),
    R.values,
    R.unnest,
    R.uniq,
    R.includes(field)
  )(roles);
};

const StudentsTab = ({ basePath, record, toolbar, ...rest }) => {
  const notify = useNotify();
  const classes = useStyles();
  const [tab, setTab] = useState('active');
  const [allStudents, setAllStudents] = useState({});

  const handleTabChange = (newTab) => {
    setTab(newTab);
  };

  const {
    data: students,
    loading: studentsLoading,
    error: studentsError,
    refetch: studentsRefetch,
  } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: record.id,
      pagination: {
        perPage: 100,
      },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  const {
    data: drops,
    loading: dropsLoading,
    error: dropsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'drop',
    payload: {
      search: [{ $match: { cohort: record.id } }],
      pagination: {
        perPage: 1000,
      },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  useEffect(() => {
    if (students) {
      setAllStudents({ ...allStudents, active: students });
    }
  }, [students]); //eslint-disable-line

  useEffect(() => {
    if (drops) {
      const studentsByDropCategory = R.reduce(
        (acc, student) => {
          R.cond([
            [R.hasPath(['drop', 'cohort']), (s) => acc.move.push(s)],
            [
              R.pathSatisfies(
                R.anyPass([
                  R.pipe(R.defaultTo(''), R.startsWith('expel_')),
                  R.includes(R.__, ['bad_candidate', 'prework']),
                ]),
                ['drop', 'reason']
              ),
              (s) => acc.expel.push(s),
            ],
            [R.T, (s) => acc.drop.push(s)],
          ])(student);
          return acc;
        },
        { move: [], drop: [], expel: [] }
      )(drops);
      setAllStudents({ ...allStudents, ...studentsByDropCategory });
    }
  }, [drops]); //eslint-disable-line

  if (studentsLoading || dropsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (studentsError || dropsError)
    return (
      <Box p="16px">
        <Error
          error={studentsError || dropsError}
          errorInfo={studentsError || dropsError}
        />
      </Box>
    );

  // Less than 4 keys mean data is still missing
  if (R.keys(allStudents).length < 4) return null;
  const cohortIsFinished = moment().isAfter(record.end_date);
  const hasCohortRiskEvaluation = R.hasPath(['evaluation', 'risk'], record);

  return (
    <Box display="block" mt="16px">
      <Box ml="16px" height="150px" width="98%">
        <StudentsBarChart
          data={allStudents}
          target={record.target}
          handleClick={({ id }) => {
            handleTabChange(id);
          }}
        />
      </Box>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        value={tab}
        onChange={(event, newTab) => handleTabChange(newTab)}
        centered
      >
        <Tab
          label="Active"
          classes={{ selected: classes.selected }}
          key="active"
          value="active"
        />
        <Tab
          label="Moved"
          classes={{ selected: classes.selected }}
          key="move"
          value="move"
        />
        <Tab
          label="Dropped"
          classes={{ selected: classes.selected }}
          key="drop"
          value="drop"
        />
        <Tab
          label="Expelled"
          classes={{ selected: classes.selected }}
          key="expel"
          value="expel"
        />
      </Tabs>
      {tab === 'active' && (
        <OfflineList
          list={allStudents.active}
          uniqueKey="id"
          defaultSort={{ field: 'first_name', order: 'ASC' }}
        >
          <Datagrid
            rowClick={(id, basePath, record) => `/students/${record.id}/show`}
          >
            <FullNameField copyable link />
            <EmailField copyable />
            {shouldShow('outcomes_category') && (
              <OutcomesCategoryField
                label="Category"
                record={record}
                source="career_services.category"
                {...rest}
              />
            )}
            <ExpertiseAreasField />
            <HasGraduatedField cohortIsFinished={cohortIsFinished} />
            <HasCertificateField />
            {shouldShow('outcomes_form') && cohortIsFinished && (
              <OutcomesFormPendingField
                label="Outcomes Form"
                record={record}
                {...rest}
              />
            )}
            {hasCohortRiskEvaluation && (
              <RiskLevelField label="At risk info" record={record} {...rest} />
            )}
            {shouldShow('outcomes_one_on_one') && (
              <OutcomesNotesButton
                label="One On One"
                record={record}
                source="career_services.notes"
                sortable={false}
                {...rest}
              />
            )}
          </Datagrid>
        </OfflineList>
      )}
      {tab === 'move' && (
        <OfflineList
          list={allStudents.move}
          uniqueKey="id"
          defaultSort={{ field: 'drop.date', order: 'DESC' }}
        >
          <Datagrid>
            <FullNameField />
            <SelectField
              label="Reason"
              record={record}
              source="drop.reason"
              choices={[...dropReasons, ...dropReasonsOld]}
              {...rest}
            />
            <DateField
              label="Date"
              record={record}
              source="drop.date"
              {...rest}
            />
            <TextField
              label="Comment"
              record={record}
              source="drop.comment"
              {...rest}
            />
            <ReferenceField
              label="Moved To"
              source="drop.cohort"
              reference="cohorts"
              link="show"
              basePath="/cohorts"
            >
              <CohortNameField />
            </ReferenceField>
          </Datagrid>
        </OfflineList>
      )}
      {tab === 'drop' && (
        <OfflineList
          list={allStudents.drop}
          uniqueKey="id"
          defaultSort={{ field: 'drop.date', order: 'DESC' }}
        >
          <Datagrid>
            <FullNameField />
            <SelectField
              label="Reason"
              record={record}
              source="drop.reason"
              choices={[...dropReasons, ...dropReasonsOld]}
              {...rest}
            />
            <DateField
              label="Date"
              record={record}
              source="drop.date"
              {...rest}
            />
            <TextField
              label="Comment"
              record={record}
              source="drop.comment"
              {...rest}
            />
          </Datagrid>
        </OfflineList>
      )}
      {tab === 'expel' && (
        <OfflineList
          list={allStudents.expel}
          uniqueKey="id"
          defaultSort={{ field: 'drop.date', order: 'DESC' }}
        >
          <Datagrid>
            <FullNameField />
            <SelectField
              label="Reason"
              record={record}
              source="drop.reason"
              choices={[...dropReasons, ...dropReasonsOld]}
              {...rest}
            />
            <DateField
              label="Date"
              record={record}
              source="drop.date"
              {...rest}
            />
            <TextField
              label="Comment"
              record={record}
              source="drop.comment"
              {...rest}
            />
          </Datagrid>
        </OfflineList>
      )}
      <Toolbar>
        <Grid container spacing={1} direction="row">
          <Grid container item xs={12} spacing={2}>
            {authServer.isRoles(['pm']) && record.track === 'other' ? (
              <Grid item>
                <AddStudentButton record={record} />{' '}
              </Grid>
            ) : null}

            {authServer.isRoles(['outcomes']) && cohortIsFinished ? (
              <Grid item>
                <MaterialButton
                  variant="outlined"
                  color="primary"
                  startIcon={<FileCopy />}
                  onClick={() => {
                    copy(
                      `${process.env.REACT_APP_PORTAL_BASE_URL}/outcomes-form`
                    );
                    notify('Outcomes From URL Copied To Clipboard!');
                  }}
                >
                  Copy Outcomes Form URL
                </MaterialButton>{' '}
              </Grid>
            ) : null}

            {authServer.isRoles([
              'outcomes',
              'pm',
              'gm',
              'teacher',
              'ta',
              'campus_lead',
            ]) ? (
              <Grid item>
                <ExportStudentsButton students={allStudents[tab]} />{' '}
              </Grid>
            ) : null}

            {authServer.isRoles(['pm']) &&
            cohortIsFinished &&
            tab === 'active' ? (
              <Grid item>
                <CreateStudentsCertificates
                  data={record}
                  onCtaClicked={() => studentsRefetch()}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  );
};

export default StudentsTab;

import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  Datagrid,
  Labeled,
  ReferenceManyField,
  ShowController,
  ShowView,
  Tab,
  TextField,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { authServer } from 'auth/keycloak';
import { ChangesCodeBlock, DateField, NavigableTabs } from 'resources/common';
import {
  FullNameField,
  KeycloakField,
  QualifiedField,
  SalesforceField,
} from 'resources/students/fields';
import {
  CareerhackProgressTab,
  InformationTab,
  OneOnOnesTab,
  ProgressTab,
  SurveysTab,
} from './tabs';
import StudentShowActions from './StudentShowActions/StudentShowActions';

const StudentTitle = ({ record }) =>
  record ? (
    <FullNameField
      record={record}
      size={32}
      variant="h6"
      style={{ color: 'white' }}
      showBadge={false}
    />
  ) : null;

const StudentShow = (props) => {
  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  return (
    // Forces the max with to allow tabs to be scrollable and avoid horizontal scroll
    <Box css={{ maxWidth: `calc(100vw - ${isSidebarOpen ? 229 : 84}px)` }}>
      <ShowController {...props}>
        {(controllerProps) => {
          const record = R.propOr({}, 'record', controllerProps);
          return (
            <ShowView
              {...props}
              {...R.omit(['translate'], controllerProps)}
              title={<StudentTitle />}
              actions={<StudentShowActions />}
            >
              <NavigableTabs
                matchUrl={R.pipe(R.split('/'), R.take(5), R.join('/'))}
                variant="scrollable"
              >
                <Tab label="Information" path="info">
                  <InformationTab {...props} />
                </Tab>
                {authServer.isRoles(['pm', 'gm', 'campus_lead']) ? (
                  <Tab label="Surveys" path="surveys">
                    <SurveysTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles([
                  'pm',
                  'ta',
                  'teacher',
                  'outcomes',
                  'campus_lead',
                ]) && record.preparation ? (
                  <Tab label=" Prep Work Progress" path="preparation_progress">
                    <ProgressTab course="preparation" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles([
                  'pm',
                  'ta',
                  'teacher',
                  'outcomes',
                  'campus_lead',
                ]) ? (
                  <Tab label=" Prework Progress" path="prework_progress">
                    <ProgressTab course="prework" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles([
                  'pm',
                  'ta',
                  'teacher',
                  'outcomes',
                  'campus_lead',
                ]) ? (
                  <Tab label="Course Progress" path="course_progress">
                    <ProgressTab course="course" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['outcomes', 'campus_lead']) ? (
                  <Tab label="Progress" path="careerhack_progress">
                    <CareerhackProgressTab {...props} />
                  </Tab>
                ) : null}
                <Tab label="One On Ones" path="one_on_ones">
                  <OneOnOnesTab {...props} />
                </Tab>
                {authServer.isRoles(['product']) ? (
                  <Tab label="History" path="history">
                    <ReferenceManyField
                      addLabel={false}
                      source="id"
                      reference="changes"
                      target="id"
                    >
                      <Datagrid rowClick="expand" expand={<ChangesCodeBlock />}>
                        <TextField label="Event" source="event" />
                        <DateField label="Date" source="date" showTime />
                        <TextField label="User" source="user" />
                        <TextField label="App" source="client" />
                      </Datagrid>
                    </ReferenceManyField>
                  </Tab>
                ) : null}
                {authServer.isRoles(['product']) ? (
                  <Tab label="Meta" path="meta">
                    <Box px="16px" pt="16px">
                      <Box>
                        <Labeled label="Internal Id">
                          <TextField source="id" record={record} />
                        </Labeled>
                      </Box>
                      <Box>
                        <Labeled label="Keycloak Id">
                          <KeycloakField label="Keycloak Id" record={record} />
                        </Labeled>
                      </Box>
                      <Box>
                        <Labeled label="Salesforce Account">
                          <SalesforceField
                            salesforceObject="Account"
                            idName="contact"
                            record={record}
                          />
                        </Labeled>
                      </Box>
                      <Box>
                        <Labeled label="Salesforce Applicant">
                          <SalesforceField
                            label="Salesforce Applicant"
                            salesforceObject="Opportunity"
                            idName="applicant"
                            record={record}
                          />
                        </Labeled>
                      </Box>
                      <Box>
                        <Labeled label="Salesforce Student">
                          <SalesforceField
                            label="Salesforce Student"
                            salesforceObject="Enrolled_Student__c"
                            idName="student"
                            record={record}
                          />
                        </Labeled>
                      </Box>
                      {R.path(['external_ids', 'qualified'], record) && (
                        <Box>
                          <Labeled label="Qualified Candidate">
                            <QualifiedField
                              label="Qualified Candidate"
                              record={record}
                            />
                          </Labeled>
                        </Box>
                      )}
                    </Box>
                  </Tab>
                ) : null}
              </NavigableTabs>
            </ShowView>
          );
        }}
      </ShowController>
    </Box>
  );
};

export default StudentShow;

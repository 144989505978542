import React from 'react';
import * as R from 'ramda';
import {
  FileField,
  FileInput,
  FormDataConsumer,
  Labeled,
  NullableBooleanInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { notGraduatedReasons } from '../../../../data';

const validateRequired = [required()];

const useStyles = makeStyles({
  dropZone: {
    borderStyle: 'dashed',
    borderColor: 'rgba(0, 0, 0, 0.54)',
  },
});

const GraduationPanel = ({ formProps }) => {
  const classes = useStyles();
  const requireProof = ({ dirtyFields }) => {
    return R.pipe(
      R.filter(R.identity),
      R.keys,
      R.intersection([
        'graduation.graduated',
        'graduation.not_graduated_reason',
      ]),
      R.length,
      R.lt(0)
    )(dirtyFields);
  };
  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant="h5" component="h3" gutterBottom>
            Graduation
          </Typography>
          <Divider />
          <Box mt="1.5em">
            <Labeled label="Course Graduation Status">
              <NullableBooleanInput
                label="Graduated"
                source="graduation.graduated"
                variant="standard"
              />
            </Labeled>
          </Box>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                R.pathEq(['graduation', 'graduated'], false, formData) && (
                  <>
                    <Box mt="1.5em">
                      <Typography variant="h6" component="h4" gutterBottom>
                        Not Graduated Info
                      </Typography>
                    </Box>
                    <Box
                      width={{ sm: '100%', md: '50%' }}
                      mr={{ md: 0, lg: '1em' }}
                    >
                      <SelectInput
                        label="Reason"
                        source="graduation.not_graduated_reason"
                        choices={notGraduatedReasons}
                        validate={validateRequired}
                        variant="standard"
                        fullWidth
                      />
                    </Box>
                    {R.pathEq(
                      ['graduation', 'not_graduated_reason'],
                      'other',
                      formData
                    ) && (
                      <Box mr={{ md: 0, lg: '1em' }}>
                        <TextInput
                          source="graduation.not_graduated_other_reason"
                          label="Reason (Other)"
                          validate={validateRequired}
                          variant="standard"
                          fullWidth
                        />
                      </Box>
                    )}
                    {requireProof(formProps) && (
                      <Box mt="1.5em">
                        <FileInput
                          source="graduation.proofs"
                          label="Not Graduation Proof(s)"
                          accept="application/pdf,image/*"
                          labelMultiple="Drop files or click to select them (PDFs or Images). The maximum size is 3MB"
                          maxSize={3000000}
                          validate={validateRequired}
                          classes={classes}
                          multiple
                        >
                          <FileField source="src" title="title" />
                        </FileInput>
                      </Box>
                    )}
                  </>
                )
              );
            }}
          </FormDataConsumer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GraduationPanel;

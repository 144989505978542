import React from 'react';
import * as R from 'ramda';

const baseUrl = process.env.REACT_APP_SALESFORCE_BASE_URL;

const SalesforceField = ({ record = {}, salesforceObject, idName }) => {
  return (
    <a
      href={`${baseUrl}/lightning/r/${salesforceObject}/${R.path(
        ['external_ids', 'salesforce', idName],
        record
      )}/view`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {R.path(['external_ids', 'salesforce', idName], record)}
    </a>
  );
};
SalesforceField.defaultProps = {
  addLabel: true
};

export default SalesforceField;

import React from 'react';
import * as R from 'ramda';
import { Datagrid, useMutation, useNotify, useRedirect } from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { People, Remove } from '@material-ui/icons';
import { FullNameField } from 'resources/students/fields';
import { useCluster } from 'resources/student_clusters/hooks';
import { StudentSearch } from 'resources/student_clusters/components';

const StudentClusterManager = ({ record }) => {
  const [
    { id, students, selected, name, owner },
    { addStudents, removeStudents, selectStudent, selectAllStudents, setName },
  ] = useCluster(record);
  const notify = useNotify();
  const redirect = useRedirect();
  const [action, { loading }] = useMutation(
    {
      type: id ? 'update' : 'create',
      resource: 'student_clusters',
      payload: {
        id,
        data: {
          name,
          owner,
          students: R.keys(students),
        },
      },
    },
    {
      onSuccess: () => {
        redirect('/student_clusters');
        notify(`Cluster ${id ? 'Updated' : 'Created'}`);
      },
      onFailure: (error) => {
        notify(`Could Not ${id ? 'Update' : 'Create'} Cluster`, 'warning');
      },
    }
  );

  const handleCluster = () => {
    action();
  };

  const handleClusterNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h3" gutterBottom>
              Cluster Details
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Box width="33%">
                <TextField
                  label="Cluster Name"
                  placeholder="Put the cluster name here"
                  variant="standard"
                  value={name}
                  onChange={handleClusterNameChange}
                  required
                  fullWidth
                />
              </Box>
              <Box width="33%">
                <TextField
                  label="Owner"
                  variant="standard"
                  value={owner}
                  required
                  disabled
                  fullWidth
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<People />}
                onClick={handleCluster}
                disabled={R.isEmpty(students) || R.isEmpty(name) || loading}
              >
                {`${id ? 'Update' : 'Create'}  Cluster`}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <StudentSearch handleSelectedStudents={addStudents} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h3" gutterBottom>
              Students In Cluster
            </Typography>
            <Datagrid
              rowClick="toggleSelection"
              data={students}
              ids={R.keys(students)}
              loading={false}
              loaded={true}
              hasBulkActions={true}
              currentSort={{ field: 'id', order: 'ASC' }}
              onSelect={selectAllStudents}
              onToggleItem={selectStudent}
              selectedIds={selected}
            >
              <FullNameField />
            </Datagrid>
            <Box display="flex" justifyContent="flex-end" mt="1em">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Remove />}
                onClick={removeStudents}
                disabled={R.isEmpty(selected)}
              >
                Remove
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StudentClusterManager;

import React from 'react';
import * as R from 'ramda';

import { NewProgressField } from 'resources/common';

const UnitProgressField = ({ record: student, course }) => {
  const completed = R.pipe(
    R.pathOr([], [course, 'progress']),
    R.length
  )(student);
  const total = R.pathOr(0, [course, 'learning_units_count'], student);
  const progress = R.pipe(
    R.defaultTo(0),
    R.multiply(100),
    R.divide(R.__, total),
    Math.round,
    R.clamp(0, 100)
  )(completed);

  const text = `${progress}%`;

  return <NewProgressField value={progress} text={text} />;
};

UnitProgressField.defaultProps = {
  source: 'progress',
  label: 'Unit Progress',
};

export default UnitProgressField;

import * as R from 'ramda';

import { authServer } from '../auth/keycloak';
import providers from './providers';

const dataProvider = {
  getList: getCaller('getList'),
  getOne: getCaller('getOne'),
  getMany: getCaller('getMany'),
  getManyReference: getCaller('getManyReference'),
  create: getCaller('create'),
  update: getCaller('update'),
  updateMany: getCaller('updateMany'),
  delete: getCaller('delete'),
  deleteMany: getCaller('deleteMany'),
};

function getCaller(operation) {
  return async function caller(resource, params) {
    const provider = R.path([resource, operation], providers);
    if (!provider) {
      throw new Error(
        `Provider ${resource} does not support the operation ${operation}`
      );
    } else {
      const { url, options = {} } = await provider(params);
      options.headers = new Headers(
        R.merge(R.propOr({}, 'headers', options), {
          Accept: 'application/json',
          Authorization: `Bearer ${authServer.token}`,
        })
      );
      return fetch(url, options)
        .then((res) => res.json())
        .then((json) => {
          switch (operation) {
            case 'getList':
            case 'getManyReference':
              return {
                data: R.contains('/students/search', url)
                  ? R.reverse(json.result)
                  : json.result,
                total: R.pathOr(
                  json.result.length,
                  ['meta', 'pagination', 'total'],
                  json
                ),
              };
            default:
              return { data: json.result };
          }
        });
    }
  };
}

export default dataProvider;

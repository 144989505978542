import React from 'react';
import * as R from 'ramda';
import { Error, Loading, useQuery } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { blue, green, red } from '@material-ui/core/colors';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveStream } from '@nivo/stream';

import { surveyQuestions } from 'data';
import { getGradingColors } from 'lib/gradingColors';

const FeelingsStream = ({ data }) => {
  const colors = [
    red[600],
    red[400],
    red[100],
    blue[200],
    blue[400],
    blue[600],
  ];

  if (!R.hasPath(['0', 'id'], data)) {
    return (
      <ResponsiveStream
        data={data}
        margin={{ top: 10, right: 16, bottom: 50, left: 16 }}
        keys={R.pipe(R.head, R.keys)(data)}
        offsetType="expand"
        axisBottom={{
          legend: 'Survey',
          legendOffset: 36,
          legendPosition: 'middle',
          format: R.inc,
        }}
        colors={({ index }) => {
          return colors[index];
        }}
      />
    );
  } else {
    return null;
  }
};

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle
      fill="#fff"
      r={size / 2}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    <circle
      r={size / 5}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
);

const QuestionScoreLine = ({ data }) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 20, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 1, max: 5 }}
      xFormat={(value) => `Survey ${R.inc(value)}`}
      axisBottom={{
        legend: 'Survey',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickValues: [1, 2, 3, 4, 5],
        legend: 'Score',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      colors={[blue[500]]}
      pointSymbol={CustomSymbol}
      pointSize={16}
      pointBorderWidth={1}
      pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
      enableSlices="x"
    />
  );
};

const NpsLine = ({ data }) => {
  if (data[0].id === 'nps') {
    return (
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 20, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: -100, max: 100 }}
        xFormat={(value) => `Survey ${R.inc(value)}`}
        axisBottom={{
          legend: 'Survey',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          legend: 'NPS',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        markers={[
          {
            axis: 'y',
            value: 70,
            lineStyle: { stroke: green[400], strokeWidth: 2 },
            legend: 'Target',
            legendPosition: 'top-left',
          },
        ]}
        colors={[blue[500]]}
        pointSymbol={CustomSymbol}
        pointSize={16}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
        enableSlices="x"
      />
    );
  } else {
    return null;
  }
};

const questionsMap = R.pipe(
  R.groupBy(R.prop('id')),
  R.map(R.head)
)(surveyQuestions);

const graphMap = {
  nps: NpsLine,
  feelings: FeelingsStream,
};

const getAverageColor = (metric, value) => {
  if (metric === 'nps') {
    return getGradingColors(value / 20);
  } else {
    return getGradingColors(value);
  }
};

function QuestionMetrics({ cohortId, question }) {
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'metrics',
    payload: {
      filter: {
        metric: questionsMap[question].metric,
        params: { cohort_id: cohortId, question },
      },
    },
  });

  if (loading)
    return (
      <Box m="16px">
        <Loading />
      </Box>
    );
  if (error)
    return (
      <Box m="16px">
        <Error error={error} errorInfo={error} />
      </Box>
    );
  if (!data) return null;

  const Graph = R.propOr(QuestionScoreLine, question, graphMap);
  return (
    <Box display="flex" mt="16px">
      {question !== 'prework' && (
        <Box width={data.average ? '66%' : '99%'}>
          <Box ml="16px">
            <Typography variant="h5" gutterBottom>
              Evolution
            </Typography>
            <Box height="350px">
              <Graph data={data.data} loading={loading} />
            </Box>
          </Box>
        </Box>
      )}

      {data.average ? (
        <Box flex={1}>
          <Box
            display="flex"
            ml="16px"
            justifyContent="center"
            alignItems="center"
            height="400px"
          >
            <Box
              display="flex"
              borderRadius={4}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              bgcolor={getAverageColor(question, data.average).background}
              m="1em"
              p="16px"
              width="180px"
              height="180px"
            >
              <Box
                color={getAverageColor(question, data.average).typography}
                flex={1}
              >
                <Typography variant="h5" align="center">
                  Cohort Average
                </Typography>
              </Box>
              <Box
                color={getAverageColor(question, data.average).typography}
                flex={1}
              >
                <Typography variant="h1">{data.average}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        // For some reason resposive graphs don't get smaller with 100% width
        <Box width="1%" />
      )}
    </Box>
  );
}

export default QuestionMetrics;

import React from 'react';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';

const HasGraduatedField = ({ record, cohortIsFinished }) => {
  const hasGraduated = R.pathSatisfies(
    R.equals(true),
    ['graduation', 'graduated'],
    record
  );

  return (
    <Typography component="span" variant="body2">
      {cohortIsFinished && hasGraduated ? 'Yes' : 'No'}
    </Typography>
  );
};

HasGraduatedField.defaultProps = {
  label: 'Graduated',
  source: 'graduation.graduated',
};

export default HasGraduatedField;

import React from 'react';
import * as R from 'ramda';
import { SelectField } from 'react-admin';
import { Check } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

const choices = [
  { id: 'yes', name: 'Pending' },
  { id: 'no', name: 'Done' },
  { id: 'no_need', name: '' }
];

const calculateValue = student => {
  const hasAnswer = R.hasPath(['career_services', 'outcome'], student);
  const hasPendingStatus = R.includes(student.career_services.status, [
    'searching',
    'back_to_job',
    'entrepreneur',
    'back_to_university'
  ]);
  const result = { value: 'no_need' };
  if (hasAnswer) {
    result.value = 'no';
  } else if (hasPendingStatus && !hasAnswer) {
    result.value = 'yes';
  }
  return result;
};

const FormPendingValue = ({ record }) => {
  if (record.id === 'no') {
    return <Check />;
  } else if (record.id === 'yes') {
    return (
      <Typography component="span" variant="body2">
        {record.name}
      </Typography>
    );
  } else {
    return null;
  }
};
const OutcomesFormPendingField = ({ record, source }) => {
  const formPending = calculateValue(record);
  return (
    <SelectField
      record={formPending}
      source="value"
      choices={choices}
      optionText={<FormPendingValue />}
    />
  );
};

OutcomesFormPendingField.defaultProps = {
  label: 'Outcomes From'
};

export default OutcomesFormPendingField;

import React, { useState } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import { authServer } from 'auth/keycloak';

const useStyles = makeStyles({
  root: ({ color, hoverColor, value }) => {
    return {
      '&:hover': { backgroundColor: hoverColor },
      '&.Mui-selected': {
        backgroundColor: color,
        color: [0, 3].includes(value) ? 'white' : 'black',
        '&:hover': { backgroundColor: color },
      },
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
      },
      color: 'black',
    };
  },
});

const ScoreButton = ({
  color,
  hoverColor,
  valueLabel = '',
  key,
  value,
  disabled,
  ...rest
}) => {
  const classes = useStyles({ color, hoverColor, value });
  return (
    <ToggleButton
      key={key}
      value={value}
      classes={{ root: classes.root }}
      disabled={disabled}
      {...rest}
    >
      {`${value} - ${valueLabel}`}
    </ToggleButton>
  );
};

export default function ScoreInput({ defaultScore, onScore }) {
  const [score, setScore] = useState(defaultScore);
  const [grading, setGrading] = useState();

  const handleChange = async (event, newScore) => {
    if (newScore !== null && score !== newScore) {
      setGrading(true);
      await onScore(newScore);
      setScore(newScore);
      setGrading(false);
    }
  };

  const disabled = authServer.isRoles(['teacher', 'ta']) ? grading : true;

  return (
    <ToggleButtonGroup
      size="small"
      value={score}
      exclusive
      onChange={handleChange}
    >
      <ScoreButton
        key={4}
        value={0}
        valueLabel="Incomplete"
        color={red[500]}
        hoverColor={red[100]}
        size="small"
        disabled={disabled}
      />
      <ScoreButton
        key={3}
        value={1}
        valueLabel="Fair"
        color={grey[500]}
        hoverColor={grey[400]}
        size="small"
        disabled={disabled}
      />
      <ScoreButton
        key={2}
        value={2}
        valueLabel="Good"
        color={green[500]}
        hoverColor={green[100]}
        size="small"
        disabled={disabled}
      />
      <ScoreButton
        key={1}
        value={3}
        valueLabel="Excellent"
        color={green[900]}
        hoverColor={green[300]}
        size="small"
        disabled={disabled}
      />
    </ToggleButtonGroup>
  );
}

import * as R from 'ramda';
import React from 'react';
import { Error, Loading, Tab, useQuery } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { BySkillSubtab, OverviewSubtab } from './subtabs';
import { NavigableTabs } from 'resources/common';

function SkillsTab(props) {
  const { record: cohort } = props;
  const classes = makeStyles({
    selected: { background: 'rgb(201, 206, 234)' },
  })();

  const {
    data: students,
    loading: studentsLoading,
    error: studentsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: cohort.id,
      pagination: {
        perPage: 100,
      },
      filter: { course: true },
      sort: { field: 'first_name', order: 'DESC' },
    },
  });

  if (studentsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (studentsError)
    return (
      <Box p="16px">
        <Error error={studentsError} errorInfo={studentsError} />
      </Box>
    );
  if (!students) return null;

  return (
    <NavigableTabs
      {...props}
      variant="fullWidth"
      matchUrl={R.pipe(R.split('/'), R.take(6), R.join('/'))}
    >
      <Tab
        key="overview"
        label="Overview"
        path="overview"
        classes={{ selected: classes.selected }}
      >
        <OverviewSubtab cohort={cohort} students={students} />
      </Tab>
      <Tab
        key="skills"
        label="By Skills (Coming Soon)"
        path="skills"
        classes={{ selected: classes.selected }}
        disabled
      >
        <BySkillSubtab cohort={cohort} students={students} />
      </Tab>
    </NavigableTabs>
  );
}

export default SkillsTab;

import React from 'react';
import * as R from 'ramda';

import { InputLabel, MenuItem, Select } from '@material-ui/core';

import { languages } from 'data';

const LanguageSelector = ({ value, onChange }) => {
  return (
    <>
      <InputLabel shrink>Language</InputLabel>
      <Select
        label="Language"
        value={value}
        onChange={onChange}
        variant="standard"
        fullWidth
      >
        {R.map(
          (language) => (
            <MenuItem key={language.id} value={language.id}>
              {language.name}
            </MenuItem>
          ),
          languages
        )}
      </Select>
    </>
  );
};

export default LanguageSelector;

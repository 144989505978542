import React from 'react';
import * as R from 'ramda';

import {
  BooleanField,
  FunctionField,
  Labeled,
  NumberField,
  SelectField,
  TextField,
  useQuery,
} from 'react-admin';
import { authServer } from 'auth/keycloak';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import {
  campuses,
  campusesAll,
  cohortTimezones,
  formats,
  languages,
  partTimeDays,
  partTimeHours,
  tracks,
} from 'data';
import { DateField } from 'resources/common';
import AdditionalInformationShow from '../components/AdditionalInformationShow/AdditionalInformationShow';

const QuickAccessButton = (props) => {
  const { record: cohort, course, type, label } = props;
  const courseId = R.pathOr('', [course, 'openedx'], cohort);
  const url =
    type === 'studio'
      ? `${process.env.REACT_APP_STUDIO_BASE_URL}/course/${courseId}`
      : `${process.env.REACT_APP_PREVIEW_BASE_URL}/lms/courses/${courseId}`;
  return (
    <Button
      size="small"
      color="primary"
      target="_blank"
      variant="outlined"
      href={url}
    >
      <OpenInNew style={{ paddingRight: '0.2em' }} />
      {label}
    </Button>
  );
};

const InformationTab = ({ record }) => {
  const courseWelcomeEmailTemplateId =
    record?.course?.email_templates?.welcome_email_template_id;
  const careerWelcomeEmailTemplateId =
    record?.career_services?.email_templates?.welcome_email_template_id;

  const { data: emailTemplates, loading: emailTemplatesLoading } = useQuery({
    type: 'getList',
    resource: 'emails',
    payload: {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'name',
        order: 'ASC',
      },
      filter: {
        id: {
          $in: [courseWelcomeEmailTemplateId, careerWelcomeEmailTemplateId],
        },
      },
    },
  });

  const getEmailTemplateNames = (searchedFamilies) =>
    R.compose(
      R.ifElse(
        R.has('name'),
        (template) =>
          `${template.name}${
            template.language ? ` -  ${template.language.toUpperCase()}` : ''
          }`,
        R.always('--')
      ),
      R.last,
      R.filter(R.propSatisfies(R.includes(R.__, searchedFamilies), 'family'))
    )(emailTemplates);

  const isBerlinCohort =
    record.campus === 'ber' ||
    (record.timezone === 'Europe/Madrid' && record.language === 'en');

  return (
    <Box p="16px">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Cohort
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Track">
            <SelectField source="track" choices={tracks} record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Format">
            <SelectField source="format" choices={formats} record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box flexDirection="row">
            <Box>
              <Labeled label="Campus">
                <SelectField
                  source="campus"
                  choices={campuses}
                  record={record}
                />
              </Labeled>
            </Box>
            <Box>
              {R.has('timezone', record) && (
                <Labeled label="Timezone">
                  <SelectField
                    source="timezone"
                    choices={cohortTimezones}
                    record={record}
                  />
                </Labeled>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Language">
            <SelectField
              source="language"
              choices={languages}
              record={record}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Quick Access
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          {R.has('prework', record) && (
            <>
              <Grid item xs={12}>
                <Labeled label="Prework">
                  <QuickAccessButton
                    record={record}
                    course="prework"
                    type="studio"
                    label="Studio"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <QuickAccessButton
                  record={record}
                  course="prework"
                  type="preview"
                  label="Preview"
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item xs={12}>
            <Labeled label="Main Course">
              <QuickAccessButton
                record={record}
                course="course"
                type="studio"
                label="Studio"
              />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <QuickAccessButton
              record={record}
              course="course"
              type="preview"
              label="Preview"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {R.has('career_services', record) && (
            <>
              <Grid item xs={12}>
                <Labeled label="Career Services">
                  <QuickAccessButton
                    record={record}
                    course="career_services"
                    type="studio"
                    label="Studio"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <QuickAccessButton
                  record={record}
                  course="career_services"
                  type="preview"
                  label="Preview"
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Price
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Price">
            <NumberField
              source="price.amount"
              options={{
                style: 'currency',
                currency: R.path(['price', 'currency'], record),
              }}
              record={record}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Discount">
            <NumberField
              source="price.discount"
              options={{
                style: 'currency',
                currency: R.path(['price', 'currency'], record),
              }}
              record={record}
            />
          </Labeled>
        </Grid>
        {record.offerings && (
          <>
            <Grid item xs={12}>
              <Box mt="2em">
                <Typography variant="h5" gutterBottom>
                  Offerings
                </Typography>
              </Box>
            </Grid>
            {record.offerings.map((offering) => {
              return (
                <>
                  <Grid item xs={12} md={3}>
                    <Labeled label="Campus">
                      <SelectField
                        source="campus"
                        choices={campusesAll}
                        record={offering}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Labeled label="Price">
                      <NumberField
                        source="price.amount"
                        options={{
                          style: 'currency',
                          currency: R.path(['price', 'currency'], offering),
                        }}
                        record={offering}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Labeled label="Discount">
                      <NumberField
                        source="price.discount"
                        options={{
                          style: 'currency',
                          currency: R.path(['price', 'currency'], offering),
                        }}
                        record={offering}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Labeled label="Target">
                      <NumberField source="target" record={offering} />
                    </Labeled>
                  </Grid>
                </>
              );
            })}
          </>
        )}
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Dates
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Labeled label="Start Date">
            <DateField source="start_date" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
          <Labeled label="End Date">
            <DateField source="end_date" record={record} />
          </Labeled>
        </Grid>
        {record.format !== 'other' && (
          <Grid item xs={12} md={6}>
            <Labeled label="Career Week Start Date">
              <DateField source="hiring_fair.start_date" record={record} />
            </Labeled>
          </Grid>
        )}
        {record.format !== 'other' && (
          <Grid item xs={12} md={6}>
            <Labeled label="Career Week End Date">
              <DateField source="hiring_fair.end_date" record={record} />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={3}>
            <Labeled label="Part Time Days">
              <SelectField
                source="timetable.days"
                choices={partTimeDays}
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={3}>
            <Labeled label="Weekday Start Time">
              <SelectField
                source="timetable.time.0.start_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={2}>
            <Labeled label="Weekday End Time">
              <SelectField
                source="timetable.time.0.end_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={2}>
            <Labeled label="Weekend Start Time">
              <SelectField
                source="timetable.time.1.start_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={2}>
            <Labeled label="Weekend End Time">
              <SelectField
                source="timetable.time.1.end_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Target
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Target">
            <NumberField source="target" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Web Status
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Shown On Web">
            <BooleanField source="active" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Shown As Sold Out">
            <BooleanField source="sold_out" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Staff
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="PM">
            <FunctionField
              source="staff.pm"
              record={record}
              render={R.pipe(
                R.pathOr({}, ['staff', 'pm']),
                R.props(['first_name', 'last_name']),
                R.join(' ')
              )}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="PM Assistant">
            <FunctionField
              source="staff.pm_assistant"
              record={record}
              render={R.pipe(
                R.pathOr({}, ['staff', 'pm_assistant']),
                R.props(['first_name', 'last_name']),
                R.join(' '),
                R.trim
              )}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Student Success Manager">
            <FunctionField
              source="staff.om"
              record={record}
              render={R.pipe(
                R.pathOr({}, ['staff', 'om']),
                R.props(['first_name', 'last_name']),
                R.join(' ')
              )}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Student Success Assistant">
            <FunctionField
              source="staff.om_assistant"
              record={record}
              render={R.pipe(
                R.pathOr({}, ['staff', 'om_assistant']),
                R.props(['first_name', 'last_name']),
                R.join(' '),
                R.trim
              )}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Teachers">
            <FunctionField
              record={record}
              render={R.pipe(
                R.pathOr([], ['staff', 'teachers']),
                R.map(
                  R.pipe(
                    R.props(['first_name', 'last_name']),
                    R.join(' '),
                    R.trim
                  )
                ),
                R.join(', '),
                R.trim
              )}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Number Of TAs">
            <TextField source="staff.ta_amount" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em" mb="10px">
            <Typography variant="h5" gutterBottom>
              Courses
            </Typography>
          </Box>
        </Grid>
        <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
          {R.has('prework', record) && (
            <Box>
              <Box mb="10px">
                <Typography variant="h6" gutterBottom>
                  {R.has('preparation', record) ? 'Prework (M0)' : 'Prework'}
                </Typography>
              </Box>
              <Box>
                <Labeled label="Student Portal Title">
                  <TextField source="prework.title" record={record} />
                </Labeled>
              </Box>
              <Box>
                <Labeled label="OpenEdx Id">
                  <TextField source="prework.openedx" record={record} />
                </Labeled>
              </Box>
            </Box>
          )}
        </Box>
        <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
          <Box mb="10px">
            <Typography variant="h6" gutterBottom>
              Main Course
            </Typography>
          </Box>
          <Box>
            <Labeled label="Student Portal Title">
              <TextField source="course.title" record={record} />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="OpenEdx Id">
              <TextField source="course.openedx" record={record} />
            </Labeled>
          </Box>
        </Box>
        <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
          {R.has('career_services', record) && (
            <Box>
              <Box mb="10px">
                <Typography variant="h6" gutterBottom>
                  Career Services
                </Typography>
              </Box>
              <Box>
                <Labeled label="Student Portal Title">
                  <TextField source="career_services.title" record={record} />
                </Labeled>
              </Box>
              <Box>
                <Labeled label="OpenEdx Id">
                  <TextField source="career_services.openedx" record={record} />
                </Labeled>
              </Box>
            </Box>
          )}
        </Box>
        {authServer.isRoles(['admin', 'gm', 'pm', 'outcomes']) &&
          !emailTemplatesLoading && (
            <>
              <Grid item xs={12}>
                <Box mt="2em">
                  <Typography variant="h5" gutterBottom>
                    Cohort specifics
                  </Typography>
                </Box>
              </Grid>
              <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                <Grid item xs={12} md={6}>
                  <Labeled label="Course Welcome Email">
                    <Typography>
                      {getEmailTemplateNames([
                        'course_welcome',
                        'course_welcome_preparation',
                      ])}
                    </Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Labeled label="Career Services Welcome Email">
                    <Typography>
                      {getEmailTemplateNames('career_welcome')}
                    </Typography>
                  </Labeled>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Labeled label="AI Chatbot activated">
                    <BooleanField
                      record={
                        R.hasPath(
                          ['feature_flags', 'ai_chatbot_enabled'],
                          record
                        )
                          ? record
                          : { feature_flags: { ai_chatbot_enabled: false } }
                      }
                      source="feature_flags.ai_chatbot_enabled"
                    />
                  </Labeled>
                  <Labeled label="Ai feedback enabled">
                    <BooleanField
                      record={
                        R.hasPath(['feature_flags', 'labs_enabled'], record)
                          ? record
                          : { feature_flags: { labs_enabled: false } }
                      }
                      source="feature_flags.labs_enabled"
                    />
                  </Labeled>
                </Grid>
              </Box>
            </>
          )}
      </Grid>
      {isBerlinCohort && (
        <Grid item xs={12}>
          <Box mt="2em">
            <AdditionalInformationShow record={record} />
          </Box>
        </Grid>
      )}
      {R.propEq('track', 'cy', record) && (
        <>
          <Grid item xs={12}>
            <Box mt="2em">
              <Typography variant="h5" gutterBottom>
                External IDs
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Labeled label="Cybint Access Code">
              <TextField
                source="external_ids.cybint_access_code"
                record={record}
              />
            </Labeled>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default InformationTab;

import * as R from 'ramda';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const dropProvider = {
  create: async (params) => {
    const url = `${apiUrl}/students/${params.data.student}/move`;
    const options = {
      method: 'POST',
      body: JSON.stringify(R.omit(['student'], params.data)),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
};

export default dropProvider;

import React, { useEffect } from 'react';
import { Error, Loading } from 'react-admin';
import { Box } from '@material-ui/core';

import { useBackend } from 'resources/common';
import { StudentClusterManager } from './components';

const StudentClusterUpadte = ({ id }) => {
  const [{ data, loading, error }, setCall] = useBackend();

  useEffect(() => {
    setCall({
      url: `/student_clusters/${id}`,
      method: 'GET',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return (
      <Box>
        <Loading />
      </Box>
    );
  }
  if (error) {
    return (
      <Box>
        <Error error={error} errorInfo={error} />
      </Box>
    );
  }
  if (data) {
    return <StudentClusterManager record={data.result} />;
  }
  return null;
};

export default StudentClusterUpadte;

import React from 'react';
import * as R from 'ramda';

import { Box, TextField } from '@material-ui/core';
import {
  FormWithRedirect,
  FormDataConsumer,
  minValue,
  number,
  NumberInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import { LanguageSelector } from './';
import { emailFamilies, tracks, campuses, riskLevels } from 'data';

const EmailForm = ({ emailInfo, setEmailInfo, emailInfoRef }) => {
  const buildName = (info) => {
    const family = R.pipe(
      R.find(R.propEq('id', info.family)),
      R.prop('name')
    )(emailFamilies);
    const variants = R.pipe(
      R.propOr([], 'variants'),
      R.toPairs,
      R.sortBy(R.head),
      R.map(R.last),
      R.reject(R.isNil),
      R.join('-')
    )(info);
    return R.toUpper(`${family}${variants.length > 0 ? ` (${variants})` : ''}`);
  };

  const handleChange = (patch) => {
    setEmailInfo((prev) => {
      const updatedInfo = R.pipe(
        R.mergeDeepLeft(patch),
        R.when(R.always(R.has('family', patch)), R.dissoc('variants')),
        (info) =>
          R.evolve(
            { name: R.or(R.prop('name', info), R.always(buildName(info))) },
            info
          )
      )(prev);
      emailInfoRef.current = updatedInfo;
      return updatedInfo;
    });
  };

  return (
    <FormWithRedirect
      record={emailInfo}
      render={() => (
        <form>
          <TextField
            label="Name"
            value={emailInfo.name}
            onChange={(event) => {
              handleChange({ name: event.target.value });
            }}
            margin="dense"
            variant="standard"
            fullWidth
          />
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
              width={{ xs: '100%', sm: '50%' }}
            >
              <SelectInput
                label="Email Family"
                source="family"
                choices={emailFamilies}
                variant="standard"
                onChange={(event) =>
                  handleChange({ family: event.target.value })
                }
                fullWidth
              />
            </Box>
            <Box flex={1} mt="5px">
              <LanguageSelector
                value={emailInfo.language}
                onChange={(event) => {
                  handleChange({ language: event.target.value });
                }}
              />
            </Box>
          </Box>
          <FormDataConsumer>
            {({ formData }) => {
              const variants = R.pipe(
                R.find(R.propEq('id', formData.family)),
                R.propOr([], 'variants')
              )(emailFamilies);
              return (
                <>
                  {R.includes('track', variants) && (
                    <SelectInput
                      label="Track"
                      source="variants.track"
                      choices={tracks}
                      onChange={(event) => {
                        handleChange({
                          variants: { track: event.target.value },
                        });
                      }}
                      allowEmpty
                      emptyValue={null}
                      emptyText="None"
                      variant="standard"
                      fullWidth
                    />
                  )}
                  {R.includes('campus', variants) && (
                    <SelectInput
                      label="Campus"
                      source="variants.campus"
                      choices={campuses}
                      onChange={(event) => {
                        handleChange({
                          variants: { campus: event.target.value },
                        });
                      }}
                      allowEmpty
                      emptyValue={null}
                      emptyText="None"
                      variant="standard"
                      fullWidth
                    />
                  )}
                  {R.includes('ordinal', variants) && (
                    <NumberInput
                      label="Ordinal"
                      source="variants.ordinal"
                      defaultValue={0}
                      min={0}
                      validate={[number(), minValue(0)]}
                      onChange={(event) => {
                        handleChange({
                          variants: { ordinal: event.target.valueAsNumber },
                        });
                      }}
                      variant="standard"
                      fullWidth
                    />
                  )}
                  {R.includes('risk_level', variants) && (
                    <SelectInput
                      label="Risk Level"
                      source="variants.risk_level"
                      choices={riskLevels}
                      onChange={(event) => {
                        handleChange({
                          variants: { risk_level: event.target.value },
                        });
                      }}
                      allowEmpty
                      emptyValue={null}
                      emptyText="None"
                      variant="standard"
                      fullWidth
                    />
                  )}
                  {R.includes('has_prework', variants) && (
                    <Box width={'100px'}>
                      <BooleanInput
                        label="Has prework"
                        defaultValue={true}
                        source="variants.has_prework"
                        variant="standard"
                        onChange={(event) => {
                          handleChange({
                            variants: { has_prework: event },
                          });
                        }}
                      />
                    </Box>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
          <TextField
            label="Subject"
            value={emailInfo.subject}
            onChange={(event) => handleChange({ subject: event.target.value })}
            margin="dense"
            variant="standard"
            fullWidth
          />
        </form>
      )}
    />
  );
};

export default EmailForm;

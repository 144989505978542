import React, { useEffect, useState, useMemo } from 'react';
import { useQuery, Loading } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import * as R from 'ramda';
import csv from 'csvtojson';
import { green, blue, red } from '@material-ui/core/colors';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: blue,
};

const acceptStyle = {
  borderColor: green,
};

const rejectStyle = {
  borderColor: red,
};

const conditions = [
  [R.has('first_name'), 'File should have a "first_name" column'],
  [R.has('last_name'), 'File should have a "last_name" column'],
  [R.has('email'), 'File should have a "email" column'],
  [R.prop('first_name'), 'Student should have "first_name"'],
  [R.prop('last_name'), 'Student should have "last_name"'],
  [R.prop('email'), 'Student should have "email"'],
  [
    R.propSatisfies(R.test(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/), 'email'),
    'Email should have a proper format',
  ],
];

const AddStudentsDialog = (props) => {
  const { onClose, open, record: cohort } = props;
  const [validatedList, setValidatedList] = useState();

  const onDialogClose = () => {
    setValidatedList(null);
    onClose();
  };

  return (
    <Dialog
      maxWidth={'lg'}
      open={open}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{'Add new students'}</DialogTitle>
      {validatedList ? (
        <OnboardingPhaseContent
          list={validatedList}
          cohortId={cohort.id}
          onConfirm={onDialogClose}
        />
      ) : (
        <UploadingPhaseContent
          onCancel={onDialogClose}
          onUpload={(list) => setValidatedList(list)}
        />
      )}
    </Dialog>
  );
};

const OnboardingPhaseContent = (props) => {
  const { cohortId, list, onConfirm } = props;
  const studentList = R.map(R.pick(['first_name', 'last_name', 'email']), list);

  const {
    data: onboardingResult,
    loading: onboardingLoading,
    error: onboardingError,
  } = useQuery({
    type: 'create',
    resource: 'students',
    payload: {
      b2b: true,
      data: {
        cohort: cohortId,
        students: studentList,
      },
    },
  });

  if (onboardingLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );

  if (onboardingError)
    return (
      <Box p="16px">
        <DialogContent>
          <DialogContentText>
            There has been some problem with the onboarding, please write
            support
          </DialogContentText>
        </DialogContent>
      </Box>
    );

  const { onboarded, not_onboarded } = onboardingResult;

  return (
    <Box>
      <DialogContent dividers>
        <DialogContentText>{`Onboarded students: ${onboarded.length}`}</DialogContentText>
        {Boolean(not_onboarded.length) && (
          <Box>
            <DialogContentText>Already onboarded:</DialogContentText>
            <List dense>
              {not_onboarded.map((email) => (
                <ListItem>
                  <ListItemText primary={email} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>
          <Box display="flex" pr="0.5em"></Box>
          Confirm
        </Button>
      </DialogActions>
    </Box>
  );
};

const UploadingPhaseContent = (props) => {
  const { onCancel, onUpload } = props;

  const [onboardingList, setOnboardingList] = useState();
  const [error, setError] = useState();
  const { acceptedFiles, getRootProps, getInputProps, isFocused } = useDropzone(
    {
      accept: {
        'file/csv': ['.csv'],
      },
      maxFiles: 1,
    }
  );

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused && focusedStyle),
      ...(error && rejectStyle),
      ...(onboardingList && acceptStyle),
    }),
    [error, isFocused, onboardingList]
  );

  useEffect(() => {
    const file = R.head(acceptedFiles);
    setOnboardingList(null);
    setError(null);
    if (file) {
      const parseList = async () => {
        const list = await csv().fromString(await file.text());
        const error = validateList(list);
        error ? setError(error) : setOnboardingList(list);
      };
      parseList().catch(() => {
        setError({ message: 'Something bad happened', index: 0 });
      });
    }
  }, [acceptedFiles]);

  return (
    <Box>
      <DialogContent maxWidth={'lg'} dividers>
        <a
          href="https://cdn.ironhack.com/campus-tools/onboarding-example.csv"
          download
        >
          {'Click to download an example CSV'}
        </a>
        <Box mt={2}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>{'Drop or click to select the onboarding list (only CSV)'}</p>
          </div>
        </Box>
        {(error || onboardingList) && (
          <Box mt={2}>
            <DialogContentText>{`List validation: ${
              error ? `${error.message}; in line ${error.index + 2}` : 'OK'
            }`}</DialogContentText>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <Box display="flex" pr="0.5em"></Box>
          Cancel
        </Button>
        <Button
          onClick={() => onUpload(onboardingList)}
          disabled={error}
          autoFocus
        >
          <Box display="flex" pr="0.5em"></Box>
          Upload
        </Button>
      </DialogActions>
    </Box>
  );
};

function validateList(list) {
  const condArgs = [
    ...R.map(
      ([condition, msg]) => [R.complement(condition), R.always(msg)],
      conditions
    ),
    [R.T, R.always('')],
  ];

  for (let index = 0; index < list.length; index++) {
    const errorMsg = R.cond(condArgs)(list[index]);
    if (errorMsg) {
      return { message: errorMsg, index };
    }
  }
}

export default AddStudentsDialog;

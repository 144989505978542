import * as R from 'ramda';
import React from 'react';
import { Chip } from '@material-ui/core';
import { lightGreen, grey, red } from '@material-ui/core/colors';

const scoreStyle = R.cond([
  [R.lte(80), R.always({ color: 'black', backgroundColor: lightGreen[500] })],
  [R.gt(50), R.always({ color: 'white', backgroundColor: red[300] })],
  [R.T, R.always({ color: 'black', backgroundColor: grey[300] })],
]);

export default ({ score }) => {
  return typeof score === 'number' ? (
    <Chip label={score} style={scoreStyle(score)} />
  ) : (
    <></>
  );
};

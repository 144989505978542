import React from 'react';
import _ from 'lodash';
import { Labeled } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';
import { DateTimePicker } from 'mui-rff';
import MomentUtils from '@date-io/moment';

import { DateField } from 'resources/common';
import TaskStatus from '../TaskStatus';

export const Create = () => {
  return (
    <DateTimePicker
      name="initial_config.date"
      margin="normal"
      label="Date"
      dateFunsUtils={MomentUtils}
    />
  );
};

export const Edit = () => {
  return (
    <DateTimePicker
      name="initial_config.date"
      margin="normal"
      label="Date"
      dateFunsUtils={MomentUtils}
    />
  );
};

export const Show = (props) => {
  const { action } = props;
  return (
    <Grid container spacing={3}>
      {Object.entries(action.tasks).map(([taskName, task], index) => (
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Typography>
              {taskName.split('_').map(_.capitalize).join(' ')}
            </Typography>
            <Box w="50%">
              <TaskStatus status={task.status} />
            </Box>
            <Labeled
              label={task.status !== 'pending' ? 'Sent On' : 'Scheduled For'}
            >
              <DateField source="hook_date" record={task} showTime />
            </Labeled>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectArrayInput,
  SelectField,
  TopToolbar,
  useGetList,
  Loading,
} from 'react-admin';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';

import { BookmarkField } from '../../common/cohorts/list/fields';
import { DateInput } from 'resources/common/DatePickers';

import { authServer } from 'auth/keycloak';
import { campuses, formats, cohortStatus, tracks } from 'data';
import { DateField, OpenInNewIconButton } from 'resources/common';
import CohortListAside from './CohortListAside';

const useFilterStyles = makeStyles({ filter: { width: 240 } });
const useListStyles = makeStyles({ openInNew: { width: 30 } });

const CohortFilter = ({ campusChoices, ...props }) => {
  const classes = useFilterStyles();

  return (
    <Filter {...props} variant="standard">
      <SelectArrayInput
        label="Status"
        source="status"
        choices={cohortStatus}
        className={classes.filter}
      />
      <SelectArrayInput
        label="Track"
        source="track"
        choices={tracks}
        className={classes.filter}
      />
      <SelectArrayInput
        label="Format"
        source="format"
        choices={formats}
        className={classes.filter}
      />
      {campusChoices.length === 1 ? null : (
        <SelectArrayInput
          label="Campus"
          source="campus"
          choices={authServer.isRoles(['admin']) ? campuses : campusChoices}
          className={classes.filter}
        />
      )}
      <DateInput
        label="Starts On Or After"
        source="starts_since"
        options={{ clearable: true, format: 'Do MMMM YYYY' }}
        isRequired={false}
      />
      <DateInput
        label="Starts On Or Before"
        source="starts_before"
        options={{ clearable: true, format: 'Do MMMM YYYY' }}
        isRequired={false}
      />
    </Filter>
  );
};

const CohortListToolbar = ({
  basePath,
  displayedFilters,
  filterValues,
  filters,
  resource,
  showFilter,
}) => {
  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {authServer.isRoles(['finance']) && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

const CohortListPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const getPermanentFilters = () => {
  return authServer.isRoles(['admin'])
    ? {}
    : { campuses: authServer.getCampuses() };
};

const Campus = (props) => {
  return (
    <SelectField
      optionText={(choice) =>
        choice.id === 'rmt'
          ? `${choice.name} - ${
              props.record.timezone
            } - ${props.record.language.toUpperCase()}`
          : choice.name
      }
      {...props}
    />
  );
};

const CohortList = (props) => {
  const classes = useListStyles();
  const [isLoaded, setLoaded] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState({ status: ['ongoing'] });

  const { data, loaded } = useGetList(
    'cohorts',
    {},
    {},
    { bookmarked_by: authServer.getUser() }
  );

  useEffect(() => {
    if (!R.isEmpty(data)) {
      setDefaultFilters({
        bookmarked_by: [authServer.getUser()],
      });
    }
    setLoaded(loaded);
  }, [loaded]); //eslint-disable-line

  const campusChoices = R.pipe(
    R.pathOr([], ['roles', 'campus']),
    R.of,
    R.partialRight(R.includes, R.__),
    R.propSatisfies(R.__, 'id'),
    R.filter(R.__, campuses)
  )(authServer);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  if (!isLoaded) {
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  }

  return (
    <List
      toolbar={<CohortListToolbar />}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      aside={<CohortListAside campusChoices={campusChoices} />}
      filter={getPermanentFilters()}
      filters={
        isSmall ? <CohortFilter campusChoices={campusChoices} /> : undefined
      }
      filterDefaultValues={defaultFilters}
      pagination={<CohortListPagination />}
      sort={{ field: 'start_date', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        {!isXSmall && <OpenInNewIconButton cellClassName={classes.openInNew} />}
        <SelectField
          label="Track"
          source="track"
          choices={tracks}
          sortable={false}
        />
        <SelectField
          label="Format"
          source="format"
          choices={formats}
          sortable={false}
        />
        <Campus
          label="Campus"
          source="campus"
          choices={campuses}
          sortable={false}
        />
        <DateField label="Start Date" source="start_date" />
        <BookmarkField label="Bookmark" />
        {authServer.isRoles(['gm', 'pm']) && (
          <BooleanField source="active" label="Shown On Web" />
        )}
      </Datagrid>
    </List>
  );
};

export default CohortList;

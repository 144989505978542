import { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { stringify } from 'query-string';

import { useBackend } from 'resources/common';

const studentsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    case 'SET_LIMIT':
      return { ...state, limit: action.payload };
    case 'SET_SEARCH':
      return { ...state, search: action.payload };
    case 'SET_STUDENTS':
      return { ...state, students: action.payload };
    case 'SET_TOTAL':
      return { ...state, total: action.payload };
    default:
      return { ...state };
  }
};

const useStudents = (defaultSearch = {}) => {
  const [{ data, loading, error }, setCall] = useBackend();
  const [searchState, searchDispatch] = useReducer(studentsReducer, {
    students: {},
    total: 0,
    page: 1,
    limit: 10,
    search: defaultSearch,
    loading,
    error,
  });
  const setPage = (page) => searchDispatch({ type: 'SET_PAGE', payload: page });
  const setLimit = (limit) =>
    searchDispatch({ type: 'SET_LIMIT', payload: limit });
  const setSearch = (search) =>
    searchDispatch({ type: 'SET_SEARCH', payload: search });

  useEffect(() => {
    if (data) {
      searchDispatch({
        type: 'SET_STUDENTS',
        payload: R.pipe(
          R.propOr([], ['result']),
          R.groupBy(R.prop('id')),
          R.map(R.head)
        )(data),
      });
      searchDispatch({
        type: 'SET_TOTAL',
        payload: data.meta.pagination.total,
      });
    }
  }, [data]);

  useEffect(() => {
    setCall({
      url: `/students/search?${stringify(
        R.pick(['page', 'limit'], searchState)
      )}`,
      method: 'POST',
      body: searchState.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.page, searchState.limit, searchState.search]);
  return [searchState, { setPage, setLimit, setSearch }];
};

export default useStudents;

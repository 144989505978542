import { stringify } from 'query-string';
import * as R from 'ramda';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const deliveriesProvider = {
  getMany: async (params) => {
    const data = R.pipe(
      R.pathOr('', ['ids', '0']),
      R.split('#'),
      R.zipObj(['id', 'cohort', 'course'])
    )(params);
    const search = [{ $match: { cohort: data.cohort, id: data.id } }];
    const options = {
      method: 'POST',
      body: JSON.stringify(search),
      headers: { 'Content-Type': 'application/json' },
    };
    const query = { course: data.course };
    const url = `${apiUrl}/students/search/deliveries?${stringify(query)}`;
    return { url, options };
  },
  getManyReference: async (params) => {
    const search = [{ $match: { cohort: params.id } }];
    const limit = params.pagination.perPage;
    const page = params.pagination.page;
    const options = {
      method: 'POST',
      body: JSON.stringify(search),
      headers: { 'Content-Type': 'application/json' },
    };
    const query = {
      limit,
      page,
      ...params.filter,
    };
    const url = `${apiUrl}/students/search/assignments?${stringify(query)}`;
    return { url, options };
  },
  update: async (params) => {
    const url = `${apiUrl}/students/${params.id}/course/${params.data.course}/deliveries/${params.data.name}`;
    const options = {
      method: 'PATCH',
      body: JSON.stringify(R.pick(['grade'], params.data)),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
};

export default deliveriesProvider;

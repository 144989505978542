import React from 'react';
import * as R from 'ramda';

import { Labeled, SelectField } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';

import {
  BackToJobFields,
  BackToUniveristyFields,
  EntrepreneurFields,
  FreelancerFields,
  JobFields,
  OmJobFields,
} from './components';
import { outcomeType } from 'data';

const OutcomeInfo = ({ student }) => {
  return R.hasPath(['career_services', 'outcome', 'outcome_type'], student) ||
    R.hasPath(['career_services', 'outcome', 'offer_date'], student) ? (
    <Grid container>
      <Grid item xs={12}>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            After Ironhack
          </Typography>
        </Box>
      </Grid>
      {R.hasPath(['career_services', 'outcome', 'outcome_type'], student) && (
        <Grid item xs={12} xl={1}>
          <Labeled label="Outcome ">
            <SelectField
              record={student}
              source="career_services.outcome.outcome_type"
              choices={outcomeType}
            />
          </Labeled>
        </Grid>
      )}
      <Box display="block" width={1}>
        {R.pathEq(
          ['career_services', 'outcome', 'outcome_type'],
          'new_job',
          student
        ) && <JobFields student={student} basePath="/students" />}
        {R.pathEq(
          ['career_services', 'outcome', 'outcome_type'],
          'back_to_job',
          student
        ) && <BackToJobFields student={student} />}
        {R.pathEq(
          ['career_services', 'outcome', 'outcome_type'],
          'back_to_university',
          student
        ) && <BackToUniveristyFields student={student} />}
        {R.pathEq(
          ['career_services', 'outcome', 'outcome_type'],
          'entrepreneur',
          student
        ) && <EntrepreneurFields student={student} />}
        {R.pathEq(
          ['career_services', 'outcome', 'outcome_type'],
          'freelance',
          student
        ) && <FreelancerFields student={student} />}
        {!R.hasPath(['career_services', 'outcome', 'outcome_type'], student) &&
          R.hasPath(['career_services', 'outcome', 'offer_date'], student) && (
            <OmJobFields student={student} basePath="/students" />
          )}
      </Box>
    </Grid>
  ) : null;
};

export default OutcomeInfo;

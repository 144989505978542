import React from 'react';
import * as R from 'ramda';

const baseUrl = process.env.REACT_APP_SALESFORCE_BASE_URL;

const SalesforceField = ({ record = {} }) => (
  <a
    href={`${baseUrl}/lightning/r/Product2/${R.path(
      ['external_ids', 'salesforce'],
      record
    )}/view`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {R.path(['external_ids', 'salesforce'], record)}
  </a>
);
SalesforceField.defaultProps = {
  addLabel: true
};

export default SalesforceField;

import React, { useEffect, useState, useRef } from 'react';
import * as R from 'ramda';
import _ from 'lodash';
import { Datagrid, Error, Pagination, ReferenceField } from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { stringify } from 'query-string';
import moment from 'moment-timezone';

import { careerServiceStatuses } from 'data';
import { authServer } from 'auth/keycloak';
import {
  CohortNameField,
  FullNameField,
  OutcomesStatusField,
} from 'resources/students/fields';
import { useStudents } from 'resources/student_clusters/hooks';

const StudentFilters = ({ handleFilterUpdate }) => {
  const [selectedCohort, setSelectedCohort] = useState('');
  const [name, setName] = useState('');
  const [outcomesStatus, setOutcomesStatus] = useState('');
  const throttledHandler = useRef(_.throttle(handleFilterUpdate, 500)).current;

  useEffect(() => {
    const search = [];
    if (!R.isEmpty(name)) {
      search.push({ $match: { $text: { $search: name } } });
    }
    if (!R.isEmpty(selectedCohort)) {
      search.push({ $match: { cohort: selectedCohort } });
    }
    if (!R.isEmpty(outcomesStatus)) {
      search.push({ $match: { 'career_services.status': outcomesStatus } });
    }
    throttledHandler(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, selectedCohort, outcomesStatus]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCohortSelect = (event) => {
    setSelectedCohort(event.target.value);
  };

  const handleOutcomesStatusSelect = (event) => {
    setOutcomesStatus(event.target.value);
  };

  return (
    <Box display="flex" flexWrap="wrap">
      <Box mr="2em">
        <NameFilter name={name} handleNameChange={handleNameChange} />
      </Box>
      <Box mr="2em">
        <CohortFilter
          selectedCohort={selectedCohort}
          handleCohortSelect={handleCohortSelect}
        />
      </Box>
      <Box>
        <OutcomesStatusFilter
          outcomesStatus={outcomesStatus}
          handleOutcomesStatusSelect={handleOutcomesStatusSelect}
        />
      </Box>
    </Box>
  );
};

const NameFilter = ({ name, handleNameChange }) => {
  const classes = makeStyles({ root: { minWidth: 176 } })();
  return (
    <FormControl>
      <TextField
        className={classes.root}
        label="Filter By Name"
        variant="standard"
        value={name}
        onChange={handleNameChange}
      />
    </FormControl>
  );
};

const CohortFilter = ({ selectedCohort, handleCohortSelect }) => {
  const apiUrl = process.env.REACT_APP_BACKEND_HOST;
  const classes = makeStyles({ root: { minWidth: 176 } })();

  const [cohorts, setCohorts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const today = moment().startOf('day');
      const response = await fetch(
        `${apiUrl}/cohorts/search?${stringify({ limit: 1000 })}`,
        {
          method: 'POST',
          body: JSON.stringify([
            {
              $match: {
                start_date: {
                  $lte: today.format('YYYY-MM-DD'),
                  $gte: today.subtract(1, 'year').format('YYYY-MM-DD'),
                },
              },
            },
            {
              $project: {
                campus: 1,
                track: 1,
                format: 1,
                start_date: 1,
                timezone: 1,
                language: 1,
              },
            },
            { $sort: { campus: 1, start_date: -1 } },
          ]),
          headers: {
            Authorization: `Bearer ${authServer.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const result = await response.json();
      setCohorts(result.result);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl>
      <InputLabel>Filter By Cohort</InputLabel>
      <Select
        className={classes.root}
        label="Filter By Cohort"
        value={selectedCohort}
        onChange={handleCohortSelect}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {R.map(
          (cohort) => (
            <MenuItem key={cohort.id} value={cohort.id}>
              <CohortNameField key={cohort.id} record={cohort} />
            </MenuItem>
          ),
          cohorts
        )}
      </Select>
    </FormControl>
  );
};

const OutcomesStatusFilter = ({
  outcomesStatus,
  handleOutcomesStatusSelect,
}) => {
  const classes = makeStyles({ root: { minWidth: 176 } })();

  return (
    <FormControl>
      <InputLabel>Filter By Status</InputLabel>
      <Select
        className={classes.root}
        label="Filter By Status"
        value={outcomesStatus}
        onChange={handleOutcomesStatusSelect}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {R.map(
          ({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ),
          careerServiceStatuses
        )}
      </Select>
    </FormControl>
  );
};

const StudentSearch = ({ handleSelectedStudents }) => {
  const [
    { students, page, limit, total, loading, error },
    { setPage, setLimit, setSearch },
  ] = useStudents({});
  const [selectedStudents, setStudentsSelected] = useState([]);
  const [loadedStudents, setLoadedStudents] = useState([]);
  useEffect(() => {
    setLoadedStudents({ ...loadedStudents, ...students });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students]);

  const handleAddToCluster = () => {
    const newStudents = R.pick(selectedStudents, loadedStudents);
    handleSelectedStudents(newStudents);
    setStudentsSelected([]);
  };

  const handleFilterUpdate = (filter) => {
    setLoadedStudents([]);
    const search = filter;
    setSearch(search);
  };

  const handleToggle = (id) => {
    if (R.contains(id, selectedStudents)) {
      setStudentsSelected(R.without([id], selectedStudents));
    } else {
      setStudentsSelected(R.uniq([...selectedStudents, id]));
    }
  };

  const handleToggleAll = (ids) => {
    setStudentsSelected(R.uniq(ids));
  };
  if (error) {
    return (
      <Box>
        <Error error={error} errorInfo={error} />
      </Box>
    );
  } else {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" gutterBottom>
            Search Students
          </Typography>
          <StudentFilters handleFilterUpdate={handleFilterUpdate} />
          <Datagrid
            rowClick="toggleSelection"
            data={students}
            ids={R.keys(students)}
            loading={loading}
            loaded={!loading}
            hasBulkActions={true}
            currentSort={{ field: 'id', order: 'ASC' }}
            onSelect={handleToggleAll}
            onToggleItem={handleToggle}
            selectedIds={selectedStudents}
          >
            <FullNameField />
            <ReferenceField
              label="Cohort"
              source="cohort"
              reference="cohorts"
              link="show"
              basePath="/cohorts"
            >
              <CohortNameField />
            </ReferenceField>
            <OutcomesStatusField
              label="Status"
              source="career_services.status"
              sortable={false}
            />
          </Datagrid>
          <Pagination
            loading={loading}
            page={page}
            perPage={limit}
            total={total}
            setPage={setPage}
            setPerPage={setLimit}
          />
          <Box display="flex" flexDirection="row-reverse">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              onClick={handleAddToCluster}
              disabled={R.isEmpty(selectedStudents)}
            >
              Add To Cluster
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }
};

export default StudentSearch;

import React, { useState } from 'react';
import * as R from 'ramda';
import {
  Datagrid,
  Labeled,
  FunctionField,
  SelectField,
  Tab,
  TextField,
} from 'react-admin';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import {
  AssessmentScoreChip,
  AssignmentGradeDistributionField,
  DateField,
  DeliveriesList,
  DeliveryTitle,
  NavigableTabs,
  OfflineList,
} from 'resources/common';
import {
  AssignmentsProgressField,
  UnitProgressField,
  AssessmentsProgressField,
  LabsProgressField,
} from 'resources/common/cohorts/list/fields';
import { assignmentStatus } from 'data';
import RetakeAssessmentControl from './components/RetakeAssessmentControl';
import LabsList from 'resources/common/LabsList';

const ChallengeList = ({ record }) => {
  return (
    <OfflineList list={record.challenges} uniqueKey="challenge_id">
      <Datagrid>
        <TextField label="Challenge Name" source="title" />
        <SelectField
          label="Status"
          choices={assignmentStatus}
          source="status"
        />
        <FunctionField
          label="Submit Date"
          render={(challenge) => {
            if (R.propEq('status', 'pending', challenge)) return null;
            return <DateField record={challenge} source="date" />;
          }}
        />
        <FunctionField
          label="Score"
          render={(challenge) => {
            if (R.propEq('status', 'pending', challenge)) return null;
            return (
              <AssessmentScoreChip
                record={challenge}
                scoreSource="score"
                cutScoreSource="assessment_cut_score"
              />
            );
          }}
        />
      </Datagrid>
    </OfflineList>
  );
};

const ProgressTab = (props) => {
  const { record, course } = props;
  const [student, updateStudent] = useState(record);

  const assignments = R.pathOr([], [course, 'deliveries'], student);
  const assessments = R.pipe(
    R.pathOr([], [course, 'challenges']),
    R.groupBy(R.prop('assessment_id')),
    R.map(
      R.applySpec({
        challenge_count: R.length,
        title: R.path(['0', 'assessment_title']),
        assessment_id: R.path(['0', 'assessment_id']),
        assessment_cut_score: R.path(['0', 'assessment_cut_score']),
        score: R.ifElse(
          R.any(R.propEq('status', 'pending')),
          R.always(null),
          (challenges) =>
            Math.round(
              (R.path(['0', 'assessment_score_average'], challenges) +
                Number.EPSILON) *
                100
            ) / 100
        ),
        challenges: R.identity,
        required: R.path(['0', 'required']),
      })
    ),
    R.values
  )(student);
  const labs = R.pathOr([], [course, 'labs'], student);

  const isRequired = R.propEq('required', true);
  const averageAssessmentsScore = R.pipe(
    R.when(R.any(isRequired), R.filter(isRequired)),
    R.pluck('score'),
    R.reject(R.isNil),
    R.mean,
    (mean) => Math.round((mean + Number.EPSILON) * 100) / 100
  )(assessments);

  const averageAssessmentsCutScore = R.pipe(
    R.when(R.any(isRequired), R.filter(isRequired)),
    R.pluck('assessment_cut_score'),
    R.reject(R.isNil),
    R.mean,
    (mean) => Math.round((mean + Number.EPSILON) * 100) / 100
  )(assessments);

  const hasLabs = R.pipe(
    R.path([course, 'labs']),
    R.defaultTo([]),
    R.length,
    R.lt(0)
  )(student);

  return (
    <Box px="16px">
      <Card style={{ margin: '15px 0px' }}>
        <CardContent>
          <Typography variant="h5" component="h3" gutterBottom>
            Progress
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Labeled label="Unit Progress" fullWidth>
                <UnitProgressField
                  course={course}
                  target="progress"
                  record={student}
                  sortable={false}
                />
              </Labeled>
            </Grid>
            <Grid item xs={5}>
              <Labeled
                label={`${
                  R.any(isRequired, assignments) ? 'Required ' : ''
                }Assignments`}
                fullWidth
              >
                <AssignmentsProgressField course={course} record={student} />
              </Labeled>
            </Grid>
            {hasLabs && (
              <Grid item xs={5}>
                <Labeled
                  label={`${R.any(isRequired, labs) ? 'Required ' : ''}Labs`}
                  fullWidth
                >
                  <LabsProgressField course={course} record={student} />
                </Labeled>
              </Grid>
            )}
            <Grid item xs={5}>
              <Labeled
                label={`${
                  R.any(isRequired, assessments) ? 'Required Passed ' : ''
                }Assessments`}
                fullWidth
              >
                <AssessmentsProgressField course={course} record={student} />
              </Labeled>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Labeled label="Assignments Score Distribution" fullWidth>
                <AssignmentGradeDistributionField
                  label="Grades"
                  source={`${course}.deliveries`}
                  sortable={false}
                  record={student}
                />
              </Labeled>
            </Grid>
            <Grid item xs={5}>
              <Labeled label="Assessments Average Score" fullWidth>
                <AssessmentScoreChip
                  label="Cohort Average"
                  textAlign="center"
                  scoreSource="score"
                  cutScoreSource="cutScore"
                  record={{
                    score: averageAssessmentsScore,
                    cutScore: averageAssessmentsCutScore,
                  }}
                />
              </Labeled>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <NavigableTabs
        {...props}
        variant="fullWidth"
        matchUrl={R.pipe(R.split('/'), R.take(6), R.join('/'))}
      >
        <Tab
          label="Assignments"
          key="assignments"
          value="assignments"
          path="assignments"
        >
          <DeliveriesList
            label="Progress"
            type="students"
            record={student}
            source={`${course}.deliveries`}
            course={course}
          />
        </Tab>
        {hasLabs && (
          <Tab label="Labs" key="labs" value="labs" path="labs">
            <LabsList
              label="Progress"
              type="students"
              record={student}
              source={`${course}.labs`}
              course={course}
            />
          </Tab>
        )}
        <Tab
          label="Assessments"
          key="assessments"
          value="assessments"
          path="assessments"
        >
          <OfflineList list={assessments} uniqueKey="assessment_id">
            <Datagrid rowClick="expand" expand={<ChallengeList />}>
              <DeliveryTitle />
              <TextField label="# of Challenges" source="challenge_count" />
              <AssessmentScoreChip
                label="Score"
                scoreSource="score"
                cutScoreSource="assessment_cut_score"
              />
              <RetakeAssessmentControl
                student={student}
                course={course}
                updateStudent={updateStudent}
              />
            </Datagrid>
          </OfflineList>
        </Tab>
      </NavigableTabs>
    </Box>
  );
};

export default ProgressTab;

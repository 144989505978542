import React from 'react';
import * as R from 'ramda';
import {
  Datagrid,
  downloadCSV,
  ExportButton,
  Filter,
  List,
  Pagination,
  ReferenceField,
  SearchInput,
  SelectArrayInput,
  TopToolbar,
} from 'react-admin';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

import { DateInput } from 'resources/common/DatePickers';
import { OpenInNewIconButton } from 'resources/common';
import { authServer } from 'auth/keycloak';
import {
  CohortNameField,
  FullNameField,
  EmailField,
  OutcomesCategoryField,
  OutcomesStatusField,
  JobSearchLocationField,
  JobSearchStartDate,
  CohortEndDateField,
  OptedInCareerHackField,
} from '../fields';
import StudentListAside from './StudentListAside';
import { tracks, formats, campuses, careerServiceStatuses } from 'data';
import moment from 'moment';

const useFilterStyles = makeStyles({ filter: { width: 240 } });
const useListStyles = makeStyles({ openInNew: { width: 30 } });

const StudentFilter = (props) => {
  const classes = useFilterStyles();

  const { campusChoices } = props;
  return (
    <Filter variant="standard" {...props}>
      <SearchInput placeholder="Search By Name" source="name_search" alwaysOn />
      <SearchInput
        placeholder="Search By Email"
        source="email_search"
        alwaysOn
      />
      {campusChoices.length === 1 ? null : (
        <SelectArrayInput
          label="Cohort Campus"
          source="cohort_campus"
          choices={authServer.isRoles(['admin']) ? campuses : campusChoices}
          className={classes.filter}
        />
      )}
      <SelectArrayInput
        label="Cohort Track"
        source="cohort_track"
        choices={tracks}
        className={classes.filter}
      />
      <SelectArrayInput
        label="Cohort Format"
        source="cohort_format"
        choices={formats}
        className={classes.filter}
      />
      {authServer.isRoles(['outcomes']) && (
        <SelectArrayInput
          label="Outcomes Status"
          source="outcomes_status"
          choices={careerServiceStatuses}
          className={classes.filter}
          alwaysOn
        />
      )}
      <DateInput
        label="Starts On Or After"
        source="cohort_starts_since"
        options={{ clearable: true, format: 'Do MMMM YYYY' }}
      />
      <DateInput
        label="Starts On Or Before"
        source="cohort_starts_before"
        options={{ clearable: true, format: 'Do MMMM YYYY' }}
      />
    </Filter>
  );
};

const getPermanentFilters = () => {
  if (authServer.isRoles(['admin'])) return {};
  return authServer.isNotRoles(['b2b'])
    ? { campuses: authServer.getCampuses() }
    : { is_b2b: true };
};

const exporter = async (students, fetchRelatedRecords) => {
  const cohorts = await fetchRelatedRecords(students, 'cohort', 'cohorts');
  const cohortsB2b = await fetchRelatedRecords(
    students,
    'cohort',
    'cohorts_b2b'
  );

  const totalCohorts = { ...cohorts, ...cohortsB2b };

  const studentsForExport = R.map((student) =>
    R.mergeAll([
      R.applySpec({
        first_name: R.prop('first_name'),
        last_name: R.prop('last_name'),
        cohort: R.prop('cohort'),
        email: R.prop('email'),
        job_search_start_date: R.pathOr('', [
          'career_services',
          'job_search_start',
          'date',
        ]),
        category: R.pathOr('', ['career_services', 'category']),
        subcategory: R.pathOr('', ['career_services', 'status']),
        '1:1 notes': R.pipe(
          R.pathOr('', ['career_services', 'notes']),
          R.replace(/(<([^>]+)>)/gi, '')
        ),
        job_hunt_country: R.pathOr('', [
          'career_services',
          'job_search_location',
          'locations',
          '0',
          'country',
        ]),
        job_hunt_city_1: R.pathOr('', [
          'career_services',
          'job_search_location',
          'locations',
          '0',
          'city',
        ]),
        job_hunt_city_2: R.ifElse(
          R.pipe(
            R.pathOr('', [
              'career_services',
              'job_search_location',
              'locations',
            ]),
            R.length,
            R.equals(2)
          ),
          R.pathOr('', [
            'career_services',
            'job_search_location',
            'locations',
            '1',
            'city',
          ]),
          R.always('')
        ),
        remote_open: R.pathOr('', [
          'career_services',
          'job_search_location',
          'remote_open',
        ]),
      })(student),
      R.applySpec({
        campus: R.prop('campus'),
        track: R.prop('track'),
        format: R.prop('format'),
        start_date: R.compose(
          (date) => moment.utc(date).format('YYYY-MM-DD'),
          R.prop('start_date')
        ),
        end_date: R.compose(
          (date) => moment.utc(date).format('YYYY-MM-DD'),
          R.prop('end_date')
        ),
        ssm: R.pathOr('', ['staff', 'om', 'email']),
      })(totalCohorts[student.cohort]),
    ])
  )(students);

  jsonExport(
    studentsForExport,
    {
      headers: [
        'first_name',
        'last_name',
        'cohort',
        'email',
        'campus',
        'track',
        'format',
        'start_date',
        'end_date',
        'ssm',
        'job_search_start_date',
        'category',
        'subcategory',
        'job_hunt_country',
        'job_hunt_city_1',
        'job_hunt_city_2',
        'remote_open',
      ],
    },
    (error, csv) => {
      downloadCSV(csv, 'students');
    }
  );
};

const StudentListToolbar = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filterValues,
  filters,
  maxResults,
  permanentFilter,
  resource,
  showFilter,
  total,
}) => {
  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const StudentListPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export default ({ ...props }) => {
  const classes = useListStyles();
  const campusChoices = R.pipe(
    R.pathOr([], ['roles', 'campus']),
    R.of,
    R.partialRight(R.includes, R.__),
    R.propSatisfies(R.__, 'id'),
    R.filter(R.__, campuses)
  )(authServer);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <List
      actions={<StudentListToolbar {...props} />}
      aside={<StudentListAside campusChoices={campusChoices} />}
      bulkActionButtons={false}
      filter={getPermanentFilters()}
      filters={
        isSmall ? <StudentFilter campusChoices={campusChoices} /> : undefined
      }
      pagination={<StudentListPagination />}
      exporter={exporter}
      defaultSort={{ field: 'professional_profile.progress', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        {!isXSmall && <OpenInNewIconButton cellClassName={classes.openInNew} />}
        <FullNameField copyable sortable={false} />
        <ReferenceField
          label="Cohort"
          source="cohort"
          reference="cohorts"
          link="show"
          sortable={false}
        >
          <CohortNameField />
        </ReferenceField>
        {authServer.isRoles(['outcomes']) && (
          <OutcomesCategoryField
            label="Category"
            source="career_services.category"
            sortable={false}
            {...props}
          />
        )}
        {authServer.isRoles(['outcomes']) && (
          <OutcomesStatusField
            label="Status"
            source="career_services.status"
            sortable={false}
            {...props}
          />
        )}
        <JobSearchLocationField sortable={false} />
        <ReferenceField
          label="End date"
          source="cohort"
          reference="cohorts"
          link="show"
          sortable={false}
        >
          <CohortEndDateField />
        </ReferenceField>
        <OptedInCareerHackField sortable={false} />
        <JobSearchStartDate sortable={true} />
        <EmailField copyable sortable={false} />
      </Datagrid>
    </List>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  SaveButton,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { CheckCircle, ErrorOutline } from '@material-ui/icons';
import { useFormState } from 'react-final-form';

const ConfirmDialog = (props) => {
  const { isOpen, onClose, onConfirm } = props;

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(e);
    },
    [onConfirm]
  );

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">OpenEdx Id Changed</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will completely erase all the progress and deliveries from all
          the current students in the cohort. Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Box display="flex" pr="0.5em">
            <ErrorOutline />
          </Box>
          Cancel
        </Button>
        <Button onClick={handleConfirm} autoFocus>
          <Box display="flex" pr="0.5em">
            <CheckCircle />
          </Box>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CohortB2bSaveButton = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [updates, setUpdates] = useState({});
  const [hasCourseIdUpdates, setHasCourseIdUpdates] = useState(false);
  const [isSaveAgreed, setSaveAgreed] = useState(false);

  const formState = useFormState();

  useEffect(() => {
    const save = () => {
      dataProvider
        .update('cohorts_b2b', {
          id: updates.cohort_id,
          data: R.omit(['cohort_id'], updates),
        })
        .then((response) => {
          redirect(`/cohorts_b2b/${updates.cohort_id}/show`);
          notify('Cohort Updated');
        })
        .catch((error) => {
          notify(`Cohort update error: ${error.message}`, 'warning');
        });
    };
    if (!R.isEmpty(updates) && isSaveAgreed) {
      save();
    }
  }, [dataProvider, isSaveAgreed, notify, redirect, updates]);

  useEffect(() => {
    if (hasCourseIdUpdates) {
      setConfirmDialogOpen(true);
    }
  }, [hasCourseIdUpdates]);

  const handleConfirm = useCallback(() => {
    setConfirmDialogOpen(false);
    setSaveAgreed(true);
  }, []);

  const handleClose = useCallback((e) => {
    setConfirmDialogOpen(false);
    setHasCourseIdUpdates(false);
    e.stopPropagation();
  }, []);

  const handleSave = useCallback(() => {
    const editUpdates = R.pipe(
      R.propOr({}, 'modified'),
      R.filter(R.identity),
      R.keys,
      R.map(
        R.pipe(R.replace(/\[|\]/g, '.'), R.split('.'), R.reject(R.isEmpty))
      ),
      R.reject((path) =>
        R.eqBy(R.path(path), formState.initialValues, formState.values)
      ),
      R.reduce(
        (acc, path) => R.assocPath(path, R.path(path, formState.values), acc),
        {}
      )
    )(formState);
    if (R.isEmpty(editUpdates)) {
      notify('No Changes Detected');
    } else {
      if (R.has('timetable', editUpdates)) {
        editUpdates.timetable = formState.values.timetable;
      }
      editUpdates.external_ids = formState.values.external_ids;
      editUpdates.cohort_id = formState.values.id;
      const courseIdsChanged = R.anyPass([
        R.hasPath(['prework', 'openedx']),
        R.hasPath(['course', 'openedx']),
        R.hasPath(['career_services', 'openedx']),
      ])(editUpdates);
      if (courseIdsChanged) {
        setHasCourseIdUpdates(true);
      } else {
        setSaveAgreed(true);
      }
      setUpdates(editUpdates);
    }
  }, [formState, notify]);
  return (
    <>
      <SaveButton {...props} onSave={handleSave} />
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  );
};

export default CohortB2bSaveButton;

import React from 'react';
import {
  Button,
  EditButton,
  TopToolbar as MuiToolbar,
  useQuery,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Delete, TransferWithinAStation } from '@material-ui/icons';
import * as R from 'ramda';
import moment from 'moment';
import { authServer } from 'auth/keycloak';
import CreateStudentCertificate from './components/CreateStudentCertificate';
import CreateStudentB2bCertificate from './components/CreateStudentB2bCertificate';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const TopToolbar = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})(MuiToolbar);

const StudentShowActions = ({ basePath, data: student }) => {
  const classes = useStyles();

  const { data: cohort } = useQuery({
    type: 'getOne',
    resource: student?.is_b2b ? 'cohorts_b2b' : 'cohorts',
    payload: { id: student?.cohort },
  });

  if (!cohort) return null;

  const hasGraduated =
    R.pathEq(['graduation', 'graduated'], true, student) &&
    moment().isAfter(cohort.end_date);

  return (
    <TopToolbar>
      {authServer.isRoles(['pm', 'outcomes', 'campus_lead', 'teacher']) && (
        <EditButton basePath={basePath} record={student} />
      )}
      {authServer.isRoles(['pm', 'campus_lead']) && student && (
        <Button
          component={Link}
          to={{
            pathname: `/move/create/${student.id}`,
            state: { student: student },
          }}
          label="Change Cohort"
        >
          <TransferWithinAStation />
        </Button>
      )}
      {authServer.isRoles(['pm', 'campus_lead']) && student && (
        <Button
          className={classes.deleteButton}
          component={Link}
          to={{
            pathname: `/drop/create/${student.id}`,
            state: { student: student },
          }}
          label="Drop/Expel Student"
        >
          <Delete />
        </Button>
      )}
      {authServer.isRoles(['pm', 'campus_lead']) && student && hasGraduated && (
        <div style={{ marginLeft: '10px' }}>
          {student?.is_b2b ? (
            <CreateStudentB2bCertificate cohort={cohort} student={student} />
          ) : (
            <CreateStudentCertificate student={student} />
          )}
        </div>
      )}
    </TopToolbar>
  );
};

export default StudentShowActions;

export default {
  page: {
    body: {
      container: {
        style: {
          'background-color': '#ffffff',
        },
      },
      content: {
        computedStyle: {
          linkColor: '#69afff',
          messageBackgroundColor: '#ffffff',
          messageWidth: '645px',
        },
        style: {
          color: '#000000',
          'font-family': 'Helvetica Neue, Helvetica, Arial, sans-serif',
        },
      },
      type: 'mailup-bee-page-properties',
      webFonts: [
        {
          fontFamily:
            "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
          name: 'Montserrat',
          url: 'https://fonts.googleapis.com/css?family=Montserrat',
        },
      ],
    },
    description: 'BF-ecommerce-template',
    rows: [
      {
        columns: [
          {
            'grid-columns': 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    class: 'center fixedwidth',
                    width: '129px',
                  },
                  image: {
                    alt: 'Alternate text',
                    height: '100px',
                    href: '',
                    src: 'https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/5639fdec-77bb-46c5-ac70-c86141774f78/campus-tools/IH_BLUE-LOGO_100x100.png',
                    width: '100px',
                  },
                  style: {
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                    width: '100%',
                  },
                },
                locked: false,
                type: 'mailup-bee-newsletter-modules-image',
                uuid: 'e8d4dea6-32ea-4e25-b999-d1d823a511a6',
              },
            ],
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-top': '0px solid transparent',
              'padding-bottom': '5px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '5px',
            },
            uuid: '3a6015bd-fce1-44ca-91ac-2dada7a01e34',
          },
        ],
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            'background-color': '#ffffff',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
            color: '#000000',
            width: '645px',
          },
        },
        locked: false,
        type: 'row-1-columns-12',
        uuid: '65b22fbf-4f30-4a85-aa90-8af53ccb8d0d',
      },
      {
        columns: [
          {
            'grid-columns': 12,
            modules: [],
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-top': '0px solid transparent',
              'padding-bottom': '5px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '5px',
            },
            uuid: 'f278b073-36be-45b0-8a77-e6c7a39d38be',
          },
        ],
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            'background-color': '#ffffff',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
            color: '#000000',
            width: '645px',
          },
        },
        locked: false,
        type: 'row-1-columns-12',
        uuid: '8d986975-f741-470a-bd1a-7a4b853cffd8',
      },
      {
        columns: [
          {
            'grid-columns': 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  style: {
                    'padding-bottom': '10px',
                    'padding-left': '10px',
                    'padding-right': '10px',
                    'padding-top': '15px',
                  },
                  text: {
                    computedStyle: {
                      linkColor: '#69afff',
                    },
                    html: '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 18px; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;" data-mce-style="font-size: 12px; line-height: 18px; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;"><p style="font-size: 14px; line-height: 21px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 21px; text-align: center; word-break: break-word;"><em>Copyright © 2021 Ironhack, All rights reserved.</em></p></div>',
                    style: {
                      color: '#959595',
                      'font-family':
                        "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
                      'line-height': '150%',
                    },
                  },
                },
                locked: false,
                type: 'mailup-bee-newsletter-modules-text',
                uuid: '821415df-e283-439c-bde4-e02d9ed657e2',
              },
            ],
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px solid transparent',
              'border-left': '0px solid transparent',
              'border-right': '0px solid transparent',
              'border-top': '0px solid transparent',
              'padding-bottom': '30px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '30px',
            },
            uuid: '4b9996dc-5918-4425-ac4d-800b55e42847',
          },
        ],
        container: {
          style: {
            'background-color': '#ffffff',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
          },
          style: {
            'background-color': '#ffffff',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
            color: '#333',
            width: '645px',
          },
        },
        locked: false,
        type: 'row-1-columns-12',
        uuid: 'bb555652-343a-499d-849a-70205fa00a3b',
      },
    ],
    template: {
      name: 'template-base',
      type: 'basic',
      version: '2.0.0',
    },
    title: 'BF-ecommerce-template',
  },
  comments: {},
};

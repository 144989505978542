import * as R from 'ramda';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const attendanceB2bProvider = {
  create: async (params) => {
    const url = `${apiUrl}/attendance_b2b`;
    const options = {
      method: 'POST',
      body: JSON.stringify(R.omit(['cohort'], params.data)),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
  getOne: async (params) => {
    const url = `${apiUrl}/attendance_b2b/${params.id}`;
    return { url };
  },
};

export default attendanceB2bProvider;

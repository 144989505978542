import React from 'react';
import * as R from 'ramda';
import { Box, makeStyles } from '@material-ui/core';
import { Error, Loading, Tab, useQuery } from 'react-admin';

import { NavigableTabs } from 'resources/common';
import { ByQuestionTab, BySurveyTab, ScheduleTab } from './tabs';

const useStyles = makeStyles({
  selected: { background: 'rgb(201, 206, 234)' },
});

function SurveysTab(props) {
  const { record: cohort } = props;
  const classes = useStyles();

  const {
    data: students,
    loading,
    error,
  } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: cohort.id,
      pagination: {
        perPage: 100,
      },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  if (loading)
    return (
      <Box m="16px">
        <Loading />
      </Box>
    );
  if (error)
    return (
      <Box m="16px">
        <Error error={error} errorInfo={error} />
      </Box>
    );
  if (!students) return null;

  return (
    <NavigableTabs
      {...props}
      variant="fullWidth"
      matchUrl={R.pipe(R.split('/'), R.take(6), R.join('/'))}
    >
      <Tab
        key="survey"
        label="By Survey"
        path="survey"
        classes={{ selected: classes.selected }}
      >
        <BySurveyTab students={students} />
      </Tab>
      <Tab
        key="question"
        label="By Question"
        path="question"
        classes={{ selected: classes.selected }}
      >
        <ByQuestionTab students={students} />
      </Tab>
      <Tab
        key="schedule"
        label="Schedule"
        path="schedule"
        classes={{ selected: classes.selected }}
      >
        <ScheduleTab />
      </Tab>
    </NavigableTabs>
  );
}

export default SurveysTab;

import React from 'react';
import { Chip } from '@material-ui/core';
import { lightGreen, red } from '@material-ui/core/colors';

const ScoreField = ({ value }) => {
  const scoreColors = [red[300], null, null, null, lightGreen[500]];
  return value ? (
    <Chip
      style={{ color: 'black', backgroundColor: scoreColors[value - 1] }}
      label={value}
    />
  ) : null;
};

export default ScoreField;

import React, { useState } from 'react';
import * as R from 'ramda';
import {
  CheckboxGroupInput,
  FormDataConsumer,
  SelectInput,
  required,
} from 'react-admin';
import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import { grey } from '@material-ui/core/colors';

import { riskLevels, riskLevelHardSkills } from '../../../../data';

const validateRequired = [required()];

const StudentRiskPanel = ({ record: cohort, formProps }) => {
  const [selectedLevel, setSelectedLevel] = useState(null);

  const hasCohortRiskLevelEvaluation = R.hasPath(
    ['evaluation', 'risk', 'checkpoints'],
    cohort
  );
  const studentRiskLevel = R.path(
    ['record', 'performance', 'risk', 'level'],
    formProps
  );

  const isRiskLevelChanged =
    selectedLevel && selectedLevel !== studentRiskLevel;
  const onRisk = R.includes(selectedLevel, ['level_1', 'level_2', 'level_3']);

  if (!hasCohortRiskLevelEvaluation) return null;

  return (
    <Card>
      <CardContent>
        <Box>
          <Box display="flex">
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              mt="16px"
              style={{ opacity: studentRiskLevel ? 1 : 0.4 }}
            >
              At risk info
            </Typography>
            {!studentRiskLevel && (
              <Typography variant="h6" style={{ margin: '0 14px' }}>
                Waiting for M0 assessments check...
              </Typography>
            )}
          </Box>
          <Divider />
          <Box
            display="flex"
            style={{
              opacity: studentRiskLevel ? 1 : 0.4,
            }}
          >
            <Box mt="4px" width="512px">
              <SelectInput
                label={
                  studentRiskLevel || isRiskLevelChanged
                    ? 'Assign at risk level'
                    : 'Select'
                }
                source="performance.risk.level"
                choices={riskLevels}
                variant="standard"
                onChange={(props) => {
                  setSelectedLevel(props.target.value);
                }}
                fullWidth
                disabled={!studentRiskLevel}
                style={{ marginTop: '20px' }}
              />
            </Box>
            <a
              href="https://www.notion.so/ironhack/At-risk-Off-track-students-students-with-difficulties-6530acb450dd4830b85fb436950a4030"
              style={{
                margin: '45px 0px 0px 20px',
              }}
            >
              At risk level scale
            </a>
          </Box>
          <FormDataConsumer>
            {() => {
              return (
                isRiskLevelChanged && (
                  <Box>
                    {onRisk && (
                      <Box>
                        <Typography
                          style={{
                            fontSize: '13px',
                            color: grey[600],
                          }}
                        >
                          Difficulty - main issue *
                        </Typography>
                        <CheckboxGroupInput
                          label=""
                          validate={validateRequired}
                          source="performance.risk.details.weaknesses"
                          choices={riskLevelHardSkills[cohort.track]}
                        />
                      </Box>
                    )}
                    <Box id="quill" width={1}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: '13px',
                          color: grey[600],
                        }}
                      >
                        Add more details - If at risk: describe the level
                        throughly and/or add material to be revised with
                        student, links to labs, assessments and other materials
                        where the student has shown specific problems/struggles
                        (optional)
                      </Typography>
                      <RichTextInput
                        label=""
                        source="performance.risk.details.notes"
                        options={{ bounds: '#quill' }}
                        toolbar={[
                          [{ header: [1, 2, 3, false] }],
                          ['bold', 'italic', 'underline'],
                          ['link'],
                          [{ list: 'ordered' }, { list: 'bullet' }],
                        ]}
                        width="1360px"
                      />
                    </Box>
                  </Box>
                )
              );
            }}
          </FormDataConsumer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StudentRiskPanel;

import React from 'react';
import * as R from 'ramda';
import { Box, Card, Typography } from '@material-ui/core';
import {
  Loading,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  useQuery,
} from 'react-admin';
import { authServer } from './keycloak';

const ROLES_WHITELIST = [
  'Admissions',
  'B2B',
  'Education',
  'Finance',
  'Growth',
  'Outcomes',
  'PM',
  'TA',
  'Teacher',
];
const CAMPUSES = [
  'AMS',
  'BCN',
  'BER',
  'LIS',
  'Global',
  'MAD',
  'MEX',
  'MIA',
  'PAR',
  'RMT',
  'SAO',
];

const FormToolbar = ({ onRolesUpdated, ...props }) => (
  <Toolbar {...props}>
    <SaveButton
      onSave={async (values) => {
        await fetch(
          `${process.env.REACT_APP_BACKEND_HOST}/users/${authServer.subject}`,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${authServer.token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              groups: [values.role, ...values.campuses],
              id: authServer.subject,
            }),
          }
        );
        onRolesUpdated();
      }}
    />
  </Toolbar>
);

const GroupsSelectionForm = (props) => {
  const { data: rawGroups = [], loading: groupsLoading } = useQuery({
    type: 'getList',
    resource: 'groups',
  });

  const roles = R.filter(
    R.propSatisfies(R.includes(R.__, ROLES_WHITELIST), 'name'),
    rawGroups
  );

  const campuses = R.filter(
    R.propSatisfies(R.includes(R.__, CAMPUSES), 'name'),
    rawGroups
  );

  if (!R.isEmpty(authServer.getRoles())) return null;
  if (groupsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );

  return (
    <Card>
      <Box marginTop={2} marginLeft={2}>
        <Typography variant="h5">
          We haven't found you in Hibob. Please specify your role and the
          campuses you are assigned to.
        </Typography>
      </Box>
      <SimpleForm
        record={{}}
        toolbar={
          <FormToolbar onRolesUpdated={() => (window.location.href = '/')} />
        }
      >
        <SelectInput source="role" choices={roles} validate={[required()]} />
        <SelectArrayInput
          source="campuses"
          choices={campuses}
          validate={[required()]}
        />
      </SimpleForm>
    </Card>
  );
};

export default GroupsSelectionForm;

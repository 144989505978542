import React, { useEffect, useRef, useState } from 'react';
import { useUpdate, useNotify } from 'react-admin';
import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

import { authServer } from 'auth/keycloak';

const useStyles = makeStyles({
  root: ({ color, hoverColor }) => {
    return {
      '&:hover': { backgroundColor: hoverColor },
      '&.Mui-selected': {
        backgroundColor: color,
        '&:hover': { backgroundColor: color },
      },
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
      },
    };
  },
});

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

const GradeButton = ({
  color,
  hoverColor,
  icon,
  key,
  value,
  disabled,
  ...rest
}) => {
  const classes = useStyles({ color, hoverColor });
  return (
    <ToggleButton
      key={key}
      value={value}
      classes={{ root: classes.root }}
      disabled={disabled}
      {...rest}
    >
      {icon}
    </ToggleButton>
  );
};

export default function GradeInput({ record: delivery, studentId, course }) {
  const [grade, setGrade] = useState(delivery.grade);
  const [tempGrade, setTempGrade] = useState();
  const notify = useNotify();
  const [sendGrade, { loading }] = useUpdate(
    'deliveries',
    studentId,
    { grade: tempGrade, name: delivery.name, course },
    delivery,
    {
      undoable: false,
      onSuccess: () => {
        setGrade(tempGrade);
        notify('Assignment Graded');
      },
      onFailure: () => {
        setTempGrade(grade);
        notify('Could not grade the assignment', 'warning');
      },
    }
  );

  useDidMountEffect(() => {
    const updateGrade = async () => {
      if (tempGrade) {
        return sendGrade();
      }
    };
    updateGrade();
  }, [tempGrade]);

  const handleChange = (event, newGrade) => {
    if (newGrade !== null && newGrade !== grade) {
      setTempGrade(newGrade);
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={grade}
      exclusive
      onChange={handleChange}
    >
      <GradeButton
        key={1}
        value="very_good"
        color={green[900]}
        hoverColor={green[300]}
        icon={
          <SentimentVerySatisfied
            style={grade === 'very_good' ? { color: 'white' } : {}}
          />
        }
        size="small"
        disabled={authServer.isRoles(['teacher', 'ta']) ? loading : true}
      />
      ,
      <GradeButton
        key={2}
        value="good"
        color={green[500]}
        hoverColor={green[100]}
        icon={<SentimentSatisfiedAlt />}
        size="small"
        disabled={authServer.isRoles(['teacher', 'ta']) ? loading : true}
      />
      ,
      <GradeButton
        key={3}
        value="normal"
        color={grey[500]}
        hoverColor={grey[400]}
        icon={<SentimentSatisfied />}
        size="small"
        disabled={authServer.isRoles(['teacher', 'ta']) ? loading : true}
      />
      ,
      <GradeButton
        key={4}
        value="bad"
        color={red[500]}
        hoverColor={red[100]}
        icon={<SentimentDissatisfied />}
        size="small"
        disabled={authServer.isRoles(['teacher', 'ta']) ? loading : true}
      />
      ,
      <GradeButton
        key={5}
        value="very_bad"
        color={red[900]}
        hoverColor={red[300]}
        icon={
          <SentimentVeryDissatisfied
            style={grade === 'very_bad' ? { color: 'white' } : {}}
          />
        }
        size="small"
        disabled={authServer.isRoles(['teacher', 'ta']) ? loading : true}
      />
    </ToggleButtonGroup>
  );
}

import React from 'react';
import * as R from 'ramda';

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import { RiskLevel, RiskLevelTimeline, RiskLevelDetails } from './components';

const StudentRiskPanel = ({ record: student }) => {
  const risk = R.pathOr('', ['performance', 'risk'], student);

  return (
    <Card style={{ margin: '15px 0px' }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3" gutterBottom>
              At risk info
            </Typography>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={12}>
            <RiskLevel level={risk.level} />
          </Grid>
          <Grid item xs={6}>
            <RiskLevelDetails
              historyItem={R.pipe(R.propOr([], 'history'), R.last)(risk)}
            />
          </Grid>
          <Grid item xs={12}>
            <RiskLevelTimeline record={student} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudentRiskPanel;

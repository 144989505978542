import React from 'react';
import { Button } from '@material-ui/core';
import { Help, Report } from '@material-ui/icons';

const ProductHelpBtn = (props) => {
  const { text, url } = props;
  return (
    <Button
      color="inherit"
      href={url}
      style={{ fontSize: '13px' }}
      target="_blank"
      variant="text"
      {...props}
    >
      {text}
    </Button>
  );
};

export const ProductHelpLinks = () => (
  <>
    <ProductHelpBtn
      startIcon={<Help />}
      text="Documentation and FAQ's"
      url="https://www.notion.so/Product-Documentation-8766056cd1d54b02b9187839b11ac72f"
    />
    <ProductHelpBtn
      startIcon={<Report />}
      text="Have a bug/request?"
      url="https://forms.clickup.com/9008098768/f/8cetbeg-15865/8HFL6JFM6L8YGXXC7P"
    />
  </>
);

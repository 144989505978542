import { stringify } from 'query-string';
import * as R from 'ramda';
import moment from 'moment-timezone';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const cohortsProvider = {
  getList: async (params) => {
    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const sortPrefix = params.sort.order === 'DESC' ? '-' : '';
    const sort = `${sortPrefix}${params.sort.field}`;
    const finalFilter = R.pipe(
      R.evolve({
        ends_since: (date) => moment(date).format('YYYY-MM-DD'),
        ends_before: (date) => moment(date).format('YYYY-MM-DD'),
        starts_since: (date) => moment(date).format('YYYY-MM-DD'),
        starts_before: (date) => moment(date).format('YYYY-MM-DD'),
      }),
      R.when(R.has('campuses'), (filter) =>
        R.has('campus', filter)
          ? filter
          : { campus: filter.campuses, ...filter }
      ),
      R.omit(['campuses'])
    )(params.filter);
    const filter = JSON.stringify(finalFilter);
    const query = {
      page,
      limit,
      sort,
      filter,
    };
    const url = `${apiUrl}/cohorts?${stringify(query)}`;
    return { url };
  },
  getOne: async (params) => {
    const url = `${apiUrl}/cohorts/${params.id}`;
    return { url };
  },
  getMany: async (params) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ _id: { $in: params.ids } }),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = `${apiUrl}/cohorts/search?${stringify({
      limit: params.ids.length,
    })}`;
    return { url, options };
  },
  create: async (params) => {
    const url = `${apiUrl}/cohorts`;
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
  delete: async (params) => {
    const options = { method: 'DELETE' };
    const url = `${apiUrl}/cohorts/${params.id}`;
    return { url, options };
  },
  update: async (params) => {
    const url = `${apiUrl}/cohorts/${params.id}`;
    const options = {
      method: 'PATCH',
      body: JSON.stringify(R.omit(['id'], params.data)),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
};

export default cohortsProvider;

import React, { useEffect } from 'react';
import * as R from 'ramda';
import { capitalize } from 'lodash';
import { useUpdate } from 'react-admin';
import { Box, Button, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';

import actionsMap from './actions';

const EditAction = (props) => {
  const { action, onUpdated } = props;
  const ActionFields = actionsMap[action.type].Edit;

  const validate = () => {};
  const [updateAction, { loading, error, data: updatedAction }] = useUpdate(
    'actions',
    action.id
  );
  const onSubmit = (data) => {
    const update = R.propOr({}, 'initial_config', data);
    if (!R.is(String, update.date)) {
      update.date = update.date.format();
    }
    updateAction({ payload: { data: update } });
  };

  useEffect(() => {
    if (updatedAction) {
      onUpdated('success', { ...action, initial_config: updatedAction });
    } else if (error) {
      onUpdated('error', error);
    }
  }, [action, error, onUpdated, updatedAction]);

  return (
    <>
      <Typography variant="h6" component="h3">
        {action.type.split('_').map(capitalize).join(' ')}
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={action}
        validate={validate}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <>
              <ActionFields />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pt="16px"
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || loading}
                >
                  Update
                </Button>
                <Box ml="16px">
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={submitting || loading}
                    onClick={() => onUpdated('cancelled')}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </>
          </form>
        )}
      />
    </>
  );
};

export default EditAction;

import React from 'react';
import * as R from 'ramda';
import { EditButton, SelectField } from 'react-admin';
import { Chip } from '@material-ui/core';
import {
  lightGreen,
  red,
  brown,
  orange,
  yellow,
  grey,
} from '@material-ui/core/colors';
import { Edit, Warning } from '@material-ui/icons';
import { Box } from '@material-ui/core';

import { riskLevels } from '../../../data';

const riskLevelChipStyles = {
  good: {
    backgroundColor: lightGreen[200],
    color: lightGreen[900],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  potentially_at_risk: {
    backgroundColor: grey[100],
    color: orange[900],
    fontWeight: 500,
  },
  level_1: {
    backgroundColor: yellow[100],
    color: brown[500],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  level_2: {
    backgroundColor: orange[200],
    color: brown[500],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  level_3: {
    backgroundColor: red[100],
    color: red[900],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
};

const RiskLevelChipLabel = (props) => {
  const { record: risk } = props;

  const hasRiskLevel = R.includes(risk.id, ['level_1', 'level_2', 'level_3']);
  const hasPotentiallyRisk = R.equals(risk.id, 'potentially_at_risk');

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {hasPotentiallyRisk && (
        <Warning style={{ fontSize: '14px', marginRight: '2px' }} />
      )}
      {risk.short_name}
      {hasRiskLevel && (
        <EditButton
          basePath="/students"
          label={false}
          record={props}
          style={{
            minWidth: 0,
            color: riskLevelChipStyles[risk.id].color,
            paddingRight: 0,
          }}
          icon={<Edit style={{ fontSize: '14px' }} />}
        />
      )}
      {hasPotentiallyRisk && (
        <EditButton
          icon={false}
          label="Assign level"
          basePath="/students"
          record={props}
          style={{
            color: '#304BE1',
            fontWeight: 600,
            textDecoration: 'underline',
            fontSize: '11px',
            textTransform: 'none',
            minWidth: 'fit-content',
            paddingLeft: '1px',
          }}
        />
      )}
    </Box>
  );
};

const RiskLevelChip = (props) => {
  const { record: risk } = props;
  const isNotInRisk = R.equals(risk.id, 'good');

  return (
    <Box display="flex" alignItems="center">
      <Chip
        style={{
          ...riskLevelChipStyles[risk.id],
          fontSize: '12px',
          padding: isNotInRisk ? '0 6px' : '6px 0 6px 6px',
        }}
        label={<RiskLevelChipLabel {...props} />}
      />
      {isNotInRisk && (
        <EditButton
          icon={false}
          label="Flag as at risk"
          basePath="/students"
          record={props}
          style={{
            fontWeight: 500,
            textDecoration: 'underline',
            fontSize: '11px',
            color: grey[700],
            textTransform: 'none',
            minWidth: 'fit-content',
          }}
        />
      )}
    </Box>
  );
};

const RiskLevelField = ({ record, source }) => (
  <SelectField
    basePath="/students"
    record={
      R.hasPath(['performance', 'risk', 'level'], record)
        ? record
        : { performance: { risk: { level: 'good' } } }
    }
    source={source}
    choices={riskLevels}
    optionText={<RiskLevelChip {...record} />}
  />
);

RiskLevelField.defaultProps = {
  source: 'performance.risk.level',
  label: 'At risk info',
};

export default RiskLevelField;

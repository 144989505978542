import React from 'react';
import * as R from 'ramda';
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  FormWithRedirect,
  minValue,
  number,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DateInput } from 'resources/common/DatePickers';
import { authServer } from 'auth/keycloak';
import {
  campuses,
  campusesAll,
  cohortCreateDefaults,
  cohortTimezones,
  currencies,
  formats,
  languages,
  tracks,
} from 'data';

const validateDupeOfferings = (offerings) => {
  const hasDupes = R.pipe(
    R.defaultTo([]),
    R.pluck('campus'),
    R.reject(R.isNil),
    R.converge(R.complement(R.equals), [R.identity, R.uniq])
  )(offerings);
  return hasDupes
    ? 'One or more campuses have duplicated offerings'
    : undefined;
};
const validateRequired = [required('Required')];
const validateNumbers = [number(), minValue(0)];
const validateRequiredNumbers = R.concat(validateRequired, validateNumbers);
const validateOfferings = [validateDupeOfferings];

const useFormIteratorStyles = makeStyles({
  form: {
    display: 'flex',
    flex: 1,
    '& > .ra-input': {
      display: 'flex',
      flex: 1,
      marginRight: '2em',
    },
  },
});

const CohortCreateForm = (props) => {
  const campusChoices = R.pipe(
    R.pathOr([], ['roles', 'campus']),
    R.of,
    R.partialRight(R.includes, R.__),
    R.propSatisfies(R.__, 'id'),
    R.filter(R.__, campuses)
  )(authServer);
  const singleCampusChoice = campusChoices.length === 1;
  const initialValues = singleCampusChoice
    ? cohortCreateDefaults[campusChoices[0].id]
    : {};
  const formIteratorClasses = useFormIteratorStyles();

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        ...initialValues,
        active: false,
        sold_out: false,
        salesforce_sync: true,
        has_career_services: true,
        has_prework: true,
      }}
      render={(formProps) => {
        return (
          <form>
            <Box display="block" p="1.5em">
              <Typography variant="h5" gutterBottom>
                Cohort
              </Typography>
              <Box
                display={{ sm: 'block', md: 'flex' }}
                justifyContent="space-between"
              >
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Track"
                    source="track"
                    choices={tracks}
                    validate={validateRequired}
                    variant="standard"
                    onChange={R.path(
                      ['form', 'mutators', 'courseTitleMutator'],
                      formProps
                    )}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Format"
                    source="format"
                    choices={formats}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                  <Box>
                    <SelectInput
                      label="Campus"
                      source="campus"
                      choices={campusChoices}
                      validate={validateRequired}
                      disabled={singleCampusChoice}
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                  <FormDataConsumer>
                    {({ formData }) =>
                      R.equals('rmt', formData.campus) && (
                        <Box>
                          <SelectInput
                            label="Timezone"
                            source="timezone"
                            choices={cohortTimezones}
                            validate={validateRequired}
                            variant="standard"
                            fullWidth
                          />
                        </Box>
                      )
                    }
                  </FormDataConsumer>
                </Box>
                <Box flex={1}>
                  <SelectInput
                    label="Language"
                    source="language"
                    choices={languages}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Pricing
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <NumberInput
                    label="Price"
                    source="price.amount"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <NumberInput
                    label="Discount"
                    source="price.discount"
                    validate={validateNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flex={1}>
                  <SelectInput
                    label="Currency"
                    choices={currencies}
                    source="price.currency"
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Offerings
              </Typography>
              <ArrayInput source="offerings" validate={validateOfferings}>
                <SimpleFormIterator classes={formIteratorClasses}>
                  <SelectInput
                    label="Campus"
                    source="campus"
                    choices={R.reject(R.propEq('id', 'rmt'), campusesAll)}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                  <NumberInput
                    label="Price"
                    source="price.amount"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />

                  <NumberInput
                    label="Discount"
                    source="price.discount"
                    validate={validateNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />

                  <SelectInput
                    label="Currency"
                    choices={currencies}
                    source="price.currency"
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                  <NumberInput
                    label="Target"
                    source="target"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />
                </SimpleFormIterator>
              </ArrayInput>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Dates
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1}>
                  <DateInput
                    label="Start Date"
                    source="start_date"
                    options={{
                      format: 'Do MMMM YYYY',
                      openTo: 'year',
                      views: ['year', 'month', 'date'],
                    }}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <FormDataConsumer>
                  {({ formData }) =>
                    R.equals('other', formData.format) && (
                      <Box flex={1} ml={{ md: '2em' }}>
                        <DateInput
                          label="End Date"
                          source="end_date"
                          options={{
                            format: 'Do MMMM YYYY',
                            openTo: 'year',
                            views: ['year', 'month', 'date'],
                          }}
                          validate={validateRequired}
                          variant="standard"
                          fullWidth
                        />
                      </Box>
                    )
                  }
                </FormDataConsumer>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Target
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1}>
                  <NumberInput
                    label="Target"
                    source="target"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box mb="3em" />
              <FormDataConsumer>
                {({ formData }) => (
                  <>
                    {R.propEq('track', 'other', formData) && (
                      <Box mb="3em">
                        <Typography variant="h5" gutterBottom>
                          Custom Cohort Config
                        </Typography>
                        <Box display={{ sm: 'block', md: 'flex' }}>
                          <Box flex={1}>
                            <BooleanInput
                              label="Sync With Salesforce"
                              source="salesforce_sync"
                              defaultValue={true}
                              variant="standard"
                              disabled={formData.track !== 'other'}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1}>
                            <BooleanInput
                              label="Has Prework?"
                              source="has_prework"
                              defaultValue={true}
                              variant="standard"
                              disabled={formData.track !== 'other'}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1}>
                            <BooleanInput
                              label="Has Careerhack?"
                              source="has_career_services"
                              defaultValue={true}
                              variant="standard"
                              disabled={formData.track !== 'other'}
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {R.propEq('track', 'cy', formData) && (
                      <Box mb="3em">
                        <Typography variant="h5" gutterBottom>
                          External IDs
                        </Typography>
                        <Box display={{ sm: 'block', md: 'flex' }}>
                          <Box flex={1}>
                            <TextInput
                              label="Cybint Access Code"
                              source="external_ids.cybint_access_code"
                              variant="standard"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </FormDataConsumer>
            </Box>
            <Toolbar>
              {authServer.isRoles(['finance', 'gm']) && (
                <Box display="flex" width="100%">
                  <Box flex={2}>
                    <SaveButton
                      record={formProps.record}
                      basePath={formProps.basePath}
                      undoable={true}
                      invalid={formProps.invalid}
                      saving={formProps.saving}
                      resource="cohorts"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect
                      }
                    />
                  </Box>
                </Box>
              )}
            </Toolbar>
          </form>
        );
      }}
    />
  );
};

const CohortCreate = ({ classes, ...props }) => {
  return (
    <Create actions={null} undoable={false} {...props}>
      <CohortCreateForm redirect="list" />
    </Create>
  );
};

export default CohortCreate;

import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  ReferenceArrayField,
  SearchInput,
  SingleFieldList,
  TextField
} from 'react-admin';

const UserFilter = props => (
  <Filter {...props} variant="standard">
    <SearchInput source="search" alwaysOn />
  </Filter>
);

const UserList = props => (
  <List
    {...props}
    filters={<UserFilter />}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid rowClick="show">
      <TextField label="Email" source="email" />
      <TextField label="First Name" source="first_name" />
      <TextField label="Last Name" source="last_name" />
      <ReferenceArrayField label="Groups" reference="groups" source="groups">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export default UserList;

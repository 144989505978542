import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'ra-core';

import { authServer } from 'auth/keycloak';
import BookmarkButton from '../../../BookmarkButton';

const BookmarkField = ({ record: cohort, size, labeled }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const user = useMemo(authServer.getUser);
  const [bookmarked, setBookmarked] = useState(() =>
    R.pipe(R.propOr([], 'bookmarked_by'), R.includes(user))(cohort)
  );
  const [loading, setLoading] = useState(false);

  const toggleBookmark = async () => {
    setLoading(true);
    try {
      await dataProvider.update(
        R.has('company', cohort) ? 'cohorts_b2b' : 'cohorts',
        {
          id: cohort.id,
          data: {
            bookmarked_by: R.pipe(
              R.propOr([], 'bookmarked_by'),
              bookmarked ? R.without([user]) : R.append(user)
            )(cohort),
          },
        }
      );
      setBookmarked(!bookmarked);
      setLoading(false);
    } catch (e) {
      notify(
        `There was an error while ${
          bookmarked ? 'un' : ''
        }bookmarking the cohort, please try again later.`,
        'warning'
      );
      setLoading(false);
    }
  };

  return (
    <BookmarkButton
      isBookmarked={bookmarked}
      isLoading={loading}
      onClick={toggleBookmark}
      size={size}
      labeled={labeled}
    />
  );
};

BookmarkField.propTypes = {
  cohort: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labeled: PropTypes.bool,
};

export default BookmarkField;

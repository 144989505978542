import React from 'react';
import * as R from 'ramda';

import { Labeled, SelectField } from 'react-admin';
import { Grid } from '@material-ui/core';

import { SalaryField } from 'resources/students/fields';
import { DateField } from 'resources/common';
import { outcomeWeeklyHours } from 'data';

const FreelancerFields = ({ student }) => {
  return (
    <Grid container>
      <Grid item xs={12} lg={2}>
        <Labeled label="Start Date">
          <DateField
            record={student}
            source="career_services.outcome.offer_date"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Working Hours">
          <SelectField
            record={student}
            source="career_services.outcome.weekly_hours"
            choices={outcomeWeeklyHours}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Labeled label="Salary">
          <SalaryField
            amount={R.path(
              ['career_services', 'outcome', 'salary', 'amount'],
              student
            )}
            currency={R.path(
              ['career_services', 'outcome', 'salary', 'currency'],
              student
            )}
            type={R.path(
              ['career_services', 'outcome', 'salary', 'salary_type'],
              student
            )}
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default FreelancerFields;

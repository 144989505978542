import React, { useState } from 'react';
import { Typography, Box } from '@material-ui/core';

import CopyToClipboard from '../../common/CopyToClipboard';

const EmailField = ({ record, copyable = false }) => {
  const [showCopy, setShowCopy] = useState(false);

  return (
    <Box
      onMouseEnter={() => setShowCopy(true)}
      onMouseLeave={() => setShowCopy(false)}
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
    >
      <Typography component="span" variant="body2">
        {record.email}
      </Typography>

      {copyable && <CopyToClipboard active={showCopy} text={record.email} />}
    </Box>
  );
};

EmailField.defaultProps = {
  source: 'email',
  label: 'Email',
};

export default EmailField;

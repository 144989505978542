import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  ArrayField,
  Datagrid,
  Error,
  FunctionField,
  Loading,
  TextField,
  useQuery,
} from 'react-admin';
import { Box, Button, makeStyles } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import {
  AssignmentDueDateField,
  AssignmentGradeDistributionField,
  ChipField,
  DeliveriesList,
  DeliveryTitle,
  OfflineList,
} from 'resources/common';
import { AssignmentProgressField } from 'resources/common/cohorts/list/fields';

const scoreMap = {
  very_good: 5,
  good: 4,
  normal: 3,
  bad: 2,
  very_bad: 1,
};

const LinkToStudio = ({ record }) => {
  const baseUrl = process.env.REACT_APP_STUDIO_BASE_URL;
  const courseId = R.pipe(
    R.pathOr('', ['path', 0, 'id']),
    R.split(':'),
    R.last
  )(record);
  const unitId = R.pathOr('', ['path', 3, 'id'])(record);
  const url = `${baseUrl}/container/block-v1:${courseId}+type@vertical+block@${unitId}`;
  return (
    <Button
      size="small"
      color="primary"
      target="_blank"
      href={url}
      label="See In Studio"
    >
      <OpenInNew style={{ paddingRight: '0.2em' }} />
      See In Studio
    </Button>
  );
};

const useListStyles = makeStyles({
  grades: { width: 250 },
  average_grade: { width: 100 },
});

const AssignmentList = ({ record, course, section = null }) => {
  const classes = useListStyles();
  const [assignments, setAssignments] = useState([]);

  const {
    data: apiAssignments,
    loading: assignmentsLoading,
    error: assignmentsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'deliveries',
    payload: {
      target: 'cohort',
      id: record.id,
      pagination: {
        perPage: 100,
      },
      filter: R.reject(R.isNil, { course, section }),
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  useEffect(() => {
    if (apiAssignments) {
      const tableData = R.pipe(
        R.map((assignment) => {
          const averageGrade = R.pipe(
            R.propOr([], 'deliveries'),
            R.pluck('grade'),
            R.reject(R.isNil),
            R.map(R.prop(R.__, scoreMap)),
            R.mean,
            (mean) => Math.round((mean + Number.EPSILON) * 100) / 100
          )(assignment);
          return R.assoc('average_grade', averageGrade, assignment);
        })
      )(apiAssignments);
      setAssignments(tableData);
    }
  }, [apiAssignments, course]);

  if (assignmentsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (assignmentsError)
    return (
      <Box p="16px">
        <Error error={assignmentsError} errorInfo={assignmentsError} />
      </Box>
    );

  if (R.isEmpty(assignments)) {
    return null;
  }

  return (
    <OfflineList
      list={assignments}
      uniqueKey="id"
      defaultSort={{ field: 'due_date', order: 'ASC' }}
      sorters={{
        assignment_progress: R.pipe(R.propOr([], 'deliveries'), R.length),
        deliveries: R.pipe(
          R.pathOr([], [course, 'deliveries']),
          R.filter(R.propEq('grade', 'very_good')),
          R.length
        ),
      }}
    >
      <Datagrid
        rowClick="expand"
        expand={
          <DeliveriesList record={record} type="assignment" course={course} />
        }
      >
        <DeliveryTitle />
        <FunctionField
          label="Type"
          source="type"
          render={(record) => {
            return record.type === 'file_type' ? 'File Upload' : 'URL';
          }}
        />
        <AssignmentDueDateField label="Due Date" source="due_date" />
        <AssignmentProgressField
          label="Progress"
          source="assignment_progress"
        />
        <AssignmentGradeDistributionField
          label="Grades"
          source="deliveries"
          sortable={false}
          cellClassName={classes.grades}
        />
        <ChipField
          label="Avg. Grade"
          source="average_grade"
          chipStyle="assignmentGrade"
          cellClassName={classes.average_grade}
        />
        <LinkToStudio label="Studio Link" sortable={false} />
      </Datagrid>
    </OfflineList>
  );
};

const SectionAssignmentList = ({ record, cohort, course }) => {
  return <AssignmentList record={cohort} course={course} section={record.id} />;
};

const SectionList = ({ record, course }) => {
  const sections = R.pipe(
    R.pathOr([], [course, 'assignments']),
    R.map(R.pipe(R.prop('path'), R.nth(1))),
    R.uniq
  )(record);
  return (
    <ArrayField source="sections" record={{ sections }}>
      <Datagrid
        rowClick="expand"
        expand={<SectionAssignmentList cohort={record} course={course} />}
      >
        <TextField label="Section" source="display_name" sortable={false} />
      </Datagrid>
    </ArrayField>
  );
};

function ByAssignmentsSubtab(props) {
  const { record, course } = props;
  if (course === 'course') {
    return <SectionList record={record} course={course} />;
  } else {
    return <AssignmentList record={record} course={course} />;
  }
}

export default ByAssignmentsSubtab;

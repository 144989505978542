const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const projectOutcomesProvider = {
  update: async (params) => {
    const url = `${apiUrl}/students/${params.id}/course/${params.data.course}/projects/${params.data.projectName}/outcomes/${params.data.outcomeId}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({ score: params.data.score }),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
};

export default projectOutcomesProvider;

import { useState, useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { fetchEnd, fetchStart } from 'react-admin';

import { authServer } from 'auth/keycloak';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    default:
      throw new Error();
  }
};

const useBackend = () => {
  const [call, setCall] = useState({});
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loaded: false,
    loading: false,
    error: false,
    data: null,
  });
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (!R.isEmpty(call)) {
      let didCancel = false;
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        reduxDispatch(fetchStart());
        try {
          const response = await fetch(
            `${apiUrl}${call.url}`,
            R.pipe(
              R.omit('url'),
              R.assocPath(
                ['headers', 'Authorization'],
                `Bearer ${authServer.token}`
              ),
              R.when(
                R.has('body'),
                R.pipe(
                  R.assocPath(['headers', 'Content-Type'], 'application/json'),
                  R.evolve({ body: JSON.stringify })
                )
              )
            )(call)
          );
          if (response.ok) {
            const result = await response.json();
            if (!didCancel) {
              dispatch({ type: 'FETCH_SUCCESS', payload: result });
            }
          } else {
            if (!didCancel) {
              dispatch({ type: 'FETCH_FAILURE' });
            }
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }
        reduxDispatch(fetchEnd());
      };
      fetchData();
      return () => {
        didCancel = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call]);
  return [state, setCall];
};

export default useBackend;

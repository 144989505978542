import React from 'react';
import * as R from 'ramda';

import { Labeled } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';

import { ProfileLinkField } from '../fields';

const getUrl = {
  card: R.pipe(
    R.prop('profile_slug'),
    R.concat(`${process.env.REACT_APP_PROFILE_BASE_URL}/`)
  ),
  cv: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'cv')),
    R.prop('url')
  ),
  github: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'github')),
    R.prop('url')
  ),
  linkedin: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'linkedin')),
    R.prop('url')
  ),
  portfolio: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'portfolio')),
    R.prop('url')
  ),
  medium: R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('name', 'medium')),
    R.prop('url')
  ),
};

const ProfileInfo = ({ student }) => {
  if (R.isNil(student?.links)) return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            Profile
          </Typography>
        </Box>
      </Grid>
      {getUrl['cv'](student) && (
        <Grid item xs={12} lg={2}>
          <Labeled label="CV Link">
            <ProfileLinkField record={student} target="cv" />
          </Labeled>
        </Grid>
      )}
      {getUrl['linkedin'](student) && (
        <Grid item xs={12} lg={2}>
          <Labeled label="LinkedIn">
            <ProfileLinkField record={student} target="linkedin" />
          </Labeled>
        </Grid>
      )}
      {getUrl['github'](student) && (
        <Grid item xs={12} lg={2}>
          <Labeled label="GitHub">
            <ProfileLinkField record={student} target="github" />
          </Labeled>
        </Grid>
      )}
      {getUrl['portfolio'](student) && (
        <Grid item xs={12} lg={2}>
          <Labeled label="PortFolio">
            <ProfileLinkField record={student} target="portfolio" />
          </Labeled>
        </Grid>
      )}
      {getUrl['medium'](student) && (
        <Grid item xs={12} lg={2}>
          <Labeled label="Medium">
            <ProfileLinkField record={student} target="medium" />
          </Labeled>
        </Grid>
      )}
    </Grid>
  );
};

export default ProfileInfo;

import * as React from 'react';
import { Children, cloneElement, isValidElement } from 'react';
import { Divider, Tabs } from '@material-ui/core';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { escapePath } from 'ra-core';

const sanitizeRestProps = ({
  children,
  className,
  record,
  resource,
  basePath,
  version,
  initialValues,
  staticContext,
  translate,
  tabs,
  ...rest
}) => rest;

const useStyles = makeStyles(
  (theme) => ({
    content: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
  { name: 'RaTabbedShowLayout' }
);

export const NavigableTabs = (props) => {
  const {
    basePath,
    children,
    classes: classesOverride,
    className,
    record,
    resource,
    tabs,
    value,
    version,
    matchUrl,
    variant = 'fullWidth',
    ...rest
  } = props;
  const location = useLocation();
  const match = useRouteMatch();
  const classes = useStyles(props);
  const validChildren = Children.toArray(children).filter(
    (child) => child !== null && isValidElement(child)
  );

  const tabValue = matchUrl(location.pathname);

  return (
    <div className={className} key={version} {...sanitizeRestProps(rest)}>
      <Tabs indicatorColor="primary" value={tabValue} variant={variant}>
        {Children.map(validChildren, (tab) =>
          cloneElement(tab, {
            context: 'header',
            value: `${match.url}/${tab.props.path}`,
          })
        )}
      </Tabs>
      <Divider />
      <div className={classes.content}>
        <Switch>
          <Redirect
            exact
            from={`${match.url}`}
            to={`${match.url}/${validChildren[0].props.path}`}
          />
          {Children.map(validChildren, (tab) => {
            return (
              <Route
                path={escapePath(`${match.url}/${tab.props.path}`)}
                render={() => {
                  return cloneElement(tab, {
                    context: 'content',
                    resource,
                    record,
                    basePath,
                  });
                }}
              />
            );
          })}
          <Route>
            <Redirect to="/404" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

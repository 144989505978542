import { stringify } from 'query-string';
import * as R from 'ramda';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const studentClustersProvider = {
  create: async (params) => {
    const url = `${apiUrl}/student_clusters`;
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
  getList: async (params) => {
    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const sortPrefix =
      R.pathOr('ASC', ['sort', 'order'], params) === 'DESC' ? '-' : '';
    const sort = `${sortPrefix}${R.pathOr('id', ['sort', 'field'], params)}`;
    const query = { page, limit, sort };
    const url = `${apiUrl}/student_clusters?${stringify(query)}`;
    return { url };
  },
  update: async (params) => {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = `${apiUrl}/student_clusters/${params.id}`;
    return { url, options };
  },
};

export default studentClustersProvider;

import React from 'react';
import * as R from 'ramda';

import { NewProgressField } from 'resources/common';

const AssignmentProgressField = ({ record }) => {
  const progress = R.defaultTo(
    0,
    Math.round((100 * record.deliveries.length) / record.expected)
  );
  const text = `${progress}%`;
  return <NewProgressField value={progress} text={text} />;
};

export default AssignmentProgressField;

import { stringify } from 'query-string';
import * as R from 'ramda';
import * as P from 'p-iteration';
import * as flatten from 'flat';
import { diff } from 'deep-object-diff';
import { authServer } from 'auth/keycloak';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const studentsProvider = {
  getList: async (params) => {
    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const filter = params.filter;
    const sortPrefix = params.sort.order === 'DESC' ? '-' : '';
    const sort = `${sortPrefix}${R.pathOr('id', ['sort', 'field'], params)}`;

    let search = [{ $match: {} }];
    if (R.has('name_search', filter)) {
      search = [{ $match: { $text: { $search: filter.name_search } } }];
    }
    if (R.has('email_search', filter)) {
      search = [{ $match: { email: filter.email_search } }];
    }
    if (
      R.anyPass([
        R.has('careerhack_opted_in'),
        R.has('outcomes_category'),
        R.has('outcomes_status'),
        R.has('outcomes_form_filled'),
      ])(filter)
    ) {
      const matchEntry = R.pipe(
        R.toPairs,
        R.reduce((matchEntryAcc, [filterKey, filterValue]) => {
          const [field, query] = R.cond([
            [
              R.equals('careerhack_opted_in'),
              () => R.pair('career_services.opted_in', { $eq: filterValue }),
            ],
            [
              R.equals('outcomes_category'),
              () => R.pair('career_services.category', { $in: filterValue }),
            ],
            [
              R.equals('outcomes_status'),
              () => R.pair('career_services.status', { $in: filterValue }),
            ],
            [
              R.equals('outcomes_form_filled'),
              () =>
                R.pair('career_services.outcome', {
                  $exists: R.head(filterValue),
                }),
            ],
            [
              R.equals('progress_gte'),
              () =>
                R.pair('professional_profile.progress', { $gte: filterValue }),
            ],
            [
              R.equals('progress_lte'),
              () =>
                R.pair('professional_profile.progress', { $lte: filterValue }),
            ],
            [R.T, R.always([])],
          ])(filterKey);
          return field
            ? R.mergeDeepLeft(matchEntryAcc, { [field]: query })
            : matchEntryAcc;
        }, {})
      )(filter);
      search = [{ $match: matchEntry }];
    }

    if (R.has('is_b2b', filter)) search[0].$match.is_b2b = filter.is_b2b;
    if (R.has('cohort', filter)) search[0].$match.cohort = filter.cohort;

    const cohortQuery = R.pipe(
      R.defaultTo({}),
      R.toPairs,
      R.filter(
        ([key]) => R.startsWith('cohort', key) && !R.equals('cohort', key)
      ),
      R.map(([key, value]) => [
        R.pipe(
          R.replace('cohort_', 'cohort.'),
          R.when(R.includes('starts'), R.always('cohort.start_date')),
          R.when(R.includes('ends'), R.always('cohort.end_date'))
        )(key),
        R.cond([
          [R.is(Array), R.objOf('$in')],
          [
            R.test(/^\d{4}-\d{2}-\d{2}$/),
            (date) =>
              // Tests if is querying for start or end date
              R.test(/^cohort_.*_since$/, key)
                ? { $gte: date }
                : { $lte: date },
          ],
          [R.T, R.identity],
        ])(value),
      ]),
      R.reduce((acc, [key, value]) => {
        acc[key] = acc[key] ? R.mergeDeepRight(acc[key], value) : value;
        return acc;
      }, {}),
      (query) =>
        R.has('campuses', filter)
          ? { 'cohort.campus': { $in: filter.campuses }, ...query }
          : query
    )(filter);

    if (!R.isEmpty(cohortQuery)) {
      search = R.concat(search, [
        {
          $lookup: {
            from: authServer.isNotRoles(['b2b']) ? 'cohorts' : 'cohorts_b2b',
            localField: 'cohort',
            foreignField: '_id',
            as: 'cohort',
          },
        },
        { $unwind: { path: '$cohort' } },
        {
          $match: cohortQuery,
        },
        {
          $addFields: {
            cohort: '$cohort._id',
          },
        },
      ]);
    }
    const options = {
      method: 'POST',
      body: JSON.stringify(search),
      headers: { 'Content-Type': 'application/json' },
    };
    const query = { page, limit, sort };
    const url = `${apiUrl}/students/search?${stringify(query)}`;
    return { url, options };
  },
  getOne: async (params) => {
    const url = `${apiUrl}/students/${params.id}`;
    return { url };
  },
  getMany: async (params) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ _id: { $in: params.ids } }),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = `${apiUrl}/students/search?${stringify({
      limit: params.ids.length,
    })}`;
    return { url, options };
  },
  create: async (params) => {
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = params.b2b
      ? `${apiUrl}/students/b2b_onboarding`
      : `${apiUrl}/students`;
    return { url, options };
  },
  update: async (params) => {
    const changes = R.pipe(
      R.props(['data', 'previousData']),
      R.apply(diff),
      flatten,
      R.keys,
      R.map(R.split('.')),
      R.reduce((result, changedPath) => {
        return R.assocPath(
          changedPath,
          R.path(changedPath, params.data),
          result
        );
      }, {})
    )(params);
    const convertFileToBase64 = async (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () =>
          resolve({
            key: file.rawFile.name,
            size: file.rawFile.size,
            type: file.rawFile.type,
            base64: reader.result,
          });
        reader.onerror = reject;
      });
    if (R.hasPath(['career_services', 'proofs'], changes)) {
      changes.career_services.proofs = await P.mapSeries(
        changes.career_services.proofs,
        convertFileToBase64
      );
    }
    if (R.hasPath(['graduation', 'proofs'], changes)) {
      changes.graduation.proofs = await P.mapSeries(
        changes.graduation.proofs,
        convertFileToBase64
      );
    }
    const options = {
      method: 'PATCH',
      body: JSON.stringify(changes),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = `${apiUrl}/students/${params.id}`;
    return { url, options };
  },
  delete: async (params) => {
    const options = { method: 'DELETE' };
    const url = `${apiUrl}/students/${params.id}`;
    return { url, options };
  },
  getManyReference: async (params) => {
    const search = [{ $match: { cohort: params.id, ...params.filter } }];

    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const sortPrefix =
      R.pathOr('ASC', ['sort', 'order'], params) === 'DESC' ? '-' : '';
    const sort = `${sortPrefix}${R.pathOr('id', ['sort', 'field'], params)}`;
    const query = {
      sort,
      page,
      limit,
    };
    if (R.hasPath(['filter', 'course'], params)) {
      query.course = params.filter.course;
      delete search[0].$match.course;
    }
    const url = `${apiUrl}/students/search?${stringify(query)}`;
    const options = {
      method: 'POST',
      body: JSON.stringify(search),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
};

export default studentsProvider;

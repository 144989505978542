import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

const CohortEndDateField = ({ record: cohort = {} }) => (
  <Typography component="span" variant="body2">
    {moment.utc(cohort?.end_date).format('DD MMM YYYY')}
  </Typography>
);

export default CohortEndDateField;

import React from 'react';
import { NumberInput, TextInput } from 'react-admin';
import { Box, Typography } from '@material-ui/core';

import { DateInput } from 'resources/common/DatePickers';

const AdditionalInformationEdit = () => (
  <>
    <Typography variant="h5" gutterBottom>
      Cohort additional information
    </Typography>
    <Typography variant="h6" gutterBottom>
      BG Financial Germany
    </Typography>
    <Box display={{ sm: 'block', md: 'flex' }}>
      <Box flex={1} mr={{ md: '2em' }}>
        <TextInput
          source="additional_information.bg_financing.bg_title"
          label="BG Title"
          variant="standard"
          fullWidth
        />
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <TextInput
          source="additional_information.bg_financing.bg_action_number"
          label="BG Action Number"
          variant="standard"
          fullWidth
        />
      </Box>
    </Box>
    <Box display={{ sm: 'block', md: 'flex' }}>
      <Box flex={1} mr={{ md: '2em' }}>
        <NumberInput
          label="BG Number of Hours"
          source="additional_information.bg_financing.bg_hours"
          defaultValue={0}
          variant="standard"
          fullWidth
        />
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <NumberInput
          label="BG Number of Weeks"
          source="additional_information.bg_financing.bg_weeks"
          defaultValue={0}
          variant="standard"
          fullWidth
        />
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <TextInput
          source="additional_information.bg_financing.bg_schedule"
          label="BG Schedule"
          variant="standard"
          fullWidth
        />
      </Box>
    </Box>
    <Box display={{ sm: 'block', md: 'flex' }}>
      <Box flex={1} mr={{ md: '2em' }}>
        <DateInput
          label="BG Start Date"
          source="additional_information.bg_financing.bg_start_date"
          options={{
            format: 'Do MMMM YYYY',
            openTo: 'year',
            views: ['year', 'month', 'date'],
          }}
          variant="standard"
          fullWidth
        />
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <DateInput
          label="BG End Date"
          source="additional_information.bg_financing.bg_end_date"
          options={{
            format: 'Do MMMM YYYY',
            openTo: 'year',
            views: ['year', 'month', 'date'],
          }}
          variant="standard"
          fullWidth
        />
      </Box>
      <Box flex={1} mr={{ md: '2em' }}>
        <DateInput
          label="BG Public End Date"
          source="additional_information.bg_financing.bg_public_end_date"
          options={{
            format: 'Do MMMM YYYY',
            openTo: 'year',
            views: ['year', 'month', 'date'],
          }}
          variant="standard"
          fullWidth
        />
      </Box>
    </Box>
  </>
);

export default AdditionalInformationEdit;

import React from 'react';
import * as R from 'ramda';
import * as moment from 'moment';
import { BooleanField, Labeled, SelectField } from 'react-admin';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { careerServiceStatuses } from 'data';
import i18nIsoCountries from '@cospired/i18n-iso-languages/';
import i18nIsoCountriesEng from '@cospired/i18n-iso-languages/langs/en.json';

i18nIsoCountries.registerLocale(i18nIsoCountriesEng);

const levelTextKey = {
  elementary: 'Elementary proficiency',
  limited: 'Limited working proficiency',
  professional: 'Professional proficiency',
  full: 'Full professional proficiency',
  native: 'Native or bilingual proficiency',
};

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const getSearchingDaysColors = R.pipe(
  R.cond([
    [R.lt(R.__, 60), () => ({ backgroundColor: '#4CAF50', color: 'black' })],
    [R.lt(R.__, 90), () => ({ backgroundColor: '#FF9800', color: 'black' })],
    [R.lt(R.__, 180), () => ({ backgroundColor: '#B71C1C', color: 'white' })],
    [
      R.T,
      () => ({ backgroundColor: 'rgba(158, 158, 158, 0.1)', color: 'black' }),
    ],
  ])
);

const LocationJobHuntField = ({ locations }) => {
  const cities = locations && R.pipe(
    R.pluck('city'),
    R.ifElse((cities) => cities.length === 1, R.head, R.join(', '))
  )(locations);
  const country = locations && regionNames.of(R.pathOr('', ['0', 'country'], locations));
  return (
    <Labeled label="Location for job hunt">
      <Typography variant="body2">
        {locations ? '--' : `${cities} (${country})`}
      </Typography>
    </Labeled>
  );
};

const BackgroundInfo = ({ student }) => {
  const jobSearchStartDate = moment(
    R.pathOr('', ['career_services', 'job_search_start', 'date'], student)
  );

  const searchingDays = moment().diff(jobSearchStartDate, 'days');

  const hasStartedSearching = searchingDays >= 0;

  const searchingDaysBgColor = getSearchingDaysColors(searchingDays);

  const { color, backgroundColor } = searchingDaysBgColor;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            Background
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Student subcategory">
          <SelectField
            record={student}
            source="career_services.status"
            choices={careerServiceStatuses}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Opted In">
          <BooleanField
            label="Opted In"
            source="career_services.opted_in"
            record={student}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Eligible To Work In Country">
          <BooleanField record={student} source="career_services.eligible" />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Job search starts">
          <Typography variant="body2">
            {jobSearchStartDate.format('DD MMM YYYY')}
          </Typography>
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Searching days">
          {hasStartedSearching && (
            <Chip
              style={{
                color,
                fontWeight: '700',
                backgroundColor,
              }}
              label={searchingDays + ' days'}
            />
          )}
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Inactive">
          <BooleanField
            label="Inactive"
            source="career_services.inactive"
            record={student}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Labeled label="Languages">
          {!R.pathSatisfies(R.isNil, ['languages'], student) ? (
            <Box display="flex" flexDirection="row">
              {R.pipe(
                R.pathOr([], ['languages']),
                R.map((language) => (
                  <Typography
                    style={{ marginRight: '32px' }}
                    variant="body2"
                    key={language.language_code}
                  >
                    {language.language_code === 'pt-br'
                      ? 'Portuguese (Brazil) '
                      : i18nIsoCountries.getName(
                          `${language.language_code}`,
                          'en'
                        )}{' '}
                    - {levelTextKey[language.language_level]}
                  </Typography>
                ))
              )(student)}
            </Box>
          ) : (
            <Typography>--</Typography>
          )}
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Labeled label="Education">
          <Typography variant="body2">
            {R.pathOr(
              '--',
              ['professional_profile', 'education', 'title'],
              student
            )}
          </Typography>
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Labeled label="Additional technologies">
          <Typography variant="body2">
            {R.pipe(
              R.pathOr([], ['professional_profile', 'technologies']),
              R.join(', ')
            )(student)}
          </Typography>
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Labeled label="Background industry">
          <Typography variant="body2">
            {R.pipe(
              R.pathOr([], ['professional_profile', 'expertise_areas']),
              R.join(', ')
            )(student)}
          </Typography>
        </Labeled>
      </Grid>
      {!R.pathEq(['career_services', 'category'], 'excluded', student) && (
        <>
          <Grid item xs={12} lg={2}>
            <LocationJobHuntField
              locations={R.pathOr(
                undefined,
                ['career_services', 'job_search_location', 'locations'],
                student
              )}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Labeled label="Open to remote">
              {R.hasPath(
                ['career_services', 'job_search_location', 'remote_open'],
                student
              ) ? (
                <Typography variant="body2">
                  {R.pathEq(
                    ['career_services', 'job_search_location', 'remote_open'],
                    true,
                    student
                  )
                    ? 'Yes'
                    : 'No'}
                </Typography>
              ) : (
                <Typography>--</Typography>
              )}
            </Labeled>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default BackgroundInfo;

import React from 'react';
import * as R from 'ramda';
import { BooleanField } from 'react-admin';

const HasCommentField = ({ record, surveyNumber }) => {
  const survey = R.pipe(
    R.propOr([], ['weekly_surveys']),
    R.find(({ survey }) => survey.toString() === surveyNumber)
  )(record);
  const hasComment = R.pipe(
    R.defaultTo({}),
    R.propOr([], 'answers'),
    R.find(answer => 
      R.includes(answer.question, ['comments', 'v2_low_experience_comment']) && 
      !R.isNil(answer.answer)
    ),
    R.isNil,
    R.not
  )(survey);
  return survey ? (
    <BooleanField record={{ hasComment }} source="hasComment" />
  ) : null;
};

export default HasCommentField;

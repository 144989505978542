import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const labsProvider = {
  getManyReference: async (params) => {
    const search = [{ $match: { cohort: params.id } }];
    const limit = params.pagination.perPage;
    const page = params.pagination.page;
    const options = {
      method: 'POST',
      body: JSON.stringify(search),
      headers: { 'Content-Type': 'application/json' },
    };
    const query = {
      limit,
      page,
      ...params.filter,
    };
    const url = `${apiUrl}/students/search/labs?${stringify(query)}`;
    return { url, options };
  },
};

export default labsProvider;

import React from 'react';
import {
  Edit,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  Toolbar
} from 'react-admin';

const UserTitle = ({ record }) =>
  record ? `${record.first_name} ${record.last_name}` : null;

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserEdit = props => (
  <Edit title={<UserTitle />} actions={null} undoable={false} {...props}>
    <SimpleForm redirect="list" variant="standard" toolbar={<EditToolbar />}>
      <ReferenceArrayInput
        label="Groups"
        source="groups"
        reference="groups"
        allowEmpty
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default UserEdit;

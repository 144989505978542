import React from 'react';
import * as R from 'ramda';
import {
  Datagrid,
  Error,
  Loading,
  SelectField,
  TextField,
  useQuery,
} from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import {
  AssessmentScoreChip,
  DateField,
  DeliveryTitle,
  NewProgressField,
  OfflineList,
} from 'resources/common';
import { FullNameField } from 'resources/students/fields';
import { assignmentStatus } from 'data';

const ReviewInQualifiedButton = ({ record: student }) => {
  const canReview = R.allPass([
    R.hasPath(['external_ids', 'qualified']),
    R.hasPath(['challenge', 'assessment_result_id']),
    R.hasPath(['challenge', 'solution_id']),
    R.complement(R.pathEq(['challenge', 'status'], 'pending')),
  ])(student);
  if (canReview) {
    const [candidateId, resultId, solutionId] = R.paths([
      ['external_ids', 'qualified'],
      ['challenge', 'assessment_result_id'],
      ['challenge', 'solution_id'],
    ])(student);
    const url = `https://www.qualified.io/hire/candidates/all/${candidateId}/${resultId}/review/${solutionId}`;
    return (
      <Button
        size="small"
        color="primary"
        target="_blank"
        href={url}
        label="Review"
      >
        <OpenInNew style={{ paddingRight: '0.2em' }} />
        Review
      </Button>
    );
  } else {
    return null;
  }
};

const ProgressField = ({ record }) => {
  const { completion } = record;
  return <NewProgressField value={completion} text={`${completion}%`} />;
};

const StudentScoreField = ({ record: student }) => {
  const isPending = R.pathEq(['challenge', 'status'], 'pending', student);
  if (isPending) return null;
  return (
    <AssessmentScoreChip
      record={student}
      scoreSource="challenge.score"
      cutScoreSource="challenge.assessment_cut_score"
    />
  );
};

const StudentList = ({ record: challenge, course, students }) => {
  const tableData = R.pipe(
    R.map((student) => {
      const studentChallenge = R.pipe(
        R.path([course, 'challenges']),
        R.find(R.propEq('challenge_id', challenge.challenge_id))
      )(student);
      return { ...student, challenge: studentChallenge };
    }),
    R.sortBy(R.prop('status'))
  )(students);

  return (
    <OfflineList list={tableData} uniqueKey="id">
      <Datagrid>
        <FullNameField />
        <SelectField
          label="Status"
          choices={assignmentStatus}
          source="challenge.status"
        />
        <DateField label="Submit Date" source="challenge.date" />
        <StudentScoreField label="Grade" textAlign="center" />
        <ReviewInQualifiedButton label="Review In Qualified" sortable={false} />
      </Datagrid>
    </OfflineList>
  );
};

const ChallengeList = ({ record: assessment, cohort, course, students }) => {
  const challengesMap = R.pipe(
    R.pathOr([], [course, 'challenges']),
    R.filter(R.propEq('assessment_id', assessment.assessment_id)),
    R.groupBy(R.prop('challenge_id')),
    R.map(R.path(['0'])),
    R.map(
      R.applySpec({
        title: R.prop('title'),
        challenge_id: R.prop('challenge_id'),
      })
    )
  )(cohort);

  const tableData = R.pipe(
    R.chain(R.pathOr([], [course, 'challenges'])),
    R.filter(R.propEq('assessment_id', assessment.assessment_id)),
    R.groupBy(R.prop('challenge_id')),
    R.map(
      R.pipe(
        R.reject(R.propEq('status', 'pending')),
        R.pluck('score'),
        (scores) => ({
          completion: Math.round((100 * scores.length) / students.length),
          average_score: R.defaultTo('noAverage', Math.round(R.mean(scores))),
        })
      )
    ),
    R.mergeDeepLeft(challengesMap),
    R.values
  )(students);

  return (
    <OfflineList list={tableData} uniqueKey="challenge_id">
      <Datagrid
        rowClick="expand"
        expand={<StudentList course={course} students={students} />}
      >
        <TextField label="Challenge Name" source="title" />
        <ProgressField label="% Completion" source="completion" />;
        <AssessmentScoreChip
          label="Cohort Average"
          textAlign="center"
          scoreSource="average_score"
          cutScoreSource="assessment_cut_score"
        />
      </Datagrid>
    </OfflineList>
  );
};

const AssessmentList = ({ cohort, course, students }) => {
  const assessmentsMap = R.pipe(
    R.pathOr([], [course, 'challenges']),
    R.groupBy(R.prop('assessment_id')),
    R.map(
      R.applySpec({
        challenge_count: R.length,
        title: R.path(['0', 'assessment_title']),
        assessment_id: R.path(['0', 'assessment_id']),
        assessment_cut_score: R.path(['0', 'assessment_cut_score']),
        required: R.path(['0', 'required']),
      })
    )
  )(cohort);

  const tableData = R.pipe(
    R.chain(R.pathOr([], [course, 'challenges'])),
    R.groupBy(R.prop('assessment_id')),
    R.map(R.pipe(R.reject(R.propEq('status', 'pending')), R.pluck('score'))),
    R.mapObjIndexed((scores, assessment_id) => ({
      average_score: R.defaultTo(undefined, Math.round(R.mean(scores))),
      completion: R.defaultTo(
        0,
        Math.round(
          (100 * scores.length) /
            (assessmentsMap[assessment_id].challenge_count * students.length)
        )
      ),
    })),
    R.mergeDeepLeft(assessmentsMap),
    R.values
  )(students);

  return (
    <OfflineList list={tableData} uniqueKey="assessment_id">
      <Datagrid
        rowClick="expand"
        expand={
          <ChallengeList cohort={cohort} course={course} students={students} />
        }
      >
        <DeliveryTitle />
        <TextField label="# of Challenges" source="challenge_count" />
        <ProgressField label="% Completion" source="completion" />;
        <AssessmentScoreChip
          label="Cohort Average"
          textAlign="center"
          scoreSource="average_score"
          cutScoreSource="assessment_cut_score"
        />
      </Datagrid>
    </OfflineList>
  );
};

function ByAssessmentsSubtab(props) {
  const { record: cohort, course } = props;

  const {
    data: students,
    loading: studentsLoading,
    error: studentsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: cohort.id,
      pagination: {
        perPage: 100,
      },
      filter: { course: true },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  if (studentsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (studentsError)
    return (
      <Box p="16px">
        <Error error={studentsError} errorInfo={studentsError} />
      </Box>
    );
  if (!students) return null;

  return <AssessmentList cohort={cohort} course={course} students={students} />;
}

export default ByAssessmentsSubtab;

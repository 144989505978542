import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { linkToRecord } from 'ra-core';

const OpenInNewIconButton = (props) => {
  const { basePath, record } = props;
  const recordPath = linkToRecord(basePath, record?.id, 'show');
  const stopPropagation = (event) => event.stopPropagation();
  return (
    <Tooltip title="Open In New Tab" arrow>
      <IconButton
        size="small"
        color="primary"
        target="_blank"
        variant="outlined"
        href={`#${recordPath}`}
        onClick={stopPropagation}
      >
        <OpenInNew style={{ paddingRight: '0.2em' }} />
      </IconButton>
    </Tooltip>
  );
};

export default OpenInNewIconButton;

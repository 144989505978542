import React from 'react';
import * as R from 'ramda';

const QualifiedField = ({ record = {} }) => {
  const qualifiedId = R.path(['external_ids', 'qualified'], record);
  return (
    <a
      href={`https://www.qualified.io/hire/candidates/all/${qualifiedId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {qualifiedId}
    </a>
  );
};

QualifiedField.defaultProps = {
  addLabel: true,
};

export default QualifiedField;

import React from 'react';
import * as R from 'ramda';
import { Box, Typography } from '@material-ui/core';
import { ArrayField, Datagrid, Error, Loading, useQuery } from 'react-admin';

import { OutcomesSatisfactionField } from './fields';
import { FullNameField } from 'resources/students/fields';
import { getGradingColors } from 'lib/gradingColors';

const SatisfactionMetrics = ({ students }) => {
  const metrics = R.pipe(
    R.pluck('career_services'),
    R.reject(R.isNil),
    R.pluck('surveys'),
    R.reject(R.isNil),
    R.flatten,
    R.map(
      R.converge(R.pair, [
        R.prop('stage'),
        R.pipe(R.pick(['content', 'coach']), R.reject(R.isNil)),
      ])
    ),
    R.groupBy(R.head),
    R.map(R.map(R.nth(-1))),
    R.map(
      R.pipe(
        R.applySpec({
          completion: (answers) => (answers.length / students.length) * 100,
          content: R.pipe(R.pluck('content'), R.mean),
          coach: R.pipe(R.pluck('coach'), R.mean),
        }),
        R.reject(R.equals(NaN))
      )
    ),
    R.map(R.map((value) => Math.round((value + Number.EPSILON) * 100) / 100))
  )(students);

  return !R.isEmpty(metrics) ? (
    <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
      <Box display="flex" flex={1} flexDirection="column" m="16px">
        <Typography variant="h5" gutterBottom>
          Content Metrics
        </Typography>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {R.pipe(
              R.mapObjIndexed((metrics, stage) => {
                const colors = getGradingColors(metrics.content);
                return (
                  <Box
                    display="flex"
                    borderRadius={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={colors.background}
                    m="1em"
                    p="10px"
                    width="120px"
                    height="120px"
                    key={`stage-${stage}`}
                  >
                    <Box color={colors.typography} flex={1}>
                      <Typography variant="subtitle1" align="center">
                        {`Stage ${stage}`}
                      </Typography>
                    </Box>
                    <Box color={colors.typography} flex={1}>
                      <Typography variant="h3">{metrics.content}</Typography>
                    </Box>
                  </Box>
                );
              }),
              R.values()
            )(metrics)}
          </Box>
        </Box>
        <Box mt="2em">
          <Typography variant="h5" gutterBottom>
            Coach Metrics
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box width="170px"></Box>
            {R.pipe(
              R.filter(R.has('coach')),
              R.mapObjIndexed((metrics, stage) => {
                const colors = getGradingColors(metrics.coach);
                return (
                  <Box
                    display="flex"
                    borderRadius={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={colors.background}
                    m="1em"
                    p="10px"
                    width="120px"
                    height="120px"
                    key={`stage-${stage}`}
                  >
                    <Box color={colors.typography} flex={1}>
                      <Typography variant="subtitle1" align="center">
                        {`Stage ${stage}`}
                      </Typography>
                    </Box>
                    <Box color={colors.typography} flex={1}>
                      <Typography variant="h3">{metrics.coach}</Typography>
                    </Box>
                  </Box>
                );
              }),
              R.values()
            )(metrics)}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" flex={1} m="16px">
        <Typography variant="h5" gutterBottom>
          Completion Rates
        </Typography>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {R.pipe(
              R.mapObjIndexed((metrics, stage) => {
                const colors = getGradingColors(metrics.completion / 20);
                return (
                  <Box
                    display="flex"
                    borderRadius={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={colors.background}
                    m="1em"
                    p="10px"
                    width="120px"
                    height="120px"
                    key={`stage-${stage}`}
                  >
                    <Box color={colors.typography} flex={1}>
                      <Typography variant="subtitle1" align="center">
                        {`Stage ${stage}`}
                      </Typography>
                    </Box>
                    <Box color={colors.typography} flex={1}>
                      <Typography variant="h3">{metrics.completion}</Typography>
                    </Box>
                  </Box>
                );
              }),
              R.values()
            )(metrics)}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};

function OutcomesSurveysTab({ record: cohort }) {
  const { data: students, loading, error } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: cohort.id,
      pagination: {
        perPage: 100,
      },
      filter: {
        'career_services.category': { $in: ['searching', 'employed'] },
      },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  if (loading)
    return (
      <Box m="16px">
        <Loading />
      </Box>
    );
  if (error)
    return (
      <Box m="16px">
        <Error error={error} errorInfo={error} />
      </Box>
    );
  if (!students) return null;
  return (
    <Box>
      <SatisfactionMetrics students={students} />
      <Box mt="4em" mx="16px">
        <Typography variant="h5" gutterBottom>
          Searching Student Answers
        </Typography>
      </Box>
      <ArrayField record={{ students }} source="students" basePath="/students">
        <Datagrid>
          <FullNameField sortable={false} link />
          <OutcomesSatisfactionField
            label="Content - Stage 0"
            stage={0}
            area="content"
          />
          <OutcomesSatisfactionField
            label="Content - Stage 1"
            stage={1}
            area="content"
          />
          <OutcomesSatisfactionField
            label="Content - Stage 2.1"
            stage={2.1}
            area="content"
          />
          <OutcomesSatisfactionField
            label="Content - Stage 2.2"
            stage={2.2}
            area="content"
          />
          <OutcomesSatisfactionField
            label="Coach - Stage 1"
            stage={1}
            area="coach"
          />
          <OutcomesSatisfactionField
            label="Coach - Stage 2.1"
            stage={2.1}
            area="coach"
          />
          <OutcomesSatisfactionField
            label="Coach - Stage 2.2"
            stage={2.2}
            area="coach"
          />
        </Datagrid>
      </ArrayField>
    </Box>
  );
}

export default OutcomesSurveysTab;

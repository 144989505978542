import * as R from 'ramda';

const extractChallenges = R.curry((filter, record) =>
  R.pipe(
    R.pathOr([], [filter.path, 'challenges']),
    R.filter(R.propEq('type', R.prop('type', filter)))
  )(record)
);

const getScoreForSkill = R.curry((challenges, skill, subskill) =>
  R.pipe(
    R.filter(
      R.allPass([
        R.propEq('status', 'delivered'),
        R.propSatisfies(R.any(R.propEq('category', skill)), 'categories'),
      ])
    ),
    R.when(
      R.always(subskill),
      R.filter(
        R.pipe(
          R.propOr([], 'categories'),
          R.any(R.propEq('subcategory', subskill))
        )
      )
    ),
    R.pluck('score'),
    R.ifElse(R.isEmpty, R.always(-1), R.pipe(R.mean, Math.round))
  )(challenges)
);

const getSkillsTree = R.pipe(
  R.pluck('categories'),
  R.flatten,
  R.groupBy(R.prop('category')),
  R.map(R.pipe(R.pluck('subcategory'), R.uniq, R.reject(R.isNil)))
);

const getSkillsData = (cohort, students, filter) => {
  const cohortChallenges = extractChallenges(filter, cohort);
  const skillsTree = getSkillsTree(cohortChallenges);
  const skills = R.keys(skillsTree);

  const skilledStudents = R.map((student) => {
    const challenges = extractChallenges(filter, student);
    const skillScores = R.map(getScoreForSkill(challenges, R.__, null), skills);
    return R.assoc('scoreBySkill', R.zipObj(skills, skillScores), student);
  }, students);

  const skillStats = R.mapObjIndexed(
    (subskills, skill) => ({
      score: R.pipe(
        R.map(R.pathOr(-1, ['scoreBySkill', skill])),
        R.reject(R.equals(-1)),
        R.ifElse(R.isEmpty, R.always('-'), R.pipe(R.mean, Math.round))
      )(skilledStudents),
      subskills: R.map(
        R.applySpec({
          subskill: R.identity,
          Score: (subskill) =>
            R.pipe(
              R.map(
                R.pipe(
                  extractChallenges(filter),
                  getScoreForSkill(R.__, skill, subskill)
                )
              ),
              R.reject(R.equals(-1)),
              R.ifElse(
                R.isEmpty,
                R.always('Not Assessed'),
                R.pipe(R.mean, Math.round)
              )
            )(students),
        }),
        subskills
      ),
    }),
    skillsTree
  );

  return { skills, skillStats, skilledStudents };
};

export default getSkillsData;

import React, { useEffect, useCallback } from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';

import {
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';

import { authServer } from '../../auth/keycloak';
import { DateInput } from '../common/DatePickers';
import { CohortNameField } from '../students/fields';
import { useBackend } from 'resources/common';
import { useFormState } from 'react-final-form';

const validateRequired = [required()];

const buildFullName = R.pipe(
  R.defaultTo({}),
  R.props(['first_name', 'middle_name', 'last_name']),
  R.reject(R.either(R.isNil, R.isEmpty)),
  R.join(' '),
  R.replace(/(^|[\s-])\S/g, R.toUpper)
);

const validateAttendanceCreation = (values) => {
  const errors = {};

  const findRepeated = (focus, others) =>
    R.pipe(
      (values) => [
        R.propOr([], focus)(values),
        R.pipe(
          R.props(others),
          R.map(R.defaultTo([])),
          R.apply(R.concat)
        )(values),
      ],
      R.apply(R.intersection),
      R.complement(R.isEmpty)
    );

  const repeatedAbsent = findRepeated('absent', ['late', 'excused'])(values);
  if (repeatedAbsent) {
    errors.absent = 'Students cannot be repeated';
  }
  const repeatedLate = findRepeated('late', ['absent', 'excused'])(values);
  if (repeatedLate) {
    errors.late = 'Students cannot be repeated';
  }
  const repeatedExcused = findRepeated('excused', ['late', 'absent'])(values);
  if (repeatedExcused) {
    errors.excused = 'Students cannot be repeated';
  }

  return errors;
};

const AttendanceCreateToolbar = (props) => {
  const { redirect: redirectPath, currentAttendance } = props;

  const [{ data, loading, error }, setCall] = useBackend();
  const notify = useNotify();
  const redirect = useRedirect();
  const formState = useFormState();

  useEffect(() => {
    if (data) {
      notify('Attendance Updated');
      redirect(redirectPath);
    }
  }, [data, notify, redirectPath, redirect]);

  useEffect(() => {
    if (!loading && error) {
      notify('There was an error with the attendance');
      redirect(redirectPath);
    }
  }, [error, loading, notify, redirectPath, redirect]);

  const handleSave = useCallback(() => {
    const deletedStudents = R.converge(
      R.differenceWith((a, b) => a.id === b),
      [
        R.always(currentAttendance),
        R.pipe(
          R.props(['late', 'absent', 'excused']),
          R.flatten,
          R.uniq,
          R.map((id) => ({ id }))
        ),
      ]
    )(formState.values);
    setCall({
      url: `/attendance`,
      method: 'POST',
      body: {
        ...formState.values,
        ...(deletedStudents.length > 0 && {
          deleted_students: deletedStudents,
        }),
      },
    });
  }, [currentAttendance, formState, setCall]);

  return (
    <Toolbar {...props}>
      {authServer.isRoles(['ta', 'pm', 'teacher']) && (
        <SaveButton onSave={handleSave} />
      )}
    </Toolbar>
  );
};

const SortedSelectInput = ({ choices, ...rest }) => {
  const sortedChoices = R.sortBy(R.prop('first_name'), choices);
  return <SelectArrayInput choices={sortedChoices} {...rest} />;
};

const AttendanceCreate = (props) => {
  const cohort = R.pathOr({}, ['location', 'state', 'cohort'], props);
  const cohortId = cohort.id;
  const redirect = cohortId ? `/cohorts/${cohortId}/show/attendance` : 'list';
  const attendance = R.pathOr(
    { id: moment().format('YYYY-MM-DD'), absent: [], late: [], excused: [] },
    ['location', 'state', 'attendance'],
    props
  );
  attendance.date = attendance.id;

  const currentAttendance = R.pipe(
    R.pick(['late', 'absent', 'excused']),
    R.mapObjIndexed((students, attendanceType) =>
      R.map(
        (student) => ({
          id: student,
          attendance_type: attendanceType,
          date: attendance.id,
        }),
        students
      )
    ),
    R.values,
    R.unnest,
    R.reject(R.isEmpty)
  )(attendance);

  return (
    <Create actions={null} undoable={false} {...props}>
      <SimpleForm
        redirect={redirect}
        toolbar={
          <AttendanceCreateToolbar currentAttendance={currentAttendance} />
        }
        validate={validateAttendanceCreation}
        initialValues={{
          ...attendance,
          id: cohortId,
        }}
      >
        <ReferenceInput
          label="Cohort"
          source="id"
          reference="cohorts"
          fullWidth
          variant="standard"
          validate={validateRequired}
        >
          <SelectInput optionText={<CohortNameField />} disabled={true} />
        </ReferenceInput>
        <DateInput
          label="Date"
          source="date"
          options={{
            clearable: true,
            format: 'Do MMMM YYYY',
            minDate: R.propOr('1900-01-01', 'start_date', cohort),
            disableFuture: true,
            shouldDisableDate: (day) =>
              !parseInt(cohort.timetable.days[day.weekday()], 10),
          }}
          fullWidth
          variant="standard"
          validate={validateRequired}
          disabled={true}
        />
        <ReferenceArrayInput
          label="Absent Students"
          source="absent"
          reference="students"
          target="cohort"
          perPage={100}
          fullWidth
          variant="standard"
          filter={{ cohort: cohortId }}
        >
          <SortedSelectInput optionText={buildFullName} />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Late Students"
          source="late"
          reference="students"
          target="cohort"
          perPage={100}
          filter={{ cohort: cohortId }}
          fullWidth
          variant="standard"
        >
          <SortedSelectInput optionText={buildFullName} />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Excused Students"
          source="excused"
          reference="students"
          target="cohort"
          perPage={100}
          filter={{ cohort: cohortId }}
          fullWidth
          variant="standard"
        >
          <SortedSelectInput optionText={buildFullName} />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
export default AttendanceCreate;

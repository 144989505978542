import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import { useNotify, useRedirect } from 'react-admin';

import { BeeEditor, EmailForm } from './components';
import { useBackend } from 'resources/common';

const EmailCreate = () => {
  const [{ data }, setCall] = useBackend();
  const [emailInfo, setEmailInfo] = useState({
    subject: '',
    name: '',
    language: 'en',
    family: '',
  });
  const emailInfoRef = useRef(emailInfo);
  const notify = useNotify();
  const redirect = useRedirect();

  useEffect(() => {
    if (data) {
      redirect(`/emails/${data.result.id}`);
    }
  }, [data, redirect]);

  const onSave = (jsonFile, htmlFile) => {
    setCall({
      url: '/email_templates',
      method: 'POST',
      body: {
        template: jsonFile,
        html: htmlFile,
        roles: ['product'],
        ...emailInfoRef.current,
      },
    });
  };

  const onError = (error) =>
    notify(`Email create error: ${error.message}`, 'warning');

  return (
    <>
      <EmailForm
        emailInfo={emailInfo}
        setEmailInfo={setEmailInfo}
        emailInfoRef={emailInfoRef}
      />
      {/* Header plus two rows of input fields */}
      <Box height="calc(100vh - 80px - 56px - 56px)">
        <BeeEditor onSave={onSave} onError={onError} />
      </Box>
    </>
  );
};

export default EmailCreate;

import React, { useEffect, useCallback, useState } from 'react';
import * as R from 'ramda';
import { useGetOne, useNotify, useRedirect } from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Cancel, CheckCircle, FileCopy } from '@material-ui/icons';
import { useBackend } from 'resources/common';

import { LanguageSelector } from './components';

const EmailDuplicate = ({ record }) => {
  const [isFormDialogOpen, setFormDialogOpen] = useState(false);
  const [{ data, error: errorDuplicating }, setCall] = useBackend();
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    if (data) {
      setFormDialogOpen(false);
      redirect(`/emails/${data.result.id}`);
    }
  }, [data, redirect]);

  useEffect(() => {
    if (errorDuplicating) {
      setFormDialogOpen(false);
      notify(`Could not duplicate email`, 'warning');
    }
  }, [errorDuplicating, notify]);

  const handleClickOpen = useCallback((e) => {
    setFormDialogOpen(true);
    e.stopPropagation();
  }, []);

  const handleClose = useCallback((e) => {
    setFormDialogOpen(false);
    e.stopPropagation();
  }, []);

  const handleSave = useCallback(
    (email) => {
      setCall({
        url: '/email_templates',
        method: 'POST',
        body: {
          ...email,
          name: `Copy of ${record.name}`,
          roles: ['product'],
          template: JSON.stringify(email.template),
        },
      });
    },
    [record.name, setCall]
  );

  return (
    <>
      <Button onClick={handleClickOpen} label="Duplicate" color="primary">
        <FileCopy />
        <Typography
          component="span"
          style={{ paddingLeft: '0.5rem', fontSize: '0.8125rem' }}
        >
          DUPLICATE
        </Typography>
      </Button>
      <DuplicateFormDialog
        emailId={record.id}
        isOpen={isFormDialogOpen}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </>
  );
};

const DuplicateFormDialog = ({ isOpen, emailId, handleClose, handleSave }) => {
  const { data: email, loading: loadingEmail } = useGetOne('emails', emailId);
  const [language, setLanguage] = useState(email.language);

  if (loadingEmail) return null;
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Email name: {email.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to duplicate this email?
        </DialogContentText>
        <LanguageSelector
          value={language}
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Box display="flex" pr="0.5em">
            <Cancel />
          </Box>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave({
              language,
              ...R.pick(
                ['subject', 'template', 'html', 'family', 'variants'],
                email
              ),
            });
          }}
        >
          <Box display="flex" pr="0.5em">
            <CheckCircle />
          </Box>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDuplicate;

import React from 'react';
import * as R from 'ramda';

import { NewProgressField } from 'resources/common';

const AssignmentsProgressField = ({ record: student, course }) => {
  const assignments = R.pathOr([], [course, 'deliveries'], student);
  const isRequired = R.propEq('required', true);
  const requiredAssignments = R.when(
    R.any(isRequired),
    R.filter(isRequired)
  )(assignments);
  const total = R.length(requiredAssignments);
  const completed = R.pipe(
    R.filter(R.pipe(R.propOr('', 'status'), R.startsWith('delivered'))),
    R.defaultTo([]),
    R.length
  )(requiredAssignments);
  const text = `${completed}/${total}`;
  const progress = R.defaultTo(0, Math.round((100 * completed) / total));
  return <NewProgressField value={progress} text={text} />;
};

AssignmentsProgressField.defaultProps = {
  source: 'progress',
  label: 'Assignments Progress',
};

export default AssignmentsProgressField;

import * as R from 'ramda';
import { red, green, grey } from '@material-ui/core/colors';

/**
 * Returns the color schema for a given grade.
 * Unifies grading color criteria.
 *
 * @param {number} grade Grade to color. 0 - 5 format.
 *
 * @return {colors.background} Color for background
 * @return {colors.typography} Color for typography
 *
 */
const getGradingColors = (grade) => {
  return R.cond([
    [R.lt(5), R.always({ background: '#b7bcdf', typography: 'black' })],
    [R.lt(4), R.always({ background: green[900], typography: 'white' })],
    [R.lt(3), R.always({ background: green[500], typography: 'black' })],
    [R.lt(2), R.always({ background: grey[500], typography: 'black' })],
    [R.lt(1), R.always({ background: red[500], typography: 'black' })],
    [R.lte(0), R.always({ background: red[900], typography: 'white' })],
    [R.T, R.always({ background: '#b7bcdf', typography: 'black' })],
  ])(grade);
};

export { getGradingColors };

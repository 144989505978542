import React, { useState, useCallback } from 'react';
import * as R from 'ramda';
import { useNotify, fetchStart, fetchEnd } from 'react-admin';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { CheckCircle, ErrorOutline } from '@material-ui/icons';
import { authServer } from 'auth/keycloak';

const RetakeAssessmentControl = ({
  record: assessment = {},
  student,
  course,
  updateStudent,
}) => {
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();

  const challenges = R.propOr([], 'challenges', assessment);

  const handleClick = useCallback((e) => {
    setConfirmDialogOpen(true);
    e.stopPropagation();
  }, []);

  const handleClose = useCallback((e) => {
    setConfirmDialogOpen(false);
    e.stopPropagation();
  }, []);

  const retakeAssessment = useCallback(() => {
    dispatch(fetchStart());
    fetch(
      `${process.env.REACT_APP_BACKEND_HOST}/students/${student.id}/course/${course}/assessments/${assessment.assessment_id}/retake`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authServer.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        updateStudent(data.result);
        notify('Assessment enabled for retaking');
      })
      .catch((error) => {
        notify(`Retake assessment error: ${error.message}`, 'warning');
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  }, [
    assessment.assessment_id,
    course,
    dispatch,
    notify,
    student.id,
    updateStudent,
  ]);

  const handleConfirm = useCallback(() => {
    retakeAssessment();
    setConfirmDialogOpen(false);
  }, [retakeAssessment]);

  if (R.any(R.propEq('status', 'pending'), challenges)) return null;

  return (
    <>
      <Button
        size="small"
        color="primary"
        onClick={handleClick}
        disabled={R.any(R.propEq('retake', true))(challenges)}
      >
        Retake Assessment
      </Button>
      <ConfirmDialog
        assessmentTitle={assessment.title}
        isOpen={isConfirmDialogOpen}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  );
};

const ConfirmDialog = (props) => {
  const { assessmentTitle, isOpen, onClose, onConfirm } = props;

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(e);
    },
    [onConfirm]
  );

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        Retake: {assessmentTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will completely erase the student's previous score. Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Box display="flex" pr="0.5em">
            <ErrorOutline />
          </Box>
          Cancel
        </Button>
        <Button onClick={handleConfirm} autoFocus>
          <Box display="flex" pr="0.5em">
            <CheckCircle />
          </Box>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RetakeAssessmentControl;

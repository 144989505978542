import React from 'react';
import * as R from 'ramda';

import {
  lightGreen,
  red,
  brown,
  orange,
  yellow,
  grey,
} from '@material-ui/core/colors';
import { Warning } from '@material-ui/icons';
import { Box, Chip } from '@material-ui/core';

import { riskLevels } from '../../../../../../../../data';

const riskLevelChipStyles = {
  good: {
    backgroundColor: lightGreen[200],
    color: lightGreen[900],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  potentially_at_risk: {
    backgroundColor: grey[100],
    color: orange[900],
    fontWeight: 500,
  },
  level_1: {
    backgroundColor: yellow[100],
    color: brown[500],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  level_2: {
    backgroundColor: orange[200],
    color: brown[500],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  level_3: {
    backgroundColor: red[100],
    color: red[900],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
};

const RiskLevel = (props) => {
  const { level, ...rest } = props;

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Chip
        style={{
          ...riskLevelChipStyles[level],
          fontSize: '12px',
          padding: R.equals(level, 'good') ? '0 6px' : '6px',
        }}
        label={
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {R.equals(level, 'potentially_at_risk') && (
              <Warning style={{ fontSize: '12px', marginRight: '2px' }} />
            )}
            {R.pipe(
              R.find(R.propEq('id', level)),
              R.prop('short_name')
            )(riskLevels)}
          </Box>
        }
      />
    </Box>
  );
};

export default RiskLevel;

import React from 'react';
import * as R from 'ramda';
import { Box, Typography as MuiTypography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Typography = withStyles({
  body1: { fontSize: '14px' },
})(MuiTypography);

const CohortNameField = ({ data, isBreak, record = {}, ...rest }) => {
  const cohort = R.isEmpty(record) ? data : record;
  let cohortName = `${cohort.campus.toUpperCase()} ${cohort.track.toUpperCase()} ${cohort.format.toUpperCase()} ${
    cohort.start_date.split('T')[0]
  } ${cohort.language.toUpperCase()}`;
  if (cohort.campus === 'rmt') {
    cohortName += ` ${cohort.timezone} ${cohort.language.toUpperCase()}`;
  }
  return (
    <>
      {isBreak ? (
        <Box display="flex" flexDirection="column">
          <Typography component="span" variant="body1">
            {cohort.campus.toUpperCase()} {cohort.track.toUpperCase()}{' '}
            {cohort.format.toUpperCase()}
          </Typography>
          <Typography component="span" variant="body1">
            {cohort.start_date.split('T')[0]} {cohort.language.toUpperCase()}
          </Typography>
        </Box>
      ) : (
        <Typography component="span" variant="body2">
          {cohortName}
        </Typography>
      )}
    </>
  );
};

export default CohortNameField;

import React from 'react';
import moment from 'moment-timezone';
import * as R from 'ramda';
import {
  Box,
  Chip,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  careerServiceStatuses,
  careerServiceStatusColors,
  changeOfMindReasonsFromOM,
  changeOfMindReasonsFromStudent,
} from 'data';

const StatusChip = (props) => {
  const { status } = props;
  const { name } = careerServiceStatuses.find((s) => s.id === status);
  return (
    <Box zIndex={4}>
      <Chip
        style={{
          color: 'white',
          backgroundColor: careerServiceStatusColors[status],
        }}
        label={name}
      />
    </Box>
  );
};

const getReasonCopy = (origin, reasonId, fullReport) => {
  const copies = R.equals('campus_tools', origin)
    ? changeOfMindReasonsFromOM
    : changeOfMindReasonsFromStudent;
  return R.pipe(
    R.find(R.propEq('id', R.path(['career_services', reasonId], fullReport))),
    R.propOr('', 'name')
  )(copies);
};

const Connector = withStyles({
  line: { borderWidth: 4 },
})(StepConnector);

const StatusTimeline = (props) => {
  const { record: student } = props;
  const steps = R.pathOr([], ['career_services', 'status_history'], student);
  const isLgOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <Box overflow="auto">
      <Stepper
        activeStep={steps.length}
        alternativeLabel={isLgOrMore}
        connector={<Connector />}
        orientation={isLgOrMore ? 'horizontal' : 'vertical'}
      >
        {steps.map(({ status, date, user, origin, full_report }) => {
          return (
            <Step key={`${status}_${date}`}>
              <StepLabel
                StepIconComponent={StatusChip}
                StepIconProps={{ status }}
              >
                <Typography variant="body3">
                  {date
                    ? moment(date).format('Do MMM YYYY, HH:mm:ss')
                    : 'Date Unknown'}
                </Typography>
                <br />
                <Typography variant="body3">
                  {user ? user : 'User Unknown'}
                </Typography>
                {R.hasPath(
                  ['career_services', 'change_mind_reason'],
                  full_report
                ) && (
                  <>
                    <br />
                    <Typography variant="body3">
                      {getReasonCopy(origin, 'change_mind_reason', full_report)}
                    </Typography>
                  </>
                )}
                {R.hasPath(
                  ['career_services', 'deferred_search_reason'],
                  full_report
                ) && (
                  <>
                    <br />
                    <Typography variant="body3">
                      {getReasonCopy(
                        origin,
                        'deferred_search_reason',
                        full_report
                      )}
                    </Typography>
                  </>
                )}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default StatusTimeline;

import React from 'react';
import { useNotify } from 'react-admin';

import { Box, IconButton } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import copy from 'copy-to-clipboard';

const CopyToClipboard = ({ text, active }) => {
  const notify = useNotify();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copy(text);
    notify('Copied to clipboard');
  };

  return (
    <Box width={30} height={30} ml={1} onClick={handleClick}>
      {active && (
        <IconButton size="small">
          <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default CopyToClipboard;

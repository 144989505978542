import React, { useState } from 'react';
import * as R from 'ramda';
import jsonExport from 'jsonexport/dist';
import {
  ArrayField,
  Datagrid,
  downloadCSV,
  TextField,
  Toolbar,
} from 'react-admin';
import {
  Box,
  Button as MaterialButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Close, GetApp } from '@material-ui/icons';
import {
  Link,
  Redirect,
  Route,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import moment from 'moment-timezone';

import { FullNameField } from 'resources/students/fields';
import { NewProgressField } from 'resources/common';
import { HasCommentField, QuestionField } from '../fields';
import { SurveyMetrics } from '../components';
import { authServer } from 'auth/keycloak';

const COMMENT_LABELS = {
  comments: 'Comment',
  v2_low_experience_comment: 'Low Experience comment',
};

const getStudentComment = (student, surveyNumber) =>
  R.pipe(
    R.propOr([], ['weekly_surveys']),
    R.find(({ survey }) => survey.toString() === surveyNumber),
    R.defaultTo({}),
    R.propOr([], 'answers'),
    R.filter(
      R.propSatisfies(
        R.includes(R.__, ['comments', 'v2_low_experience_comment']),
        'question'
      )
    ),
    R.reduce(
      (comment, { question = '', answer = '' }) =>
        `${answer && `${COMMENT_LABELS[question]}: ${answer}`}${
          comment && `\n\n${comment}`
        }`,
      ''
    )
  )(student);

const CommentText = ({ record, surveyNumber }) => {
  const comment = getStudentComment(record, surveyNumber);
  return <TextField record={{ comment }} source="comment" />;
};

const getSurveyOptions = (cohort) => {
  const now = moment.utc();
  const surveyOptions = R.pipe(
    R.propOr([], 'weekly_surveys'),
    R.addIndex(R.reduce)((acc, s, index) => {
      const fromWeek = acc[index - 1]?.cohort_week || 0;
      const startDay = moment
        .utc(cohort.start_date)
        .add(fromWeek, 'weeks')
        .day(1)
        .format('MMM Do');
      const endDay = moment
        .utc(cohort.start_date)
        .add(s.cohort_week - 1, 'weeks')
        .day(7)
        .format('MMM Do');
      acc.push({
        id: s.survey,
        date: s.date,
        text: `${
          s.survey === '0'
            ? 'Survey 0: Prework experience survey'
            : `Survey ${s.survey}: ${startDay} - ${endDay}`
        }`,
        cohort_week: s.cohort_week,
        is_project: s.is_project,
        is_nps: s.is_nps,
      });
      return acc;
    }, []),
    R.filter((survey) => (survey.id !== '0' ? now.isAfter(survey.date) : []))
  )(cohort);
  return surveyOptions;
};

const calculateCompletionRate = (students, surveyNumber) => {
  return R.pipe(
    R.defaultTo([]),
    R.reject(
      R.pipe(
        R.propOr([], 'weekly_surveys'),
        R.find(({ survey }) => survey.toString() === surveyNumber),
        R.defaultTo({}),
        R.isEmpty
      )
    ),
    (studentsWithSurvey) => {
      return Math.round((100 * studentsWithSurvey.length) / students.length);
    }
  )(students);
};

function NoCommentsAlert({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle style={{ position: 'relative', marginBottom: '1em' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          There are no comments to be exported regarding this survey.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

const ExportStudentsCommentsButton = ({ students, surveyNumber }) => {
  const [open, setOpen] = useState(false);

  const exportStudentsComments = () => {
    const comments = R.pipe(
      R.map((student) => getStudentComment(student, surveyNumber)),
      R.pluck('answer'),
      R.reject(R.isEmpty)
    )(students);

    if (R.isEmpty(comments)) {
      setOpen(true);
    } else {
      jsonExport(comments, { rename: ['comment'] }, (error, csv) => {
        downloadCSV(csv, `students_comments_survey_${surveyNumber}`);
      });
    }
  };

  return (
    <>
      <MaterialButton
        variant="outlined"
        color="primary"
        startIcon={<GetApp />}
        onClick={exportStudentsComments}
      >
        Export Students Comments
      </MaterialButton>
      <NoCommentsAlert
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

function BySurveyTab({ record: cohort, students }) {
  const surveys = getSurveyOptions(cohort);
  const defaultSurvey = R.pipe(R.last(), R.propOr(1, 'id'))(surveys);
  const match = useRouteMatch();
  const location = useLocation();

  const isInstructionalStaff =
    authServer.isRoles(['teacher', 'ta']) && !authServer.isRoles(['admin']);

  if (location.pathname.endsWith('survey')) {
    return <Redirect to={`${match.url}/${defaultSurvey}`} />;
  }

  const hasLegacyWeeklySurveysEnabled = R.pathSatisfies(
    R.equals(true),
    ['feature_flags', 'legacy_weekly_surveys_enabled'],
    cohort
  );

  const shouldShow = (students, surveyNumber, questionId) =>
    R.pipe(
      R.map(
        R.pipe(
          R.propOr([], 'weekly_surveys'),
          R.find(({ survey }) => survey.toString() === surveyNumber),
          R.defaultTo({}),
          R.propOr([], 'answers'),
          R.find(R.propEq('question', questionId)),
          R.defaultTo({ answer: '' }),
          R.prop('answer') 
        )
      ),
      R.any((answer) => Boolean(answer)) 
    )(students);

  const getUnanswerStudents = (survey) =>
    R.reject(
      R.pipe(R.propOr([], 'weekly_surveys'), R.any(R.propEq('survey', survey))),
      students
    );

  return (
    <Route
      path={`${match.url}/:surveyId`}
      render={(routeProps) => {
        const surveyId = routeProps.match.params.surveyId;
        const completionRate = calculateCompletionRate(students, surveyId);
        return (
          <Box>
            <Box m="16px" display="flex">
              <Box width="25%" mr="2em">
                <FormControl fullWidth>
                  <Typography variant="h5" component="h3" gutterBottom>
                    Weekly Survey
                  </Typography>
                  <Select label="Select Survey" value={surveyId}>
                    {R.map(
                      (survey) => (
                        <MenuItem
                          key={survey.id}
                          value={survey.id}
                          component={Link}
                          to={`${match.url}/${survey.id}`}
                        >
                          {survey.text}
                        </MenuItem>
                      ),
                      surveys
                    )}
                  </Select>
                </FormControl>
              </Box>
               
              {authServer.isRoles([
                'teacher',
                'ta',
                'gm',
                'pm',
                'campus_lead',
              ]) && (
                <Box ml="2em" width="25%" justifyContent="center">
                  <Typography variant="h5" component="h3" gutterBottom>
                    Completion Rate
                  </Typography>
                  <NewProgressField
                    value={completionRate}
                    text={`${completionRate}%`}
                  />
                </Box>
              )}
            </Box>
            <Box mx="16px" mt="4em">
              <SurveyMetrics students={students} surveyNumber={surveyId} />
            </Box>
            <Box mx="16px" mt="4em">
              <Typography variant="h5" gutterBottom>
                {isInstructionalStaff
                  ? 'Unresponsive Students'
                  : 'Students Answers'}
              </Typography>
            </Box>
            <ArrayField
              record={{
                students: isInstructionalStaff
                  ? getUnanswerStudents(surveyId)
                  : students,
              }}
              source="students"
              basePath="/students"
              resource="students"
            >
              <Datagrid
                rowClick="expand"
                expand={<CommentText surveyNumber={surveyId} />}
              >
                <FullNameField sortable={false} link />
                {shouldShow(students, surveyId, 'comments') && (
                  <HasCommentField
                    label="Comment"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'feelings') && (
                  <QuestionField
                    hasLegacyWeeklySurveysEnabled={
                      hasLegacyWeeklySurveysEnabled
                    }
                    label="Feeling"
                    questionId="feelings"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'prework') && (
                  <QuestionField
                    label="Prework"
                    questionId="prework"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'challenge') && (
                  <QuestionField
                    label="Difficulty"
                    questionId="challenge"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'instruction') && (
                  <QuestionField
                    label="Instructor Clarity"
                    questionId="instruction"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'material') && (
                  <QuestionField
                    label="Material Clarity"
                    questionId="material"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'lab_preparation') && (
                  <QuestionField
                    label="Instruction/Lab Alignment"
                    questionId="lab_preparation"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'instructional_staff') && (
                  <QuestionField
                    label="Instructor Experience"
                    questionId="instructional_staff"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'ironhack') && (
                  <QuestionField
                    label="IH Experience"
                    questionId="ironhack"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'overall_experience') && (
                  <QuestionField
                    label="Student Experience"
                    questionId="overall_experience"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_learning') && (
                  <QuestionField
                    label="Learning"
                    questionId="v2_learning"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_support') && (
                  <QuestionField
                    label="Support"
                    questionId="v2_support"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_tech_support') && (
                  <QuestionField
                    label="Tech support"
                    questionId="v2_tech_support"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(
                  students,
                  surveyId,
                  'v2_tech_support_satisfaction'
                ) && (
                  <QuestionField
                    label="Tech support satisfaction"
                    questionId="v2_tech_support_satisfaction"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_sp_ai') && (
                  <QuestionField
                    label="AI Tools"
                    questionId="v2_sp_ai"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_campus_experience') && (
                  <QuestionField
                    label="Campus Experience"
                    questionId="v2_campus_experience"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_job_readiness') && (
                  <QuestionField
                    label="Job Readiness"
                    questionId="v2_job_readiness"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_expectations') && (
                  <QuestionField
                    label="Expectations"
                    questionId="v2_expectations"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'nps') && (
                  <QuestionField
                    label="NPS"
                    questionId="nps"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_nps_reason_promoter') && (
                  <QuestionField
                    label="NPS promoter reason"
                    questionId="v2_nps_reason_promoter"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
                {shouldShow(students, surveyId, 'v2_nps_reason_detractor') && (
                  <QuestionField
                    label="NPS detractor reason"
                    questionId="v2_nps_reason_detractor"
                    surveyNumber={surveyId}
                    sortable={false}
                  />
                )}
              </Datagrid>
            </ArrayField>
            {authServer.isRoles(['pm']) && (
              <Toolbar>
                <Box display="flex" width="100%">
                  <ExportStudentsCommentsButton
                    students={students}
                    surveyNumber={surveyId}
                  />
                </Box>
              </Toolbar>
            )}
          </Box>
        );
      }}
    />
  );
}

export default BySurveyTab;

import React from 'react';
import * as R from 'ramda';
import { EditButton } from 'react-admin';
import { Add } from '@material-ui/icons';

const OutcomesNotesButton = ({ record, source }) => {
  const hasNotes = R.pipe(
    R.split('.'),
    R.pathOr('', R.__, record),
    R.isEmpty,
    R.not
  )(source);
  if (hasNotes) {
    return (
      <EditButton
        basePath="/students"
        record={record}
        label="Edit Notes"
        variant="outlined"
      />
    );
  } else {
    return (
      <EditButton
        basePath="/students"
        record={record}
        label="Create Notes"
        variant="outlined"
        icon={<Add />}
      />
    );
  }
};

OutcomesNotesButton.defaultProps = {};

export default OutcomesNotesButton;

import React from 'react';
import * as R from 'ramda';
import { Datagrid, DateField, FunctionField, SelectField } from 'react-admin';
import { Button } from '@material-ui/core';
import { GetApp, OpenInNew } from '@material-ui/icons';
import { green, orange, red, yellow } from '@material-ui/core/colors';

import { FullNameField } from 'resources/students/fields';
import { GradeInput } from 'resources/cohorts/inputs';
import {
  AssignmentDueDateField,
  OfflineList,
  DeliveryTitle,
} from 'resources/common';
import { assignmentStatus } from 'data';

const rowStyle = (record, index, defaultStyle = {}) => {
  if (record.status === 'delivered')
    return {
      ...defaultStyle,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'pending')
    return {
      ...defaultStyle,
      borderLeftColor: yellow[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'delivered_late')
    return {
      ...defaultStyle,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.status === 'not_delivered')
    return {
      ...defaultStyle,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return defaultStyle;
};

const gradeMap = {
  very_good: 5,
  good: 4,
  normal: 3,
  bad: 2,
  very_bad: 1,
};

const MyGradeInput = ({ record, studentId, course, ...rest }) => {
  return R.includes(record.status, ['delivered', 'delivered_late']) ? (
    <GradeInput
      studentId={studentId ? studentId : record.id}
      course={course}
      record={record}
      {...rest}
    />
  ) : null;
};

const LinkToAssignment = ({ record }) => {
  return R.hasPath(['link', 'url'], record) ? (
    <Button
      size="small"
      color="primary"
      target="_blank"
      rel="noreferrer"
      href={record.link.url}
    >
      {record.type === 'file_type' ? (
        <GetApp style={{ paddingRight: '0.2em' }} />
      ) : (
        <OpenInNew style={{ paddingRight: '0.2em' }} />
      )}
      {record.type === 'file_type' ? 'Download Assignment' : 'See Assignment'}
    </Button>
  ) : null;
};

const AssignmentDeliveriesList = ({ record, grade = true, course }) => {
  const deliveries = R.pipe(
    R.props(['deliveries', 'pending']),
    R.unnest
  )(record);
  return (
    <OfflineList
      list={deliveries}
      uniqueKey="id"
      defaultSort={{ field: 'status', order: 'ASC' }}
      sorters={{
        grade: R.pipe(R.propOr('', 'grade'), R.propOr(0, R.__, gradeMap)),
      }}
    >
      <Datagrid rowStyle={rowStyle}>
        <FullNameField />
        <SelectField
          label="Status"
          choices={assignmentStatus}
          source="status"
        />
        <DateField
          label="Submit Date"
          source="submit_date"
          options={{ hour12: true }}
        />
        {grade && <MyGradeInput label="Grade" course={course} source="grade" />}
        <LinkToAssignment />
      </Datagrid>
    </OfflineList>
  );
};

const StudentDeliveriesList = (props) => {
  const { record, source, grade = true, course } = props;
  const deliveries = R.pipe(R.split('.'), R.pathOr([], R.__, record))(source);
  return (
    <OfflineList
      list={deliveries}
      uniqueKey="name"
      sorters={{
        grade: R.pipe(R.propOr('', 'grade'), R.propOr(0, R.__, gradeMap)),
      }}
    >
      <Datagrid rowStyle={rowStyle}>
        <DeliveryTitle />
        <FunctionField
          label="Type"
          render={(record) => {
            return record.type === 'file_type' ? 'File Upload' : 'URL';
          }}
          source="type"
        />
        <SelectField
          label="Status"
          choices={assignmentStatus}
          source="status"
        />
        <AssignmentDueDateField label="Due Date" source="due_date" />
        <DateField label="Submit Date" source="submit_date" />
        {grade && (
          <MyGradeInput
            label="Grade"
            studentId={record.id}
            course={course}
            source="grade"
          />
        )}
        <LinkToAssignment />
      </Datagrid>
    </OfflineList>
  );
};

const DeliveriesList = ({ type, ...rest }) => {
  return type === 'students' ? (
    <StudentDeliveriesList {...rest} />
  ) : (
    <AssignmentDeliveriesList {...rest} />
  );
};

export default DeliveriesList;
import React from 'react';
import * as R from 'ramda';

import { Typography } from '@material-ui/core';

import { salaryType } from '../../../data';

const SalaryField = ({ amount, currency = 'EUR', locale = 'en-US', type }) => {
  const salary = R.cond([
    [R.equals('unpaid'), R.always('Unpaid')],
    [R.equals('not_specified'), R.always('Not Disclosed')],
    [
      R.T,
      () => {
        const formattedAmount = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency
        }).format(amount);
        const formattedType = R.pipe(
          R.find(R.propEq('id', type)),
          R.prop('name')
        )(salaryType);
        return `${formattedAmount} (${formattedType})`;
      }
    ]
  ])(type);
  return (
    <Typography component="span" variant="body2">
      {salary}
    </Typography>
  );
};

export default SalaryField;

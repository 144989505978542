import * as React from 'react';
import { memo } from 'react';
import * as R from 'ramda';
import { Form } from 'react-final-form';
import { useListFilterContext } from 'react-admin';

import { DateInput } from 'resources/common/DatePickers';

const FilterDateInput = (props) => {
  const { source, ...rest } = props;
  const { filterValues, setFilters } = useListFilterContext();

  const onDateChange = (date) => {
    if (date) {
      setFilters({ ...filterValues, [source]: date }, null);
    } else {
      setFilters(R.omit([source], filterValues), null);
    }
  };

  const onSubmit = () => undefined;
  return (
    <Form onSubmit={onSubmit} initialValues={filterValues}>
      {({ handleSubmit }) => (
        <DateInput source={source} onChange={onDateChange} {...rest} />
      )}
    </Form>
  );
};

export default memo(FilterDateInput);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 10,
    borderRadius: 20,
    width: '100%',
    display: 'inline-block',
    'margin-right': '10px',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: 'green',
  },
});

const NewProgressField = ({ value, text }) => {
  const classes = useStyles();
  return (
    <Box display="flex" width="100%" alignItems="center">
      <LinearProgress variant="determinate" value={value} classes={classes} />
      <Typography component="span" variant="body2">
        {text}
      </Typography>
    </Box>
  );
};

export default NewProgressField;

import React from 'react';

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  BackgroundInfo,
  ProfileInfo,
  RemindersInfo,
  SatisfactionInfo,
  StatusInfo,
  OneOnOneInfo,
  OutcomeInfo,
} from './components';

const CareerServicesPanel = ({ record: student }) => {
  return (
    <Card style={{ margin: '15px 0px' }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3" gutterBottom>
              Career Services
            </Typography>
            <Divider variant="fullWidth" />
          </Grid>
          <StatusInfo student={student} />
          <ProfileInfo student={student} />
          <BackgroundInfo student={student} />
          <RemindersInfo student={student} />
          <SatisfactionInfo student={student} />
          <OutcomeInfo student={student} />
          <OneOnOneInfo student={student} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CareerServicesPanel;

import React from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton } from '@material-ui/core';
import { StarBorder, Star } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

const progressSizes = {
  small: '20px',
  medium: '24px',
  large: '35px',
};

const BookmarkButton = ({
  isBookmarked,
  isLoading,
  onClick,
  size = 'medium',
  labeled,
}) => {
  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  const StatusIcon = isLoading ? (
    <CircularProgress size={progressSizes[size] || size} />
  ) : isBookmarked ? (
    <Star color="primary" fontSize={size} />
  ) : (
    <StarBorder color="primary" fontSize={size} />
  );

  if (labeled) {
    return (
      <Button
        onClick={handleClick}
        variant="text"
        color="primary"
        size={size}
        disabled={isLoading}
        startIcon={StatusIcon}
      >
        {isBookmarked ? 'Remove Bookmark' : 'Bookmark'}
      </Button>
    );
  }

  return (
    <IconButton disabled={isLoading} onClick={handleClick} size={size}>
      {StatusIcon}
    </IconButton>
  );
};

BookmarkButton.propTypes = {
  isBookmarked: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labeled: PropTypes.bool,
};

export default BookmarkButton;

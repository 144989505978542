import React from 'react';
import * as R from 'ramda';
import { Chip } from '@material-ui/core';
import { getGradingColors } from 'lib/gradingColors';

const ScoreField = ({ record, question, surveyNumber }) => {
  const score = R.pipe(
    R.propOr([], 'weekly_surveys'),
    R.find(({ survey }) => survey.toString() === surveyNumber),
    R.propOr([], 'answers'),
    R.find(R.propEq('question', question)),
    R.defaultTo({}),
    R.prop('answer')
  )(record);
  const color = getGradingColors(score);
  return score ? (
    <Chip
      style={{ color: color.typography, backgroundColor: color.background }}
      label={score}
    />
  ) : null;
};

export default ScoreField;

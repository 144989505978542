import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { Button, Labeled, Toolbar, useNotify, useUpdate } from 'react-admin';
import {
  Box,
  Button as MuiButton,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import { red, green, blue } from '@material-ui/core/colors';
import { CheckCircle, PieChart } from '@material-ui/icons';
import moment from 'moment-timezone';
import { Form } from 'react-final-form';
import { Switches } from 'mui-rff';
import { Link } from 'react-router-dom';
import { DateField } from 'resources/common';
const npsSurveys = {
  pt: [4, 8, 12],
  ft: [3, 6, 9],
  other: [3, 6, 9],
};

const generateCalendar = (cohort) => {
  const startDate = moment.utc(cohort.start_date);
  const endDate = moment.utc(cohort.end_date);

  const cohortWeeks = endDate.diff(startDate, 'weeks') + 1;

  const today = moment.utc();
  return R.pipe(
    R.times(R.inc),
    R.reduce((acc, cohortWeek) => {
      const survey = R.find(
        R.propEq('cohort_week', cohortWeek),
        cohort.weekly_surveys || []
      );

      const weekStart = moment
        .utc(startDate)
        .add(cohortWeek - 1, 'weeks')
        .day(1);
      const weekEnd = moment
        .utc(startDate)
        .add(cohortWeek - 1, 'weeks')
        .day(7);
      const defaultSubtitle = `Week ${cohortWeek} (${weekStart.format(
        'MMMM Do'
      )} - ${weekEnd.format('MMMM Do')})`;
      const isPast = today.isAfter(weekEnd);
      const isCurrent = today.isBetween(weekStart, weekEnd);
      if (survey) {
        acc.push({
          ...survey,
          subtitle: survey.subtitle
            ? `Week (${survey.subtitle})`
            : defaultSubtitle,
          sent: today.isAfter(survey.date),
          past: isPast,
          current: isCurrent,
        });
      } else {
        let lastDate = R.last(acc)?.date;
        if (!lastDate) {
          lastDate =
            cohort.format === 'ft'
              ? moment.utc(weekStart).day(-2).hour(12).format()
              : moment
                  .utc(weekStart)
                  .hour(12)
                  .tz(cohort.timezone, true)
                  .format();
        }
        const surveyDate = moment.utc(lastDate).add(1, 'weeks').format();
        acc.push({
          cohort_week: cohortWeek,
          skip: true,
          date: surveyDate,
          subtitle: acc.subtitle || defaultSubtitle,
          past: isPast,
          current: isCurrent,
        });
      }
      return acc;
    }, [])
  )(cohortWeeks);
};

function ScheduleTab({ record: cohort }) {
  const notify = useNotify();
  const [updateCohort, { loading, error, data: updatedCohort }] = useUpdate(
    'cohorts_b2b',
    cohort.id
  );

  useEffect(() => {
    if (updatedCohort) {
      notify('Survey schedule succesfully updated');
    } else if (error) {
      notify('Could not update the cohort survey schedule', 'warning');
    }
  }, [error, notify, updatedCohort]);

  const calendar = useMemo(() => generateCalendar(cohort), [cohort]);

  const onSubmit = ({ cohortWeeks }) => {
    let surveyCount = 0;
    const weekly_surveys = R.pipe(
      R.map((cohortWeek) => {
        if (!cohortWeek.skip) {
          surveyCount++;
          return {
            ...R.omit(
              ['subtitle', 'skip', 'current', 'past', 'sent'],
              cohortWeek
            ),
            survey: `${surveyCount}`,
            is_nps: R.includes(surveyCount, npsSurveys[cohort.format]),
          };
        } else {
          return null;
        }
      }),
      R.reject(R.isNil)
    )(cohortWeeks);
    updateCohort({ payload: { data: { weekly_surveys } } });
  };

  if (!calendar) return null;
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ cohortWeeks: calendar }}
      render={({
        handleSubmit,
        submitting,
        values,
        errors,
        hasValidationErrors,
      }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <>
              <Box p="16px">
                <Grid container spacing={4}>
                  {calendar.map((survey, index) => {
                    return (
                      <Grid item xs={12} md={4} key={index}>
                        <Box
                          p="16px"
                          border={
                            survey.current
                              ? `2px solid ${blue[200]}`
                              : '2px solid transparent'
                          }
                          borderRadius="4px"
                        >
                          <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="h6"
                                style={
                                  survey.past
                                    ? { color: 'rgba(0,0,0,0.38)' }
                                    : {}
                                }
                              >
                                {survey.subtitle}
                              </Typography>
                              {survey.past && (
                                <Box ml="16px">
                                  <Chip size="small" label="Past" />
                                </Box>
                              )}
                              {survey.current && (
                                <Box ml="16px">
                                  <Chip
                                    size="small"
                                    label="Current"
                                    style={{ backgroundColor: blue[200] }}
                                  />
                                </Box>
                              )}
                            </Box>
                            {!values.cohortWeeks[index].skip && (
                              <Box display="flex" alignItems="center">
                                <Labeled
                                  label={
                                    survey.past
                                      ? 'Survey Sent Date'
                                      : 'Survey Scheduled Date'
                                  }
                                >
                                  <DateField
                                    source="date"
                                    record={survey}
                                    showTime
                                    format="dddd MMMM Do hh:mm A"
                                    timezone={
                                      cohort.timezone || 'Europe/Madrid'
                                    }
                                    disabled={
                                      values.cohortWeeks[index].skip ||
                                      survey.sent
                                    }
                                  />
                                </Labeled>
                                {survey.sent && (
                                  <>
                                    <Box ml="16px">
                                      <Chip
                                        size="small"
                                        icon={
                                          <CheckCircle
                                            style={{ color: green[500] }}
                                          />
                                        }
                                        label="Sent"
                                        style={{ backgroundColor: green[100] }}
                                      />
                                    </Box>
                                    <Box ml="16px">
                                      <Button
                                        component={Link}
                                        variant="outlined"
                                        to={{
                                          pathname: `/cohorts/${cohort.id}/show/surveys/survey/${survey.survey}`,
                                        }}
                                        label="See Results"
                                      >
                                        <PieChart />
                                      </Button>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            )}
                            {!values.cohortWeeks[index].skip && (
                              <Switches
                                name={`cohortWeeks[${index}].is_project`}
                                disabled={
                                  values.cohortWeeks[index].skip || survey.past
                                }
                                data={{
                                  label: 'Project Survey',
                                  value: survey.is_project,
                                }}
                              />
                            )}

                            <Switches
                              name={`cohortWeeks[${index}].skip`}
                              data={{
                                label: 'Skip Week',
                                value: survey.skip,
                              }}
                              disabled={survey.past}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
                <Box display="flex" justifyContent="flex-end" mt="16px">
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ fontStyle: 'italic' }}
                  >
                    {'All dates are expressed in the campus local timezone'}
                  </Typography>
                </Box>
              </Box>

              <Toolbar>
                <Box display="flex" width="100%" alignItems="center">
                  <MuiButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting || loading || hasValidationErrors}
                  >
                    Update Schedule
                  </MuiButton>
                  {hasValidationErrors && (
                    <Box ml="16px">
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ color: red[500] }}
                      >
                        {errors['cohortWeeks[0].survey_type']}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Toolbar>
            </>
          </form>
        );
      }}
    />
  );
}

export default ScheduleTab;

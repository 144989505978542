import React from 'react';
import * as R from 'ramda';

import { NewProgressField } from 'resources/common';

const AssessmentsProgressField = ({ record: student, course }) => {
  const challenges = R.pathOr([], [course, 'challenges'], student);
  const isRequired = R.propEq('required', true);
  const submittedAssessmentsMap = R.pipe(
    R.when(R.any(isRequired), R.filter(isRequired)),
    R.groupBy(R.prop('assessment_id')),
    R.map(R.any(R.propEq('status', 'delivered')))
  )(challenges);
  const total = R.pipe(R.keys, R.length)(submittedAssessmentsMap);
  const submitted = R.pipe(
    R.filter(R.identity),
    R.keys,
    R.length
  )(submittedAssessmentsMap);
  const text = `${submitted}/${total}`;
  const progress = R.defaultTo(0, Math.round((100 * submitted) / total));
  return <NewProgressField value={progress} text={text} />;
};

export default AssessmentsProgressField;

import { useEffect, useReducer } from 'react';
import * as R from 'ramda';

import { stringify } from 'query-string';

import { useBackend } from 'resources/common';
import { authServer } from 'auth/keycloak';

const clusterReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_STUDENTS':
      const students = action.payload;
      return R.mergeDeepRight(state, { students });
    case 'REMOVE_STUDENTS':
      return {
        ...state,
        students: R.omit(state.selected, state.students),
        selected: [],
      };
    case 'SELECT_STUDENT':
      const studentId = action.payload;
      return R.evolve({
        selected: R.ifElse(
          R.contains(studentId),
          R.without(studentId),
          R.append(studentId)
        ),
      })(state);
    case 'SELECT_ALL':
      return { ...state, selected: R.keys(state.students) };
    case 'SET_NAME':
      return { ...state, name: action.payload };
    default:
      return { ...state };
  }
};

const useCluster = (initialCluster) => {
  const [{ data, loading, error }, setCall] = useBackend();
  const [clusterState, clusterDispatch] = useReducer(clusterReducer, {
    students: {},
    selected: [],
    name: R.propOr('', 'name', initialCluster),
    owner: authServer.tokenParsed.email,
    id: R.propOr(null, 'id', initialCluster),
    loading,
    error,
  });
  const addStudents = (students) =>
    clusterDispatch({ type: 'ADD_STUDENTS', payload: students });
  const removeStudents = () => clusterDispatch({ type: 'REMOVE_STUDENTS' });
  const selectStudent = (studentId) =>
    clusterDispatch({ type: 'SELECT_STUDENT', payload: studentId });
  const selectAllStudents = () => clusterDispatch({ type: 'SELECT_ALL' });
  const setName = (name) =>
    clusterDispatch({ type: 'SET_NAME', payload: name });

  useEffect(() => {
    const shouldLoadStudents = R.pipe(
      R.propOr([], 'students'),
      R.isEmpty,
      R.not
    )(initialCluster);
    if (shouldLoadStudents) {
      setCall({
        url: `/students/search?${stringify({
          limit: initialCluster.students.length,
        })}`,
        method: 'POST',
        body: { _id: { $in: initialCluster.students } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCluster]);

  useEffect(() => {
    if (data) {
      addStudents(
        R.pipe(
          R.propOr([], 'result'),
          R.groupBy(R.prop('id')),
          R.map(R.head)
        )(data)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return [
    clusterState,
    {
      addStudents,
      removeStudents,
      selectStudent,
      selectAllStudents,
      setName,
    },
  ];
};

export default useCluster;

import React from 'react';
import { TextField } from 'react-admin';
import { Badge, makeStyles } from '@material-ui/core';

const DeliveryTitle = ({ record }) => {
  const useStyles = makeStyles({
    badge: {
      right: 6,
      top: 0,
      height: 'auto',
      padding: '2px 4px',
      fontSize: '0.6rem',
    },
  });
  const classes = useStyles();
  const Title = () => (
    <TextField label="Title" source="title" record={record} />
  );
  return record.required ? (
    <Badge
      classes={{ badge: classes.badge }}
      color="primary"
      badgeContent="Required"
    >
      <Title />
    </Badge>
  ) : (
    <Title />
  );
};

export default DeliveryTitle;

import React from 'react';
import { SelectField } from 'react-admin';
import { Chip } from '@material-ui/core';
import { red, green, indigo, grey } from '@material-ui/core/colors';

import { careerServiceCategories } from '../../../data';

const categoryStyles = {
  employed: green[500],
  excluded: red[900],
  searching: indigo[500],
  pending: grey[500],
};

const CategoryChip = ({ record }) => {
  return (
    <Chip
      style={{
        color: 'white',
        backgroundColor: categoryStyles[record.id],
      }}
      label={record.name}
    />
  );
};

const OutcomesCategoryField = ({ record, source }) => {
  return (
    <SelectField
      record={record}
      source={source}
      choices={careerServiceCategories}
      optionText={<CategoryChip />}
    />
  );
};

OutcomesCategoryField.defaultProps = {
  source: 'career_services.category',
  label: 'Outcomes Category',
};

export default OutcomesCategoryField;

import React from 'react';
import { Typography } from '@material-ui/core';
import * as R from 'ramda';

const JobSearchLocationField = ({ record }) => {
  const locations = R.pipe(
    R.pathOr([], ['career_services', 'job_search_location', 'locations']),
    R.pluck(['city']),
    R.join(', ')
  )(record);

  return (
    <Typography component="span" variant="body2">
      {locations}
    </Typography>
  );
};

JobSearchLocationField.defaultProps = {
  source: 'career_services.job_search_location.locations',
  label: 'Job search locations',
};

export default JobSearchLocationField;

import React, { useState } from 'react';
import moment from 'moment-timezone';
import * as R from 'ramda';

import { Labeled } from 'react-admin';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { RiskLevel, RiskLevelDetails } from './';

import { riskLevelCheckpoints } from '../../../../../../../../data';

const Connector = withStyles({
  line: { borderWidth: 4 },
})(StepConnector);

const ViewDetailsButton = withStyles({
  root: {
    fontWeight: 500,
    textDecoration: 'underline',
    fontSize: '13px',
    color: '#0000EE',
    cursor: 'pointer',
  },
})(Box);

const RiskLevelTimeline = ({ record: student }) => {
  const historyItems = R.pathOr(
    [],
    ['performance', 'risk', 'history'],
    student
  );
  const isLgOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [showDetails, setShowDetails] = useState(false);
  const [currentHistoryItem, setCurrentHistoryItem] = useState(false);

  const onClickViewDetails = (index) => {
    setShowDetails(index === currentHistoryItem ? !showDetails : true);
    setCurrentHistoryItem(index);
  };

  return (
    <Labeled label="History">
      <Box overflow="auto" paddingLeft="0" position="relative" left={-10}>
        <Stepper
          activeStep={historyItems.length}
          alternativeLabel={isLgOrMore}
          connector={<Connector />}
          orientation={isLgOrMore ? 'horizontal' : 'vertical'}
          style={{ paddingLeft: 0 }}
        >
          {historyItems.map((historyItem, index) => {
            const { level, date, user, origin, report } = historyItem;
            const isCheckpointType = R.equals('action_service', origin);
            const isNotLastItem = index !== historyItems.length - 1;
            const showHistoryItemDetails =
              showDetails && currentHistoryItem === index;

            return (
              <Step key={`${level}-${date}`} style={{ padding: 0 }}>
                <StepLabel
                  StepIconComponent={RiskLevel}
                  StepIconProps={{ level, zIndex: 4 }}
                >
                  <Typography variant="body3">
                    {date
                      ? moment(date).format('Do MMM YYYY, HH:mm:ss')
                      : 'Date Unknown'}
                  </Typography>
                  <br />
                  <Typography variant="body3">
                    {isCheckpointType
                      ? `Via ${
                          R.pipe(
                            R.find(R.propEq('id', report.type)),
                            R.prop('name'),
                            R.includes('M1')
                          )(riskLevelCheckpoints)
                            ? 'M0 + M1'
                            : 'M0'
                        } assessment check`
                      : `${user ? user : 'User Unknown'}`}
                  </Typography>
                  <br />
                  {isNotLastItem && (
                    <ViewDetailsButton
                      onClick={() => onClickViewDetails(index)}
                    >
                      {showHistoryItemDetails ? 'Hide details' : 'View details'}
                    </ViewDetailsButton>
                  )}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {showDetails && (
          <Box width="max-content">
            <RiskLevelDetails
              historyItem={R.nth(currentHistoryItem, historyItems)}
              isTimelineItem={true}
            />
          </Box>
        )}
      </Box>
    </Labeled>
  );
};

export default RiskLevelTimeline;

import React from 'react';
import { Typography } from '@material-ui/core';
import * as R from 'ramda';

const OptedInCareerHackField = ({ record = {}, data, ...rest }) => {
  const optedIn = R.pathEq(['career_services', 'opted_in'], true, record);
  return (
    <Typography component="span" variant="body2">
      {optedIn ? 'Yes' : 'No'}
    </Typography>
  );
};

OptedInCareerHackField.defaultProps = {
  source: 'career_services.opten_in',
  label: 'Opt into Careerhack',
};
export default OptedInCareerHackField;

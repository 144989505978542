import React from 'react';
import copy from 'copy-to-clipboard';
import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayField,
  TextField,
  TopToolbar,
  useNotify,
} from 'react-admin';
import { Button } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import { authServer } from 'auth/keycloak';
import {
  FullNameField,
  OutcomesCategoryField,
  OutcomesStatusField,
} from 'resources/students/fields';
import { DateField } from 'resources/common';

const ClusterStudents = ({ basePath, record }) => {
  return (
    <ReferenceArrayField
      label="Students"
      reference="students"
      source="students"
      record={record}
      basePath={basePath}
    >
      <Datagrid>
        <FullNameField sortable={false} />
        <OutcomesCategoryField
          label="Category"
          source="career_services.category"
          sortable={false}
        />
        <OutcomesStatusField
          label="Status"
          source="career_services.status"
          sortable={false}
        />
      </Datagrid>
    </ReferenceArrayField>
  );
};

const StudentClusterListPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const StudentClusterListToolbar = ({ basePath }) => {
  return (
    <TopToolbar>
      {authServer.isRoles(['outcomes']) && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

const CopyClusterUrlButton = ({ record }) => {
  const notify = useNotify();
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<FileCopy />}
      onClick={(event) => {
        copy(`${process.env.REACT_APP_PROFILE_BASE_URL}/clusters/${record.id}`);
        notify('Cluster URL Copied To Clipboard!');
      }}
    >
      Copy Cluster URL
    </Button>
  );
};

export default ({ ...props }) => {
  return (
    <List
      toolbar={<StudentClusterListToolbar />}
      exporter={false}
      bulkActionButtons={false}
      pagination={<StudentClusterListPagination />}
      sort={{ field: 'owner', order: 'ASC' }}
      {...props}
    >
      <Datagrid expand={<ClusterStudents />}>
        <TextField label="Name" source="name" sortable={false} />
        <TextField label="Owner" source="owner" sortable={false} />
        <FunctionField
          label="Students In Cluster"
          render={(record) => `${record.students.length}`}
          sortable={false}
        />
        <DateField
          label="Creation Date"
          format="Do MMMM YYYY"
          source="meta.created_at"
          sortable={false}
        />
        <DateField
          label="Last Update"
          format="Do MMMM YYYY"
          source="meta.updated_at"
          sortable={false}
        />
        <EditButton variant="outlined" />
        <CopyClusterUrlButton />
      </Datagrid>
    </List>
  );
};

import React from 'react';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';

const HasCertificateField = ({ record }) => {
  const hasCertificate = R.hasPath(
    ['external_ids', 'accredible', 'uuid'],
    record
  );

  return (
    <Typography component="span" variant="body2">
      {hasCertificate ? 'Yes' : 'No'}
    </Typography>
  );
};

HasCertificateField.defaultProps = {
  label: 'Certificate',
  source: 'external_ids.accredible.uuid',
};

export default HasCertificateField;

import React from 'react';

import { Labeled, SelectField, TextField } from 'react-admin';
import { Grid } from '@material-ui/core';

import { DateField, NiceTextField } from 'resources/common';
import { outcomeJobSource } from 'data';

const JobFields = ({ student, basePath }) => {
  return (
    <Grid container>
      <Grid item xs={12} lg={2}>
        <Labeled label="Offer Date">
          <DateField
            record={student}
            source="career_services.outcome.offer_date"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Company">
          <TextField
            record={student}
            source="career_services.outcome.company"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Job Title">
          <NiceTextField
            record={student}
            source="career_services.outcome.job_title"
          />
        </Labeled>
      </Grid>

      <Grid item xs={12} lg={2}>
        <Labeled label="Job Source">
          <SelectField
            record={student}
            source="career_services.outcome.job_source"
            choices={outcomeJobSource}
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default JobFields;

import React, { useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router';
import * as R from 'ramda';
import CohortIcon from '@material-ui/icons/AccountBalance';
import StudentIcon from '@material-ui/icons/School';
import UserIcon from '@material-ui/icons/SupervisorAccount';
import StudentClusterIcon from '@material-ui/icons/People';
import EmailIcon from '@material-ui/icons/Email';

import { Layout } from './layout';
import Head from './Head';
import dataProvider from './dataProvider';
import authProvider from './auth/authProvider';
import {
  CohortCreate,
  CohortEdit,
  CohortList,
  CohortShow,
} from './resources/cohorts';
import {
  CohortB2bCreate,
  CohortB2bEdit,
  CohortB2bList,
  CohortB2bShow,
} from './resources/cohorts_b2b';
import { DropCreate } from './resources/drop';
import { EmailCreate, EmailEdit, EmailList } from './resources/emails';
import { StudentEdit, StudentList, StudentShow } from './resources/students';
import {
  StudentClusterCreate,
  StudentClusterList,
  StudentClusterUpdate,
} from './resources/student_clusters';
import { UserEdit, UserList, UserShow } from './resources/users';
import { AttendanceB2bCreate, AttendanceCreate } from './resources/attendance';
import { MoveCreate } from './resources/move';
import { OneOnOneCreate } from './resources/one_on_ones';
import { authServer } from './auth/keycloak';
import GroupsSelectionForm from './auth/GroupsForm';

import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

const App = () => {
  useEffect(() => {
    if (R.isEmpty(authServer.getRoles())) {
      (async () => {
        const userCreationResult = await fetch(
          `${process.env.REACT_APP_BACKEND_HOST}/users`,
          {
            method: 'POST',
            headers: { Authorization: `Bearer ${authServer.token}` },
          }
        ).then((res) => res.json());
        const assignedRoles = R.path(['result', 'groups'], userCreationResult);
        R.isEmpty(assignedRoles)
          ? (window.location.href = '#/role-selection')
          : window.location.reload();
      })();
    }
  }, []);

  return (
    <>
      <Head />
      <Admin
        layout={Layout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        customRoutes={[
          <Route
            key="role-selection"
            path="/role-selection"
            component={GroupsSelectionForm}
          />,
        ]}
      >
        {() => {
          return [
            authServer.hasRoles() ? (
              <Resource
                name="cohorts"
                create={
                  authServer.isRoles(['finance', 'gm']) ? CohortCreate : null
                }
                edit={
                  authServer.isRoles([
                    'gm',
                    'pm',
                    'finance',
                    'outcomes',
                    'education',
                    'campus_lead',
                  ])
                    ? CohortEdit
                    : null
                }
                list={CohortList}
                icon={CohortIcon}
                show={CohortShow}
                options={{ label: 'Cohorts B2C' }}
              />
            ) : null,
            authServer.hasRoles() ? (
              <Resource
                name="cohorts_b2b"
                create={
                  authServer.isAllRoles(['pm', 'b2b']) ? CohortB2bCreate : null
                }
                edit={
                  authServer.isAllRoles(['pm', 'b2b']) ? CohortB2bEdit : null
                }
                list={CohortB2bList}
                icon={CohortIcon}
                show={CohortB2bShow}
                options={{ label: 'Cohorts B2B' }}
              />
            ) : null,
            authServer.hasRoles() ? (
              <Resource
                name="students"
                list={StudentList}
                show={StudentShow}
                edit={
                  authServer.isRoles([
                    'pm',
                    'outcomes',
                    'campus_lead',
                    'teacher',
                  ])
                    ? StudentEdit
                    : null
                }
                icon={StudentIcon}
              />
            ) : null,
            authServer.isRoles(['outcomes']) ? (
              <Resource
                name="student_clusters"
                create={
                  authServer.isRoles(['outcomes']) ? StudentClusterCreate : null
                }
                edit={
                  authServer.isRoles(['outcomes']) ? StudentClusterUpdate : null
                }
                list={
                  authServer.isRoles(['outcomes']) ? StudentClusterList : null
                }
                icon={StudentClusterIcon}
                options={{ label: 'Student Clusters' }}
              />
            ) : null,
            authServer.isRoles(['product', 'outcomes', 'pm']) ? (
              <Resource
                name="emails"
                icon={EmailIcon}
                create={authServer.isRoles(['product']) ? EmailCreate : null}
                edit={
                  authServer.isRoles(['product', 'outcomes', 'pm'])
                    ? EmailEdit
                    : null
                }
                list={
                  authServer.isRoles(['product', 'outcomes', 'pm'])
                    ? EmailList
                    : null
                }
              />
            ) : null,
            authServer.isRoles(['product']) ? (
              <Resource
                name="users"
                edit={UserEdit}
                list={UserList}
                icon={UserIcon}
                show={UserShow}
              />
            ) : null,
            <Resource name="groups" />,
            <Resource name="changes" />,
            <Resource name="deliveries" />,
            <Resource name="data" />,
            <Resource
              name="attendance"
              create={
                authServer.isRoles(['pm', 'ta', 'teacher', 'campus_lead'])
                  ? AttendanceCreate
                  : null
              }
            />,
            <Resource
              name="attendance_b2b"
              create={
                authServer.isRoles(['pm', 'b2b']) ? AttendanceB2bCreate : null
              }
            />,
            <Resource
              name="drop"
              create={
                authServer.isRoles(['pm', 'campus_lead']) ? DropCreate : null
              }
            />,
            <Resource
              name="move"
              create={
                authServer.isRoles(['pm', 'campus_lead']) ? MoveCreate : null
              }
            />,
            <Resource
              name="one_on_ones"
              create={
                authServer.isNotRoles(['education']) ? OneOnOneCreate : null
              }
            />,
          ];
        }}
      </Admin>
    </>
  );
};
export default App;

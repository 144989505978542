import React from 'react';
import * as R from 'ramda';
import { Typography } from '@material-ui/core';

const ExpertiseAreasField = ({ record }) => {
  const expertiseAreas = R.pipe(
    R.propOr({}, 'professional_profile'),
    R.props(['expertise_areas', 'expertise_areas_other']),
    R.flatten,
    R.reject(R.equals('Other')),
    R.reject(R.isNil),
    R.join(', ')
  )(record);

  return (
    <Typography component="span" variant="body2">
      {expertiseAreas}
    </Typography>
  );
};

ExpertiseAreasField.defaultProps = {
  label: 'Background',
};

export default ExpertiseAreasField;

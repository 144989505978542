import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useNotify, useQuery, Loading, Error, Toolbar } from 'react-admin';
import { Box, Button, Drawer } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { Action, EditAction, CreateAction } from './components';
import { authServer } from 'auth/keycloak';

const ActionsTab = (props) => {
  const { record: cohort } = props;
  const notify = useNotify();
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [createAction, setCreateAction] = useState(null);

  const handleActionEdit = (status, payload) => {
    if (status === 'success') {
      notify('Notification Updated Successfully');
      setActions((prevActions) => ({ ...prevActions, [payload.id]: payload }));
    } else if (status === 'error') {
      notify(`Notification Update Error: ${payload.message}`, 'warning');
    }
    setSelectedAction(null);
  };

  const handleActionCreate = (status, payload) => {
    if (status === 'success') {
      notify('Notification Created Successfully');
      setActions((prevActions) => ({ ...prevActions, [payload.id]: payload }));
    } else if (status === 'error') {
      notify(`Notification Create Error: ${payload.message}`, 'warning');
    }
    setCreateAction(null);
  };

  const {
    data: serverActions,
    loading,
    error,
  } = useQuery({
    type: 'getList',
    resource: 'actions',
    payload: { data: { owner_id: cohort.id } },
  });

  useEffect(() => {
    if (serverActions) {
      setActions(
        R.pipe(
          R.filter(
            R.propSatisfies(
              R.includes(R.__, ['prework_reminder', 'nps_schedule']),
              'type'
            )
          ),
          R.indexBy(R.prop('id'))
        )(serverActions)
      );
    }
  }, [serverActions]);

  if (loading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (error)
    return (
      <Box p="16px">
        <Error error={error} errorInfo={error} />
      </Box>
    );

  return (
    <>
      <Box p="16px">
        {Object.values(actions).map((action) => (
          <Box mt="8px">
            <Action
              key={action.id}
              action={action}
              onSelected={() => setSelectedAction(action)}
            />
          </Box>
        ))}
      </Box>
      <Toolbar>
        {authServer.isRoles([]) && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              setCreateAction(true);
            }}
          >
            Add Action
          </Button>
        )}
      </Toolbar>
      <Drawer
        variant="temporary"
        open={selectedAction || createAction}
        anchor="right"
        onClose={() => {
          if (selectedAction) setSelectedAction(null);
          if (createAction) setCreateAction(null);
        }}
      >
        {selectedAction && (
          <Box p="16px">
            <EditAction action={selectedAction} onUpdated={handleActionEdit} />
          </Box>
        )}
        {createAction && (
          <Box p="16px">
            <CreateAction cohort={cohort} onCreated={handleActionCreate} />
          </Box>
        )}
      </Drawer>
    </>
  );
};

export default ActionsTab;

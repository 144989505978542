import React, { useEffect } from 'react';
import BeePlugin from '@mailupinc/bee-plugin';
import { Box } from '@material-ui/core';

import defaultTemplate from './default_template';

// Move credentials to backend for production.
const CLIENT_ID = '5639fdec-77bb-46c5-ac70-c86141774f78';
const CLIENT_SECRET = 'w5FVR3NSVAGJe4NxztbPmOwe48QkYcUfLduQGoNo2IokxNJ7A5Me';
const CLIENT_UID = 'campus-tools';

const BeeEditor = (props) => {
  const { template = defaultTemplate, onSave, onError } = props;

  useEffect(() => {
    const beeFinalConfig = {
      autosave: false,
      container: 'bee-plugin-container',
      language: 'en-US',
      onError,
      onSave,
      // onSaveAsTemplate,
      // onSend,
      uid: CLIENT_UID,
    };

    const init = async () => {
      const beeEditor = new BeePlugin();
      await beeEditor.getToken(CLIENT_ID, CLIENT_SECRET);
      await beeEditor.start(beeFinalConfig, template);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box height="100%" id="bee-plugin-container" />;
};

export default BeeEditor;

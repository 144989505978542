import React from 'react';
import * as R from 'ramda';
import { Chip } from '@material-ui/core';
import { lightGreen, red } from '@material-ui/core/colors';

const NpsField = ({ record, question, surveyNumber }) => {
  const npsColors = [
    red[300],
    red[300],
    red[300],
    red[300],
    red[300],
    red[300],
    null,
    null,
    lightGreen[500],
    lightGreen[500],
  ];
  const score = R.pipe(
    R.propOr([], 'weekly_surveys'),
    R.find(({ survey }) => survey.toString() === surveyNumber),
    R.propOr([], 'answers'),
    R.find(R.propEq('question', question)),
    R.defaultTo({}),
    R.prop('answer')
  )(record);
  return score ? (
    <Chip
      style={{ color: 'black', backgroundColor: npsColors[score - 1] }}
      label={score}
    />
  ) : null;
};

export default NpsField;

import React from 'react';
import * as R from 'ramda';
import * as _ from 'lodash';

import { TextField } from 'react-admin';

const NiceTextField = ({ record, source, ...rest }) => {
  const text = R.pipe(
    R.split('.'),
    R.reject(R.isNil),
    R.pathOr('', R.__, record),
    R.toLower,
    _.words,
    R.join(' '),
    R.replace(/(^|[\s-])\S/g, R.toUpper)
  )(source);
  return <TextField record={{ text }} source="text" {...rest} />;
};

export default NiceTextField;

import React, { useState } from 'react';
import * as R from 'ramda';
import {
  BooleanInput,
  FileField,
  FileInput,
  Labeled,
  required,
  SelectInput,
  TextInput,
  useQuery,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {
  Box,
  Card,
  CardContent,
  Divider,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';

import { DateInput } from '../../../common/DatePickers';

import {
  careerServiceCategories,
  careerServiceStatuses,
  changeOfMindReasonsFromOM,
  jobSources,
  mostCommonPositions,
} from '../../../../data';

const validateRequired = [required()];

const availableStatuses = R.reject(
  R.propSatisfies(
    R.includes(R.__, [
      'pending',
      'deferred_more_than_45d',
      'deferred_less_than_45d',
      'deferred_more_than_45d_sc',
      'not_graduated',
      'placement_not_successful',
      'intervention_education',
      'intervention_careers',
    ]),
    'id'
  ),
  careerServiceStatuses
);

const getCategoryByStatus = (status) =>
  R.pipe(
    R.find(R.propEq('id', status)),
    R.prop('category')
  )(careerServiceStatuses);

const hasChanged = (dirtyFields, paths) =>
  R.pipe(
    R.filter(R.identity),
    R.keys,
    R.intersection(paths),
    R.length,
    R.lt(0)
  )(dirtyFields);

const hasChangedToExcluded = (dirtyFields, values) =>
  R.both(
    ({ dirtyFields }) => hasChanged(dirtyFields, ['career_services.status']),
    ({ values }) =>
      R.pipe(
        R.path(['career_services', 'status']),
        getCategoryByStatus,
        R.equals('excluded')
      )(values)
  )({ dirtyFields, values });

const useStyles = makeStyles({
  dropZone: {
    borderStyle: 'dashed',
    borderColor: 'rgba(0, 0, 0, 0.54)',
  },
});

const requireProof = (dirtyFields) => {
  return hasChanged(dirtyFields, [
    'career_services.status',
    'career_services.outcome.company',
    'career_services.outcome.job_source',
    'career_services.outcome.offer_date',
    'career_services.outcome.job_title',
  ]);
};

export const CareerServicesPanel = ({ record: student }) => {
  const { values, dirtyFields } = useFormState();
  const classes = useStyles();

  const isStudentSearching = R.pipe(
    R.path(['career_services', 'status']),
    getCategoryByStatus,
    R.equals('searching')
  )(student);

  const isEmployedSelected = R.pipe(
    R.path(['career_services', 'status']),
    getCategoryByStatus,
    R.equals('employed')
  )(values);

  return (
    <Card>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Career Services
            </Typography>
            <Divider />
            <Box display={{ sm: 'block', md: 'flex' }} mt="1.5em">
              <Box flex={1} pr={{ md: '2em' }}>
                <SelectInput
                  label="Student subcategory"
                  source="career_services.status"
                  choices={availableStatuses}
                  optionText={(status) => {
                    const category = R.find(
                      R.propEq('id', status.category),
                      careerServiceCategories
                    );
                    return `${category.name} > ${status.name}`;
                  }}
                  variant="standard"
                  fullWidth
                />
              </Box>
              <Box flex={1}>
                <Labeled label="Send outcomes form (automated emails)">
                  <BooleanInput
                    label=" "
                    source="career_services.form_reminders_enabled"
                    disabled={
                      !hasChanged(dirtyFields, ['career_services.status'])
                    }
                  />
                </Labeled>
              </Box>
            </Box>
            {isStudentSearching && hasChangedToExcluded(dirtyFields, values) && (
              <Box width={{ sm: '100%', md: 'calc(50% - 1em)' }}>
                <SelectInput
                  label="Why did they change their mind?"
                  source="career_services.change_mind_reason"
                  choices={changeOfMindReasonsFromOM}
                  validate={validateRequired}
                  variant="standard"
                  fullWidth
                />
              </Box>
            )}
            {requireProof(dirtyFields) && (
              <FileInput
                source="career_services.proofs"
                label="Outcomes Update Proof(s)"
                accept="application/pdf,image/*"
                labelMultiple="Drop files or click to select them (PDFs or Images). The maximum size is 3MB"
                maxSize={3000000}
                disabled={requireProof(dirtyFields)}
                validate={validateRequired}
                classes={classes}
                multiple
              >
                <FileField source="src" title="title" />
              </FileInput>
            )}
            {hasChanged(dirtyFields, 'career_services.status') &&
              isEmployedSelected && (
                <>
                  <Typography variant="h5" component="h3" gutterBottom>
                    Final Outcomes fields
                  </Typography>
                  <OutcomesInfoForm student={student} />
                </>
              )}
          </Box>
        </Box>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            One on One Notes
          </Typography>
        </Box>
        <Box id="quill" width={1}>
          <RichTextInput
            label=""
            source="career_services.notes"
            options={{ bounds: '#quill' }}
            toolbar={[
              [{ header: [1, 2, 3, false] }],
              ['bold', 'italic', 'underline'],
              ['link'],
              [{ list: 'ordered' }, { list: 'bullet' }],
            ]}
            fullWidth
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const OutcomesInfoForm = ({ student }) => (
  <Box display={{ xs: 'block', sm: 'flex' }} flexDirection={{ sm: 'column' }}>
    <Box display={{ xs: 'block', sm: 'flex' }} justifyContent="space-between">
      <Box flex={1}>
        <TextInput
          source="career_services.outcome.company"
          variant="standard"
          label="Name of the company"
          fullWidth
        />
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: 4 }}>
        <SelectInput
          label="Source of hiring"
          source="career_services.outcome.job_source"
          choices={jobSources}
          variant="standard"
          allowEmpty
          fullWidth
        />
      </Box>
    </Box>
    <Box display={{ xs: 'block', sm: 'flex' }} justifyContent="space-between">
      <Box flex={1}>
        <DateInput
          label="Offer Date/Communication Date"
          source="career_services.outcome.offer_date"
          options={{
            format: 'Do MMMM YYYY',
          }}
          fullWidth
        />
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: 4 }}>
        <PositionField student={student} />
      </Box>
    </Box>
  </Box>
);

const PositionField = ({ student }) => {
  const [standardPosition, setStandardPosition] = useState();
  const [otherPosition, setOtherPosition] = useState('');
  const form = useForm();
  const { data: cohort } = useQuery({
    type: 'getOne',
    resource: 'cohorts',
    payload: { id: student.cohort },
  });

  const studentJobTitle = R.pathOr(
    null,
    ['career_services', 'outcome', 'job_title'],
    student
  );

  const selectedPosition =
    standardPosition === 'other' ? otherPosition : standardPosition;
  form.change(
    'career_services.outcome.job_title',
    selectedPosition || studentJobTitle
  );

  if (!cohort) return null;
  const availablepositions = R.pipe(
    R.filter(R.propEq('track', cohort.track)),
    R.prepend({ id: null, name: 'None' }),
    R.append({ id: 'other', name: 'Other' })
  )(mostCommonPositions);

  return (
    <Box display={{ xs: 'block', sm: 'flex' }} alignItems="flex-end">
      <Box
        display={{ xs: 'block', sm: 'flex' }}
        flexDirection={{ sm: 'column' }}
        flex={1}
        mt={{ xs: 3, sm: 0 }}
      >
        <Box>
          <InputLabel shrink>Position</InputLabel>
        </Box>
        <Box>
          <Select
            defaultValue={studentJobTitle}
            onChange={(e) => {
              const position = e.target?.value;
              if (position !== 'other') setOtherPosition('');
              setStandardPosition(position);
            }}
            fullWidth
          >
            {availablepositions.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: 4 }} mt={{ xs: 3, sm: 0 }}>
        <TextField
          value={otherPosition}
          onChange={(e) => setOtherPosition(e.target.value)}
          placeholder="Please, type position"
          fullWidth
          disabled={standardPosition !== 'other'}
        />
      </Box>
    </Box>
  );
};

import React from 'react';
import * as R from 'ramda';

import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Check, Warning } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';

import { riskLevelCriteria } from '../../../../../../../../data';

const criteriaNotionPageLink =
  'https://www.notion.so/ironhack/At-risk-Off-track-students-students-with-difficulties-6530acb450dd4830b85fb436950a4030#6e3a33dfd9e74821b3a916aaa34c7f84';

const RiskCheckpointCriteria = ({ checkpointType, criteria }) => {
  return (
    <Card
      style={{
        boxShadow: 'none',
        border: '1px solid',
        borderColor: '#E0E0E0',
        borderRadius: '8px',
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom>
            At risk criteria
          </Typography>
          <a
            href={criteriaNotionPageLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body2" gutterBottom>
              Learn more about the criteria
            </Typography>
          </a>
        </Box>
        <Box as="ul" mt={1}>
          {criteria.map((criterion) => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              as="li"
            >
              <Typography
                variant="body2"
                gutterBottom
                style={{
                  fontWeight: criterion.passed ? '400' : '700',
                }}
              >
                {R.pipe(
                  R.find(R.propEq('id', criterion.name)),
                  R.prop('name')
                )(riskLevelCriteria)}
              </Typography>
              {criterion.passed ? (
                <Check
                  style={{
                    fontSize: '11px',
                    paddingLeft: '1em',
                    stroke: '#1CA922',
                    strokeWidth: 2,
                  }}
                />
              ) : (
                <Warning
                  style={{
                    fontSize: '11px',
                    color: orange[900],
                    paddingLeft: '1em',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default RiskCheckpointCriteria;

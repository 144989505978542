import React from 'react';
import * as R from 'ramda';
import { ArrayField, Datagrid } from 'react-admin';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  Link,
  Redirect,
  Route,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import moment from 'moment-timezone';

import { surveyQuestions } from 'data';
import { authServer } from 'auth/keycloak';
import { FullNameField } from 'resources/students/fields';
import { QuestionField } from '../fields';
import { QuestionMetrics } from '../components';

const shouldShow = R.curryN(2, (question, survey) => {
  return R.cond([
    [R.equals('prework'), R.always(survey.survey === '1')],
    [R.equals('overall_experience'), R.always(R.prop('is_project', survey))],
    [R.T, R.T],
  ])(question);
});

const teacherHiddenQuestions = ['comments'];
const b2cLegacyHiddenQuestions = [
  'teacher_assistant',
  'lead_teacher',
  'v2_learning',
  'v2_support',
  'v2_sp_ai',
  'v2_campus_experience',
  'v2_job_readiness',
  'v2_expectations',
  'v2_nps_reason_promoter',
  'v2_nps_reason_detractor',
  'v2_sp_ai_reason_promoter',
  'v2_sp_ai_reason_detractor',
];

const b2cHiddenQuestions = [
  'prework',
  'challenge',
  'instruction',
  'material',
  'lab_preparation',
  'instructional_staff',
  'lead_teacher',
  'teacher_assistant',
  'overall_experience',
  'sp_satisfaction',
  'sp_main_issue',
  'sp_secondary_issue',
  'v2_nps_reason_promoter',
  'v2_nps_reason_detractor',
  'v2_sp_ai_reason_promoter',
  'v2_sp_ai_reason_detractor',
  'v2_tech_support',
];
function ByQuestionTab({ record: cohort, students }) {
  let filteredQuestions = surveyQuestions;
  const isInstructionalStaff =
    authServer.isRoles(['teacher', 'ta']) && !authServer.isRoles(['admin']);
  if (isInstructionalStaff) {
    filteredQuestions = R.reject(
      R.propSatisfies(R.contains(R.__, teacherHiddenQuestions), 'id'),
      surveyQuestions
    );
  }

  const isB2b = R.hasPath(['company'], cohort);
  const hasLegacyWeeklySurveysEnabled = R.pathSatisfies(
    R.equals(true),
    ['feature_flags', 'legacy_weekly_surveys_enabled'],
    cohort
  );

  if (!isB2b) {
    const hiddenQuestions = hasLegacyWeeklySurveysEnabled
      ? b2cLegacyHiddenQuestions
      : b2cHiddenQuestions;
    filteredQuestions = R.reject(
      R.propSatisfies(R.includes(R.__, hiddenQuestions), 'id'),
      surveyQuestions
    );
  }

  const now = moment.utc();
  const sentSurveys = R.pipe(
    R.propOr([], 'weekly_surveys'),
    R.filter((survey) =>
      survey.survey !== '0' ? now.isAfter(survey.date) : []
    )
  )(cohort);

  const match = useRouteMatch();
  const location = useLocation();

  if (location.pathname.endsWith('question')) {
    return <Redirect to={`${match.url}/ironhack`} />;
  }
  return (
    <Route
      path={`${match.url}/:question`}
      render={(routeProps) => {
        const question = routeProps.match.params.question;
        return (
          <Box display="block">
            <Box display="flex" m="16px" width="25%">
              <FormControl fullWidth>
                <Typography variant="h5" component="h3" gutterBottom>
                  Question
                </Typography>
                <Select label="Select Question" value={question}>
                  {R.map(
                    (question) => (
                      <MenuItem
                        key={question.id}
                        value={question.id}
                        component={Link}
                        to={`${match.url}/${question.id}`}
                      >
                        {question.short_name}
                      </MenuItem>
                    ),
                    filteredQuestions
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box mt="4em">
              <QuestionMetrics cohortId={cohort.id} question={question} />
            </Box>
            {!isInstructionalStaff && (
              <>
                <Box mx="16px" mt="4em">
                  <Typography variant="h5" gutterBottom>
                    Students Answers
                  </Typography>
                </Box>
                <ArrayField
                  record={{ students }}
                  source="students"
                  basePath="/students"
                >
                  <Datagrid question={question}>
                    <FullNameField sortable={false} link />
                    {R.map((sentSurvey) => {
                      const surveyNumber = parseInt(sentSurvey.survey);
                      return shouldShow(question, sentSurvey) ? (
                        <QuestionField
                          label={`Survey ${surveyNumber}`}
                          questionId={question}
                          surveyNumber={`${surveyNumber}`}
                        />
                      ) : null;
                    }, sentSurveys)}
                  </Datagrid>
                </ArrayField>
              </>
            )}
          </Box>
        );
      }}
    />
  );
}

export default ByQuestionTab;

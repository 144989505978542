import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'react-admin';
import AppBar from './AppBar';
import { darkTheme, lightTheme } from './themes';

const MyMenu = props => {
  return <Menu {...props} dense={false} />;
};

const CustomLayout = props => {
  const theme = useSelector(state =>
    state.theme === 'dark' ? darkTheme : lightTheme
  );
  return <Layout {...props} appBar={AppBar} menu={MyMenu} theme={theme} />;
};

export default CustomLayout;

import React from 'react';
import * as R from 'ramda';
import { Chip } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';

const chipStyles = {
  assignmentGrade: R.cond([
    [R.lte(4.5), R.always({ color: 'white', backgroundColor: green[900] })],
    [R.lte(3.5), R.always({ color: 'black', backgroundColor: green[500] })],
    [R.lte(2.5), R.always({ color: 'black', backgroundColor: grey[500] })],
    [R.lte(1.5), R.always({ color: 'black', backgroundColor: red[500] })],
    [R.lte(1), R.always({ color: 'white', backgroundColor: red[900] })],
    [R.T, R.always({ color: 'black', backgroundColor: '#BCB6E2' })],
  ]),

  labGrade: R.cond([
    [R.lte(90), R.always({ color: 'white', backgroundColor: green[900] })],
    [R.lte(70), R.always({ color: 'black', backgroundColor: green[500] })],
    [R.lte(50), R.always({ color: 'black', backgroundColor: grey[500] })],
    [R.lte(30), R.always({ color: 'black', backgroundColor: red[500] })],
    [R.lte(20), R.always({ color: 'white', backgroundColor: red[900] })],
    [R.T, R.always({ color: 'black', backgroundColor: '#BCB6E2' })],
  ]),
  outcomesScore: R.cond([
    [R.lte(2.5), R.always({ color: 'white', backgroundColor: green[900] })],
    [R.lte(1.5), R.always({ color: 'black', backgroundColor: green[500] })],
    [R.lte(0.5), R.always({ color: 'black', backgroundColor: grey[500] })],
    [R.lte(0), R.always({ color: 'white', backgroundColor: red[900] })],
    [R.T, R.always({ color: 'black', backgroundColor: '#BCB6E2' })],
  ]),
};

const GradeField = (props) => {
  const { record, source, chipStyle } = props;

  const value = R.pathOr(null, R.split('.', source))(record);
  const style = R.propOr(() => {}, chipStyle, chipStyles)(value);
  return Number.isFinite(value) ? <Chip label={value} style={style} /> : null;
};

export default GradeField;

import React, { Fragment, useState, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import {
  Button,
  crudGetMatching,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import FilterCohortsIcon from '@material-ui/icons/FilterList';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { authServer } from 'auth/keycloak';
import { campuses } from 'data';
import { CohortNameField } from 'resources/students/fields';
import SearchCohortForm from './SearchCohortForm';

const CohortSearchInput = ({ relatedTo, ...rest }) => {
  const campusChoices = R.pipe(
    R.pathOr([], ['roles', 'campus']),
    R.of,
    R.partialRight(R.includes, R.__),
    R.propSatisfies(R.__, 'id'),
    R.filter(R.__, campuses),
    R.pluck('id')
  )(authServer);
  return (
    <Fragment>
      <ReferenceInput
        {...rest}
        reference="cohorts"
        filter={{ campus: campusChoices }}
        perPage={1000}
        sort={{ field: 'start_date', order: 'ASC' }}
      >
        <SelectInput optionText={<CohortNameField />} />
      </ReferenceInput>
      <SearchCohortButton relatedTo={relatedTo} />
    </Fragment>
  );
};

const SearchCohortButton = props => {
  const dispatch = useDispatch();
  const { change } = useForm();

  const [showDialog, setShowDialog] = React.useState(false);
  const [searchParams, setSearchParams] = useState({});

  const campusChoices = R.pipe(
    R.pathOr([], ['roles', 'campus']),
    R.of,
    R.partialRight(R.includes, R.__),
    R.propSatisfies(R.__, 'id'),
    R.filter(R.__, campuses)
  )(authServer);

  useEffect(() => {
    dispatch(
      crudGetMatching(
        'cohorts',
        props.relatedTo,
        { page: 1, perPage: 1000 },
        { field: 'start_date', order: 'ASC' },
        R.merge(R.pick(['campus', 'format', 'track'], searchParams), {
          campus: R.pipe(
            R.propOr([], 'campus'),
            R.intersection(R.pluck('id', campusChoices)),
            R.when(R.isEmpty, R.always(R.pluck('id', campusChoices)))
          )(searchParams)
        })
      )
    );
    change('cohort', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleOpenDialog = useCallback(() => {
    setShowDialog(true);
  }, [setShowDialog]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleSave = useCallback(
    searchParams => {
      setShowDialog(false);
      setSearchParams(searchParams);
    },
    [setShowDialog, setSearchParams]
  );

  return (
    <Fragment>
      <Button onClick={handleOpenDialog} label="Filter Cohorts">
        <FilterCohortsIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseDialog}
        aria-label="Search Cohort"
      >
        <DialogTitle>Search Cohort</DialogTitle>
        <DialogContent>
          <SearchCohortForm
            onCancel={handleCloseDialog}
            onSave={handleSave}
            initialValues={searchParams}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default CohortSearchInput;

export const darkTheme = {
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  sidebar: {
    width: '200px',
  },
  palette: {
    secondary: {
      light: '#32c3ff',
      main: '#1e2334',
      dark: '#001064',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        height: '48px',
      },
    },
    RaRichTextInput: {
      '@global': {
        '.ra-rich-text-input': {
          '& .ql-editor': {
            resize: 'vertical',
            minHeight: '150px',
            border: '1px solid rgba(0, 0, 0, 0.42)',
          },
        },
      },
    },
    RaTabbedShowLayout: {
      content: {
        paddingTop: '0',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
    MuiFormControlLabel: {
      root: {
        display: 'grid',
        gridTemplateColumns: 'auto 250px',
      },
    },
  },
};

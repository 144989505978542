import React from 'react';
import { indigo } from '@material-ui/core/colors';
import { Box, Chip } from '@material-ui/core';
import { CohortNameField } from 'resources/students/fields';

const StudentMovesStep = (props) => {
  const { cohort, isNotLastItem, ...rest } = props;

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Chip
        style={{
          backgroundColor: isNotLastItem ? '#F7F7F7' : '#E4E8FB',
          color: isNotLastItem ? '#646464' : indigo[500],
          fontSize: '12px',
          textAlign: 'center',
          height: '50px',
          padding: '8px 12px',
        }}
        label={
          cohort ? (
            <CohortNameField record={cohort} isBreak />
          ) : (
            'Deleted cohort'
          )
        }
      />
    </Box>
  );
};

export default StudentMovesStep;

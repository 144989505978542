import React from 'react';
import * as R from 'ramda';
import { DateField } from 'react-admin';

const LabDueDateField = ({ record }) => {
  return R.isEmpty(record.due_date) ? (
    <span>None</span>
  ) : (
    <DateField
      record={record}
      label="Due Date"
      source="due_date"
      sortable={false}
      options={{ hour12: true }}
    />
  );
};

LabDueDateField.defaultProps = {
  addLabel: true,
  label: 'Due Date',
};

export default LabDueDateField;

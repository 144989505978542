import React from 'react';
import * as R from 'ramda';
import { Avatar, Badge, makeStyles } from '@material-ui/core';
import defaultUserPicture from './default_user_picture.png';

const useStyles = makeStyles({
  badge: {
    right: 6,
    top: 0,
    height: 'auto',
    padding: '2px 4px',
    fontSize: (size) => (size === 25 ? '0.6rem' : '1.2rem'),
  },
});

const AvatarField = ({ record = {}, size = 25, showBadge = true }) => {
  const classes = useStyles(size);
  const picture = record.picture ? record.picture : defaultUserPicture;
  const financeOptions = R.propOr({}, 'finance', record);
  const areAllOptionsFalse = R.pipe(
    R.values,
    R.none(R.identity)
  )(financeOptions);

  return (
    <Badge
      classes={{ badge: classes.badge }}
      color="primary"
      badgeContent={R.cond([
        [R.prop('isIsa'), R.always('ISA')],
        [R.prop('isDeferredTuition'), R.always('D.Tuition')],
        [R.T, R.always('')],
      ])(financeOptions)}
      invisible={!showBadge || areAllOptionsFalse}
    >
      <Avatar src={picture} size={size} style={{ width: size, height: size }} />
    </Badge>
  );
};

export default AvatarField;

import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { capitalize } from 'lodash';
import { useCreate } from 'react-admin';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';

import actionsMap from './actions';

const actionTypes = R.pipe(
  R.keys,
  R.map(
    R.applySpec({
      id: R.identity,
      name: R.pipe(R.split('_'), R.map(capitalize), R.join(' ')),
    })
  )
)(actionsMap);

const CreateAction = (props) => {
  const { cohort, onCreated } = props;
  const [actionType, setActionType] = useState();

  const validate = () => {};
  const [createAction, { loading, error, data: createdAction }] =
    useCreate('actions');
  const onSubmit = (data) => {
    createAction({
      payload: {
        data: {
          type: actionType,
          owner: 'cohort',
          owner_id: cohort.id,
          ...data,
        },
      },
    });
  };
  const handleActionType = (event) => {
    const type = event.target.value;
    setActionType(type);
  };

  useEffect(() => {
    if (createdAction) {
      onCreated('success', createdAction);
    } else if (error) {
      onCreated('error', error);
    }
  }, [createdAction, error, onCreated]);

  const ActionFields = actionsMap[actionType]?.Create;

  return (
    <>
      <FormControl fullWidth>
        <Typography variant="h5" component="h3" gutterBottom>
          Action Type
        </Typography>
        <Select
          label="Action Type"
          value={actionType}
          onChange={handleActionType}
        >
          {R.map(
            (action) => (
              <MenuItem key={action.id} value={action.id}>
                {action.name}
              </MenuItem>
            ),
            actionTypes
          )}
        </Select>
      </FormControl>
      {ActionFields && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <>
                <ActionFields />
                <Box
                  display="flex"
                  flexDirection="row"
                  justfyContent="space-between"
                  pt="16px"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting || loading}
                  >
                    Create
                  </Button>
                  <Box ml="16px">
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={submitting || loading}
                      onClick={() => onCreated('cancelled')}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </>
            </form>
          )}
        />
      )}
    </>
  );
};

export default CreateAction;

import { always, cond, path, whereEq } from 'ramda';
import moment from 'moment-timezone';
import arrayMutators from 'final-form-arrays';

const mutators = {
  cohortDatesMutator: ([name], state, { changeValue }) => {
    const startDate = path(['formState', 'values', 'start_date'], state);
    const offsets = cond([
      [whereEq({ track: 'cy' }), always({ weeks: 11, days: 4 })],
      [whereEq({ format: 'ft' }), always({ weeks: 8, days: 4 })],
      [whereEq({ format: 'pt' }), always({ weeks: 23, days: 5 })],
    ])(state.formState.values);
    const endDate = moment(startDate)
      .add(offsets.weeks, 'weeks')
      .startOf('isoWeek')
      .add(offsets.days, 'days')
      .format('YYYY-MM-DD');
    changeValue(state, 'end_date', () => endDate);
    state.fields['end_date'].modified = true;
  },
  ...arrayMutators,
};

export default mutators;

import React from 'react';
import * as R from 'ramda';
import {
  Edit,
  FormWithRedirect,
  ReferenceField,
  SaveButton,
  Toolbar,
  useDataProvider,
} from 'react-admin';
import { Card, CardContent, Box } from '@material-ui/core';

import { authServer } from 'auth/keycloak';

import { FullNameField } from 'resources/students/fields';

import {
  CareerServicesPanel,
  GraduationPanel,
  PersonalInformationPanel,
  StudentRiskPanel,
} from './panels';

const StudentTitle = ({ record }) =>
  record ? <FullNameField record={record} size={32} showBadge={false} /> : null;

const StudentEdit = (props) => {
  return (
    <Edit
      title={<StudentTitle />}
      actions={null}
      undoable={false}
      component="div"
      {...props}
    >
      <StudentEditForm variant="standard" />
    </Edit>
  );
};

const StudentEditForm = (props) => {
  const dataProvider = useDataProvider();
  const emailCache = { [props.record.email]: false };
  const validateExistingUser = async function (email) {
    if (!R.has(email, emailCache)) {
      const result = await dataProvider.getList('users', {
        filter: { email, realm: process.env.REACT_APP_KEYCLOAK_STUDENTS_REALM },
        pagination: {},
      });
      emailCache[email] = !R.isEmpty(result.data);
    }
    return emailCache[email];
  };

  const validateStudentUpdate = async (values) => {
    const errors = {};
    const email = R.propOr('', 'email', values);

    const isIronhackEmail = R.includes('@ironhack.com', email);
    if (isIronhackEmail) {
      errors.email = `Are you an employee pretending to be a student? This really harms our data! Use preview.my.ironhack.com or contact the Product Team!`;
    }

    if (!isIronhackEmail) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const invalidFormat = !R.test(emailRegex, email);
      if (invalidFormat) {
        errors.email = 'The email format is not valid.';
      }

      if (!isIronhackEmail && !invalidFormat) {
        const emailExists = await validateExistingUser(email);
        if (emailExists) {
          errors.email = 'A student with this email already exists!';
        }
      }
    }

    return errors;
  };

  return (
    <FormWithRedirect
      {...props}
      redirect="show"
      subscription={{
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
        dirtyFields: true,
      }}
      validate={validateStudentUpdate}
      render={(formProps) => {
        return (
          <Card>
            <form>
              <CardContent>
                <Box display="block">
                  {authServer.isRoles(['pm', 'outcomes', 'campus_lead']) && (
                    <PersonalInformationPanel record={props.record} />
                  )}
                  <Box mt="1em" />
                  {authServer.isRoles(['pm', 'campus_lead', 'teacher']) && (
                    <ReferenceField
                      source="cohort"
                      reference="cohorts"
                      link={false}
                    >
                      <StudentRiskPanel formProps={formProps} />
                    </ReferenceField>
                  )}
                  <Box mt="1em" />
                  {authServer.isRoles(['pm', 'outcomes']) && (
                    <GraduationPanel formProps={formProps} />
                  )}
                  <Box mt="1em" />
                  {authServer.isRoles(['outcomes']) && (
                    <CareerServicesPanel record={props.record} />
                  )}
                </Box>
              </CardContent>
              <Toolbar
                invalid={formProps.invalid}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                saving={formProps.saving}
                redirect="show"
              >
                {authServer.isRoles([
                  'pm',
                  'outcomes',
                  'campus_lead',
                  'teacher',
                ]) && <SaveButton />}
              </Toolbar>
            </form>
          </Card>
        );
      }}
    />
  );
};

export default StudentEdit;

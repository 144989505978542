import React from 'react';
import * as R from 'ramda';
import { Labeled, ReferenceField, required, TextInput } from 'react-admin';
import { Card, CardContent, Box, Divider, Typography } from '@material-ui/core';

import { authServer } from 'auth/keycloak';

import { CohortNameField } from 'resources/students/fields';

const validateRequired = [required()];

const shouldDisable = (field) => {
  const permissionsMap = {
    first_name: ['pm', 'campus_lead'],
    last_name: ['pm', 'campus_lead'],
    email: ['pm', 'campus_lead', 'outcomes'],
    cohort: [],
  };
  const allowed = authServer.isRoles(R.propOr([], field, permissionsMap));
  return !allowed;
};

const PersonalInformationPanel = ({ record }) => {
  const isB2b = record.is_b2b;

  return (
    <Card>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6" gutterBottom>
              Personal Information
            </Typography>
            <Divider />
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                  source="first_name"
                  variant="standard"
                  validate={validateRequired}
                  disabled={shouldDisable('first_name')}
                  fullWidth
                />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                  source="last_name"
                  validate={validateRequired}
                  variant="standard"
                  disabled={shouldDisable('last_name')}
                  fullWidth
                />
              </Box>
            </Box>
            <TextInput
              source="email"
              variant="standard"
              validate={validateRequired}
              disabled={shouldDisable('email')}
              fullWidth
            />
            <Labeled label="Cohort">
              <ReferenceField
                label="Cohort"
                source="cohort"
                reference={isB2b ? 'cohorts_b2b' : 'cohorts'}
                basePath={isB2b ? '/cohorts_b2b' : '/cohorts'}
                variant="standard"
                fullWidth
                record={record}
                link={false}
              >
                <CohortNameField />
              </ReferenceField>
            </Labeled>
            <Box mt="1em" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PersonalInformationPanel;

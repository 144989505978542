import { stringify } from 'query-string';
import * as R from 'ramda';
import * as P from 'p-iteration';
import moment from 'moment-timezone';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const cohortsB2bProvider = {
  getList: async (params) => {
    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const sortPrefix = params.sort.order === 'DESC' ? '-' : '';
    const sort = `${sortPrefix}${params.sort.field}`;
    const finalFilter = R.pipe(
      R.evolve({
        ends_since: (date) => moment(date).format('YYYY-MM-DD'),
        ends_before: (date) => moment(date).format('YYYY-MM-DD'),
        starts_since: (date) => moment(date).format('YYYY-MM-DD'),
        starts_before: (date) => moment(date).format('YYYY-MM-DD'),
      })
    )(params.filter);
    const filter = JSON.stringify(finalFilter);
    const query = {
      page,
      limit,
      sort,
      filter,
    };
    const url = `${apiUrl}/cohorts_b2b?${stringify(query)}`;
    return { url };
  },
  getOne: async (params) => {
    const url = `${apiUrl}/cohorts_b2b/${params.id}`;
    return { url };
  },
  getMany: async (params) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ _id: { $in: params.ids } }),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = `${apiUrl}/cohorts_b2b/search?${stringify({
      limit: params.ids.length,
    })}`;
    return { url, options };
  },
  create: async (params) => {
    const url = `${apiUrl}/cohorts_b2b`;
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
  delete: async (params) => {
    const options = { method: 'DELETE' };
    const url = `${apiUrl}/cohorts_b2b/${params.id}`;
    return { url, options };
  },
  update: async (params) => {
    const { id, data } = params;
    const convertFileToBase64 = async (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () =>
          resolve({
            key: file.rawFile.name,
            size: file.rawFile.size,
            type: file.rawFile.type,
            base64: reader.result,
          });
        reader.onerror = reject;
      });

    if (R.has('images', data)) {
      let processedImages = {};
      const toBeUploaded = R.filter(R.has('rawFile'), data.images);
      if (toBeUploaded.length > 0) {
        processedImages.to_be_uploaded = await P.mapSeries(
          toBeUploaded,
          convertFileToBase64
        );
      }

      const previouslyUploaded = R.reject(R.has('rawFile'), data.images);
      if (previouslyUploaded.length > 0)
        processedImages.previously_uploaded = previouslyUploaded;

      const shouldRemovePreviousImages = R.isEmpty(data.images);
      if (shouldRemovePreviousImages) processedImages.previously_uploaded = [];
      data.images = processedImages;
    }

    const url = `${apiUrl}/cohorts_b2b/${id}`;
    const options = {
      method: 'PATCH',
      body: JSON.stringify(R.omit(['id'], data)),
      headers: { 'Content-Type': 'application/json' },
    };
    return { url, options };
  },
};

export default cohortsB2bProvider;

import React from 'react';
import * as R from 'ramda';
import { Labeled } from 'react-admin';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';

import { DeliveriesList, NewProgressField } from 'resources/common';
import { UnitProgressField } from 'resources/common/cohorts/list/fields';

const SectionProgress = ({ record, section }) => {
  const { value, text } = R.pipe(
    R.pathOr([], ['career_services', 'deliveries']),
    R.values,
    R.unnest,
    R.filter(R.pathEq(['path', '1', 'display_name'], section)),
    R.reduce(
      (acc, delivery) => {
        acc.total += 1;
        if (R.startsWith('delivered', delivery.status)) {
          acc.delivered += 1;
        }
        return acc;
      },
      { total: 0, delivered: 0 }
    ),
    R.applySpec({
      value: ({ total, delivered }) =>
        R.defaultTo(0, Math.round((100 * delivered) / total)),
      text: ({ total, delivered }) => `${delivered}/${total}`,
    })
  )(record);
  return <NewProgressField value={value} text={text} />;
};

const CareerhackProgressTab = ({ record }) => {
  const sections = R.pipe(
    R.pathOr([], ['career_services', 'deliveries']),
    R.map(R.path(['path', '1', 'display_name'])),
    R.uniq
  )(record);
  return (
    <Box px="16px">
      <Card style={{ margin: '15px 0px' }}>
        <CardContent>
          <Typography variant="h5" component="h3" gutterBottom>
            Progress
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Labeled label="Unit Progress" fullWidth>
                <UnitProgressField
                  course="career_services"
                  target="progress"
                  record={record}
                  sortable={false}
                />
              </Labeled>
            </Grid>
            {R.map((section) => {
              return (
                <Grid item xs={3}>
                  <Labeled label={`${section} Progress`} fullWidth>
                    <SectionProgress
                      record={record}
                      label={`${section} Progress`}
                      section={section}
                    />
                  </Labeled>
                </Grid>
              );
            }, sections)}
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ margin: '15px 0px' }}>
        <CardContent>
          <Typography variant="h5" component="h3" gutterBottom>
            Assignments
          </Typography>
          <DeliveriesList
            label="Progress"
            type="students"
            record={record}
            source="career_services.deliveries"
            grade={false}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default CareerhackProgressTab;

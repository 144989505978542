import React from 'react';
import { Labeled, Loading, useQuery, SelectField } from 'react-admin';
import * as R from 'ramda';
import { Box, Grid, Typography } from '@material-ui/core';
import { careerServiceStatuses } from 'data';

import _ from 'lodash';

import { DateField } from 'resources/common';

import { capitalize } from 'lodash';
import { Chip } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';

const chipStyles = {
  pending: { color: 'white', backgroundColor: grey[500] },
  success: { color: 'white', backgroundColor: green[900] },
  failed: { color: 'white', backgroundColor: red[500] },
};

const TaskStatus = (props) => {
  const { status } = props;

  const style = R.propOr({}, status, chipStyles);
  return <Chip label={capitalize(status)} style={style} />;
};

const RemindersInfo = ({ student }) => {
  const {
    data: userActions,
    loading,
    error,
  } = useQuery({
    type: 'getList',
    resource: 'actions',
    payload: { data: { owner_id: student.id } },
  });

  if (error || !userActions) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  const remindersAction = R.find(
    R.propEq('type', 'outcomes_form_reminder'),
    userActions
  );
  const submissionDate = R.pipe(
    R.pathOr([], ['career_services', 'status_history']),
    R.reject(R.propEq('forced', true)),
    R.sortBy(R.prop('date')),
    R.last,
    R.prop('date')
  )(student);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            Final Status
          </Typography>
        </Box>
      </Grid>
      {R.path(['career_services', 'status'], student) && (
        <Grid item xs={12} lg={2}>
          <Labeled label="Outcome with bootcamp">
            <SelectField
              record={student}
              source="career_services.status"
              choices={careerServiceStatuses}
            />
          </Labeled>
        </Grid>
      )}

      {R.path(['career_services', 'status_proof'], student) && (
        <Grid item xs={12} lg={2}>
          <Labeled label="Proof">
            <a
              href={`${student.career_services.status_proof.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click to download proof
            </a>
          </Labeled>
        </Grid>
      )}

      {remindersAction && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt="1em">
              <Typography>Outcomes form (Automated emails)</Typography>
            </Box>
          </Grid>
          {Object.entries(remindersAction.tasks).map(
            ([taskName, task], index) => (
              <Grid item xs={12} md={3}>
                <Box display="flex" flexDirection="column">
                  <Typography>
                    {taskName.split('_').map(_.capitalize).join(' ')}
                  </Typography>
                  <Box w="50%" mt={1}>
                    <TaskStatus status={task.status} />
                  </Box>
                  {task.hook_date && (
                    <Labeled
                      label={
                        task.status !== 'pending' ? 'Sent On' : 'Scheduled For'
                      }
                    >
                      <DateField source="hook_date" record={task} showTime />
                    </Labeled>
                  )}
                </Box>
              </Grid>
            )
          )}
          {R.path(['career_services', 'outcome'], student) && (
            <Grid item xs={12} md={3}>
              <Box display="flex" flexDirection="column">
                <Typography>{'Outcomes Form'}</Typography>
                <Box w="50%">
                  <TaskStatus status={'Filled'} />
                </Box>
                <Labeled label={'When'}>
                  <DateField
                    source="submissionDate"
                    record={{ submissionDate }}
                    showTime
                  />
                </Labeled>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default RemindersInfo;

import React from 'react';
import {
  ChipField,
  ReferenceArrayField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField
} from 'react-admin';

const UserTitle = ({ record }) =>
  record ? `${record.first_name} ${record.last_name}` : null;

const UserList = props => (
  <Show title={<UserTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Email" source="email" />
      <TextField label="First Name" source="first_name" />
      <TextField label="Last Name" source="last_name" />
      <ReferenceArrayField label="Groups" reference="groups" source="groups">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

export default UserList;

import React from 'react';
import * as R from 'ramda';
import { FunctionField, Labeled, ReferenceField } from 'react-admin';
import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core';
import {
  CohortNameField,
  FullNameField,
  EmailField,
  AvatarField,
  SalesforceField,
} from '../../../../../fields';
import StudentMovesTimeline from './components/StudentMovesTimeline';
import { authServer } from 'auth/keycloak';

const PersonalInformationPanel = ({ record }) => {
  const isB2b = record.is_b2b;

  return (
    <Card style={{ margin: '15px 0px' }}>
      <CardContent>
        <Box display="block" width={1}>
          <Typography variant="h5" component="h3" gutterBottom>
            Personal Information
          </Typography>
          <Divider variant="fullWidth" />
          <Box mt="1.5em">
            <AvatarField size={100} record={record} />
          </Box>
          <Box
            display={{ md: 'block', lg: 'flex' }}
            justifyContent="space-between"
            width={1}
          >
            <Box mr={{ lg: '1.5em' }}>
              <Labeled label="Name">
                <FullNameField record={record} showAvatar={false} copyable />
              </Labeled>
            </Box>
            <Box mr={{ lg: '1.5em' }}>
              <Labeled label="Email">
                <EmailField record={record} copyable />
              </Labeled>
            </Box>
            <Box mr={{ lg: '1.5em' }}>
              <Labeled label="Phone">
                <FunctionField
                  record={record}
                  render={(record) =>
                    R.has('phone', record)
                      ? `+${record.phone.country_code} ${record.phone.number}`
                      : 'Not Available'
                  }
                />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Cohort">
                <ReferenceField
                  link="show"
                  source="cohort"
                  reference={isB2b ? 'cohorts_b2b' : 'cohorts'}
                  record={record}
                  basePath={isB2b ? '/cohorts_b2b' : '/cohorts'}
                >
                  <CohortNameField />
                </ReferenceField>
              </Labeled>
            </Box>
          </Box>
        </Box>
        {authServer.isRoles(['pm']) && (
          <Box mr="1.5em" mt="0.5em" width="100%">
            <Labeled label="Salesforce Student">
              <SalesforceField
                label="Salesforce Student"
                salesforceObject="Enrolled_Student__c"
                idName="student"
                record={record}
              />
            </Labeled>
          </Box>
        )}
        <Box mr="1.5em" mt="0.5em" width="100%">
          <StudentMovesTimeline record={record} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default PersonalInformationPanel;

import React from 'react';
import * as R from 'ramda';

import { RichTextField } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';

import { authServer } from 'auth/keycloak';
import { OutcomesNotesButton } from 'resources/students/fields';

const OneOnOneInfo = ({ student }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mt="1.5em">
          <Typography variant="h6" component="h4" gutterBottom>
            One On One Notes
          </Typography>
        </Box>
      </Grid>
      {!R.isEmpty(R.pathOr('', ['career_services', 'notes'], student)) && (
        <Grid item xs={12}>
          <Box px="0.5em" border="1px solid rgba(0, 0, 0, 0.42)" mb="1.5em">
            <RichTextField record={student} source="career_services.notes" />
          </Box>
        </Grid>
      )}
      {authServer.isRoles(['outcomes']) && (
        <Grid item xs={12}>
          <OutcomesNotesButton
            record={student}
            basePath="/students"
            source="career_services.notes"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default OneOnOneInfo;

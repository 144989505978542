import { stringify } from 'query-string';
import * as R from 'ramda';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const groupsProvider = {
  getList: async params => {
    const url = `${apiUrl}/groups`;
    return { url };
  },
  getMany: async params => {
    const ids = R.map(R.prop('id'), params.ids);
    const query = { ids };
    const url = `${apiUrl}/groups?${stringify(query)}`;
    return { url };
  }
};

export default groupsProvider;

import React, { useState } from 'react';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { Labeled, useQuery } from 'react-admin';
import {
  Box,
  Card as MuiCard,
  CircularProgress,
  StepConnector,
  CardContent,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper,
  Typography as MuiTypography,
  useMediaQuery,
} from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { avoidingSurchargeReasons, dropReasons } from 'data';
import StudentMovesStep from './StudentMovesStep';

const Connector = withStyles({
  line: { borderWidth: 4, position: 'relative', top: 10 },
  lineVertical: { height: '150px' },
})(StepConnector);

const ViewDetailsButton = withStyles({
  root: {
    color: '#0000EE',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: 500,
    textDecoration: 'underline',
  },
})(Box);

const Card = withStyles({
  root: {
    border: '1px solid',
    borderColor: '#E0E0E0',
    borderRadius: '8px',
    boxShadow: 'none',
    marginBottom: '10px',
    marginTop: '10px',
    textAlign: 'left',
    width: '450px',
  },
})(MuiCard);

const Typography = withStyles({
  body1: { fontSize: '15px' },
  body2: { fontSize: '14px' },
  body3: { fontSize: '13px' },
  body4: { fontSize: '12px' },
  link: { fontSize: '13px', fontWeight: 500 },
  h6: {
    fontSize: '16px',
    fontWeight: 500,
  },
})(MuiTypography);

const StepLabel = withStyles({
  alternativeLabel: {
    '&.MuiStepLabel-alternativeLabel': { marginTop: 0 },
  },
})(MuiStepLabel);

const Step = withStyles({
  horizontal: {
    left: -110,
    position: 'relative',
    width: '350px',
  },
})(MuiStep);

const CardTypography = (props) => {
  const { subtitle, text } = props;
  return (
    <Box mt="12px">
      <Typography variant="h6" gutterBottom>
        {subtitle}
      </Typography>
      <Typography variant="body4" gutterBottom>
        {text ? text : '--'}
      </Typography>
    </Box>
  );
};

const StudentMovesTimeline = ({ record: student }) => {
  const {
    data: moves,
    loading: movesLoading,
    error: movesError,
  } = useQuery(
    {
      type: 'getManyReference',
      resource: 'drop',
      payload: {
        search: [
          {
            $match: {
              'drop.cohort': { $exists: true },
              email: student?.email,
            },
          },
          {
            $lookup: {
              from: 'cohorts',
              localField: 'drop.cohort',
              foreignField: '_id',
              as: 'drop.cohort',
            },
          },
          { $unwind: { path: '$drop.cohort' } },
          {
            $lookup: {
              from: 'cohorts',
              localField: 'cohort',
              foreignField: '_id',
              as: 'cohort',
            },
          },
        ],
        pagination: {
          perPage: 1000,
        },
        sort: { field: 'drop.date', order: 'ASC' },
      },
    },
    { enabled: !R.isNil(student) }
  );

  const isLgOrMore = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [showDetails, setShowDetails] = useState(false);
  const [currentHistoryItem, setCurrentHistoryItem] = useState(false);

  const [moveDetails, setMoveDetails] = useState({});

  const onClickViewDetails = (index) => {
    setShowDetails(index === currentHistoryItem ? !showDetails : true);
    setCurrentHistoryItem(index);
  };

  if (movesLoading) return <CircularProgress />;

  if (movesError || !R.length(moves)) return null;

  const cohortsStudentBelongedTo = R.pipe(
    R.map(R.prop('drop')),
    R.concat([{ cohort: R.pipe(R.head, R.prop('cohort'), R.last)(moves) }])
  )(moves);

  return (
    <>
      <Labeled label="Moves history" />
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        height={isLgOrMore ? '110px' : 0}
        overflow="auto"
        position="relative"
      >
        <Stepper
          activeStep={cohortsStudentBelongedTo.length}
          alternativeLabel={isLgOrMore}
          connector={<Connector />}
          orientation={isLgOrMore ? 'horizontal' : 'vertical'}
          style={{ padding: 0 }}
        >
          {cohortsStudentBelongedTo.map(
            (cohortStudentBelongedToItem, index) => {
              const { cohort, date, reason } = cohortStudentBelongedToItem;

              const isNotFirstItem = index !== 0;
              const isNotLastItem =
                index !== cohortsStudentBelongedTo.length - 1;

              const showHistoryItemDetails =
                showDetails && currentHistoryItem === index;

              return (
                <Step key={`${cohort?.id}-${date}`}>
                  <StepLabel
                    StepIconComponent={StudentMovesStep}
                    StepIconProps={{ cohort, isNotLastItem, zIndex: 4 }}
                  >
                    {isNotFirstItem && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        left={isLgOrMore ? -260 : -120}
                        position="relative"
                        top={isLgOrMore ? -20 : -115}
                        width="500px"
                      >
                        {date && (
                          <Typography variant="body1">
                            {moment(date).format('Do MMM YYYY')}
                          </Typography>
                        )}
                        {reason && (
                          <Typography variant="body3">
                            "
                            {R.pipe(
                              R.find(R.propEq('id', reason)),
                              R.prop('name')
                            )(dropReasons)}
                            "
                          </Typography>
                        )}
                        <ViewDetailsButton
                          onClick={() => {
                            onClickViewDetails(index);
                            setMoveDetails(cohortStudentBelongedToItem);
                          }}
                          style={{ color: indigo[500] }}
                        >
                          <Typography variant="link">
                            {showHistoryItemDetails
                              ? 'Hide details'
                              : 'View details'}
                          </Typography>
                        </ViewDetailsButton>
                      </Box>
                    )}
                  </StepLabel>
                </Step>
              );
            }
          )}
        </Stepper>
      </Box>
      {showDetails && (
        <Card>
          <CardContent style={{ paddingBottom: '17px' }}>
            <Typography variant="h6" gutterBottom>
              Comment
            </Typography>
            <Typography gutterBottom variant="body1" mt="12px">
              {moveDetails.comment ? moveDetails.comment : '--'}
            </Typography>
            {R.hasPath(['surcharge'], moveDetails) && (
              <>
                <CardTypography
                  subtitle="Surcharged move"
                  text={moveDetails.surcharge ? 'Yes' : 'No'}
                />
                {!moveDetails.surcharge && (
                  <CardTypography
                    subtitle="Reason to avoid surcharge"
                    text={R.pipe(
                      R.find(
                        R.propEq('id', moveDetails.avoiding_surcharge_reason)
                      ),
                      R.prop('name')
                    )(avoidingSurchargeReasons)}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default StudentMovesTimeline;

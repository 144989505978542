import React from 'react';
import * as R from 'ramda';

const baseUrl = process.env.REACT_APP_KEYCLOAK_URL;
const realm = process.env.REACT_APP_KEYCLOAK_STUDENTS_REALM;

const KeycloakField = ({ record = {} }) => (
  <a
    href={`${baseUrl}/admin/master/console/#/realms/${realm}/users/${R.path(
      ['external_ids', 'keycloak'],
      record
    )}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {R.path(['external_ids', 'keycloak'], record)}
  </a>
);
KeycloakField.defaultProps = {
  addLabel: true
};

export default KeycloakField;

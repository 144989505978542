import React from 'react';

import { Labeled, TextField } from 'react-admin';
import { Grid } from '@material-ui/core';

import { NiceTextField } from 'resources/common';

const BackToUniveristyFields = ({ student }) => {
  return (
    <Grid container>
      <Grid item xs={12} lg={2}>
        <Labeled label="Studies">
          <NiceTextField
            record={student}
            source="career_services.outcome.studies_type"
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Labeled label="Field Of Study">
          <TextField
            record={student}
            source="career_services.outcome.studies_field"
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default BackToUniveristyFields;

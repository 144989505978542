import React, { useState } from 'react';
import * as R from 'ramda';
import { Link } from 'react-admin';
import { Typography } from '@material-ui/core';

import CopyToClipboard from '../../../resources/common/CopyToClipboard';

import { AvatarField } from './';

const FullNameField = ({
  record,
  data,
  size,
  showAvatar = true,
  showBadge = true,
  link = false,
  copyable = false,
}) => {
  const [showCopy, setShowCopy] = useState(false);

  const student = R.isEmpty(record) ? data : record;
  const fullName = R.pipe(
    R.defaultTo({}),
    R.props(['first_name', 'middle_name', 'last_name']),
    R.reject(R.either(R.isNil, R.isEmpty)),
    R.join(' '),
    R.replace(/(^|[\s-])\S/g, R.toUpper)
  )(student);
  if (link) {
    return (
      <Link
        to={`/students/${student.id}/show`}
        onClick={(event) => event.stopPropagation()}
        onMouseEnter={() => setShowCopy(true)}
        onMouseLeave={() => setShowCopy(false)}
      >
        <div
          style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}
        >
          {showAvatar && (
            <span style={{ display: 'inline-block', marginRight: '10px' }}>
              <AvatarField record={student} size={size} showBadge={showBadge} />
            </span>
          )}
          <Typography component="span" variant="body2">
            {fullName}
          </Typography>
          {copyable && <CopyToClipboard active={showCopy} text={fullName} />}
        </div>
      </Link>
    );
  } else {
    return (
      <div
        onMouseEnter={() => setShowCopy(true)}
        onMouseLeave={() => setShowCopy(false)}
        style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}
      >
        {showAvatar && (
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            <AvatarField record={student} size={size} showBadge={showBadge} />
          </span>
        )}
        <Typography component="span" variant="body2">
          {fullName}
        </Typography>
        {copyable && <CopyToClipboard active={showCopy} text={fullName} />}
      </div>
    );
  }
};

FullNameField.defaultProps = {
  source: 'first_name',
  label: 'Full Name',
};

export default FullNameField;

import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import {
  Box,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  SvgIcon,
  Typography as MuiTypography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Close as MuiSvgIconClock,
  Check as MuiSvgIconCheck,
} from '@material-ui/icons';
import { grey, indigo } from '@material-ui/core/colors';
import { useBackend } from 'resources/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  checkText: { fontSize: '14px', color: indigo[500] },
  cancelText: { fontSize: '14px', color: grey[700] },
});

const Button = withStyles({
  label: { alignItems: 'center' },
  outlined: {
    border: `1px solid ${indigo[500]}`,
    backgroundColor: indigo[500],
    color: 'white',
    '&:hover': {
      backgroundColor: indigo[700],
      color: 'white',
    },
  },
  startIcon: { marginRight: '2px', marginLeft: 0 },
  text: { fontSize: '14px', paddingRight: '8px', paddingLeft: 0 },
})(MuiButton);

const Dialog = withStyles({
  paperWidthSm: {
    width: '682px',
    minWidth: '682px',
    minHeight: '82px',
  },
})(MuiDialog);

const DialogActions = withStyles({
  root: {
    padding: '0 32px 0 0',
    margin: 0,
    position: 'relative',
    bottom: 10,
  },
})(MuiDialogActions);

const DialogContent = withStyles({
  root: {
    padding: 0,
    margin: 0,
  },
})(MuiDialogContent);

const DialogTitle = withStyles({
  root: {
    padding: '32px 32px 0 32px',
  },
})(MuiDialogTitle);

const DialogContentText = withStyles({
  root: {
    padding: '10px 32px 0px',
  },
})(MuiDialogContentText);

const Close = withStyles({
  root: {
    color: grey[700],
  },
})(MuiSvgIconClock);

const Check = withStyles({
  root: {
    color: indigo[500],
  },
})(MuiSvgIconCheck);

const Typography = withStyles({
  body1: { fontSize: '16px', lineHeight: '18.4px' },
  h6: { lineHeight: '24.14px', fontSize: '21px' },
})(MuiTypography);

const ConfirmDialog = (props) => {
  const { isOpen, onClose, onConfirm, isLoading } = props;
  const classes = useStyles();

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(e);
    },
    [onConfirm]
  );

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="682px"
          height="82px"
        >
          <Typography variant="body1">Sending certificates...</Typography>
        </Box>
      ) : (
        <Box height="192px">
          <DialogTitle id="alert-dialog-title" variant="h6">
            <Typography variant="h6">
              Are you sure you want to send the graduation certificate to all
              graduated students?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1">
                This action cannot be undone. Please make sure student’s info
                (name, surname and email) and student’s graduation status are
                correct.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              <Box display="flex" pr="8px" alignItems="center">
                <Close />
              </Box>
              <Typography className={classes.cancelText}>NO, CANCEL</Typography>
            </Button>
            <Button onClick={handleConfirm}>
              <Box display="flex" pr="8px" position="relative" bottom="1px">
                <Check />
              </Box>
              <Typography className={classes.checkText}>YES, SEND</Typography>
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

const CreateStudentsCertificates = (props) => {
  const { data: cohort, onCtaClicked } = props;
  const notify = useNotify();

  const [{ loaded, error, loading }, setCall] = useBackend();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleCreateBulkAccredibleCertificates = () => {
    setCall({
      url: `/cohorts/${cohort.id}/certificates`,
      method: 'POST',
    });
  };

  useEffect(() => {
    if (error) {
      notify(`Something went wrong. Please try again.`, 'warning');
      setConfirmDialogOpen(false);
    }
  }, [error]); //eslint-disable-line

  useEffect(() => {
    if (loaded && !error) {
      notify('Certificates sent', 'success');
      setConfirmDialogOpen(false);
      onCtaClicked();
    }
  }, [error, loaded]); //eslint-disable-line

  const handleClose = useCallback((e) => {
    setConfirmDialogOpen(false);
    e.stopPropagation();
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        startIcon={
          <SvgIcon
            {...props}
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M5.68 11.69 8 9.93l2.31 1.76-.88-2.85L11.75 7H8.91L8 4.19 7.09 7H4.25l2.31 1.84-.88 2.85ZM16 8c0-4.42-3.58-8-8-8S0 3.58 0 8c0 2.03.76 3.87 2 5.28V21l6-2 6 2v-7.72A7.96 7.96 0 0 0 16 8ZM8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6Z"
              fill="#fff"
            />
          </SvgIcon>
        }
        onClick={() => setConfirmDialogOpen(true)}
      >
        SEND CERTS. TO GRADUATED STUDENTS
      </Button>
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onConfirm={handleCreateBulkAccredibleCertificates}
        onClose={handleClose}
        isLoading={loading}
      />
    </>
  );
};

export default CreateStudentsCertificates;

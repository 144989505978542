import * as React from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  AccessTime,
  BusinessCenter,
  Language,
  LinearScale,
  School,
  Today,
  Star,
} from '@material-ui/icons';
import { FilterList } from 'react-admin';

import { authServer } from 'auth/keycloak';
import { FilterDateInput, FilterListItemMulti } from 'resources/common';
import {
  cohortStatus,
  cohortTimezones,
  formats,
  tracksB2b,
  b2bProjectTypes,
} from 'data';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const CohortListAside = () => {
  return (
    <Card>
      <CardContent>
        <FilterList label="Bookmarks" icon={<Star />}>
          <FilterListItemMulti
            key={'Bookmarked'}
            label={'Bookmarked'}
            value={{ bookmarked_by: authServer.getUser() }}
          />
        </FilterList>
        <FilterList label="Status" icon={<LinearScale />}>
          {cohortStatus.map((cohortState) => (
            <FilterListItemMulti
              key={cohortState.name}
              label={cohortState.name}
              value={{ status: cohortState.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Tracks" icon={<School />}>
          {tracksB2b.map((track) => (
            <FilterListItemMulti
              key={track.name}
              label={track.name}
              value={{ track: track.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Format" icon={<AccessTime />}>
          {formats.map((format) => (
            <FilterListItemMulti
              key={format.name}
              label={format.name}
              value={{ format: format.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Project Type" icon={<BusinessCenter />}>
          {b2bProjectTypes.map((type) => (
            <FilterListItemMulti
              key={type.name}
              label={type.name}
              value={{ project_type: type.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Timezones" icon={<Language />}>
          {cohortTimezones.map((tz) => (
            <FilterListItemMulti
              key={tz.name}
              label={tz.name}
              value={{ timezone: tz.id }}
            />
          ))}
        </FilterList>
        <FilterList label="Start Date" icon={<Today />}>
          <FilterDateInput
            label="From"
            source="starts_since"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
          <FilterDateInput
            label="To"
            source="starts_before"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
        </FilterList>
        <FilterList label="End Date" icon={<Today />}>
          <FilterDateInput
            label="From"
            source="ends_since"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
          <FilterDateInput
            label="To"
            source="ends_before"
            options={{ clearable: true, format: 'Do MMMM YYYY' }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default CohortListAside;

import React from 'react';
import * as R from 'ramda';
import { BooleanField, SelectField, TextField } from 'react-admin';

import { surveyFeelings } from 'data';
import { NpsField, ScoreField } from './';

const AnwserField = ({ questionId, answer, hasLegacyWeeklySurveysEnabled }) => {
  switch (questionId) {
    case 'feelings':
      return (
        <SelectField
          record={{ answer }}
          source="answer"
          choices={surveyFeelings}
          optionValue="id"
        />
      );
    case 'prework':
    case 'challenge':
    case 'instruction':
    case 'material':
    case 'lab_preparation':
    case 'instructional_staff':
    case 'ironhack':
    case 'overall_experience':
    case 'ai_confidence':
    case 'v2_learning':
    case 'v2_support':
    case 'v2_tech_support_satisfaction':
    case 'v2_sp_ai':
    case 'v2_campus_experience':
    case 'v2_job_readiness':
    case 'v2_expectations':
      return <ScoreField value={answer} />;
    case 'nps':
      return <NpsField value={answer} />;
    case 'v2_tech_support':
      return <BooleanField record={{ answer }} source="answer" />;
    default:
      return <TextField record={{ answer }} source="answer" />;
  }
};

const QuestionField = ({
  record: answers,
  questionId,
  hasLegacyWeeklySurveysEnabled,
}) => {
  const answer = R.pipe(
    R.propOr([], 'answers'),
    R.find(R.propEq('question', questionId)),
    R.propOr('', 'answer')
  )(answers);
  return (
    <AnwserField
      questionId={questionId}
      answer={answer}
      hasLegacyWeeklySurveysEnabled={hasLegacyWeeklySurveysEnabled}
    />
  );
};

export default QuestionField;

import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const usersProvider = {
  getList: async (params) => {
    const page = params.pagination.page;
    const limit = params.pagination.perPage;
    const query = {
      page,
      limit,
      ...params.filter,
    };
    const url = `${apiUrl}/users?${stringify(query)}`;
    return { url };
  },
  getManyReference: async (params) => {
    const url = `${apiUrl}/realms/employees/users/${params.id}/groups`;
    return { url };
  },
  getOne: async (params) => {
    const url = `${apiUrl}/users/${params.id}`;
    return { url };
  },
  update: async (params) => {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(params.data),
      headers: { 'Content-Type': 'application/json' },
    };
    const url = `${apiUrl}/users/${params.id}`;
    return { url, options };
  },
};

export default usersProvider;

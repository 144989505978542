import React from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import {
  Create,
  Error,
  FormDataConsumer,
  FormWithRedirect,
  Loading,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useGetOne,
  useRedirect,
  useQuery,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

import { authServer } from 'auth/keycloak';
import { campuses, dropReasons, avoidingSurchargeReasons } from 'data';
import { DateInput } from 'resources/common/DatePickers';
import { FullNameField } from 'resources/students/fields';
import { useFormState } from 'react-final-form';

const validateRequired = [required()];

const useStyles = makeStyles({
  radioButtonGroup: {
    '& legend > span': { fontSize: '1.4rem' },
  },
});

const ShouldBeSurcharged = ({ previousMoves }) => {
  const { values } = useFormState();
  const classes = useStyles();

  const previousSurcharges = () =>
    R.any(R.path(['drop', 'surcharge']), previousMoves)
      ? ' and was previously surcharged'
      : '';

  return (
    <>
      <Box mt="16px">
        <RadioButtonGroupInput
          defaultValue={true}
          label={`It will be the ${
            previousMoves.length + 1
          } move${previousSurcharges()}. Should we invoice a surcharged move?`}
          source="surcharge"
          fullWidth
          validate={validateRequired}
          choices={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
          ]}
          className={classes.radioButtonGroup}
        />
      </Box>
      {values.surcharge === false && (
        <SelectInput
          label="Reasons for avoiding surcharge"
          source="avoiding_surcharge_reason"
          choices={avoidingSurchargeReasons}
          validate={validateRequired}
          variant="standard"
          fullWidth
        />
      )}
    </>
  );
};

const MoveCreateForm = (props) => {
  const { student, previousMoves, ...rest } = props;

  return (
    <FormWithRedirect
      {...rest}
      initialValues={{ student: student.id }}
      render={(formProps) => {
        return (
          <form>
            <Box p="16px">
              <FullNameField size={50} record={student} />
              {!student.is_b2b && (
                <SelectInput
                  label="Destination Campus"
                  source="campus"
                  choices={campuses}
                  validate={validateRequired}
                  variant="standard"
                  fullWidth
                />
              )}
              <FormDataConsumer>
                {({ formData }) => (
                  <Box>
                    <ReferenceInput
                      label="Cohort"
                      source="cohort"
                      reference={student.is_b2b ? 'cohorts_b2b' : 'cohorts'}
                      filter={{
                        status: ['ongoing', 'upcoming'],
                        ...(!student.is_b2b && {
                          campus: formData.campus || 'bad',
                        }),
                      }}
                      sort={{ field: 'start_date', order: 'DESC' }}
                      perPage={200}
                      variant="standard"
                      fullWidth
                      disabled={!student.is_b2b && !R.has('campus', formData)}
                    >
                      <SelectInput
                        optionText={(cohort) =>
                          `${cohort.campus.toUpperCase()} ${cohort.track.toUpperCase()} ${cohort.format.toUpperCase()} ${
                            cohort.start_date.split('T')[0]
                          }${
                            cohort.campus === 'rmt'
                              ? ` ${
                                  cohort.timezone
                                } ${cohort.language.toUpperCase()}`
                              : ''
                          }`
                        }
                      />
                    </ReferenceInput>
                  </Box>
                )}
              </FormDataConsumer>
              <SelectInput
                variant="standard"
                label="Reason"
                source="reason"
                choices={dropReasons}
                fullWidth
                validate={validateRequired}
              />
              <TextInput
                variant="standard"
                label="Comment"
                source="comment"
                fullWidth
                validate={validateRequired}
              />
              <DateInput
                label="Effective Date"
                source="date"
                options={{
                  clearable: true,
                  format: 'Do MMMM YYYY',
                  disableFuture: true,
                }}
                fullWidth
                variant="standard"
                validate={validateRequired}
                defaultValue={moment().format('YYYY-MM-DD')}
              />
              {previousMoves.length >= 1 && (
                <ShouldBeSurcharged previousMoves={previousMoves} />
              )}
            </Box>

            <Toolbar>
              {authServer.isRoles(['pm']) && (
                <SaveButton
                  record={formProps.record}
                  basePath={formProps.basePath}
                  undoable={true}
                  invalid={formProps.invalid}
                  saving={formProps.saving}
                  resource="move"
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              )}
            </Toolbar>
          </form>
        );
      }}
    />
  );
};

const MoveCreate = (props) => {
  const redirect = useRedirect();
  const studentId = R.pipe(
    R.pathOr({}, ['location', 'pathname']),
    R.split('/'),
    R.nth(3)
  )(props);

  if (!R.test(/^[a-f\d]{24}$/, studentId)) {
    redirect('/students');
  }

  const {
    data: student,
    loading: studentLoading,
    error: studentError,
  } = useGetOne('students', studentId);

  const {
    data: previousMoves,
    loading: movesLoading,
    error: movesError,
  } = useQuery(
    {
      type: 'getManyReference',
      resource: 'drop',
      payload: {
        search: [
          {
            $match: {
              $and: [
                { 'drop.cohort': { $exists: true } },
                { email: student?.email },
              ],
            },
          },
        ],
        pagination: {
          perPage: 1000,
        },
      },
    },
    { enabled: !R.isNil(student) }
  );

  if (studentLoading || movesLoading) {
    return (
      <Box>
        <Loading />
      </Box>
    );
  }

  if (studentError || movesError) {
    return (
      <Box>
        <Error error={studentError} errorInfo={studentError} />
      </Box>
    );
  }

  if (!student) {
    return null;
  }

  return (
    <Create
      {...props}
      actions={null}
      undoable={false}
      title="Change Student Cohort"
    >
      <MoveCreateForm
        redirect="/"
        student={student}
        previousMoves={previousMoves || []}
      />
    </Create>
  );
};
export default MoveCreate;

import React from 'react';
import * as R from 'ramda';

import { Labeled } from 'react-admin';
import {
  Box,
  Card as MuiCard,
  CardContent,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { RiskCheckpointCriteria } from './';

const Card = withStyles({
  root: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: '#E0E0E0',
    borderRadius: '8px',
    textAlign: 'left',
  },
})(MuiCard);

const RiskLevelDetails = ({ historyItem, isTimelineItem }) => {
  const isCheckpointType = R.propEq('origin', 'action_service', historyItem);
  const { type, details } = R.propOr({}, 'report', historyItem);

  const TeacherDetailsWrapper = ({ children }) =>
    isTimelineItem ? (
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    ) : (
      <Box textAlign="left">{children}</Box>
    );

  return isCheckpointType ? (
    <RiskCheckpointCriteria
      checkpointType={type}
      criteria={R.propOr([], 'criteria', details)}
    />
  ) : (
    <TeacherDetailsWrapper>
      {R.includes(historyItem.level, ['level_1', 'level_2', 'level_3']) && (
        <Labeled label="Difficulty - main issue" fullWidth>
          <Typography variant="body2">
            {R.pipe(R.propOr('--', 'weaknesses'), R.join(', '))(details)}
          </Typography>
        </Labeled>
      )}
      <Labeled label="More details" fullWidth>
        <Typography variant="body2">
          {R.propOr('--', 'notes', details)}
        </Typography>
      </Labeled>
    </TeacherDetailsWrapper>
  );
};

export default RiskLevelDetails;
